<template>
  <div>
    <p class="mt-3 mb-3 has-text-centered">
      Aby pobrać skierowanie z systemu P1 wybierz sposób identyfikacji oraz
      wprowadź dane
    </p>

    <b-field grouped position="is-centered">
      <b-radio-button size="is-small" v-model="type" :native-value="0">ID Skierowania</b-radio-button>
      <b-radio-button size="is-small" v-model="type" :native-value="1">Kod dostępu i PESEL</b-radio-button>
    </b-field>

    <div v-if="type === 0">
      <b-field class="mt-2" label="ID skierowania" label-position="on-border">
        <b-input v-model="queryP1.referralId"></b-input>
      </b-field>
      <ValidationObserver v-if="editPesel" ref="observerOne" v-slot="{ invalid }">
        <b-field expanded>
          <ValidationProvider
            expanded
            mode="eager"
            rules="required|pesel"
            v-slot="{ errors, valid }"
          >
            <b-field
              expanded
              label="PESEL"
              label-position="on-border"
              :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
              :message="t(errors[0])"
            >
              <b-input
                v-model="queryP1.referralPesel"
                @keydown.native.enter="patientSearch"
                expanded
              ></b-input>
            </b-field>
          </ValidationProvider>
          <b-tooltip position="is-left" label="Wyszukaj pacjenta">
            <b-button
              @click="patientSearch"
              :loading="isLoading"
              :disabled="invalid"
              class="control"
            >
              <b-icon icon="magnify" type="is-primary" style="vertical-align: middle"></b-icon>
            </b-button>
          </b-tooltip>
        </b-field>
      </ValidationObserver>
    </div>

    <div v-if="type === 1" class="columns mt-1">
      <div class="column">
        <b-field label="Kod dostepu" label-position="on-border">
          <b-input v-model="queryP1.referralCode"></b-input>
        </b-field>
      </div>
      <div class="column">
        <b-field v-if="!editPesel" label="PESEL" label-position="on-border">
          <b-input :disabled="!editPesel ? true : false" v-model="queryP1.referralPesel"></b-input>
        </b-field>
        <ValidationObserver v-else ref="observerTwo" v-slot="{ invalid }">
          <b-field expanded>
            <ValidationProvider mode="eager" rules="required|pesel" v-slot="{ errors, valid }">
              <b-field
                expanded
                label="PESEL"
                label-position="on-border"
                :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                :message="t(errors[0])"
              >
                <b-input
                  v-model="queryP1.referralPesel"
                  @keydown.native.enter="patientSearch"
                  expanded
                ></b-input>
              </b-field>
            </ValidationProvider>
            <b-tooltip position="is-left" label="Wyszukaj pacjenta">
              <b-button
                @click="patientSearch"
                :loading="isLoading"
                :disabled="invalid"
                class="control"
              >
                <b-icon icon="magnify" type="is-primary" style="vertical-align: middle"></b-icon>
              </b-button>
            </b-tooltip>
          </b-field>
        </ValidationObserver>
      </div>
    </div>

    <div
      class="mt-3 mb-3"
      style="color: red"
      v-if="
        resultP1 &&
        resultP1.errors &&
        (resultP1.eSkierowanieStatus == 4 || resultP1.eSkierowanieStatus == 7)
      "
    >
      <div v-if="resultP1.eSkierowanieStatus == 7">
        Brak uprawnień do pobierania e-skierowania lub błąd konifiguracji użytkownika.
      </div>
      <div v-else>
        <p class="has-text-weight-medium">Błąd:</p>
        <p
          v-for="(item, index) in resultP1.errors"
          :key="index"
        >
          <span v-if="item.errorCode == 'string'">Nieznany błąd</span>
          <span v-else>{{ item.errorMessage }} ({{ item.errorCode }})</span>
        </p>
      </div>
      <AppointmentEreferralPatient :patient="patient" v-if="resultP1ErrorUsed" />
    </div>

    <!-- <div v-if="type === 0">
      <div class="is-size-7 has-text-grey">
        <p>
          10031475333689049402211173037583954310727530,
          10031762246300933346735266709065440559064170,
          10033240128332278189478329462102174892769080,
          10030822863025977930697431788892018500039120,
          10031890871432098868822694081765631157397250
        </p>
      </div>
    </div>-->

    <b-modal
      :active.sync="isConfirmReferralModalActive"
      has-modal-card
      full-screen
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">Potwierdzenie skierowania</p>
        </header>
        <section class="modal-card-body" style="min-height: 400px">
          <b-notification
            v-if="resultP1Errors.length > 0"
            type="is-warning"
            has-icon
            aria-close-label="Close notification"
            role="alert"
            :closable="false"
          >
            <p class="mb-3">Możliwe pomyłki:</p>

            <p v-for="(item, index) in resultP1Errors" :key="index">
              <b>{{item}}</b>
            </p>

            <p class="mt-3">
              lub wystąpił błędny odczyt danych w pobranym e-skierowaniu,
              na wszelki wypadek sprawdź zgodność danych samodzielnie.
            </p>
          </b-notification>

          <p class="mt-3 mb-3 is-size-5 has-text-centered has-text-weight-bold">
            <b-icon icon="alert-circle-outline" style="color: red" class="vam" />
            <span class="ml-3 vam">Skierowanie zrealizowano na NFZ:</span>
            <b-field
              grouped
              class="is-centered vam ml-3"
              style="display: inline-block; vertical-align: middle"
            >
              <b-radio-button
                class="vam"
                size="is-small"
                :disabled="isNFZButtonDisabled"
                v-model="isNFZ"
                :native-value="false"
              >Nie</b-radio-button>
              <b-radio-button
                class="vam"
                size="is-small"
                :disabled="isNFZButtonDisabled"
                v-model="isNFZ"
                :native-value="true"
              >Tak</b-radio-button>
            </b-field>
          </p>

          <div
            class="mt-3 mb-3"
            style="color: red"
            v-if="
              resultP1 &&
              resultP1.errors &&
              (resultP1.eSkierowanieStatus == 4 ||
                resultP1.eSkierowanieStatus == 7)
            "
          >
            <p class="has-text-weight-medium">Błąd:</p>
            <p
              v-for="(item, index) in resultP1.errors"
              :key="index"
            >{{ item.errorMessage }} ({{ item.errorCode }})</p>
          </div>

          <!-- <div v-html="resultP1.eSkierowanie"></div> -->

          <iframe
            v-if="resultP1.eSkierowanie"
            :srcdoc="resultP1.eSkierowanie"
            style="width:100%;height:24rem;"
          ></iframe>
        </section>
        <footer class="modal-card-foot has-background-kin">
          <b-button
            :loading="isLoading"
            :type="confirmReferralButtonType"
            @click="confirmReferral()"
          >{{confirmReferralButtonText}}</b-button>
          <b-button
            :loading="isLoading"
            type="is-orange"
            @click="closeModal()"
          >Rezygnacja z przyjęcia skierowania do realizacji</b-button>
          <b-button
            :loading="isLoading"
            type="is-danger"
            @click="isCancelReferralModalActive = true"
          >Odmowa przyjęcia skierowania do realizacji</b-button>
        </footer>
      </div>
    </b-modal>

    <b-modal
      :active.sync="isCancelReferralModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :can-cancel="false"
    >
      <div class="modal-card">
        <header class="modal-card-head has-background-kin">
          <p class="modal-card-title has-text-light">Odmowa przyjęcia skierowania</p>
        </header>
        <section class="modal-card-body" style="width: auto; min-height: 400px">
          <p class="mt-3 mb-3 is-size-5 has-text-centered has-text-weight-bold">
            <b-field label="Komentarz (wymagany przy odmowie min. 5 znaków)" class="is-centered">
              <b-input type="textarea" v-model="comment" maxlength="1000" />
            </b-field>
          </p>
        </section>
        <footer class="modal-card-foot has-background-kin">
          <b-button
            :loading="isLoading"
            type="is-danger"
            @click="cancelReferral()"
            :disabled="comment && comment.length < 5"
          >Odmowa przyjęcia skierowania do realizacji</b-button>
          <b-button :loading="isLoading" @click="isCancelReferralModalActive = false">Anuluj</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import { Action } from "../../store/config_actions";
import { Mutation } from "../../store/config_mutations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppointmentEreferralPatient from "@/components/appointment-ereferral/AppointmentEreferralPatient"

export default {
  name: "AppointmentAddVisitReferralP1",
  mixins: [CommonHelpers],
  components: {
    ValidationProvider, ValidationObserver,
    AppointmentEreferralPatient,
  },

  props: {
    trigger: { type: Number, required: true },
    officeId: { type: String, required: false },
    editPesel: { type: Boolean, required: false, default: false },
    appointmentType: { type: Object, required: false, default: null },
    registrationData: { type: Object, required: false, default: null },
    patientProp: { type: Object, required: false, default: null },
  },

  data: function () {
    return {
      interval: null,
      intervalConfirm: null,
      intervalCancel: null,
      timeout: null,
      timeoutConfirm: null,
      timeoutCancel: null,
      office: null,
      subunit: null,
      isLoading: false,
      type: 1,
      officeLoading: false,
      subunitLoading: false,
      queryP1: {
        referralId: "",
        referralCode: "",
        referralPesel: "",
      },

      requestP1ver2: {
        eSkierowanieDataRequest: {
          id: "string",
          domain: "string",
          patientId: "",
          eSkierowanieDataAction: 3,
          eSkierowanieStatus: 0,
          eSkierowanieStatusChange: "2021-04-28T22:05:16.417Z",
          workerGuid: "ADM",
          domainId: "string",
          p1PatientData: {
            pesel: "string",
            country: "string",
            zip: "string",
            city: "string",
            street: "string",
            housenumber: "string",
            apartmentnumber: "string",
            simc: "string",
            firstname: "string",
            secondname: "string",
            lastname: "string",
            gender: 0,
            birthdate: "string",
            uprawnieniaDodatkowe: [
              {
                code: "string",
                displayName: "string",
                codeSystem: "string",
              },
            ],
            ewusCode: "string",
            nfZ_code: "string",
            isEwus: true,
          },
          eSkierowanieData: {
            guid: "string",
            data: "2021-04-28T22:05:16.417Z",
            typSkierowania: {
              code: "string",
              description: "string",
            },
            icD10Item: {
              code: "string",
              description: "string",
            },
            iCD9Items: [
              {
                code: "string",
                description: "string",
              },
            ],
            documentItems: [
              {
                description: "string",
                dateTime: "2021-04-28T22:05:16.417Z",
                isLabResult: true,
              },
            ],
            siteInfo: {
              code: "string",
              displayName: "string",
            },
            comments: "string",
            isCito: true,
            numerSkierowania: "string",
            numerZbioru: "string",
            oldNumerSkierowania: "string",
          },
          comment: "",
          isNFZ: false,
          kodSkierowania: "",
          kluczSkierowania: "string",
          numerSkierowania: "string",
          authorUnitCode: "string",
          errors: [
            {
              errorCode: "string",
              errorMessage: "string",
              errorOrigin: "string",
            },
          ],
          version: "string",
        },
      },

      resultP1Confirm: null,
      resultP1Get: null,

      resultP1: {
        id: "string",
        workerGuid: "ADM",
        domainId: "string",
        eSkierowanieStatus: 0,
        eSkierowanieStatusChange: "2021-04-28T22:16:45.567Z",
        eSkierowanieKod: "string",
        eSkierowanieKlucz: "string",
        eSkierowanieNumer: "string",
        eSkierowanie: "string",
        authorUnitCode: "string",
        errors: [
          {
            errorCode: "string",
            errorMessage: "string",
            errorOrigin: "string",
          },
        ],
      },

      isNFZ: false,
      isConfirmReferralModalActive: false,
      isCancelReferralModalActive: false,
      comment: "",

      patientPeselSearch: "",
    };
  },

  mounted() {
    if (this.officeId) {
      this.getOffice();
    }

    if (this.patientPesel) {
      this.queryP1.referralPesel = this.patientPesel
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeoutCancel);
    clearTimeout(this.timeoutConfirm);

    clearInterval(this.interval);
    clearInterval(this.intervalConfirm);
    clearInterval(this.intervalCancel);
  },

  watch: {
    officeId(val) {
      if (val) {
        this.getOffice();
      } else {
        this.subunit = null;
        this.office = null;
      }
    },

    "patient.pesel": function () {
      if (this.patientPesel) {
        this.queryP1.referralPesel = this.patientPesel
      }
    },

    "queryP1.referralId": function () {
      this.$emit("code-change", this.queryP1);
    },

    "queryP1.referralCode": function () {
      this.$emit("code-change", this.queryP1);
    },

    "queryP1.referralPesel": function () {
      this.$emit("code-change", this.queryP1);
      if (this.editPesel) {
        if (
          this.queryP1.referralPesel.length === 11
        ) {
          this.patientSearch();
        } else {
          this.patientSearchReset();
        }
      }
    },

    trigger() {
      if (this.trigger > 0) {
        this.getReferralP1();
      }
    },
  },

  computed: {
    subunits() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    offices() { 
      return this.$store.state.offices.offices;
    },

    me() {
      return this.$store.state.employee.me;
    },

    patient() {
      if (this.patientProp) {
        return this.patientProp
      }

      return this.$store.state.registration.patient;
    },

    patientPesel() {
      if (this.patient) {
        return this.patient.pesel
      } else return null
    },

    resultHasPatientPesel() {
      if (this.resultP1 && this.resultP1.patientData) {
        if (this.patientPesel) {
          return this.resultP1.patientData.pesel == this.patientPesel
        } else return false
      } return false
    },

    resultOfficeDifference() {
      if (this.office && this.resultP1 && this.resultP1.eSkierowanieData && this.resultP1.eSkierowanieData.destinationClinic) {
        if (this.office.clinicId != this.resultP1.eSkierowanieData.destinationClinic) {
          return true
        }
      }

      return false
    },

    resultP1Errors() {
      let errorsText = []

      if (!this.resultHasPatientPesel) {
        errorsText.push("Odczytany z e-skierowania PESEL różni się od danych pacjenta w systemie")
      }

      if (this.resultOfficeDifference) {
        errorsText.push("Odczytana z e-skierowania poradnia różni się od wybranej w systemie")
      }

      return errorsText
    },

    confirmReferralButtonType() {
      if (this.resultP1Errors.length > 0) {
        return "is-warning"
      }

      return "is-success"
    },

    confirmReferralButtonText() {
      if (this.resultP1Errors.length > 0) {
        return "Przyjęcie skierowania do realizacji (pomimo ostrzeżenia)"
      }

      return "Przyjęcie skierowania do realizacji"

    },

    isNFZButtonDisabled() {
      if (this.appointmentType) {
        let payers = this.appointmentType.payers
        if (payers) {
          if (payers.length == 1) {
            return true
          }
        }
      }

      return false
    },

    isNFZValue() {
      if (this.appointmentType) {
        let payers = this.appointmentType.payers
        if (payers) {
          if (payers.length == 1) {
            if (payers.includes(1)) {
              return true
            } else if (payers.includes(0) || payers.includes(2)) {
              return false
            }
          }
        }
      }

      return false
    },

    resultP1ErrorUsed() {
      if (this.resultP1 && this.resultP1.errors) {
        let text = "Aktualny status obiektu nie pozwala na wykonanie operacji."
        let findObject = this.resultP1.errors.find((obj) => obj.errorMessage == text)
        if (findObject) {
          return true
        } else return false
      } else return false
    }
  },

  methods: {
    getReferralP1() {
      this.comment = "";

      if(this.registrationData) {
        this.requestP1ver2.eSkierowanieDataRequest.registrationData = this.registrationData
      }

      if (this.type === 0) {
        this.requestP1ver2.eSkierowanieDataRequest.eSkierowanieDataAction = 3;
        this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania =
          this.queryP1.referralId;
      } else if (this.type === 1) {
        this.requestP1ver2.eSkierowanieDataRequest.eSkierowanieDataAction = 4;
        this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania =
          "" + this.queryP1.referralCode + this.queryP1.referralPesel;
      }

      if (this.subunit && this.subunit.departmentalCodes) {
        let authorData = {
          specs: this.subunit.name,
          specsCode: this.subunit.departmentalCodes.identifierCode7th,
          data: this.office.name,
        };

        this.requestP1ver2.eSkierowanieDataRequest.authorData = authorData;
        this.requestP1ver2.eSkierowanieDataRequest.subDomain = this.subunit.departmentalCodes.identifierCode7th;
      } else {
        this.requestP1ver2.eSkierowanieDataRequest.authorData = null;
      }

      this.requestP1ver2.eSkierowanieDataRequest.workerGuid = this.me.id.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domainId = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domain = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.patientId = this.patient.domainId + "-" + this.patient.id

      this.successSnackbar("Pobieranie z P1");
      this.isLoading = true;

      this.$store
        .dispatch(Action.TPK_REFERRAL_SEND, this.requestP1ver2)
        .then((response) => {
          this.resultP1.id = response;

          this.resultP1.workerGuid = this.me.id.toString();
          this.resultP1.domainId = this.me.domainId.toString();

          clearInterval(this.interval);

          this.interval = setInterval(() => {
            this.getReferral();
          }, 4000);

          this.timeout = setTimeout(() => {
            clearInterval(this.interval);
            //this.$emit('eReferral-failure')
            this.errorP1StopDownload();
          }, 600000);
        })
        .catch((error) => {
          this.$emit("eReferral-failure");
          this.apiProblem(error);
          this.isLoading = false;
          clearInterval(this.interval);
        });
    },

    errorP1StopDownload() {
      if (
        this.resultP1.eSkierowanieStatus != 3 &&
        this.resultP1.eSkierowanieStatus != 4 &&
        this.resultP1.eSkierowanieStatus != 7
      ) {
        this.dangerSnackbar("Błąd P1");
        //this.$emit('eReferral-failure')
        this.isLoading = false;
      } else {
        //do nothing
      }

      this.$emit("eReferral-failure");
    },

    getReferral() {
      this.$store
        .dispatch(Action.TPK_REFERRAL_GET, this.resultP1)
        .then((responseReferral) => {
          this.isLoading = false;
          this.resultP1 = responseReferral;
          if (this.resultP1.eSkierowanieStatus == 3) {
            clearInterval(this.interval);
            this.isConfirmReferralModalActive = true;
            this.isNFZ = this.isNFZValue

            this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania =
              this.resultP1.eSkierowanieKlucz;
            this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania =
              this.resultP1.eSkierowanieKlucz;

            this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = this.resultP1.authorUnitCode;

            this.resultP1Get = JSON.parse(JSON.stringify(this.resultP1));

            this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, true);
            this.$store.commit(Mutation.REGISTRATION_ADD_EREFERRAL, responseReferral)


            clearTimeout(this.timeout);
          } else if (
            this.resultP1.eSkierowanieStatus == 4 ||
            this.resultP1.eSkierowanieStatus == 7
          ) {
            this.dangerSnackbar("Błąd pobierania skierowania");
            this.$emit("eReferral-failure");
            clearInterval(this.interval);
            clearTimeout(this.timeout);
          }
        })
        .catch((error) => {
          this.apiProblem(error);
          this.$emit("eReferral-failure");
          this.isLoading = false;
          clearInterval(this.interval);
        });
    },

    getReferralConfirm() {
      this.$store
        .dispatch(Action.TPK_REFERRAL_GET, this.resultP1)
        .then((responseReferral) => {
          this.resultP1 = responseReferral;
          this.resultP1.acceptedId = responseReferral.id;
          this.resultP1.isNFZ = this.isNFZ;
          this.resultP1.eSkierowanie = this.resultP1Get.eSkierowanie;
          if (this.resultP1.eSkierowanieStatus == 3) {
            clearInterval(this.intervalConfirm);
            clearTimeout(this.timeoutConfirm);
            this.successSnackbar("Potwierdzono skierowanie");
            this.isLoading = false;
            this.isConfirmReferralModalActive = false;
            this.resultP1Get.isNFZ = this.isNFZ;
            this.resultP1Get.acceptedId = responseReferral.id;
            this.resultP1Get.statusSkierowaniaP1 =
              responseReferral.statusSkierowaniaP1;
            this.$emit("eReferral-success", this.resultP1Get);
            this.$emit("eReferral-confirm");
            this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = "";
            this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania =
              "string";
            this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = "string"

            this.$store.commit(Mutation.REGISTRATION_ADD_EREFERRAL, responseReferral)
          } else if (
            this.resultP1.eSkierowanieStatus == 4 ||
            this.resultP1.eSkierowanieStatus == 7
          ) {
            this.dangerSnackbar("Błąd pobierania skierowania");
            this.isLoading = false;
            this.$emit("eReferral-failure");
            clearInterval(this.intervalConfirm);
            clearTimeout(this.timeoutConfirm);
          }
        })
        .catch((error) => {
          this.$emit("eReferral-failure");
          this.apiProblem(error);
          this.isLoading = false;
          clearInterval(this.intervalConfirm);
          clearTimeout(this.timeoutConfirm);
        });
    },

    getOffice() {
      if(this.offices && this.offices.length > 0) {
        let office = this.offices.find(office => office.id === this.officeId)
        if(office) {
          this.office = office
          this.officeLoading = false
          if(office.subUnitId) {
            this.getSubunit(office.subUnitId)
          }
          return
        }
      }

      this.officeLoading = true;

      this.$store
        .dispatch(Action.OFFICE_GET_OFFICE, this.officeId)
        .then((response) => {
          this.office = response;
          this.officeLoading = false;

          if (response.subUnitId) {
            this.getSubunit(response.subUnitId);
          }
        })
        .catch((error) => {
          this.officeLoading = false;
          this.apiProblem(error);
        });
    },

    getSubunit(id) {
      if(this.subunits && this.subunits.length > 0) {
        let subunit = this.subunits.find(sub => sub.id === id)
        if(subunit) {
          this.subunit = subunit
          this.subunitLoading = false
          return
        }
      }

      this.subunitLoading = true

      this.$store
        .dispatch(Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID, id)
        .then((response) => {
          this.subunitLoading = false
          this.subunit = response;
        })
        .catch((error) => {
          this.subunitLoading = false
          this.apiProblem(error);
        });
    },

    confirmReferral() {
      this.requestP1ver2.eSkierowanieDataRequest.eSkierowanieDataAction = 5;
      this.requestP1ver2.eSkierowanieDataRequest.workerGuid = this.me.id.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domainId = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domain = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.patientId = this.patient.domainId + "-" + this.patient.id
      this.requestP1ver2.eSkierowanieDataRequest.isNFZ = this.isNFZ;
      // this.requestP1ver2.eSkierowanieDataRequest.comment = this.comment;
      this.isLoading = true;

      if (this.subunit && this.subunit.departmentalCodes) {
        let authorData = {
          specs: this.subunit.name,
          specsCode: this.subunit.departmentalCodes.identifierCode7th,
          data: this.office.name,
        };

        this.requestP1ver2.eSkierowanieDataRequest.authorData = authorData;
        this.requestP1ver2.eSkierowanieDataRequest.subDomain = this.subunit.departmentalCodes.identifierCode7th;
      } else {
        this.requestP1ver2.eSkierowanieDataRequest.authorData = null;
      }

      this.$store
        .dispatch(Action.TPK_REFERRAL_SEND, this.requestP1ver2)
        .then((response) => {
          this.resultP1.id = response;
          this.resultP1.workerGuid = this.me.id.toString();
          this.resultP1.domainId = this.me.domainId.toString();

          clearInterval(this.intervalConfirm);
          this.intervalConfirm = setInterval(
            () => this.getReferralConfirm(),
            4000
          );

          clearTimeout(this.timeoutConfirm);

          this.timeoutConfirm = setTimeout(() => {
            clearInterval(this.intervalConfirm);
            this.errorP1StopDownload();
          }, 600000);
        })
        .catch((error) => {
          this.apiProblem(error);
          this.isLoading = false;
          clearInterval(this.intervalConfirm);
          clearTimeout(this.timeoutConfirm);
        });
    },

    getReferralCancel() {
      this.$store
        .dispatch(Action.TPK_REFERRAL_GET, this.resultP1)
        .then((responseReferral) => {
          this.resultP1 = responseReferral;
          this.resultP1.eSkierowanie = this.resultP1Get.eSkierowanie;
          if (this.resultP1.eSkierowanieStatus == 3) {
            clearInterval(this.intervalCancel);
            clearTimeout(this.timeoutCancel)
            this.successSnackbar("Anulowano skierowanie");
            this.isLoading = false;
            this.isConfirmReferralModalActive = false;
            this.isCancelReferralModalActive = false;
            this.$emit("eReferral-failure");

            this.$emit("eReferral-refuse");

            this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false);
            this.$store.commit(Mutation.REGISTRATION_ADD_EREFERRAL, responseReferral)

            this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = "";
            this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania =
              "string";
            this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = "string"
          } else if (
            this.resultP1.eSkierowanieStatus == 4 ||
            this.resultP1.eSkierowanieStatus == 7
          ) {
            this.dangerSnackbar("Błąd pobierania skierowania");
            this.isLoading = false;
            this.$emit("eReferral-failure");
            clearInterval(this.intervalCancel);
            clearTimeout(this.timeoutCancel)
          }
        })
        .catch((error) => {
          this.$emit("eReferral-failure");
          this.apiProblem(error);
          this.isLoading = false;
          clearInterval(this.intervalCancel);
          clearTimeout(this.timeoutCancel)
        });
    },

    cancelReferral() {
      this.requestP1ver2.eSkierowanieDataRequest.eSkierowanieDataAction = 8;
      this.requestP1ver2.eSkierowanieDataRequest.workerGuid = this.me.id.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domainId = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.domain = this.me.domainId.toString();
      this.requestP1ver2.eSkierowanieDataRequest.patientId = this.patient.domainId + "-" + this.patient.id
      this.requestP1ver2.eSkierowanieDataRequest.comment = this.comment;
      this.isLoading = true;

      if (this.subunit && this.subunit.departmentalCodes) {
        let authorData = {
          specs: this.subunit.name,
          specsCode: this.subunit.departmentalCodes.identifierCode7th,
          data: this.office.name,
        };

        this.requestP1ver2.eSkierowanieDataRequest.authorData = authorData;
        this.requestP1ver2.eSkierowanieDataRequest.subDomain = this.subunit.departmentalCodes.identifierCode7th;
      } else {
        this.requestP1ver2.eSkierowanieDataRequest.authorData = null;
      }

      this.$store
        .dispatch(Action.TPK_REFERRAL_SEND, this.requestP1ver2)
        .then((response) => {
          this.resultP1.id = response;
          this.resultP1.workerGuid = this.me.id.toString();
          this.resultP1.domainId = this.me.domainId.toString();

          clearInterval(this.intervalCancel);
          this.intervalCancel = setInterval(
            () => this.getReferralCancel(),
            4000
          );

          clearTimeout(this.timeoutCancel)

          this.timeoutCancel = setTimeout(() => {
            clearInterval(this.intervalCancel);
            this.errorP1StopDownload();
          }, 600000);
        })
        .catch((error) => {
          this.apiProblem(error);
          this.isLoading = false;
          clearInterval(this.intervalCancel);
          clearTimeout(this.timeoutCancel)
        });
    },

    closeModal() {
      this.isConfirmReferralModalActive = false;
      this.$emit("eReferral-failure");
      this.$emit("eReferral-resign");
      this.isLoading = false;
      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false);

      this.resultP1 = {
        id: "string",
        workerGuid: "ADM",
        domainId: "string",
        eSkierowanieStatus: 0,
        eSkierowanieStatusChange: "2021-04-28T22:16:45.567Z",
        eSkierowanieKod: "string",
        eSkierowanieKlucz: "string",
        eSkierowanieNumer: "string",
        eSkierowanie: "string",
        authorUnitCode: "string",
        errors: [
          {
            errorCode: "string",
            errorMessage: "string",
            errorOrigin: "string",
          },
        ],
      }

      this.requestP1ver2.eSkierowanieDataRequest.kodSkierowania = "";
      this.requestP1ver2.eSkierowanieDataRequest.kluczSkierowania = "string";
      this.requestP1ver2.eSkierowanieDataRequest.authorUnitCode = "string";
      clearTimeout(this.timeout)
      clearTimeout(this.timeoutCancel)
      clearTimeout(this.timeoutConfirm)
    },

    patientSearch() {
      this.isLoading = true;
      this.$store.commit(Mutation.REGISTRATION_RESET);
      this.$store
        .dispatch(Action.PATIENT_GET_BY_PESEL, this.queryP1.referralPesel)
        .then((response) => {
          if (response.data) {
            this.$store.commit(
              Mutation.REGISTRATION_SET_PATIENT,
              response.data
            );
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.isLoading = false;
        });
    },

    patientSearchReset() {
      this.$store.commit(Mutation.REGISTRATION_RESET);
    },
  },
};
</script>

<style scoped lang="scss">
.vam {
  vertical-align: middle;
}
</style>
