import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import { Apis } from '../config_apis'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import { blockObserverMethod } from '@/mixins/blockObserver'

//import icd_categories from '../../assets/icd10_cat.json'
//import icd_full from '../../assets/icd10_full_updated.json'

export default {
    namespaced: false,

    state: {
        assistants: [],
        currentExaminations: [],
        tmpData: [],

        icdGeneral: [],// icd_categories,
        icd10: [], //icd_full,

        icds9: [],
        ticketsToSettle: [],

        appointments: [],
        appointmentsTranslatedDashboardPatient: [],
        appointmentsTranslatedDashboard: [],
        patients: [],
        ongoingAppointment: null,
        focusAppointment: null,
        focusTranslatedAppointment: null,
        focusBlock: null,
        appointmentHistory: [],
        appointmentDiagnoses: { diagnoses: [] },
        appointmentAllergies: null,
        paths: null,

        nfzInfo: null,

        documents: [],
        tickets: [],
        referralTickets: [],
        incidents: [],

        //PATIENT_PORTAL
        patientDiagnoses: null,
        patientAppointments: null,

        empReferrals: [],
        employeeReferrals: [],


        facility: null,
        chosenServices: [],
        chosenServicesIcds: [],

        servicesFromTicket: [],
        servicesFromTicketTrigger: 0,

        treatmentDecision: 2,

        drugsTableTrigger: 0,

        ///////////////////////////////////////////////////////

        trigger: 0,

        triggerMedQuestionsPrint: 0,

        // PRESCRIPTIONS
        prescriptions: [],
        tmpPrescriptions: [],
        cancelledPrescriptions: [],
        paperPrescriptions: [],
        cancelledPaperPrescriptions: [],

        prescriptionsHistoryP1: null,
        prescriptionsHistoryP1Request: null,
        prescriptionsHistoryP1Loading: false,

        // REFERRALS
        referrals: [],
        tmpReferrals: [],
        cancelledReferrals: [],
        customReferrals: [],
        cancelledCustomReferrals: [],

        // EXEMPTIONS
        exemptions: [],
        tmpExemptions: [],
        cancelledExemptions: [],

        // ATTESTATIONS
        attestations: [],
        tmpAttestations: [],
        cancelledAttestations: [],

        // ORDERS
        orders: [],
        tmpOrders: [],
        cancelledOrders: [],
        drugsForOrders: [],
        drugForOrder: null,
        triggerForOrders: 0,
        triggerForDrugOrders: 0,
        triggerForPrescriptions: 0,

        drugsForPrescription: [],

        // REPORTS
        reports: [],
        tmpReports: [],
        cancelledReports: [],

        //EDM
        finishAppointmentObject: null,
        p12AppointmentObject: null,
        scheduleAppointmentObject: null,

        //ZM
        resultZmObject: null,
        requestZmObject: null,

        //IPOM
        ipomResultObject: null,
        ipomErrorObject: null,
        hipomResultObject: null,
        hipomErrorObject: null,
        ipomDrugs: [],

        ipomConsultationLimited: false,

        //DILO
        diloInfo: null,

        //INFORMATION TO DOCTOR
        informationDoctor: null,
        informationDoctorFinish: null,
        informationDoctorRequest: null,

        // TMP VISIT
        tmpVisitData: null,
        tmpVisitDataTrigger: 0,

        focusArea: null,

        // IKZ
        ikz: [],

        drugInfo: null,
        drugPermissions: [],


        umxContract: null,
        umxContracts: [],
        umxContractLoading: false,

        medicalServices: [],

        // officeNurse - for nurse dashboard
        // Used to get nurse appointments for selected facility
        officeNurse: null,
        officeDietician: null,

        officeMidwife: null,

        officeAppointment: null,

        medicalResultsDocumentation: [],

        showEndedFilters: null,

        patientExaminations: [],

        //Correction visit
        isVisitCorrecting: false,
        lastEndApp: null,
        isDiagnosesFromOld: false,
        diagnosisFromEdm: null,
        controlNumberOngoing: null,
        isReediting: false,
        correctionReason: null,
        correctionReasons: [],
        correctionUmxTrigger: 0,


        // Program visit
        programId: null,

        // Stratification group
        stratificationGroup: null,
        patientVersionStrat: null,

        // Visit types
        visitTypes: [],
        visitSubtypes: [],

        // Med questions templates id
        medQuestionsTemplateId: [],
        triggerMedQuestionsTemplate: 0,

        medQuestionsIcd9: [],

        historyIcdToAdd: null,
        historyIcdTrigger: 0,

        leadingIcdToAdd: null,
        leadingIcdTrigger: 0,

        saveVisitTrigger: 0,
        exitVisitTrigger: 0,

        tmpVisitLastSaveDate: null,
        tmpVisitSaveCounter: 0,
        tmpVisitSaveLoading: false,
        tmpVisitExitLoading: false,

        allQuestions: [],

        drugsAddedOnVisit: false,
        drugsAddedOnVisitDetails: [],

        patientDoctorNotes: [],

        // Dashboard loading prop, set for securing begin appointment method / patients list in Dashboard comps
        dashboardLoading: false,

        continuousDiagnosesSummary: true,

        ongoingRis: null,

        resultsImmunizationAppointment: [],
        resultsImmunizationQualificationAppointment: [],

        appointmentPatientIdentificationInfo: null,

        lastInsuranceIdentificationInfo: null,
        insuranceIdentificationInfoLoading: false,

        appointmentIKZ: [],

        measurementsLastUsedInAppointment: null,
    },

    getters: {
        appointmentDiagnosesSorted(state) {
            let toSorted = []
            if (state.appointmentDiagnoses.diagnoses) {
                toSorted = [...state.appointmentDiagnoses.diagnoses]

                toSorted.sort((a, b) => {
                    if (a.indexSort < b.indexSort) {
                        return -1
                    } else if (a.indexSort > b.indexSort) {
                        return 1
                    }

                    return 0
                })

                toSorted.sort((a, b) => {
                    if (Number(a.fromBefore) < Number(b.fromBefore)) {
                        return -1
                    } else if (Number(a.fromBefore) > Number(b.fromBefore)) {
                        return 1
                    }

                    return 0
                })

                return toSorted
            } else {
                return []
            }
        },

        appointmentDiagnosesFromBefore(state) {
            if (state.appointmentDiagnoses.diagnoses) {
                return state.appointmentDiagnoses.diagnoses.filter(diagnose => diagnose.fromBefore)
            } else {
                return []
            }
        },

        appointmentDiagnosesPresent(state) {
            if (state.appointmentDiagnoses.diagnoses) {
                return state.appointmentDiagnoses.diagnoses.filter(diagnose => !diagnose.fromBefore)
            } else {
                return []
            }
        },

        leadingIcdSelected(state) {
            if (state.appointmentDiagnoses.diagnoses) {
                if (state.appointmentDiagnoses.diagnoses.find(diagnose => diagnose.isLeading)) {
                    return true
                }
            }
            return false
        }
    },

    mutations: {
        [Mutation.POZ_SET_DASHBOARD_LOADING](currentState, payload) {
            currentState.dashboardLoading = payload
        },

        [Mutation.POZ_SET_ALL_UMX_CONTRACTS](currentState, payload) {
            currentState.umxContracts = payload
            if (payload && payload.length > 0) {
                currentState.umxContract = payload[0]
            }
        },

        [Mutation.NFZ_APPOINTMENT_INFO_UPDATE](currentState, payload) {
            currentState.nfzInfo = payload
        },

        [Mutation.POZ_SET_UMX_CONTRACT](currentState, payload) {
            currentState.umxContract = payload
        },

        [Mutation.POZ_SET_UMX_CONTRACT_LOADING](currentState, payload) {
            currentState.umxContractLoading = payload
        },

        [Mutation.POZ_SET_FACILITY](currentState, payload) {
            currentState.facility = payload
        },

        [Mutation.POZ_SET_OFFICE_NURSE](currentState, payload) {
            currentState.officeNurse = payload
        },

        [Mutation.POZ_SET_OFFICE_DIETICIAN](currentState, payload) {
            currentState.officeDietician = payload
        },

        [Mutation.POZ_SET_OFFICE_MIDWIFE](currentState, payload) {
            currentState.officeMidwife = payload
        },

        [Mutation.POZ_SET_OFFICE_APPOINTMENT](currentState, payload) {
            currentState.officeAppointment = payload
        },

        [Mutation.POZ_CLEAR_OFFICE_MIDWIFE](currentState) {
            currentState.officeMidwife = null
        },

        [Mutation.POZ_SET_TREATMENT_DECISION](currentState, payload) {
            currentState.treatmentDecision = payload
        },

        [Mutation.POZ_SET_CHOSEN_SERVICES](currentState, payload) {

            if (payload) {
                payload.forEach(x => {
                    if (x.icd9) {
                        // nice
                    }
                    else {
                        x.icd9 = []
                    }

                    if (x.icds) {
                        // cool
                    }
                    else {
                        x.icds = []
                    }

                    if (x.showAllIcds) {
                        // ok
                    }
                    else {
                        x.showAllIcds = false
                    }
                });
            }

            currentState.chosenServices = payload
        },

        [Mutation.POZ_PUSH_CHOSEN_SERVICES](currentState, x) {

            if (x) {
                if (x.icds) {
                    // cool
                }
                else {
                    x.icds = []
                }

                if (x.showAllIcds) {
                    // ok
                }
                else {
                    x.showAllIcds = false
                }

                currentState.chosenServices.push(x)
            }
        },

        [Mutation.POZ_REMOVE_CHOSEN_SERVICES](currentState, payload) {
            let index = currentState.chosenServices.indexOf(payload)

            if (index > -1) {
                currentState.chosenServices.splice(index, 1)
            }
        },

        [Mutation.POZ_SET_CHOSEN_SERVICES_REPORTED_APPOINTMENT](currentState, payload) {
            if (payload.service) {
                let index = currentState.chosenServices.indexOf(payload.service)
                if (index > -1) {
                    let service = currentState.chosenServices[index]
                    Vue.set(service, 'reportedAppointment', payload.reportedAppointment)
                    Vue.set(service, 'addedBundle', payload.addedBundle)
                }
            }
        },

        [Mutation.POZ_PUSH_CHOSEN_SERVICES_ICD9](currentState, payload) {
            if (payload.service) {
                let index = currentState.chosenServices.indexOf(payload.service)
                if (index > -1) {
                    let service = currentState.chosenServices[index]

                    if (service.icd9 == null) {
                        service.icd9 = []
                    }

                    service.icd9.push(payload.icd9)
                }
            }
        },

        [Mutation.POZ_REMOVE_CHOSEN_SERVICES_ICD9](currentState, payload) {
            if (payload.service) {
                let index = currentState.chosenServices.indexOf(payload.service)
                if (index > -1) {
                    let service = currentState.chosenServices[index]
                    service.icd9.splice(payload.icd9Index, 1)
                }
            }
        },

        [Mutation.POZ_SET_CHOSEN_SERVICES_ICDS](currentState, payload) {

            if (payload) {
                payload.forEach(element => {
                    if (element.icds) {
                        // cool
                    }
                    else {
                        element.icds = []
                    }

                    if (element.showAllIcds) {
                        // ok
                    }
                    else {
                        element.showAllIcds = false
                    }
                });
            }

            currentState.chosenServicesIcds = payload
        },

        [Mutation.POZ_PUSH_SERVICE_TO_ADD](currentState, payload) {
            currentState.servicesFromTicket = payload
            currentState.servicesFromTicketTrigger += 1
        },

        [Mutation.POZ_TRIGGER_UP](currentState) {
            currentState.trigger++
        },

        [Mutation.POZ_TRIGGER_MED_QUESTIONS_PRINT_UP](currentState) {
            currentState.triggerMedQuestionsPrint++
        },

        [Mutation.POZ_TRIGGER_ORDERS_UP](currentState, payload) {
            currentState.triggerForOrders++
            currentState.drugForOrder = payload
        },

        [Mutation.POZ_TRIGGER_DRUG_ORDERS_UP](currentState, payload) {
            currentState.triggerForDrugOrders++
            currentState.drugForOrder = payload
        },

        [Mutation.POZ_TRIGGER_PRESCRIPTIONS_UP](currentState) {
            currentState.triggerForPrescriptions++
        },

        [Mutation.POZ_ADD_DRUG_PRESCRIPTION](currentState, payload) {
            currentState.drugsForPrescription.push(payload)
        },

        [Mutation.POZ_TMP_VISIT_DATA_TRIGGER_UP](currentState) {
            currentState.tmpVisitDataTrigger++
        },

        [Mutation.POZ_UMX_VISIT_TRIGGER_UP](currentState) {
            currentState.correctionUmxTrigger++
        },

        [Mutation.POZ_SET_TMP_VISIT_DATA](currentState, payload) {
            currentState.tmpVisitData = blockObserverMethod(payload)
        },

        [Mutation.POZ_ADD_ICD9](currentState, payload) {
            currentState.icds9.push(payload)
        },

        [Mutation.POZ_ADD_TICKET_TO_SETTLE](currentState, payload) {
            currentState.ticketsToSettle.push(payload)
        },

        [Mutation.POZ_REMOVE_TICKET_FROM_SETTLE](currentState, payload) {
            let index = currentState.ticketsToSettle.indexOf(payload)

            if (index > -1) {
                currentState.ticketsToSettle.splice(index, 1)
            }
        },

        //******************** REFERRALS ********************/

        [Mutation.POZ_ADD_TMP_REFERRAL](currentState, payload) {
            currentState.tmpReferrals.push(payload)
        },

        [Mutation.POZ_UPDATE_TMP_REFERRAL](currentState, payload) {
            currentState.tmpReferrals.splice(payload.index, 1, payload.referral)
        },

        [Mutation.POZ_TMP_REMOVE_REFERRAL](currentState, payload) {
            let index = currentState.tmpReferrals.indexOf(payload)

            if (index > -1) {
                currentState.tmpReferrals.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_REFERRAL](currentState, payload) {
            currentState.referrals.push(payload)
        },

        [Mutation.POZ_ADD_CUSTOM_REFERRAL](currentState, payload) {
            currentState.customReferrals.push(payload)
        },

        [Mutation.POZ_UPDATE_CUSTOM_REFERRAL](currentState, payload) {
            currentState.customReferrals.splice(payload.index, 1, payload.referral)
        },

        [Mutation.POZ_REMOVE_CUSTOM_REFERRAL](currentState, payload) {
            let index = currentState.customReferrals.indexOf(payload)

            if (index > -1) {
                currentState.customReferrals.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_REFERRAL_FOR_CANCEL](currentState, payload) {
            currentState.cancelledReferrals.push(payload)
        },

        [Mutation.POZ_ADD_CUSTOM_REFERRAL_FOR_CANCEL](currentState, payload) {
            currentState.cancelledCustomReferrals.push(payload)
        },

        //******************** PRESCRIPTIONS ********************/

        [Mutation.POZ_ADD_TMP_PRESCRIPTION](currentState, payload) {
            currentState.tmpPrescriptions.push(payload)
        },

        [Mutation.POZ_UPDATE_TMP_PRESCRIPTION](currentState, payload) {
            currentState.tmpPrescription[payload.index] = payload.prescription
        },

        [Mutation.POZ_TMP_REMOVE_PRESCRIPTION](currentState, payload) {
            let index = currentState.tmpPrescriptions.indexOf(payload)

            if (index > -1) {
                currentState.tmpPrescriptions.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_PRESCRIPTION](currentState, payload) {
            currentState.prescriptions.push(payload)
        },

        [Mutation.POZ_ADD_PRESCRIPTION_FOR_CANCEL](currentState, payload) {
            currentState.cancelledPrescriptions.push(payload)
        },

        [Mutation.POZ_ADD_PAPER_PRESCRIPTION](currentState, payload) {
            currentState.paperPrescriptions.push(payload)
        },

        [Mutation.POZ_ADD_PAPER_PRESCRIPTION_FOR_CANCEL](currentState, payload) {
            currentState.cancelledPaperPrescriptions.push(payload)
        },

        [Mutation.POZ_SET_PRESCRIPTIONS_HISTORY_P1_REQUEST](currentState, payload) {
            currentState.prescriptionsHistoryP1Request = payload
        },

        [Mutation.POZ_SET_PRESCRIPTIONS_HISTORY_P1](currentState, payload) {
            currentState.prescriptionsHistoryP1 = payload
        },

        [Mutation.POZ_SET_PRESCRIPTIONS_HISTORY_P1_LOADING](currentState, payload) {
            currentState.prescriptionsHistoryP1Loading = payload
        },

        //******************** EXEMPTIONS ********************/

        [Mutation.POZ_ADD_TMP_EXEMPTION](currentState, payload) {
            currentState.tmpExemptions.push(payload)
        },

        [Mutation.POZ_TMP_REMOVE_EXEMPTION](currentState, payload) {
            let index = currentState.tmpExemptions.indexOf(payload)

            if (index > -1) {
                currentState.tmpExemptions.splice(index, 1)
            }
        },

        [Mutation.POZ_UPDATE_TMP_EXEMPTION](currentState, payload) {
            currentState.tmpExemptions[payload.index] = payload.exemption
        },

        //******************** ATTESTATIONS ********************/

        [Mutation.POZ_ADD_TMP_ATTESTATION](currentState, payload) {
            currentState.tmpAttestations.push(payload)
        },

        [Mutation.POZ_UPDATE_TMP_ATTESTATION](currentState, payload) {
            currentState.tmpOrders.splice(payload.index, 1, payload.attestation)
        },

        [Mutation.POZ_TMP_REMOVE_ATTESTATION](currentState, payload) {
            let index = currentState.tmpAttestations.indexOf(payload)

            if (index > -1) {
                currentState.tmpAttestations.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_ATTESTATION](currentState, payload) {
            currentState.attestations.push(payload)
        },

        [Mutation.POZ_REMOVE_ATTESTATION](currentState, payload) {
            let index = currentState.attestations.indexOf(payload)

            if (index > -1) {
                currentState.attestations.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_ATTESTATION_FOR_CANCEL](currentState, payload) {
            currentState.cancelledAttestations.push(payload)
        },

        //******************** ORDERS ********************/

        [Mutation.POZ_ADD_TMP_ORDER](currentState, payload) {
            currentState.tmpOrders.push(payload)
        },

        [Mutation.POZ_UPDATE_TMP_ORDER](currentState, payload) {
            currentState.tmpOrders.splice(payload.index, 1, payload.order)
        },

        [Mutation.POZ_TMP_REMOVE_ORDER](currentState, payload) {
            let index = currentState.tmpOrders.indexOf(payload)

            if (index > -1) {
                currentState.tmpOrders.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_ORDER](currentState, payload) {
            currentState.orders.push(payload)
        },

        [Mutation.POZ_REMOVE_ORDER](currentState, payload) {
            let index = currentState.orders.indexOf(payload)

            if (index > -1) {
                currentState.orders.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_APPOINTMENT_TO_ORDER](currentState, payload) {
            let order = currentState.orders.find(order => order.id === payload.id)

            if (order) {
                let appointments = order.appointments ?? []
                appointments.push(payload.appointment)
                order.appointments = appointments
            }
        },

        [Mutation.POZ_ADD_ORDER_FOR_CANCEL](currentState, payload) {
            currentState.cancelledOrders.push(payload)
        },

        //******************** REPORTS ********************/

        [Mutation.POZ_ADD_TMP_REPORT](currentState, payload) {
            currentState.tmpReports.push(payload)
        },

        [Mutation.POZ_UPDATE_TMP_REPORT](currentState, payload) {
            currentState.tmpReports.splice(payload.index, 1, payload.report)
        },

        [Mutation.POZ_TMP_REMOVE_REPORT](currentState, payload) {
            let index = currentState.tmpReports.indexOf(payload)

            if (index > -1) {
                currentState.tmpReports.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_REPORT](currentState, payload) {
            currentState.reports.push(payload)
        },

        [Mutation.POZ_REMOVE_REPORT](currentState, payload) {
            let index = currentState.reports.indexOf(payload)

            if (index > -1) {
                currentState.reports.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_REPORT_FOR_CANCEL](currentState, payload) {
            currentState.cancelledReports.push(payload)
        },

        //******************** EDM ********************/
        [Mutation.POZ_SET_FINISH_OBJECT](currentState, payload) {
            currentState.finishAppointmentObject = payload
        },

        [Mutation.POZ_SET_P12_OBJECT](currentState, payload) {
            currentState.p12AppointmentObject = payload
        },

        [Mutation.POZ_SET_FINISH_SCHEDULE_OBJECT](currentState, payload) {
            currentState.scheduleAppointmentObject = payload
        },

        [Mutation.POZ_CLEAR_FINISH_OBJECT](currentState) {
            currentState.finishAppointmentObject = null
        },

        //******************** ZM ********************/
        [Mutation.POZ_SET_ZM_REQUEST_OBJECT](currentState, payload) {
            currentState.requestZmObject = payload
        },

        [Mutation.POZ_SET_ZM_RESULT_OBJECT](currentState, payload) {
            currentState.resultZmObject = payload
        },

        //******************** IPOM ********************/
        [Mutation.POZ_SET_IPOM_P12_OBJECT](currentState, payload) {
            currentState.ipomResultObject = payload
        },

        [Mutation.POZ_SET_IPOM_ERROR_OBJECT](currentState, payload) {
            currentState.ipomErrorObject = payload
        },

        [Mutation.PATIENT_IPOM_ADD_DRUG](state, payload) {
            if (state.ipomDrugs == null) state.ipomDrugs = []

            if (payload != null && !state.ipomDrugs.includes(payload)) state.ipomDrugs.push(payload)
        },

        [Mutation.PATIENT_IPOM_REMOVE_DRUG](state, payload) {
            if (state.ipomDrugs != null) {
                let index = state.ipomDrugs.indexOf(payload)

                state.ipomDrugs.splice(index, 1)
            }
        },
        //******************** HIPOM ********************/
        [Mutation.POZ_SET_HIPOM_P12_OBJECT](currentState, payload) {
            currentState.hipomResultObject = payload
        },

        [Mutation.POZ_SET_HIPOM_ERROR_OBJECT](currentState, payload) {
            currentState.hipomErrorObject = payload
        },

        //******************** DILO ********************/
        [Mutation.POZ_SET_DILO](currentState, payload) {
            currentState.diloInfo = payload
        },

        //*********************Information to doctor */
        [Mutation.POZ_SET_INFORMATION_TO_DOCTOR](currentState, payload) {
            currentState.informationDoctor = payload
        },

        [Mutation.POZ_RESET_INFORMATION_TO_DOCTOR](currentState) {
            currentState.informationDoctor = null
        },

        [Mutation.POZ_SET_FINISH_INFORMATION_TO_DOCTOR](currentState, payload) {
            currentState.informationDoctorFinish = payload
        },

        [Mutation.POZ_SET_INFORMATION_TO_DOCTOR_REQEST](currentState, payload) {
            currentState.informationDoctorRequest = payload
        },

        ///////////////////////////////////////////////////////////

        [Mutation.POZ_EMP_REFFERALS](currentState, payload) {
            currentState.empReferrals = payload
        },

        [Mutation.POZ_EMPLOYEE_REFFERALS](currentState, payload) {
            currentState.employeeReferrals = payload
        },

        [Mutation.POZ_UPDATE_REFERRALS](currentState, payload) {

            currentState.empReferrals = payload

            payload.forEach(refServ => {
                var local = currentState.referrals.find(r => r.guid === refServ.guid)

                if (local != null) {
                    local.status = refServ.workStatus
                }
            });
        },

        [Mutation.POZ_UPDATE_REFERRAL](currentState, payload) {
            var local = currentState.referrals.indexOf(payload.item)

            if (local > -1) {
                currentState.referrals[local].status = 0
                currentState.referrals[local].guid = payload.guid
            }
        },

        [Mutation.POZ_ADD_REFERRAL](currentState, payload) {
            currentState.referrals.push(payload)
        },

        //**Correction Visit */
        [Mutation.POZ_SET_IS_CORRECTION](currentState, payload) {
            currentState.isVisitCorrecting = payload
        },

        [Mutation.POZ_SET_LAST_END_APPOINTMENT](currentState, payload) {
            currentState.lastEndApp = payload;
        },

        [Mutation.POZ_RESET_LAST_END_APPOINTMENT](currentState) {
            currentState.lastEndApp = null;
        },

        [Mutation.POZ_SET_DIAGNOSIS_FROM_EDM](currentState, payload) {
            currentState.diagnosisFromEdm = payload
        },

        [Mutation.POZ_RESET_DIAGNOSIS_FROM_EDM](currentState) {
            currentState.diagnosisFromEdm = null
        },

        [Mutation.POZ_SET_CONTROL_NUMBER_ONGOING](currentState, payload) {
            currentState.controlNumberOngoing = payload
        },

        [Mutation.POZ_RESET_CONTROL_NUMBER_ONGOING](currentState) {
            currentState.controlNumberOngoing = null;
        },

        [Mutation.POZ_SET_IS_REEDITING](currentState, payload) {
            currentState.isReediting = payload
        },

        [Mutation.POZ_SET_CORRECTION_REASON](currentState, payload) {
            currentState.correctionReason = payload.comment;

            if (payload.reasons == null) {
                currentState.correctionReasons = []
            } else {
                currentState.correctionReasons = payload.reasons
            }
        },

        [Mutation.POZ_RESET_CORRECTION_STORE](currentState) {
            currentState.isVisitCorrecting = false;
            currentState.isReediting = false;
            currentState.lastEndApp = null;
            currentState.diagnosisFromEdm = null;
            currentState.controlNumberOngoing = null;
            currentState.correctionReason = null;
            currentState.correctionReasons = [];
        },

        ////////////////////////////////////////////

        [Mutation.POZ_TMP_SAVE](state, payload) {
            state.tmpData.push(payload)
        },

        [Mutation.POZ_ADD_EXAMINATION](state, payload) {
            let index = state.currentExaminations.findIndex(u => u.examination.id === payload.examination.id)
            if (index < 0) {
                state.currentExaminations.push(payload)
            }
        },

        [Mutation.POZ_SET_APPOINTMENTS](state, payload) {
            state.appointments = payload
        },

        [Mutation.POZ_SET_PATIENTS](state, payload) {
            state.patients = payload
        },

        [Mutation.POZ_SET_ONGOING_APPOINTMENT](state, payload) {
            state.ongoingAppointment = payload
        },

        [Mutation.POZ_SET_APPOINTMENT_HISTORY](state, payload) {
            state.appointmentHistory = payload
        },

        [Mutation.POZ_SET_TMP_SAVE_STANDARD_DIAGNOSES](state) {
            if (state.appointmentDiagnoses.diagnoses) {
                state.appointmentDiagnoses.diagnoses.forEach(diag => {
                    if (diag.isContinuous === false && diag.isActive === true) {
                        diag.isActiveTmp = true
                    } else {
                        diag.isActiveTmp = false
                    }
                })
            }
        },

        [Mutation.POZ_SET_APPOINTMENT_DIAGNOSES_INITIAL](state) {
            if (state.appointmentDiagnoses.diagnoses) {
                state.appointmentDiagnoses.diagnoses.forEach((element2, index) => {
                    if (element2.index === null || element2.index === undefined) {
                        element2.index = `icd_${index}`
                    }
                    if (element2.indexSort === null || element2.indexSort === undefined) {
                        element2.indexSort = index
                    }
                })

                if (this.getters.myPermits.diagnoseV1) {
                    state.appointmentDiagnoses.diagnoses.forEach(element2 => {
                        if (element2.medicalEventDate != null) {
                            element2.medicalEventDate = new Date(element2.medicalEventDate)
                        }

                        let startMoment = moment(element2.startDate)
                        let thisMoment = moment()
                        let differenceInDays = thisMoment.diff(startMoment, 'days')

                        if (differenceInDays > 365) {
                            element2.isActive = false
                            element2.show = false
                        } else {
                            element2.isActive = true
                        }

                        if (element2.isCured === true || element2.curedNow === true) {
                            element2.show = false
                        }

                        if (element2.isDeactivatedInCorrection) {
                            element2.isActive = false
                            element2.show = false
                        }
                    })

                } else {

                    if (state.isVisitCorrecting && state.isReediting == false && state.lastEndApp != null && state.lastEndApp.diagnoses != null && state.diagnosisFromEdm != null) {

                        state.appointmentDiagnoses.diagnoses.forEach((diag) => {
                            if (diag.isContinuous === true) {
                                diag.isActive = true
                                diag.show = true
                            } else if (diag.isLeading === true || diag.isVisibleSummary === true || diag.isIpom === true || diag.fromBefore === false) {
                                diag.isActive = true
                                diag.show = true
                            } else {
                                diag.isActive = false
                                diag.show = true
                            }
                        })

                        let activeStandardDiagnoses = state.appointmentDiagnoses.diagnoses.filter(item => item.isContinuous === false && item.isActive === true)
                        if (activeStandardDiagnoses < 1) {
                            let standardDiagnoses = state.appointmentDiagnoses.diagnoses.filter(item => item.isContinuous === false && item.isDeactivatedInCorrection === false)
                            standardDiagnoses.forEach((item, index) => {
                                if (index < 5) {
                                    item.isActive = true
                                }
                            })
                        }
                    } else if (state.tmpVisitData && state.tmpVisitData.appointmentDiagnoses) {

                        state.appointmentDiagnoses.diagnoses.forEach((diag) => {
                            if (diag.isContinuous === true) {
                                diag.isActive = true
                                diag.show = true
                            } else if (diag.isLeading === true || diag.isVisibleSummary === true || diag.isIpom === true || diag.fromBefore === false || diag.isActiveTmp === true) {
                                diag.isActive = true
                                diag.show = true
                            } else {
                                diag.isActive = false
                                diag.show = true
                            }
                        })

                        let activeStandardDiagnoses = state.appointmentDiagnoses.diagnoses.filter(item => item.isContinuous === false && item.isActive === true)
                        if (activeStandardDiagnoses < 1) {
                            let standardDiagnoses = state.appointmentDiagnoses.diagnoses.filter(item => item.isContinuous === false && item.isDeactivatedInCorrection === false)
                            standardDiagnoses.forEach((item, index) => {
                                if (index < 5) {
                                    item.isActive = true
                                }
                            })
                        }
                    } else {
                        state.appointmentDiagnoses.diagnoses.forEach((diag) => {
                            diag.fromBefore = diag.fromBefore ?? true
                            diag.isLeading = false
                            diag.isVisibleSummary = false
                            diag.isActive = true
                            diag.show = true
                            diag.ipomDiagnose = false

                            diag.isIpom = false
                            diag.isContinuous = diag.isContinuous ?? false
                            diag.diagnoseOrder = 0

                            if (diag.isContinuous) {
                                diag.isVisibleSummary = true
                            }

                            diag.isActiveTmp = false
                        })

                        let standardDiagnoses = state.appointmentDiagnoses.diagnoses.filter(item => item.isContinuous === false && item.isDeactivatedInCorrection === false)
                        standardDiagnoses.forEach((item, index) => {
                            if (index > 4) {
                                item.isActive = false
                            }
                        })

                        let deactivatedInCorrection = state.appointmentDiagnoses.diagnoses.filter(item => item.isDeactivatedInCorrection)
                        deactivatedInCorrection.forEach(item => {
                            item.isActive = false
                        })
                    }
                }
            }
        },

        [Mutation.POZ_SET_SHOW_TRUE_ALL_ICD](state) {
            if (state.appointmentDiagnoses.diagnoses) {
                state.appointmentDiagnoses.diagnoses.forEach(element => element.show = true)
            }
        },

        [Mutation.POZ_SET_SHOW_ICD](state, payload) {
            let index = state.appointmentDiagnoses.diagnoses.indexOf(payload.icd)
            if (index > -1) {
                state.appointmentDiagnoses.diagnoses[index].show = payload.value
            }
        },

        [Mutation.POZ_ADD_DIAGNOSE](state, payload) {
            state.appointmentDiagnoses.diagnoses.unshift(payload)
        },

        [Mutation.POZ_REFRESH_EXISTING_DIAGNOSE](state, payload) {
            const index = state.appointmentDiagnoses.diagnoses.findIndex(diagnose => diagnose.icd.code === payload.code)
            if (index > - 1) {
                const diagnose = state.appointmentDiagnoses.diagnoses[index]
                diagnose.isActive = true
                diagnose.show = true
                diagnose.isContinuous = payload.isContinuous
                diagnose.isCured = false
                diagnose.curedNow = false
                diagnose.isDeactivatedInCorrection = false

                let isAlreadyLeading = state.appointmentDiagnoses.diagnoses.find((icd) => icd.isLeading === true)
                if (!isAlreadyLeading) {
                    diagnose.isLeading = true
                }

                if (diagnose.isContinuous) {
                    diagnose.isVisibleSummary = true
                } else {
                    diagnose.isVisibleSummary = false
                }

                diagnose.index = `icd_HISTORY_${diagnose.icd.code}`
                diagnose.indexSort = 0

                state.appointmentDiagnoses.diagnoses.splice(index, 1)

                let findNotFromBeforeIndex = state.appointmentDiagnoses.diagnoses.findIndex(diagnose => diagnose.fromBefore === true)
                if (findNotFromBeforeIndex > -1) {
                    state.appointmentDiagnoses.diagnoses.splice(findNotFromBeforeIndex, 0, diagnose);
                } else {
                    state.appointmentDiagnoses.diagnoses.unshift(diagnose)
                }
            }
        },

        [Mutation.POZ_REMOVE_DIAGNOSE](state, payload) {
            let index = state.appointmentDiagnoses.diagnoses.indexOf(payload)
            if (index > -1) {
                state.appointmentDiagnoses.diagnoses.splice(index, 1)
            }
        },

        /**
         * ipomDiagnose - helper prop for IPOM specialist consultations
         */
        [Mutation.POZ_SET_APPOINTMENT_INITIAL_DATA](state, payload) {
            if (payload) {

                if (payload.oldAppointments) {
                    state.appointmentHistory = blockObserverMethod(payload.oldAppointments)
                }
                else {
                    state.appointmentHistory = []
                }

                state.isDiagnosesFromOld = false;
                //! changes && state.diagnosisFromEdm != null)
                if (state.isVisitCorrecting && state.isReediting == false && state.lastEndApp != null && state.lastEndApp.diagnoses != null && state.diagnosisFromEdm != null) {
                    state.isDiagnosesFromOld = true;

                    if (state.diagnosisFromEdm != null && state.diagnosisFromEdm.length > 0) {
                        if (state.lastEndApp.diagnoses.diagnoses != null) {
                            state.lastEndApp.diagnoses.diagnoses.forEach((diag) => {
                                if (diag.icd != null && diag.icd.code != null) {
                                    let found = state.diagnosisFromEdm.find((edmDiag) => edmDiag.icd?.code == diag.icd.code)
                                    if (found) {
                                        diag.fromBefore = found.fromBefore
                                        diag.isLeading = found.isLeading
                                        Vue.set(diag, 'isVisibleSummary', found.isVisibleSummary)
                                        Vue.set(diag, 'isActive', found.isActive ?? true)
                                        Vue.set(diag, 'show', found.show ?? true)

                                        Vue.set(diag, 'ipomDiagnose', found.ipomDiagnose ?? false)

                                        Vue.set(diag, 'isIpom', found.isIpom ?? false)
                                        Vue.set(diag, 'diagnoseOrder', found.diagnoseOrder ?? 0)

                                        Vue.set(diag, 'isDeactivatedInCorrection', found.isDeactivatedInCorrection ?? false)
                                    }
                                }
                            });
                        }
                    }
                    state.appointmentDiagnoses = state.lastEndApp.diagnoses
                } else if (state.tmpVisitData && state.tmpVisitData.appointmentDiagnoses) {
                    let parseDiags = JSON.parse(state.tmpVisitData.appointmentDiagnoses)
                    if (parseDiags.diagnoses) {
                        parseDiags.diagnoses.forEach((diag) => {
                            diag.fromBefore = diag.fromBefore ?? true
                            diag.isLeading = diag.isLeading ?? false
                            diag.isVisibleSummary = diag.isVisibleSummary ?? false
                            diag.isActive = diag.isActive ?? true
                            diag.show = true
                            diag.ipomDiagnose = false

                            diag.isIpom = diag.isIpom ?? false
                            diag.isContinuous = diag.isContinuous ?? false
                            diag.diagnoseOrder = diag.diagnoseOrder ?? 0

                            diag.isActiveTmp = diag.isActiveTmp ?? false

                            diag.isDeactivatedInCorrection = diag.isDeactivatedInCorrection ?? false

                            diag.drugs.forEach(drug => {
                                if (drug.hasPharmaInfo === false || drug.hasPharmaInfo === true) {
                                    // do nothing
                                } else if (drug.wybraneOpakowanie) {
                                    let drugEAN = drug.wybraneOpakowanie.kodEAN
                                    if (state.tmpVisitData.pharmaInfo && state.tmpVisitData.pharmaInfo.length > 0) {
                                        let foundPharmaInfo = state.tmpVisitData.pharmaInfo.find(info => info.ean == drugEAN)
                                        if (foundPharmaInfo) {
                                            drug.ean = drugEAN
                                            drug.hasPharmaInfo = foundPharmaInfo.hasPharmaInfo
                                            if (drug.hasPharmaInfo === false) {
                                                drug.recipeName = foundPharmaInfo.recipeName
                                            }
                                        }
                                    }
                                } else if (!drug.rawMaterials || drug.rawMaterials.length === 0) {
                                    drug.hasPharmaInfo = false
                                }
                            })
                        })
                    }
                    state.appointmentDiagnoses = parseDiags
                } else if (payload.diagnoses) {
                    if (payload.diagnoses.diagnoses) {
                        payload.diagnoses.diagnoses.forEach((diag) => {
                            diag.fromBefore = diag.fromBefore ?? true
                            diag.isLeading = false
                            diag.isVisibleSummary = false
                            diag.isActive = true
                            diag.show = true
                            diag.ipomDiagnose = false

                            diag.isIpom = false
                            diag.isContinuous = diag.isContinuous ?? false
                            diag.diagnoseOrder = 0

                            if (diag.isContinuous) {
                                diag.isVisibleSummary = true
                            }

                            diag.isActiveTmp = false

                            diag.isDeactivatedInCorrection = diag.isDeactivatedInCorrection ?? false
                        })
                        payload.diagnoses.diagnoses.reverse()
                    }
                    state.appointmentDiagnoses = payload.diagnoses
                }
                else {
                    state.appointmentDiagnoses = { diagnoses: [] }
                }

                if (state.appointmentDiagnoses && state.appointmentDiagnoses.diagnoses && state.appointmentDiagnoses.diagnoses.length > 0) {
                    state.appointmentDiagnoses.diagnoses.forEach(diagnose => {
                        if (diagnose && diagnose.drugs && diagnose.drugs.length > 0) {
                            // let foundDrug = diagnose.drugs.find(drug => drug.isAddedOnVisit)
                            // if (foundDrug) {
                            //     state.drugsAddedOnVisit = true
                            // }
                            let arr = diagnose.drugs.filter(drug => drug.isAddedOnVisit)
                            if (arr && arr.length > 0) {
                                state.drugsAddedOnVisit = true
                                arr.forEach(drug => {
                                    let drugDetailsObj = {
                                        name: drug.nazwaProduktu,
                                        ean: null
                                    }
                                    if (drug.wybraneOpakowanie) {
                                        drugDetailsObj.ean = drug.wybraneOpakowanie.kodEAN
                                    }
                                    state.drugsAddedOnVisitDetails.push(drugDetailsObj)
                                })
                            }
                        }
                    })
                }

                if (payload.allergies) {
                    state.appointmentAllergies = blockObserverMethod(payload.allergies)
                }
                else {
                    state.appointmentAllergies = {
                        id: "tmp",
                        patientId: state.focusAppointment.patient.id,
                        changeDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        items: []
                    }
                }
            }
        },

        [Mutation.POZ_SORT_DIAGNOSES_ORDER](state) {
            if (state.appointmentDiagnoses && state.appointmentDiagnoses.diagnoses) {
                state.appointmentDiagnoses.diagnoses.sort((a, b) => {
                    return Number(b.isVisibleSummary) - Number(a.isVisibleSummary)
                })
            }
        },

        [Mutation.APPOINTMENT_SIMPLE_FOCUS](state, payload) {
            if (payload) {
                state.focusArea = null
                state.focusBlock = payload
                state.focusAppointment = payload.appointment
            }
            else {
                state.focusArea = null
                state.focusBlock = null
                state.focusAppointment = null
            }
        },

        [Mutation.APPOINTMENT_WORKTIME_FOCUS](state, payload) {
            if (payload) {
                state.focusBlock = null
                state.focusAppointment = null
                state.focusArea = payload
            }
            else {
                state.focusArea = null
                state.focusBlock = null
                state.focusAppointment = null
            }
        },

        [Mutation.POZ_SET_FOCUS_APPOINTMENT](state, payload) {

            if (state.patients.length > 0) {
                let patient = state.patients.find(p => p.id === payload.patientId)

                if (patient) {
                    payload.patient = patient
                    state.focusAppointment = payload
                }
            }
        },

        [Mutation.POZ_SET_FOCUS_APPOINTMENT_NURSE](state, payload) {
            state.focusAppointment = payload
        },

        [Mutation.POZ_CLEAR_ONGOING_APPOINTMENT](state) {
            state.ongoingAppointment = null
        },

        [Mutation.POZ_SET_FOCUS_APPOINTMENT_FROM_LIST](state) {
            if (state.appointments.length > 0) {
                state.focusAppointment = state.appointments[0]
                state.focusAppointment.patient = {}

                if (state.patients.length > 0) {
                    let patient = state.patients.find(p => p.id === state.focusAppointment.patientId)

                    if (patient) {
                        state.focusAppointment.patient = patient
                    }
                }
            }
        },

        [Mutation.POZ_REMOVE_EXAMINATION](state, payload) {
            let index = state.currentExaminations.indexOf(payload)
            if (index > -1) {
                state.currentExaminations.splice(index, 1)
            }
        },

        [Mutation.POZ_RESET_EXAMINATIONS](state) {
            state.currentExaminations = []
        },

        [Mutation.POZ_RESET](state) {
            state.currentExaminations = []
            state.appointments = []
            state.umxContracts = []
            state.patients = []
            state.chosenServices = []
            state.chosenServicesIcds = []
            state.servicesFromTicket = []
            state.umxContract = null
            state.facility = null
            state.appointmentDiagnoses = { diagnoses: [] }
            state.appointmentHistory = []
            state.appointmentAllergies = []
            state.ongoingAppointment = null
            state.focusAppointment = null
            state.assistants = []
            state.documents = []
            state.tickets = []
            state.referralTickets = []
            state.nfzInfo = null
            state.incidents = []
            //
            state.prescriptions = []
            state.tmpPrescriptions = []
            state.cancelledPrescriptions = []
            state.paperPrescriptions = []
            state.cancelledPaperPrescriptions = []
            state.referrals = []
            state.tmpReferrals = []
            state.cancelledReferrals = []
            state.customReferrals = []
            state.cancelledCustomReferrals = []
            state.exemptions = []
            state.tmpExemptions = []
            state.cancelledExemptions = []
            state.attestations = []
            state.tmpAttestations = []
            state.cancelledAttestations = []
            state.orders = []
            state.tmpOrders = []
            state.cancelledOrders = []
            state.drugsForOrders = []
            state.reports = []
            state.tmpReports = []
            state.cancelledReports = []
            state.triggerForOrders = 0
            state.triggerForPrescriptions = 0
            state.drugsForPrescription = []
            state.tmpVisitData = null
            state.tmpVisitDataTrigger = 0
            state.triggerMedQuestionsPrint = 0
            state.treatmentDecision = 2
            state.correctionUmxTrigger = 0

            state.informationDoctor = null
            state.informationDoctorFinish = null
            state.informationDoctorRequest = null
            state.finishAppointmentObject = null
            state.p12AppointmentObject = null
            state.icds9 = []
            state.ticketsToSettle = []
            state.drugInfo = null
            state.drugPermissions = []


            state.medicalServices = []

            state.medicalResultsDocumentation = []

            state.patientExaminations = []

            state.programId = null
            state.ipomResultObject = null
            state.ipomErrorObject = null
            state.diloInfo = null
            state.hipomResultObject = null
            state.hipomErrorObject = null
            state.ipomDrugs = []

            state.stratificationGroup = null
            state.patientVersionStrat = null

            state.visitTypes = []
            state.visitSubtypes = []
            state.medQuestionsTemplateId = []
            state.triggerMedQuestionsTemplate = 0

            state.medQuestionsIcd9 = []

            state.historyIcdToAdd = null
            state.historyIcdTrigger = 0

            state.leadingIcdToAdd = null
            state.leadingIcdTrigger = 0

            state.saveVisitTrigger = 0
            state.exitVisitTrigger = 0

            state.tmpVisitLastSaveDate = null
            state.tmpVisitSaveCounter = 0
            state.tmpVisitSaveLoading = false
            state.drugsAddedOnVisit = false

            state.patientDoctorNotes = []

            state.prescriptionsHistoryP1 = null
            state.prescriptionsHistoryP1Request = null
            state.prescriptionsHistoryP1Loading = false

            state.ipomConsultationLimited = false

            state.officeAppointment = null

            state.continuousDiagnosesSummary = true

            state.ongoingRis = null

            state.resultsImmunizationAppointment = []
            state.resultsImmunizationQualificationAppointment = []

            state.requestZmObject = null
            state.resultZmObject = null

            state.appointmentPatientIdentificationInfo = null

            state.lastInsuranceIdentificationInfo = null
            state.insuranceIdentificationInfoLoading = false

            state.appointmentIKZ = []

            state.measurementsLastUsedInAppointment = null
        },

        [Mutation.PATIENT_UPDATE_ALLERGIES](state, payload) {
            state.appointmentAllergies = payload
        },

        [Mutation.PATIENT_ADD_ALLERGY](state, payload) {
            state.appointmentAllergies.items.push(payload)
        },

        [Mutation.PATIENT_REMOVE_ALLERGY](state, payload) {
            let ind = state.appointmentAllergies.items.indexOf(payload)

            if (ind > -1) {
                state.appointmentAllergies.items.splice(ind, 1)
            }
        },

        [Mutation.POZ_SET_PATHS](state, payload) {
            state.paths = payload
        },

        [Mutation.POZ_ADD_PATH_M](state, payload) {
            state.paths.push(payload)
        },

        //PATIENT_PORTAL
        [Mutation.POZ_SET_PATIENT_DIAGNOSES](state, payload) {
            state.patientDiagnoses = payload
        },

        [Mutation.POZ_SET_PATIENT_APPOINTMENTS](state, payload) {
            state.patientAppointments = payload
        },


        [Mutation.POZ_ADD_ASSISTANT](currentState, payload) {
            let index = currentState.assistants.indexOf(payload)

            if (index < 0) {
                currentState.assistants.push(payload)
            }
        },

        [Mutation.POZ_REMOVE_ASSISTANT](currentState, payload) {
            let index = currentState.assistants.indexOf(payload)

            if (index > -1) {
                currentState.assistants.splice(index, 1)
            }
        },

        [Mutation.POZ_UPDATE_PATIENT_ON_VISIT](state, payload) {
            if (state.ongoingAppointment) {
                state.ongoingAppointment.patient = payload
            }
        },

        [Mutation.POZ_UPDATE_PATIENT_IN_WAITING_ROOM](state, payload) {
            if (state.focusAppointment && state.focusAppointment.patient && state.focusAppointment.patient.id === payload.id) {
                state.focusAppointment.patient = payload
                if (state.patients.length > 0) {
                    let found = state.patients.find(p => p.id === payload.id)
                    if (found) {
                        let patientIndex = state.patients.indexOf(found)
                        if (patientIndex > -1) {
                            state.patients[patientIndex] = payload
                        }
                    }
                }
            }
        },

        [Mutation.POZ_ADD_DOCUMENT](state, payload) {
            state.documents.push(payload)
        },

        [Mutation.POZ_ADD_TICKET](state, payload) {
            state.tickets.push(payload)
        },

        [Mutation.POZ_ADD_REFERRAL_TICKET](state, payload) {
            state.referralTickets.push(payload)
        },

        [Mutation.POZ_UPDATE_REFERRAL_TICKET](state, payload) {
            if (payload && payload.id) {

                let index = state.referralTickets.findIndex((ticket) => ticket.id === payload.id)

                if (index > -1) {
                    state.referralTickets.splice(index, 1, payload)
                }
            }
        },

        [Mutation.POZ_ADD_INCIDENT](state, payload) {
            state.incidents.push(payload)
        },

        [Mutation.POZ_ADD_SUBUNIT_INFO](state, payload) {
            state.ongoingAppointment.subUnitInfo = blockObserverMethod(payload)
        },

        [Mutation.POZ_ADD_SUBJECT_INFO](state, payload) {
            state.ongoingAppointment.subjectInfo = blockObserverMethod(payload)
        },

        [Mutation.POZ_ADD_ORG_UNIT_INFO](state, payload) {
            state.ongoingAppointment.orgUnitInfo = blockObserverMethod(payload)
        },

        [Mutation.POZ_ADD_PROGRAM_ID](state, payload) {
            state.ongoingAppointment.programId = payload
        },

        [Mutation.POZ_SET_IKZ](state, payload) {
            state.ikz = payload
        },

        [Mutation.POZ_SET_DRUG_INFO](state, payload) {
            state.drugInfo = payload
        },

        [Mutation.POZ_CLEAR_DRUG_INFO](state) {
            state.drugInfo = null
        },

        [Mutation.POZ_ADD_DRUG_PERMISSION](currentState, payload) {
            currentState.drugPermissions.push(payload)
        },

        [Mutation.POZ_SET_MEDICAL_SERVICES](state, payload) {
            state.medicalServices = payload
        },

        //******************** MEDICAL RESULTS ********************/

        /*
            Set medical results as documentation to visit data
            Set during resume of ongoing appointment
        */
        [Mutation.POZ_SET_MED_RESULTS_DOCUMENTATION](state, payload) {
            state.medicalResultsDocumentation = payload
        },

        /*
            Add Medical result as documentation to visit data
            Add to store state after selecting exam in components/appointments/PatientExaminations comp
        */
        [Mutation.POZ_ADD_MED_RESULTS_DOCUMENTATION](state, payload) {
            state.medicalResultsDocumentation.push(payload)
        },

        /*
            Remove medical result added as documentation from visit data
            Remove from store state after deselecting exam in components/appointments/PatientExaminations comp
        */
        [Mutation.POZ_REMOVE_MED_RESULTS_DOCUMENTATION](state, payload) {
            let index = state.medicalResultsDocumentation.findIndex((result) => result.id === payload.id)

            if (index > -1) {
                state.medicalResultsDocumentation.splice(index, 1)
            }
        },

        //*********************************************************/

        [Mutation.POZ_SET_SHOW_ENDED_FILTERS](state, payload) {
            state.showEndedFilters = payload
        },

        [Mutation.POZ_SET_PATIENT_EXAMINATIONS](state, payload) {
            state.patientExaminations = payload
        },

        [Mutation.POZ_SET_PATIENT_EXAMINATION_VISIT](state, payload) {
            let index = state.patientExaminations.indexOf(payload.exam)
            if (index > -1) {
                state.patientExaminations[index].isInMedResultsDocumentationProp = payload.value
            }
        },

        [Mutation.POZ_SET_PATIENT_EXAMINATION_SETTLEMENTS](state, payload) {
            let index = state.patientExaminations.indexOf(payload.exam)
            if (index > -1) {
                state.patientExaminations[index].isInMedResultsSettlementsProp = payload.value
            }
        },

        [Mutation.POZ_SET_PATIENT_EXAMINATIONS_SORTED](state) {
            state.patientExaminations.sort((a, b) => {
                return (
                    Number(b.isInMedResultsDocumentationProp) -
                    Number(a.isInMedResultsDocumentationProp) ||
                    Number(b.isInMedResultsSettlementsProp) -
                    Number(a.isInMedResultsSettlementsProp)
                )
            })
        },

        [Mutation.POZ_UPDATE_COMPOUNDED_DRUG](state, payload) {
            if (state.appointmentDiagnoses && state.appointmentDiagnoses.diagnoses && state.appointmentDiagnoses.diagnoses.length > 0) {
                state.appointmentDiagnoses.diagnoses.forEach(diagnose => {
                    if (diagnose.drugs && diagnose.drugs.length > 0) {
                        let foundArr = diagnose.drugs.filter(drug => drug.recipeName === payload.recipeName)
                        if (foundArr && foundArr.length == 1) {
                            let index = diagnose.drugs.findIndex((drug) => drug.recipeName === payload.recipeName)
                            if (index > -1) {
                                diagnose.drugs[index].recipeDescription = payload.recipeDescription
                                diagnose.drugs[index].recipeForm = payload.recipeForm
                                diagnose.drugs[index].dose = payload.dose
                                diagnose.drugs[index].recipeFormDesc = payload.recipeFormDesc
                                diagnose.drugs[index].recipeQuantityDesc = payload.recipeQuantityDesc
                                diagnose.drugs[index].recipeQuantityDescUnit = payload.recipeQuantityDescUnit
                                diagnose.drugs[index].recipePayment = payload.recipePayment
                                diagnose.drugs[index].summary = payload.summary
                                diagnose.drugs[index].additionalInfo = payload.additionalInfo
                                diagnose.drugs[index].rawMaterials = payload.rawMaterials
                            }
                        }
                    }
                })
            }
        },

        [Mutation.POZ_UPDATE_DRUG](state, payload) {
            if (state.appointmentDiagnoses && state.appointmentDiagnoses.diagnoses && state.appointmentDiagnoses.diagnoses.length > 0) {
                state.appointmentDiagnoses.diagnoses.forEach(diagnose => {
                    if (diagnose.drugs && diagnose.drugs.length > 0) {
                        diagnose.drugs.forEach(drug => {
                            if (drug.id == payload.id) {
                                drug = payload
                            }
                        })
                    }
                })
            }
        },

        [Mutation.POZ_REMOVE_DRUG](state, payload) {
            if (state.appointmentDiagnoses.diagnoses) {
                let index = state.appointmentDiagnoses.diagnoses.indexOf(payload.icd)
                if (index > -1) {
                    let indexDrugs = state.appointmentDiagnoses.diagnoses[index].drugs.indexOf(payload.drug)
                    if (indexDrugs > -1) {
                        state.appointmentDiagnoses.diagnoses[index].drugs.splice(indexDrugs, 1)
                    }
                }
            }
        },

        [Mutation.POZ_SET_STRATIFICATION_GROUP](state, payload) {
            state.stratificationGroup = payload
        },

        [Mutation.POZ_SET_STRATIFICATION_PATIENT_VERSION](state, payload) {
            state.patientVersionStrat = payload
        },

        [Mutation.POZ_ADD_VISIT_TYPE](state, payload) {
            state.visitTypes.push(payload)
        },

        [Mutation.POZ_REMOVE_VISIT_TYPE](currentState, payload) {
            let index = currentState.visitTypes.indexOf(payload)

            if (index > -1) {
                currentState.visitTypes.splice(index, 1)
            }
        },

        [Mutation.POZ_SET_VISIT_TYPE](state, payload) {
            state.visitTypes = payload
        },

        [Mutation.POZ_ADD_VISIT_SUBTYPE](state, payload) {
            state.visitSubtypes.push(payload)
        },

        [Mutation.POZ_SET_VISIT_SUBTYPE](state, payload) {
            state.visitSubtypes = payload
        },

        [Mutation.POZ_REMOVE_VISIT_SUBTYPE](currentState, payload) {
            let index = currentState.visitSubtypes.indexOf(payload)

            if (index > -1) {
                currentState.visitSubtypes.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_MED_QUESTION_TEMPLATE](state, payload) {
            state.medQuestionsTemplateId.push(payload)
        },

        [Mutation.POZ_SET_MED_QUESTION_TEMPLATE](state, payload) {
            state.medQuestionsTemplateId = payload
        },

        [Mutation.POZ_TRIGGER_MED_QUESTION_TEMPLATE](state) {
            state.triggerMedQuestionsTemplate += 1
        },

        [Mutation.POZ_ADD_DRUG_TO_DIAGNOSE_MEDICAL_HISTORY](state, payload) {
            if (payload) {
                if (state.appointmentDiagnoses.diagnoses && state.appointmentDiagnoses.diagnoses.length > 0) {
                    let found = state.appointmentDiagnoses.diagnoses.find(diag => (payload.diagnose.id && diag.id == payload.diagnose.id) || (payload.diagnose.icd && diag.icd && diag.icd && !diag.isCured && diag.icd.code == payload.diagnose.icd.code))
                    if (found) {
                        payload.drug.openModal = true
                        found.drugs.push(payload.drug)
                    }
                }
            }
        },

        [Mutation.POZ_ADD_DRUG_TO_DIAGNOSE_MEDICAL_HISTORY_NEW](state, payload) {
            if (payload) {
                if (state.appointmentDiagnoses.diagnoses && state.appointmentDiagnoses.diagnoses.length > 0) {
                    let found = state.appointmentDiagnoses.diagnoses.find(diag => (payload.diagnose.id && diag.id == payload.diagnose.id) || (payload.diagnose.icd && diag.icd && diag.icd && !diag.isCured && diag.icd.code == payload.diagnose.icd.code))
                    if (found) {
                        found.drugs.push(payload.drug)
                        state.drugsTableTrigger++
                    }
                }
            }
        },

        [Mutation.POZ_ADD_DRUG_TO_DIAGNOSE](state, payload) {
            if (payload) {
                if (state.appointmentDiagnoses.diagnoses && state.appointmentDiagnoses.diagnoses.length > 0) {
                    let icd = state.appointmentDiagnoses.diagnoses.find(diag =>
                        (payload.diagnose.id && diag.id == payload.diagnose.id) ||
                        (payload.diagnose.icd && diag.icd && diag.icd.code == payload.diagnose.icd.code)
                    )
                    if (icd) {
                        payload.drug.openModal = true
                        icd.drugs.push(payload.drug)
                    }
                }
            }
        },



        [Mutation.POZ_SAVE_VISIT_TRIGGER_UP](state) {
            state.saveVisitTrigger += 1
        },

        [Mutation.POZ_SET_MED_QUESTIONS_ICD9](state, payload) {
            state.medQuestionsIcd9 = payload
        },

        [Mutation.POZ_ADD_MED_QUESTIONS_ICD9](state, payload) {
            state.medQuestionsIcd9.push(payload)
        },

        [Mutation.POZ_ADD_CONCAT_MED_QUESTIONS_ICD9](state, payload) {
            state.medQuestionsIcd9 = state.medQuestionsIcd9.concat(payload)
        },

        [Mutation.POZ_REMOVE_MED_QUESTIONS_ICD9](state, payload) {
            let index = state.medQuestionsIcd9.indexOf(payload)

            if (index > -1) {
                state.medQuestionsIcd9.splice(index, 1)
            }
        },

        [Mutation.POZ_ADD_HISTORY_ICD](state, payload) {
            state.historyIcdToAdd = payload
            state.historyIcdTrigger += 1
        },

        [Mutation.POZ_CLEAR_HISTORY_ICD](state) {
            state.historyIcdToAdd = null
        },

        [Mutation.POZ_ADD_LEADING_ICD](state, payload) {
            state.leadingIcdToAdd = payload
            state.leadingIcdTrigger += 1
        },

        [Mutation.POZ_SET_LEADING_ICD](state, payload) {
            if (state.appointmentDiagnoses.diagnoses) {
                let index = state.appointmentDiagnoses.diagnoses.indexOf(payload)

                if (index > -1) {
                    const diagnose = state.appointmentDiagnoses.diagnoses[index]
                    diagnose.isLeading = true
                }
            }
        },

        [Mutation.POZ_CLEAR_LEADING_ICD](state) {
            if (state.appointmentDiagnoses.diagnoses) {
                state.appointmentDiagnoses.diagnoses.forEach(diag => {
                    if (diag.isLeading) {
                        diag.isLeading = false

                        if (diag.isContinuous) {
                            // good, don't change isVisibleSummary
                        } else {
                            diag.isVisibleSummary = false
                        }
                    }
                })
            }
        },

        [Mutation.POZ_SET_PROPS_ICD](state, payload) {
            if (state.appointmentDiagnoses.diagnoses) {
                let index = state.appointmentDiagnoses.diagnoses.indexOf(payload.icd)

                if (index > -1) {
                    if (payload.type === "MAIN_DIAGNOSE") {
                        state.appointmentDiagnoses.diagnoses[index].isMainDiagnose = !state.appointmentDiagnoses.diagnoses[index].isMainDiagnose
                        state.appointmentDiagnoses.diagnoses[index].isMedicalEvent = false
                        state.appointmentDiagnoses.diagnoses[index].isImportantDiagnose = false
                    } else if (payload.type === "MEDICAL_EVENT") {
                        state.appointmentDiagnoses.diagnoses[index].isMainDiagnose = false
                        state.appointmentDiagnoses.diagnoses[index].isMedicalEvent = !state.appointmentDiagnoses.diagnoses[index].isMainDiagnose
                        state.appointmentDiagnoses.diagnoses[index].isImportantDiagnose = false
                    } else if (payload.type === "STANDARD_DIAGNOSE") {
                        state.appointmentDiagnoses.diagnoses[index].isMainDiagnose = false
                        state.appointmentDiagnoses.diagnoses[index].isMedicalEvent = false
                        state.appointmentDiagnoses.diagnoses[index].isImportantDiagnose = false
                    } else if (payload.type === "CURED") {
                        state.appointmentDiagnoses.diagnoses[index].isCured = true
                        state.appointmentDiagnoses.diagnoses[index].curedNow = true
                    }
                }
            }
        },

        [Mutation.POZ_SET_PROPS_ICD_VER2](state, payload) {
            if (state.appointmentDiagnoses.diagnoses) {
                let index = state.appointmentDiagnoses.diagnoses.indexOf(payload.icd)

                if (index > -1) {
                    const diagnose = state.appointmentDiagnoses.diagnoses[index]
                    if (payload.type === "CURED") {
                        diagnose.isCured = true
                        diagnose.curedNow = true
                    } else if (payload.type === "IPOM") {
                        diagnose.isIpom = payload.value
                    } else if (payload.type === "CONTINUOUS") {
                        diagnose.isContinuous = !diagnose.isContinuous

                        if (diagnose.isContinuous) {
                            diagnose.isVisibleSummary = true
                        } else {
                            diagnose.isVisibleSummary = false
                            diagnose.isActive = false
                            diagnose.isLeading = false
                            diagnose.isIpom = false
                        }

                    } else if (payload.type === "IS_VISIBLE") {
                        diagnose.isVisibleSummary = payload.value
                    } else if (payload.type === "IS_ACTIVE") {
                        diagnose.isActive = payload.value

                        if (diagnose.isActive === false) {
                            diagnose.isVisibleSummary = false
                            diagnose.isLeading = false
                            diagnose.isIpom = false
                        }
                    }
                }
            }
        },

        [Mutation.POZ_MOVE_ICD](state, payload) {
            if (state.appointmentDiagnoses.diagnoses) {
                const index = state.appointmentDiagnoses.diagnoses.findIndex(diagnose => diagnose.icd.code === payload.icd.icd.code)
                const indexTo = state.appointmentDiagnoses.diagnoses.findIndex(diagnose => diagnose.icd.code === payload.icdTo.icd.code)

                if (index > -1 && indexTo > -1) {
                    const diagnose = state.appointmentDiagnoses.diagnoses[index]
                    state.appointmentDiagnoses.diagnoses.splice(index, 1);
                    state.appointmentDiagnoses.diagnoses.splice(indexTo, 0, diagnose);
                }
            }
        },

        [Mutation.POZ_CHECK_DIAGNOSES_ORDER](state) {
            state.appointmentDiagnoses.diagnoses.forEach(diagnose => {
                diagnose.diagnoseOrder = 0
            })

            const diagnosesLeading = state.appointmentDiagnoses.diagnoses.filter(diagnose => diagnose.isLeading === true)
            // Should ALWAYS be single diagnose
            diagnosesLeading.forEach(diagnose => {
                diagnose.diagnoseOrder = 1
                diagnose.isVisibleSummary = true
            })

            const diagnosesIsIpom = state.appointmentDiagnoses.diagnoses.filter(diagnose => diagnose.isIpom === true)
            diagnosesIsIpom.forEach(diagnose => {
                if (diagnose.isLeading === false) {
                    diagnose.isVisibleSummary = true
                }
            })

            const diagnosesVisibleOnSummary = state.appointmentDiagnoses.diagnoses.filter(diagnose => diagnose.isLeading === false && diagnose.isVisibleSummary === true && diagnose.isDeactivatedInCorrection === false)

            let orderNumber = 2
            diagnosesVisibleOnSummary.forEach(diagnose => {
                diagnose.diagnoseOrder = orderNumber
                orderNumber += 1
            })
        },

        [Mutation.POZ_SET_LAST_TMP_SAVE_DATE](state, payload) {
            state.tmpVisitLastSaveDate = payload
        },

        [Mutation.POZ_SET_TMP_SAVE_COUNTER](state, payload) {
            state.tmpVisitSaveCounter = payload
        },

        [Mutation.POZ_ADD_TMP_SAVE_COUNTER](state) {
            state.tmpVisitSaveCounter += 1
        },


        [Mutation.POZ_SET_TMP_SAVE_LOADING](state, payload) {
            state.tmpVisitSaveLoading = payload
        },

        [Mutation.POZ_SET_TMP_EXIT_LOADING](state, payload) {
            state.tmpVisitExitLoading = payload
        },

        [Mutation.POZ_EXIT_VISIT_TRIGGER_UP](state) {
            state.exitVisitTrigger += 1
        },

        [Mutation.POZ_SET_ALL_QUESTIONS](state, payload) {
            state.allQuestions = blockObserverMethod(payload)
        },

        [Mutation.POZ_DRUG_ADDED](state, payload) {
            if (payload) {
                let drug = payload
                let drugDetailsObj = {
                    name: drug.nazwaProduktu,
                    ean: null
                }
                if (drug.wybraneOpakowanie) {
                    drugDetailsObj.ean = drug.wybraneOpakowanie.kodEAN
                }
                state.drugsAddedOnVisitDetails.push(drugDetailsObj)
            }
            state.drugsAddedOnVisit = true
        },

        [Mutation.POZ_SET_PATIENT_DOCTOR_NOTES](state, payload) {
            state.patientDoctorNotes = payload
        },

        [Mutation.POZ_SET_IPOM_DIAGNOSES](state, payload) {
            let ipomDiagnoses = payload

            if (state.appointmentDiagnoses.diagnoses && ipomDiagnoses) {

                state.appointmentDiagnoses.diagnoses.forEach((diagnose) => {
                    if (diagnose.isContinuous) {
                        diagnose.isVisibleSummary = false
                    }
                })

                let ipomDiagnosesCodes = ipomDiagnoses.map((diagnose) => {
                    return diagnose.code
                });

                state.appointmentDiagnoses.diagnoses.forEach((icd) => {
                    let icdCode = icd.icd.code
                    let isIpomDiagnose = ipomDiagnosesCodes.includes(icdCode)
                    if (isIpomDiagnose) {
                        icd.ipomDiagnose = true
                        icd.isActive = true
                        icd.show = true
                    } else {
                        icd.ipomDiagnose = false
                        icd.isActive = false
                        icd.show = true
                        if (this.getters.myPermits.diagnoseV1) {
                            icd.show = false
                        }
                    }
                })

                let findLeadingDiagnose = state.appointmentDiagnoses.diagnoses.find(icd => icd.isLeading === true)
                let findIpomDiagnose = state.appointmentDiagnoses.diagnoses.find(icd => icd.ipomDiagnose === true)
                if (!findLeadingDiagnose && findIpomDiagnose) {
                    findIpomDiagnose.isLeading = true
                    findIpomDiagnose.isVisibleSummary = true
                }

                state.appointmentDiagnoses.diagnoses.sort((a, b) => {
                    return Number(b.ipomDiagnose) - Number(a.ipomDiagnose)
                })
            } else {
                if (state.appointmentDiagnoses.diagnoses) {
                    state.appointmentDiagnoses.diagnoses.forEach((icd) => {
                        icd.isVisibleSummary = false
                        icd.isActive = false
                        icd.show = true
                        if (this.getters.myPermits.diagnoseV1) {
                            icd.show = false
                        }
                    })
                }
            }
        },

        [Mutation.POZ_SET_IS_IPOM_CONSULTATION](state, payload) {
            state.ipomConsultationLimited = payload
        },

        [Mutation.POZ_SET_CONTINUOUS_DIAGNOSES_SUMMARY](currentState, payload) {
            currentState.continuousDiagnosesSummary = payload
        },

        [Mutation.POZ_SET_TRANSLATED_APPOINTMENTS_DASHBOARD](state, payload) {
            if (payload.type === "SET") {
                state.appointmentsTranslatedDashboard = payload.data
            } else if (payload.type === "CLEAR") {
                state.appointmentsTranslatedDashboard.forEach(appointment => {
                    if (appointment.base.ticketInfo) {
                        if (appointment.base.ticketInfo.procedures) {
                            appointment.base.ticketInfo.procedures.length = 0
                        }

                        if (appointment.base.ticketInfo.referral && appointment.base.ticketInfo.referral.eReferral) {
                            appointment.base.ticketInfo.referral.eReferral = null
                        }
                    }
                    appointment.base.ticketInfo = null
                    appointment.base = null
                    appointment.patient = null
                })
                const arrayLength = state.appointmentsTranslatedDashboard.length
                state.appointmentsTranslatedDashboard.splice(0, arrayLength)
            }
        },

        [Mutation.POZ_SET_TRANSLATED_PATIENT_APPOINTMENTS_DASHBOARD](state, payload) {
            if (payload.type === "SET") {
                state.appointmentsTranslatedDashboardPatient = payload.data
            } else if (payload.type === "CLEAR") {
                state.appointmentsTranslatedDashboardPatient.forEach(appointment => {
                    if (appointment.base.ticketInfo) {
                        if (appointment.base.ticketInfo.procedures) {
                            appointment.base.ticketInfo.procedures.length = 0
                        }

                        if (appointment.base.ticketInfo.referral && appointment.base.ticketInfo.referral.eReferral) {
                            appointment.base.ticketInfo.referral.eReferral = null
                        }
                    }

                    appointment.base.ticketInfo = null
                    appointment.base = null
                    appointment.patient = null
                })

                const arrayLength = state.appointmentsTranslatedDashboardPatient.length
                state.appointmentsTranslatedDashboardPatient.splice(0, arrayLength)
            }
        },

        [Mutation.POZ_SET_FOCUS_TRANSLATED_APPOINTMENT](state, payload) {
            state.focusTranslatedAppointment = payload
        },

        [Mutation.POZ_SET_FOCUS_TRANSLATED_APPOINTMENT_PROPS](state, payload) {
            let findAppointment = null

            findAppointment = state.appointmentsTranslatedDashboard.find(app => app.id == payload.id)

            if (!findAppointment) {
                findAppointment = state.appointmentsTranslatedDashboardPatient.find(app => app.id == payload.id)
            }

            if (findAppointment) {
                if (payload.type === "TICKET") {
                    findAppointment.base.ticketInfo = payload.ticket
                } else if (payload.type === "APPOINTMENT") {
                    payload.appointment.ticketInfo = findAppointment.base.ticketInfo
                    findAppointment.base = payload.appointment
                } else if (payload.type === "PATIENT") {
                    findAppointment.patient = payload.patient
                }
            }
        },

        [Mutation.POZ_SET_ONGOING_RIS](currentState, payload) {
            currentState.ongoingRis = payload
        },

        [Mutation.POZ_SET_IMMUNIZATION](currentState, payload) {
            if (payload.type === "SET") {
                currentState.resultsImmunizationAppointment = payload.data
            }

            if (payload.type == "PUSH") {
                currentState.resultsImmunizationAppointment.unshift(payload.data)
            }

            if (payload.type == "SET_ZM_PROPS") {
                let findImmunization = currentState.resultsImmunizationAppointment.find(immunization => immunization.id == payload.data.id)
                if (findImmunization) {
                    findImmunization.zmResultHelper = JSON.parse(JSON.stringify(payload.data.zmResultHelper))
                    findImmunization.zmStatus = JSON.parse(JSON.stringify(payload.data.zmStatus))
                }
            }

            if (payload.type == "SET_ZM_STATUS") {
                let findImmunization = currentState.resultsImmunizationAppointment.find(immunization => immunization.id == payload.data.id)
                if (findImmunization) {
                    findImmunization.zmStatus = JSON.parse(JSON.stringify(payload.data.zmStatus))
                }
            }
        },

        [Mutation.POZ_SET_QUALIFICATION_IMMUNIZATION](currentState, payload) {
            if (payload.type === "SET") {
                currentState.resultsImmunizationQualificationAppointment = payload.data
            }

            if (payload.type == "PUSH") {
                currentState.resultsImmunizationQualificationAppointment.unshift(payload.data)
            }

            if (payload.type == "SET_ZM_PROPS") {
                let findQualification = currentState.resultsImmunizationQualificationAppointment.find(immunization => immunization.id == payload.data.id)
                if (findQualification) {
                    findQualification.zmResultHelper = JSON.parse(JSON.stringify(payload.data.zmResultHelper))
                    findQualification.zmStatus = JSON.parse(JSON.stringify(payload.data.zmStatus))
                }
            }

            if (payload.type == "SET_ZM_STATUS") {
                let findImmunization = currentState.resultsImmunizationQualificationAppointment.find(immunization => immunization.id == payload.data.id)
                if (findImmunization) {
                    findImmunization.zmStatus = JSON.parse(JSON.stringify(payload.data.zmStatus))
                }
            }

            if (payload.type == "SET_NURSE_ORDER") {
                let findQualification = currentState.resultsImmunizationQualificationAppointment.find(immunization => immunization.id == payload.data.id)
                if (findQualification) {
                    findQualification.orderImmunization = payload.data.orderImmunization
                }
            }
        },

        [Mutation.POZ_REPLACE_PATIENT_IN_FOCUS_APPOINTMENT](currentState, payload) {
            if (currentState.focusAppointment?.patient?.id === payload?.id) {
                currentState.focusAppointment.patient = payload
            }
        },

        [Mutation.POZ_SET_PATIENT_IDENTIFICATION_INFO](currentState, payload) {
            currentState.appointmentPatientIdentificationInfo = payload
        },

        [Mutation.POZ_SET_LAST_INSURANCE_IDENTIFICATION_INFO](currentState, payload) {
            currentState.lastInsuranceIdentificationInfo = payload
        },

        [Mutation.POZ_SET_INSURANCE_IDENTIFICATION_INFO_LOADING](currentState, payload) {
            currentState.insuranceIdentificationInfoLoading = payload
        },

        [Mutation.POZ_SET_APPOINTMENT_IKZ](currentState, payload) {
            payload.forEach(ikz => {
                ikz.ikzItem.priceAdd = (ikz.ikzItem.points * ikz.ikzItem.price) / 100

                ikz.pointsIkzItem = ikz.ikzItem.points     // HELPER PROP
                ikz.priceAddIkzItem = ikz.ikzItem.priceAdd // HELPER PROP
                ikz.bum = ikz.ikzItem.bum                  // HELPER PROP
            })

            currentState.appointmentIKZ = payload
        },

        [Mutation.POZ_ADD_APPOINTMENT_IKZ](currentState, payload) {
            currentState.appointmentIKZ.push(payload)
        },

        [Mutation.POZ_DELETE_APPOINTMENT_IKZ](currentState, payload) {
            let index = currentState.appointmentIKZ.indexOf(payload)

            if (index > -1) {
                currentState.appointmentIKZ.splice(index, 1)
            }
        },    

        [Mutation.POZ_SET_APPOINTMENT_IKZ_INFO](currentState, payload) {
            let findAppointmentIKZ =  currentState.appointmentIKZ.find(ikz => ikz.ikzId === payload.ikzId)

            if (findAppointmentIKZ) {
                findAppointmentIKZ.info = payload.info
            }
        },

        [Mutation.POZ_SET_APPOINTMENT_IKZ_AMOUNT](currentState, payload) {
            let findAppointmentIKZ =  currentState.appointmentIKZ.find(ikz => ikz.ikzId === payload.ikzId)

            if (findAppointmentIKZ) {
                findAppointmentIKZ.amount = payload.amount

                if (typeof payload.amount === 'number') {
                    findAppointmentIKZ.points = findAppointmentIKZ.pointsIkzItem * payload.amount
                    findAppointmentIKZ.price = findAppointmentIKZ.priceAddIkzItem * payload.amount
                } else {
                    findAppointmentIKZ.points = findAppointmentIKZ.pointsIkzItem * 1
                    findAppointmentIKZ.price = findAppointmentIKZ.priceAddIkzItem * 1
                }
            }
        },

        [Mutation.POZ_SET_MEASUREMENTS_LAST_USED_APPOINTMENT](currentState, payload) {
            currentState.measurementsLastUsedInAppointment = payload
        }
    },

    actions: {
        /** Attempt get all workers. Uses GET request without parameter */
        [Action.POZ_BEGIN_APPOINTMENT](context) {
            return new Promise((resolve, reject) => {
                if (context.state.focusAppointment) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    let s = context.state.focusAppointment
                    let worker = s.subjectId

                    if (s.subjectId < 0) {
                        let me = context.rootState.employee.me;
                        worker = me.id
                    }

                    if (token != null) {
                        // axios({
                        //     method: 'get',
                        //     url: Apis.PLC_LOCK.replace('{id}', s.patientId),
                        //     headers: { Authorization: "Bearer " + token }
                        // }).then(() => {
                        axios({
                            method: 'get',
                            // url: Apis.POZ_START_APPOINTMENT.replace('{patientId}', s.patientId).replace('{workerId}', worker).replace('{scheduleId}', s.externalId),
                            url: Apis.PD_START_APPOINTMENT.replace('{patientId}', s.patientId),
                            headers: { Authorization: "Bearer " + token }
                        }).then((response) => {

                            context.commit(Mutation.POZ_SET_ONGOING_APPOINTMENT, context.state.focusAppointment)
                            context.commit(Mutation.POZ_SET_APPOINTMENT_INITIAL_DATA, response.data)
                            context.commit(Mutation.POZ_SET_APPOINTMENT_DIAGNOSES_INITIAL)
                            context.commit(Mutation.POZ_SORT_DIAGNOSES_ORDER)
                            context.commit(Mutation.POZ_CHECK_DIAGNOSES_ORDER)

                            let status = 1
                            if (context.state.isVisitCorrecting) {
                                status = 9
                            }

                            let scheduleUpdate = {
                                id: s.id,
                                data: {
                                    workerId: worker,
                                    status: status,
                                    assistants: context.state.assistants.map(e => e.id),
                                    version: context.state.focusAppointment.version
                                }
                            }

                            context.dispatch(Action.S2_POST_STATUS, scheduleUpdate)
                            resolve()
                        }).catch(error => { reject(error) })




                        //}).catch(error => { reject(error) })
                    } else reject('401')
                }
                else reject('no focus!!!')
            })
        },

        /** Attempt get all workers. Uses GET request without parameter */
        [Action.PD_BUNDLE_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'POST',
                        data: payload,
                        url: Apis.PD_BUNDLE_STATS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => { reject(error) })
                } else reject('401')
            })
        },

        [Action.POZ_END_APPOINTMENT](context, payload) {
            return new Promise((resolve, reject) => {

                if (context.state.focusAppointment) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    if (token != null) {
                        axios({
                            method: 'POST',
                            data: payload,
                            url: Apis.POZ_FINISH_APPOINTMENT_V3,
                            headers: { Authorization: "Bearer " + token }
                        }).then(response => {
                            // axios({
                            //     method: 'get',
                            //     url: Apis.PLC_UNLOCK.replace('{id}', payload.patientId),
                            //     headers: { Authorization: "Bearer " + token }
                            // }).then(() => {
                            resolve(response.data)
                            // }).catch(error => { reject(error) })

                        }).catch(error => { reject(error) })
                    } else reject('401')
                }
                else reject('no focus!!!')
            })
        },


        [Action.SCHEDULE_GET_APPOINTMENTS_FOR_WORKER](context, id) {

            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.SCHEDULE_GET_APPOINTMENTS_FOR_WORKER.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        if (response.data.patients) {
                            context.commit(Mutation.POZ_SET_PATIENTS, response.data.patients)
                        }

                        if (response.data.appointments) {
                            if (response.data.appointments.length > 0) {
                                let ordered = response.data.appointments.sort((a, b) => {
                                    return moment(a.date).isBefore(moment(b.date)) ? -1 : 1
                                })
                                let orderedDay = ordered[0].appointments.sort((a, b) => {
                                    return moment(a.dateTimeInfoStart).isBefore(moment(b.dateTimeInfoStart)) ? -1 : 1
                                })

                                context.commit(Mutation.POZ_SET_APPOINTMENTS, orderedDay)
                                context.commit(Mutation.POZ_SET_FOCUS_APPOINTMENT_FROM_LIST)
                            }
                        }

                        resolve(response);
                    }).catch(error => {
                        reject(error)
                    });
                } else {
                    reject('401')
                }
            })
        },


        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_GET_ALLERGIES](context, pid) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.PATIENT_GET_ALLERGIES.replace('{patientId}', pid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.PATIENT_UPDATE_ALLERGIES, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        /** Attempt to get worker. Uses GET request */
        [Action.PATIENT_UPDATE_ALLERGIES](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.PATIENT_POST_ALLERGIES,
                        data: context.state.appointmentAllergies,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.POZ_MODIFY_PATH](context, path) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.POZ_MODIFY_PATH.replace('{patientId}', path.patientId),
                        data: path,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.POZ_SET_PATHS, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.POZ_ADD_PATH](context, path) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.POZ_ADD_PATH,
                        data: path,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.POZ_ADD_PATH_M, response.data)
                        resolve();
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.POZ_GET_PATHS](context, patientId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.POZ_GET_PATHS.replace('{patientId}', patientId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        // context.commit(Mutation.POZ_SET_PATHS, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.POZ_GET_DIAGNOSES](context, patient) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.POZ_GET_ACTIVE_DIAGNOSES.replace('{patientId}', patient.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.POZ_SET_PATIENT_DIAGNOSES, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.POZ_GET_APPOINTMENTS](context, patient) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.POZ_GET_APPOINTMENTS.replace('{patientId}', patient.id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.POZ_SET_PATIENT_APPOINTMENTS, response.data)
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },
    }
}