<template>
  <div>
    <b-button expanded icon-left="plus-thick" @click="isActiveModal = true" type="is-secondary" :disabled="disabled">{{
      buttonText }}</b-button>

    <b-modal :active.sync="isActiveModal" :destroy-on-hide="true" scroll="keep" has-modal-card :can-cancel="false"
      style="z-index: 100" class="chatsize">
      <ContentPanel :rounded="true" :paddingless="true"
        :title="`Dodawanie skierowania - ${clinicTypePropsName}, ${officeFacilityName}`"
        icon="format-list-bulleted-type">
        <template slot="addon">
          <b-button size="is-small" icon-left="information-outline" class="mr-2"
            @click="mDownloadInstruction('649ac2fb985c5a0bf2564d56')">Instrukcja dodawania skanu skierowania</b-button>
          <b-button icon-left="close" size="is-small" @click="closeWindow" type="is-danger">Zamknij</b-button>
        </template>

        <div class="modal-card" style="overflow: visible; width: 100% !important;max-height:88vh;min-height:80vh;">
          <section class="modal-card-body" style="color: black">
            <b-steps v-model="tab" :rounded="false" :animated="false" :has-navigation="false" size="is-small">
              <b-step-item label="Rodzaj skierowania" icon="format-list-bulleted-type" :clickable="false">
                <ValidationObserver ref="observerReferral" v-slot="{ invalid }">
                  <!-- <div class="block"> -->
                  <div class="columns">
                    <div class="column ml-5 mr-5">
                      <ContentPanel class="mb-0" title="Dane pacjenta" icon="account" :borderless="false"
                        v-if="patient">
                        <table class="table w-100 is-narrow">
                          <tr>
                            <td>Imię i nazwisko:</td>
                            <td class="has-text-weight-medium has-text-right">{{ patient.firstName }} {{
                              patient.lastName }}
                            </td>
                          </tr>
                          <tr v-if="patient.pesel">
                            <td>Pesel:</td>
                            <td class="has-text-weight-medium has-text-right">{{ patient.pesel }}</td>
                          </tr>
                          <tr v-if="!patient.pesel">
                            <td>Dokument tożsamości:</td>
                            <td class="has-text-weight-medium has-text-right">
                              <span v-if="
                                patient.identityDocumentNumber != null &&
                                patient.identityDocumentNumber != ''
                              ">{{ patient.identityDocumentNumber }}</span>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Adres zamieszkania:</td>
                            <td class="has-text-weight-medium has-text-right">
                              <span>{{ patient.addressStreet }} {{ patient.addressHouseNumber }}</span>
                              <span
                                v-if="patient.addressApartmentNumber != null && patient.addressApartmentNumber != ''">/{{
                                patient.addressApartmentNumber }}</span>
                              <br />
                              {{ patient.addressZipCode }} {{ patient.addressCity }}
                            </td>
                          </tr>
                          <tr>
                            <td>Telefon</td>
                            <td class="has-text-weight-medium has-text-right">{{ patient.teleAddressTelephoneNumber }}
                            </td>
                          </tr>
                        </table>
                      </ContentPanel>
                    </div>
                    <div class="column ml-5 mr-5">
                      <ContentPanel title="Rodzaj skierowania" icon="format-list-bulleted-type" :borderless="false">
                        <section>
                          <b-field v-for="item in filteredReferralsType" :key="item.id">
                            <b-radio v-model="referralType" name="name" :native-value="item">{{ item.name }}</b-radio>
                          </b-field>
                        </section>
                      </ContentPanel>
                      <ContentPanel title="Karta DILO" icon="file-document"
                        v-if="appointmentType && (appointmentType.id === 1000 || appointmentType.id === 1012 || appointmentType.id === 1023 || appointmentType.id === 10000 || appointmentType.id === 10001)">
                        <b-field v-if="perms.diloDataNotRequired">
                          <b-checkbox v-model="diloDataNotRequired">Dane karty DILO zostaną dodane później</b-checkbox>
                        </b-field>

                        <b-field>
                          <ValidationProvider :rules="{
                            required: !diloDataNotRequired,
                            digitsPL: 11,
                          }" v-slot="{ errors, valid }" immediate>
                            <b-field label="Numer karty DILO (11 cyfr)" label-position="on-border" :type="{
                              'is-danger': errors[0],
                              'is-normal': valid,
                            }" :message="t(errors[0])">
                              <b-input v-model="diloNumber" :icon="!diloDataNotRequired ? 'alert-circle-outline' : ''"
                                :placeholder="!diloDataNotRequired ? 'Wymagane' : ''"></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                      </ContentPanel>

                      <template v-if="referralType && referralType.isPaperReferral == false">
                        <AppointmentAddVisitReferralP1 :officeId="officeId" :trigger="triggerP1"
                          :appointmentType="appointmentType" :registrationData="{
                            destinationType: 0,
                            referralTypeRegistration: referralType.id
                          }" :patientProp="patient" @code-change="codeP1Change" @eReferral-success="eReferralSuccess"
                          @eReferral-failure="eReferralFailure" @eReferral-confirm="addIkzReferralConfirm"
                          @eReferral-refuse="addIkzReferralRefuse" @eReferral-resign="addIkzReferralResign" />

                        <div v-if="eReferralFail">
                          <b-field>
                            <b-checkbox v-model="addEReferralScan">Dodaj skan e-skierowania</b-checkbox>
                          </b-field>

                          <ContentPanel v-if="addEReferralScan" title="Plik skierowania" icon="file"
                            :borderless="false">
                            <b-field grouped group-multiline>
                              <b-field class="file">
                                <b-upload v-model="file" @input="checkFileSize" accept=".pdf, .jpg, .jpeg, .png"
                                  :disabled="!referralType || referralFileId != null">
                                  <a class="button is-secondary">
                                    <b-icon icon="upload"></b-icon>
                                    <span>Dodaj plik skanu</span>
                                  </a>
                                </b-upload>
                                <b-button v-if="file">{{ file.name }}</b-button>
                              </b-field>
                              <!-- <b-input
                            v-model="fileDescription"
                            expanded
                            placeholder="Opis pliku, min. 10 znaków"
                              ></b-input>-->
                              <b-field>
                                <b-button @click="sendScan" type="is-secondary" :loading="loading" :disabled="!patient || !file
                                  ">Prześlij plik na serwer</b-button>
                              </b-field>
                            </b-field>
                            <b-message class="mt-3" v-if="referralFileId != null">Plik skierowania dodany</b-message>
                          </ContentPanel>

                          <ContentPanel v-if="addEReferralScan" title="Dane E-skierowania" icon="file-outline"
                            :borderless="false">
                            <b-field>
                              <ValidationProvider rules="numeric|required" v-slot="{ errors, valid }" immediate>
                                <b-field label="Root OID" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])">
                                  <b-input v-model="eReferralPaper.rootOID" icon="alert-circle-outline"
                                    placeholder="Wymagane"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>

                            <b-field>
                              <ValidationProvider rules="numeric|required" v-slot="{ errors, valid }" immediate>
                                <b-field label="ID Skierowania" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])">
                                  <b-input v-model="eReferralPaper.referralId" icon="alert-circle-outline"
                                    placeholder="Wymagane"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>

                            <b-field>
                              <ValidationProvider rules="digitsPL:4|required" v-slot="{ errors, valid }" immediate>
                                <b-field label="Kod dostępu" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])">
                                  <b-input v-model="eReferralPaper.referralCode" icon="alert-circle-outline"
                                    placeholder="Wymagane"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                          </ContentPanel>

                          <ContentPanel v-if="addEReferralScan" title="Zgoda pacjenta" icon="alert-circle-check"
                            :borderless="false">
                            <b-field grouped group-multiline>
                              <b-field class="file">
                                <b-field>
                                  <b-button @click="printIt()" class="ml-1" type="is-primary"
                                    icon-left="printer">Wydrukuj</b-button>
                                </b-field>
                                <b-upload v-model="fileStatement" @input="checkFileStatementSize"
                                  accept=".pdf, .jpg, .jpeg, .png" :disabled="!referralType || statementFileId != null">
                                  <a class="button is-secondary">
                                    <b-icon icon="upload"></b-icon>
                                    <span>Dodaj plik zgody pacjenta</span>
                                  </a>
                                </b-upload>
                                <b-button v-if="fileStatement">{{ fileStatement.name }}</b-button>
                              </b-field>
                              <!-- <b-input
                          v-model="fileStatementDescription"
                          expanded
                          placeholder="Opis pliku, min. 10 znaków"
                              ></b-input>-->
                              <b-field>
                                <b-button @click="sendScanStatement" type="is-secondary" :loading="loading" :disabled="!patient || !fileStatement
                                  ">Prześlij plik na serwer</b-button>
                              </b-field>
                              <b-message class="mt-3" v-if="statementFileId != null">Plik zgody pacjenta
                                dodany</b-message>
                            </b-field>
                          </ContentPanel>
                        </div>
                      </template>
                      <template v-else-if="referralType && referralType.isPaperReferral == true">
                        <ContentPanel title="Plik skierowania" icon="file" :borderless="false">
                          <b-field grouped group-multiline class="mb-3">
                            <b-field class="file">
                              <b-upload v-model="file" @input="checkFileSize" accept=".pdf, .jpg, .jpeg, .png"
                                :disabled="!referralType || referralFileId != null">
                                <a class="button is-secondary">
                                  <b-icon icon="upload"></b-icon>
                                  <span>Dodaj plik skanu</span>
                                </a>
                              </b-upload>
                              <b-button v-if="file">{{ file.name }}</b-button>
                            </b-field>
                            <!-- <b-input
                          v-model="fileDescription"
                          expanded
                          placeholder="Opis pliku, min. 10 znaków"
                            ></b-input>-->
                            <b-field v-if="isModalEnableForUser" class="ml-3 mb-3">
                              <ScannerButtonSmall :onlyPdfAllowed="true" @file="handleFile($event)">
                              </ScannerButtonSmall>
                            </b-field>
                            <b-field>
                              <b-button @click="sendScan" type="is-secondary" :loading="loading" :disabled="!patient || !file
                                ">Prześlij skan na serwer</b-button>
                            </b-field>
                          </b-field>
                          <b-message class="mt-3" v-if="referralFileId != null">Plik skierowania dodany</b-message>
                          <b-field class="pt-4">
                            <b-checkbox :disabled="referralFileId != null" v-model="withoutScanFile"
                              type="is-secondary">Skan skierowania zostanie dodany później</b-checkbox>
                          </b-field>
                          <b-field v-if="canHaveExternalReferral">
                            <b-checkbox type="is-orange" v-model="hasReferralInOtherSystem">
                              Skierowanie zarejestrowano w innym systemie
                              świadczeniodawcy
                              <b-tooltip multilined
                                label="Zaznacz, jeżeli skierowanie wygenerowano lub zeskanowano w innym systemie twojej organizacji."
                                class="pl-2">
                                <b-icon icon="alert-circle-outline" type="is-orange"></b-icon>
                              </b-tooltip>
                            </b-checkbox>
                          </b-field>
                        </ContentPanel>

                        <div
                          v-if="appointmentType && appointmentType.apkolce && referralType && referralType.isPaperReferral == true">
                          <ContentPanel title="APKolce - skierowanie" icon="heart-half-full" v-if="referralType">
                            <b-field v-if="perms.apkolceReferralDataNotRequired">
                              <b-checkbox v-model="apkolceReferralDataNotRequired">Dane skierowania APKolce zostaną
                                dodane później</b-checkbox>
                            </b-field>

                            <div v-if="referralType.id == 0">
                              <p>
                                Nazwa podmiotu:
                                <span class="has-text-weight-bold">"CENTRUM MEDYCZNO-DIAGNOSTYCZNE" SPÓŁKA Z OGRANICZONĄ
                                  ODPOWIEDZIALNOŚCIĄ</span>
                              </p>
                              <b-field class="mt-3">
                                <ValidationProvider :rules="{
                                  required: !apkolceReferralDataNotRequired
                                }" v-slot="{ errors, valid }" immediate>
                                  <b-field label="Wybierz poradnię:" label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                    <b-autocomplete v-model="subunitName" :open-on-focus="true"
                                      :data="filteredSubunitsInternalApkolce" :keep-first="true" field="name"
                                      @select="selectApkolceSubunit" clearable expanded
                                      :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                      :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''">
                                      <template slot-scope="props">
                                        <div style="white-space: pre-wrap">
                                          <span :class="{
                                            'has-text-success has-text-weight-bold':
                                              internalClinicId === props.option.id,
                                          }">{{ props.option.name }}</span>
                                          <br />
                                          <span>{{ props.option.facilityFullName }}</span>
                                        </div>
                                      </template>
                                    </b-autocomplete>
                                  </b-field>
                                </ValidationProvider>
                              </b-field>
                            </div>
                            <div v-else-if="referralType.id == 2">
                              <b-notification :closable="false" type="is-orange" class="mb-3 p-2"
                                style="font-size: 0.9rem;">Uwaga:
                                jeżeli nie jesteś w stanie uzupełnić pól, skorzystaj z numeru telefonu wystawiającego na
                                pieczątce ze
                                skierowania lub zmień typ wizyty na komercyjny.</b-notification>
                              <b-notification :closable="false" v-if="checkErrorExternalReferralApkolce"
                                type="is-danger" class="mb-3 p-2" style="font-size: 0.9rem;">Podano numer REGON podmiotu
                                "CENTRUM MEDYCZNO-DIAGNOSTYCZNE"
                                - zmień typ wizyty na komercyjny.</b-notification>

                              <b-field label="Wybierz poradnię" label-position="on-border">
                                <b-autocomplete v-model="subunitName" :open-on-focus="true"
                                  :data="filteredSubunitsExternalApkolce" :keep-first="true" field="name"
                                  @select="selectApkolceSubunit" clearable expanded>
                                  <template slot-scope="props">
                                    <div style="white-space: pre-wrap">
                                      <span :class="{
                                        'has-text-success has-text-weight-bold':
                                          internalClinicId === props.option.id,
                                      }">{{ props.option.name }}</span>
                                      <br />
                                      <span>{{ props.option.facilityFullName }}</span>
                                    </div>
                                  </template>
                                </b-autocomplete>
                              </b-field>
                              <b-field label="lub wykorzystaj słownik zewnętrznych podmiotów" label-position="on-border"
                                style="word-wrap: break-word; white-space: pre-wrap">
                                <b-autocomplete v-model="externalSubjectsPaperApkolceFilter"
                                  :data="externalSubjectsPaperApkolceFiltered" expanded placeholder="Wybierz podmiot..."
                                  open-on-focus clear-on-select field="title" @select="selectExternalSubjectFormula">
                                  <template #empty>
                                    <p>Brak zewnętrznych podmiotów</p>
                                  </template>
                                  <template slot-scope="props">
                                    <p v-if="props.option.title" style="
                                    word-wrap: break-word;
                                    white-space: pre-wrap;
                                    margin: 0;
                                    padding: 0;
                                  " aria-multiline="true">{{ props.option.title }}</p>
                                  </template>
                                </b-autocomplete>
                                <p class="control">
                                  <b-tooltip label="Dodaj zewnętrzny podmiot">
                                    <b-button icon-left="plus" @click="externalSubjectsPaperApkolceModal = true">Dodaj
                                      podmiot</b-button>
                                  </b-tooltip>
                                </p>
                                <p class="control" v-if="perms.admin || perms.subadmin">
                                  <b-tooltip label="Edytuj zewnętrzny podmiot">
                                    <b-button icon-left="file-edit-outline"
                                      @click="editExternalSubjectsPaperApkolceModal = true"></b-button>
                                  </b-tooltip>
                                </p>
                              </b-field>

                              <b-field class="mt-3">
                                <ValidationProvider :rules="{
                                  required: !apkolceReferralDataNotRequired
                                }" v-slot="{ errors, valid }" immediate>
                                  <b-field label="Nazwa podmiotu" label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                    <b-input v-model="referralApkolce.subject"
                                      :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                      :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                                  </b-field>
                                </ValidationProvider>
                              </b-field>
                              <b-field>
                                <ValidationProvider :rules="{
                                  required: !apkolceReferralDataNotRequired,
                                  regon14: true,
                                }" v-slot="{ errors, valid }" immediate>
                                  <b-field label="REGON (14 cyfr)" label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                    <b-input v-model="referralApkolce.regon"
                                      :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                      :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                                  </b-field>
                                </ValidationProvider>
                              </b-field>
                              <b-field>
                                <ValidationProvider :rules="{
                                  required: !apkolceReferralDataNotRequired,
                                  digitsPL: 12,
                                }" v-slot="{ errors, valid }" immediate>
                                  <b-field label="Kod część I (12 cyfr)" label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                    <b-input v-model="referralApkolce.code1st"
                                      :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                      :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                                  </b-field>
                                </ValidationProvider>
                              </b-field>
                              <b-field>
                                <ValidationProvider :rules="{
                                  required: !apkolceReferralDataNotRequired,
                                  digitsPL: 3,
                                }" v-slot="{ errors, valid }" immediate>
                                  <b-field label="Kod część VII (3 cyfry)" label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                    <b-input v-model="referralApkolce.code7th"
                                      :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                      :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                                  </b-field>
                                </ValidationProvider>
                              </b-field>
                              <b-field>
                                <ValidationProvider :rules="{
                                  required: !apkolceReferralDataNotRequired,
                                  digitsPL: 4,
                                }" v-slot="{ errors, valid }" immediate>
                                  <b-field label="Kod część VIII (4 cyfry)" label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                    <b-input v-model="referralApkolce.code8th"
                                      :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                      :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                                  </b-field>
                                </ValidationProvider>
                              </b-field>
                            </div>

                            <b-field class="mt-3">
                              <ValidationProvider rules="numPL|length:5,6" v-slot="{ errors, valid }" immediate>
                                <b-field label="Kod miejsca udzielania świadczeń (5-6 cyfr, nieobowiązkowe)"
                                  label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                  <b-input v-model="referralApkolce.musCode"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>

                            <b-field>
                              <ValidationProvider :rules="{
                                required: !apkolceReferralDataNotRequired,
                              }" v-slot="{ errors, valid }" immediate>
                                <b-field
                                  :label="!apkolceReferralDataNotRequired ? 'Data wystawienia (obowiązkowe)' : 'Data wystawienia'"
                                  label-position="on-border" :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }" :message="t(errors[0])">
                                  <b-input type="date" v-model="referralApkolce.issueDate"
                                    :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>

                            <!-- <b-field>
                            <ValidationProvider
                              :rules="{
                                    required: !apkolceReferralDataNotRequired,
                                  }"
                              v-slot="{ errors, valid }"
                            >
                              <b-field
                                :label="!apkolceReferralDataNotRequired ? 'Numer skierowania (obowiązkowe)' : 'Numer skierowania'"
                                label-position="on-border"
                                :type="{
                                    'is-danger': errors[0],
                                    'is-normal': valid,
                                  }"
                                :message="t(errors[0])"
                              >
                                <b-input
                                  type="text"
                                  v-model="referralApkolce.referralNumber"
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                ></b-input>
                              </b-field>
                            </ValidationProvider>
                            </b-field>-->
                            <b-field :label="'Numer skierowania'" label-position="on-border">
                              <b-input type="text" v-model="referralApkolce.referralNumber"></b-input>
                            </b-field>
                          </ContentPanel>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div>
                    <b-field class="has-text-centered">
                      <b-button v-if="referralType && referralType.isPaperReferral == false && !addEReferralScan"
                        :loading="isLoading" type="is-secondary" @click="referralTrigger" icon-right="arrow-right-bold"
                        :disabled="invalid ||
                          !patient ||
                          (!referralType &&
                            !codeP1.referralId &&
                            !codeP1.referralCode &&
                            !codeP1.referralPesel)
                          ">Pobierz skierowanie</b-button>
                      <b-button v-else style="width: 12rem" :loading="isLoading" class="mt-3" type="is-primary"
                        @click="nextForPaperReferral" icon-right="arrow-right-bold" :disabled="checkErrorExternalReferralApkolce ||
                          invalid ||
                          !referralType ||
                          !patient ||
                          (referralFileId == null &&
                            withoutScanFile == false &&
                            !(canHaveExternalReferral && hasReferralInOtherSystem)) ||
                          (addEReferralScan && statementFileId == null)
                          ">Dalej</b-button>
                    </b-field>
                  </div>
                </ValidationObserver>
              </b-step-item>
              <b-step-item label="Szczegóły skierowania" icon="file" :clickable="false">
                <ValidationObserver ref="observerDoctor" v-slot="{ invalid }">
                  <div class="columns is-centered">
                    <div class="column"
                      v-if="referralType && referralType.isPaperReferral == false && !addEReferralScan">
                      <section class="section" v-if="resultP1 && resultP1.eSkierowanie">
                        <!-- <div v-html="resultP1.eSkierowanie"></div>-->

                        <iframe v-if="resultP1.eSkierowanie" :srcdoc="resultP1.eSkierowanie"
                          style="width:105%;height:24rem;"></iframe>
                      </section>
                    </div>
                    <div class="column ml-5 mr-5">
                      <ContentPanel title="Dane pacjenta" icon="account" :borderless="false" v-if="patient">
                        <table class="table is-narrow border-bottom w-100">
                          <tr>
                            <td>Imię i nazwisko:</td>
                            <td class="has-text-weight-medium">{{ patient.firstName }} {{ patient.lastName }}</td>
                          </tr>
                          <tr v-if="patient.pesel">
                            <td>Pesel:</td>
                            <td class="has-text-weight-medium">{{ patient.pesel }}</td>
                          </tr>
                          <tr v-if="!patient.pesel">
                            <td>Dokument tożsamości:</td>
                            <td class="has-text-weight-medium">
                              <span v-if="
                                patient.identityDocumentNumber != null &&
                                patient.identityDocumentNumber != ''
                              ">{{ patient.identityDocumentNumber }}</span>
                              <span v-else>-</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Telefon</td>
                            <td class="has-text-weight-medium">{{ patient.teleAddressTelephoneNumber }}</td>
                          </tr>
                        </table>
                      </ContentPanel>

                      <ContentPanel title="Poradnia kierująca" icon="medical-bag" :borderless="false">
                        <template slot="addon">
                          <b-field class="pt-1">
                            <b-checkbox v-model="internalClinic"
                              :disabled="appointmentType && appointmentType.apkolce && referralType && referralType.isPaperReferral == true && referralType.id == 0"
                              type="is-orange" :class="{ 'has-text-weight-semibold': internalClinic }">Poradnia
                              wewnętrzna</b-checkbox>
                          </b-field>
                        </template>
                        <div
                          v-if="appointmentType && appointmentType.apkolce && referralType && referralType.isPaperReferral == true && referralType.id == 0">
                          <b-field>
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Wybierz poradnię:" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-autocomplete v-model="subunitName" :open-on-focus="true"
                                  :data="filteredSubunitsInternalApkolce" :keep-first="true" field="name"
                                  @select="selectApkolceSubunit" clearable expanded
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''">
                                  <template slot-scope="props">
                                    <div style="white-space: pre-wrap">
                                      <span :class="{
                                        'has-text-success has-text-weight-bold':
                                          internalClinicId === props.option.id,
                                      }">{{ props.option.name }}</span>
                                      <br />
                                      <span>{{ props.option.facilityFullName }}</span>
                                    </div>
                                  </template>
                                </b-autocomplete>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div
                          v-else-if="appointmentType && appointmentType.apkolce && referralType && referralType.isPaperReferral == false && referralType.id == 1">
                          <b-field v-if="internalClinic">
                            <b-checkbox v-model="filterSubunitsDomain" type="is-primary"
                              :class="{ 'has-text-weight-semibold': filterSubunitsDomain }">Poradnia wewnątrz
                              domeny</b-checkbox>
                          </b-field>
                          <b-field v-if="internalClinic">
                            <ValidationProvider :rules="{
                              required: false,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Wybierz poradnię:" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-autocomplete v-model="subunitName" :open-on-focus="true"
                                  :data="filteredSubunitsDomain" :keep-first="true" field="name"
                                  @select="selectApkolceSubunit" clearable expanded>
                                  <template slot-scope="props">
                                    <div style="white-space: pre-wrap">
                                      <span :class="{
                                        'has-text-success has-text-weight-bold':
                                          internalClinicId === props.option.id,
                                      }">{{ props.option.name }}</span>
                                      <br />
                                      <span>{{ props.option.facilityFullName }}</span>
                                    </div>
                                  </template>
                                </b-autocomplete>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field label="Poradnia kierująca" v-else label-position="on-border">
                            <b-input v-model="referralClinic"></b-input>
                          </b-field>
                        </div>
                        <div
                          v-else-if="appointmentType && appointmentType.apkolce && referralType && referralType.isPaperReferral == true && referralType.id == 2">
                          <b-field v-if="internalClinic">
                            <ValidationProvider :rules="{
                              required: false,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Wybierz poradnię:" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-autocomplete v-model="subunitName" :open-on-focus="true"
                                  :data="filteredSubunitsExternalApkolce" :keep-first="true" field="name"
                                  @select="selectApkolceSubunit" clearable expanded>
                                  <template slot-scope="props">
                                    <div style="white-space: pre-wrap">
                                      <span :class="{
                                        'has-text-success has-text-weight-bold':
                                          internalClinicId === props.option.id,
                                      }">{{ props.option.name }}</span>
                                      <br />
                                      <span>{{ props.option.facilityFullName }}</span>
                                    </div>
                                  </template>
                                </b-autocomplete>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field label="Poradnia kierująca" v-else label-position="on-border">
                            <b-input v-model="referralClinic"></b-input>
                          </b-field>
                        </div>
                        <div v-else>
                          <b-field v-if="internalClinic">
                            <b-checkbox v-model="filterSubunitsDomain" type="is-primary"
                              :class="{ 'has-text-weight-semibold': filterSubunitsDomain }">Poradnia wewnątrz
                              domeny</b-checkbox>
                          </b-field>
                          <b-field label="Wybierz poradnię:" v-if="internalClinic" label-position="on-border">
                            <b-autocomplete v-model="subunitName" :open-on-focus="true" :data="filteredSubunitsDomain"
                              :keep-first="true" field="name" @select="(option) =>
                                  option != null
                                    ? (internalClinicId = option.id)
                                    : (internalClinicId = null)
                                " clearable expanded>
                              <template slot-scope="props">
                                <div style="white-space: pre-wrap">
                                  <span :class="{
                                    'has-text-success has-text-weight-bold':
                                      internalClinicId === props.option.id,
                                  }">{{ props.option.name }}</span>
                                  <br />
                                  <span>{{ props.option.facilityFullName }}</span>
                                </div>
                              </template>
                            </b-autocomplete>
                          </b-field>
                          <b-field label="Poradnia kierująca" v-else label-position="on-border">
                            <b-input v-model="referralClinic"></b-input>
                          </b-field>
                        </div>
                      </ContentPanel>

                      <ContentPanel title="Lekarz kierujący" icon="briefcase-account" :borderless="false">
                        <template slot="addon">
                          <b-field class="pt-1">
                            <b-checkbox v-model="internalDoctor" type="is-orange"
                              :class="{ 'has-text-weight-semibold': internalDoctor }">Lekarz wewnętrzny</b-checkbox>
                            <b-tag v-if="
                              internalDoctor && referredBy < 0 && referralType
                            " :class="{
                            'is-warning':
                              referralType.isDoctorRequired == false,
                            'is-danger': referralType.isDoctorRequired == true,
                          }">
                              {{
                                referralType.isDoctorRequired == false
                                  ? "(Nie wymagane)"
                                  : "(wymagane)"
                              }}
                            </b-tag>
                            <b-tag v-if="
                              !internalDoctor &&
                              (referralDoctorFirstName.length <= 0 ||
                                referralDoctorLastName.length <= 0) &&
                              referralType
                            " :class="{
                            'is-warning':
                              referralType.isDoctorRequired == false,
                            'is-danger': referralType.isDoctorRequired == true,
                          }">
                              {{
                                referralType.isDoctorRequired == false
                                  ? "(Nie wymagane)"
                                  : "(wymagane)"
                              }}
                            </b-tag>
                          </b-field>
                        </template>
                        <b-field v-if="internalDoctor">
                          <ValidationProvider :rules="{
                            required:
                              referralType && referralType.isDoctorRequired,
                          }" v-slot="{ errors, valid }" immediate>
                            <b-field label="Lekarz" label-position="on-border" :type="{
                              'is-danger': errors[0],
                              'is-normal': valid,
                            }" :message="t(errors[0])">
                              <b-autocomplete :data="filteredEmployees" v-model="employeeName"
                                :icon="referralType && referralType.isDoctorRequired ? 'alert-circle-outline' : ''"
                                :placeholder="referralType && referralType.isDoctorRequired ? 'Wyszukaj pracownika (wymagane)' : 'Wyszukaj pracownika'"
                                open-on-focus field="name" @select="(option) =>
                                    option != null
                                      ? (referredBy = option.id)
                                      : (referredBy = -1)
                                  " :keep-first="true">
                                <template slot-scope="props">
                                  <span>
                                    {{ props.option.name }} (PWZ:{{
                                      props.option.pwz
                                    }})
                                  </span>
                                </template>
                              </b-autocomplete>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                        <div v-else>
                          <b-field label="Słownik zewnętrznych lekarzy" label-position="on-border"
                            style="word-wrap: break-word; white-space: pre-wrap">
                            <b-autocomplete v-model="externalDoctorFormulaFilter" :data="externalDoctorFormulaFiltered"
                              expanded placeholder="Wybierz lekarza..." open-on-focus clear-on-select field="title"
                              @select="selectExternalDoctorFormula">
                              <template #empty>
                                <p>Brak zewnętrznych lekarzy</p>
                              </template>
                              <template slot-scope="props">
                                <p v-if="props.option.title" style="
                                    word-wrap: break-word;
                                    white-space: pre-wrap;
                                    margin: 0;
                                    padding: 0;
                                  " aria-multiline="true">{{ props.option.title }}</p>
                              </template>
                            </b-autocomplete>
                            <p class="control">
                              <b-tooltip label="Dodaj zewnętrznego lekarza">
                                <b-button icon-left="plus" @click="externalDoctorFormulaModal = true">Dodaj
                                  lekarza</b-button>
                              </b-tooltip>
                            </p>
                          </b-field>
                          <b-field grouped>
                            <b-field expanded>
                              <ValidationProvider rules v-slot="{ errors, valid }" immediate>
                                <b-field label="Tytuł" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])">
                                  <b-input v-model="referralDoctorPrefix"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                            <b-field expanded>
                              <ValidationProvider :rules="{
                                required: appointmentType != -1 && appointmentType && appointmentType.apkolce && !apkolceReferralDataNotRequired,
                                pwznumber: true,
                              }" v-slot="{ errors, valid }" immediate>
                                <b-field label="PWZ" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])">
                                  <b-input v-model="referralDoctorPwz"
                                    :icon="appointmentType != -1 && appointmentType && appointmentType.apkolce && !apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                    :placeholder="appointmentType != -1 && appointmentType && appointmentType.apkolce && !apkolceReferralDataNotRequired ? 'Wwymagane' : ''"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                          </b-field>
                          <b-field grouped>
                            <b-field expanded>
                              <ValidationProvider :rules="{
                                required: referralType && referralType.isDoctorRequired,
                                name: true,
                              }" v-slot="{ errors, valid }" immediate>
                                <b-field label="Imie:" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])" expanded>
                                  <b-input
                                    :icon="referralType && referralType.isDoctorRequired ? 'alert-circle-outline' : ''"
                                    :placeholder="referralType && referralType.isDoctorRequired ? 'Wymagane' : ''"
                                    v-model="referralDoctorFirstName" expanded></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                            <b-field expanded>
                              <ValidationProvider :rules="{
                                required: referralType && referralType.isDoctorRequired,
                                alpha_space_betwen_wordsPL: true,
                              }" v-slot="{ errors, valid }" immediate>
                                <b-field label="Nazwisko:" label-position="on-border" :type="{
                                  'is-danger': errors[0],
                                  'is-normal': valid,
                                }" :message="t(errors[0])" expanded>
                                  <b-input
                                    :icon="referralType && referralType.isDoctorRequired ? 'alert-circle-outline' : ''"
                                    :placeholder="referralType && referralType.isDoctorRequired ? 'Wymagane' : ''"
                                    expanded v-model="referralDoctorLastName"></b-input>
                                </b-field>
                              </ValidationProvider>
                            </b-field>
                          </b-field>
                        </div>
                      </ContentPanel>

                      <template v-if="referralType && referralType.isContractorAllowed &&
                        (appointmentType != -1 &&
                          appointmentType &&
                          !appointmentType.apkolce)
                      ">
                        <ContentPanel title="Kontrahent" icon="account-star" :borderless="false">
                          <template slot="addon">
                            <b-field>
                              <b-checkbox v-model="showAllContractors" type="is-orange"
                                :class="{ 'has-text-weight-semibold': showAllContractors }">Wszyscy
                                kontrahenci</b-checkbox>
                            </b-field>
                          </template>
                          <b-field expanded>
                            <b-field label="Kontrahent" label-position="on-border" expanded>
                              <b-autocomplete v-model="contractorInput" :data="filteredSubjectsContractor" clearable
                                field="nameFull" :keep-first="true" :open-on-focus="true" @select="(option) =>
                                    (contractor = option ? option.id : null)
                                  ">
                                <template slot-scope="props">
                                  <p :class="{
                                    'has-text-success has-text-weight-bold':
                                      contractor === props.option.id,
                                  }">{{ props.option.nameFull }}</p>
                                  <p class="mt-0" v-if="props.option.nameShort">Skrót: {{ props.option.nameShort }}</p>
                                </template>
                              </b-autocomplete>
                            </b-field>
                          </b-field>
                        </ContentPanel>
                      </template>

                      <div class="mt-5 pt-5">
                        <b-button type="is-secondary" @click="previewStep" :loading="isLoading"
                          icon-left="arrow-left-bold" class="mr-2">Cofnij</b-button>

                        <b-button v-if="
                          appointmentType != -1 &&
                          appointmentType &&
                          appointmentType.apkolce
                        " class="is-pulled-right" :loading="isLoading" style="width: 12rem" type="is-primary"
                          @click="goNextApkolce" icon-right="arrow-right-bold" :disabled="invalid ||
                            ikzAdded.length < 1 ||
                            patient == null ||
                            (filteredExaminationImageByClinic.length > 0 &&
                              referralProcedures.length == 0) ||
                            selectClinicType == null ||
                            (referralType &&
                              referralType.isDoctorRequired == true &&
                              internalDoctor &&
                              referredBy < 0)
                            ">Dalej</b-button>
                        <b-button v-else class="is-pulled-right" :loading="isLoading" style="width: 12rem"
                          type="is-primary" @click="generateReferralTicket" icon-left="check-bold" :disabled="invalid ||
                            ikzAdded.length < 1 ||
                            patient == null ||
                            (filteredExaminationImageByClinic.length > 0 &&
                              referralProcedures.length == 0) ||
                            selectClinicType == null ||
                            (referralType &&
                              referralType.isDoctorRequired == true &&
                              internalDoctor &&
                              referredBy < 0)
                            ">Realizuj</b-button>
                      </div>
                    </div>
                    <div class="column ml-5 mr-5">
                      <ContentPanel title="Płatnik wizyty" icon="cash">
                        <div v-if="appointmentType != null">
                          <b-field grouped>
                            <b-radio-button type="is-primary" v-for="payerItem in appPayers" :key="payerItem.id"
                              :native-value="payerItem.id" v-model="payerType">
                              <b-icon class="mr-2" :icon="payerItem.icon"></b-icon>
                              {{ payerItem.name }}
                            </b-radio-button>
                            <b-checkbox-button class="mr-1" :native-value="true" type="is-secondary"
                              v-if="payerType === 0" v-model="isPaid">Opłacona</b-checkbox-button>
                          </b-field>
                        </div>
                      </ContentPanel>

                      <ContentPanel title="Miejsce odesłania wyniku" icon="home">
                        <b-field expanded>
                          <ValidationProvider :rules="{
                            required: !isRISDens,
                          }" v-slot="{ errors, valid }" immediate>
                            <b-field label="Miejsce odesłania wyniku" label-position="on-border" :type="{
                              'is-danger': errors[0],
                              'is-normal': valid,
                            }" :message="t(errors[0])">
                              <b-autocomplete :data="facilitiesFiltered" v-model="facilityFilter" placeholder="Wybierz"
                                open-on-focus clearable field="name"
                                @select="(option) => resultSentToLocation = option ? option.internalId : null">
                                <template slot-scope="props">
                                  <p>{{ props.option.name }}</p>
                                </template>
                              </b-autocomplete>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                      </ContentPanel>

                      <ContentPanel title="Usługa" icon="format-list-bulleted-square">
                        <div style="min-height: 2.5rem">
                          <template slot="addon">
                            <b-tag v-if="ikzAdded.length < 1" type="is-danger" rounded>Wymagane</b-tag>
                          </template>
                          <b-field label="Szukaj usługi" label-position="on-border" open-on-focus
                            v-if="ikzAdded.length == 0" :type="ikzAdded.length < 1 ? 'is-danger' : ''">
                            <b-autocomplete ref="ikzAutocomplete" :open-on-focus="true" :data="filteredIkz"
                              clear-on-select field="pharse"
                              placeholder="Zacznij pisać, aby wyświetlić sugerowane usługi" @select="ikzSelected"
                              v-model="ikzFilter">
                              <template slot-scope="props">
                                <div style="white-space: pre-wrap">
                                  <b-tag v-if="props.option.icd9" type="is-secondary" class="mr-1">{{ props.option.icd9
                                    }}</b-tag>
                                  <b-tag v-if="props.option.bum" type="is-primary" class="mr-1">{{ props.option.bum
                                    }}</b-tag>
                                  <b-icon custom-size="1rem" icon="heart-half-full" class="mr-1"
                                    v-if="props.option.source == 6"></b-icon>
                                  <span class="has-text-weight-semibold">{{ props.option.name }}</span>
                                  <small class="ml-1">{{ props.option.code }}</small>
                                </div>
                              </template>
                            </b-autocomplete>
                          </b-field>
                          <div v-for="(item, index) in ikzAdded" :key="item.index">
                            <table class="w-100">
                              <tr>
                                <td style="width: 1rem; padding-top: 0.1rem">
                                  <b-tag v-if="item.source == 6" type="is-secondary" class="ml-1 mr-2">{{ item.bum
                                    }}</b-tag>
                                  <b-tag v-if="item.icd9" type="is-secondary" class="ml-1 mr-2">{{ item.icd9 }}</b-tag>
                                </td>
                                <td style="padding-top: 0.1rem">
                                  <span style="has-text-weight-semibold">{{ item.name }}</span>
                                </td>
                                <td class="has-text-right">
                                  <!-- <b-button
                                  icon-left="delete"
                                  type="is-danger"
                                  size="is-small"
                                  @click="removeIkz(index)"
                                  >Usuń</b-button
                                  >-->
                                  <b-tooltip label="Usuń" type="is-danger">
                                    <b-icon @click.native="removeIkz(index)" icon="close-circle-outline"
                                      type="is-danger" style="cursor: pointer"></b-icon>
                                  </b-tooltip>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </ContentPanel>

                      <RISWorkshopAddExamination v-if="
                        selectClinicType != null &&
                        referralType != null &&
                        payerType != null
                      " :selectClinicType="selectClinicType" @add-procedure="addExamination"
                        :readonly="cantAddExamination" :examSuggestion="suggestedExaminationAdd"
                        :deviceType="deviceType" :payerType="payerType" :referralType="referralType"
                        :services="selectedService" />

                      <ContentPanel title="Dodane badania" icon="plus-outline">
                        <b-table :data="referralProcedures" narrowed hoverable>
                          <b-table-column v-slot="props" sortable field="description" label="Nazwa badania">
                            <b-tag v-if="props.row.icd9" type="is-secondary" class="ml-1 mr-2">{{ props.row.icd9
                              }}</b-tag>
                            {{ props.row.description }}
                          </b-table-column>
                          <b-table-column v-slot="props" width="1" label="Strona">
                            <span v-if="props.row.bodySide" class="has-text-weight-bold">{{ props.row.bodySide }}</span>
                          </b-table-column>
                          <b-table-column v-slot="props" width="1" label="NFZ">
                            <b-icon v-if="props.row.isNfz" type="is-success" icon="check-circle-outline"></b-icon>
                            <b-icon size="is-small" v-else type="is-danger" icon="close-circle-outline"></b-icon>
                          </b-table-column>
                          <b-table-column v-slot="props" width="1">
                            <b-tooltip label="Usuń" type="is-danger">
                              <b-icon @click.native="referralProcedures.splice(props.index, 1)"
                                icon="close-circle-outline" type="is-danger" style="cursor: pointer"></b-icon>
                            </b-tooltip>
                            <!-- <b-button
                          icon-left="delete"
                          type="is-danger"
                          size="is-small"
                          @click="referralProcedures.splice(props.index, 1)"
                          >Usuń</b-button
                            >-->
                          </b-table-column>

                          <template slot="empty">
                            <p class="has-text-danger">Brak dodanych badań</p>
                          </template>
                        </b-table>
                      </ContentPanel>
                    </div>
                  </div>
                </ValidationObserver>
              </b-step-item>
              <b-step-item label="APKolce" icon="heart-half-full" :clickable="false" v-if="
                appointmentType != -1 &&
                appointmentType &&
                appointmentType.apkolce
              ">
                <ValidationObserver ref="observerAPKolce" v-slot="{ invalid }">
                  <div class="columns is-centered">
                    <div class="column"
                      v-if="referralType && referralType.isPaperReferral == false && !addEReferralScan">
                      <section class="section" v-if="resultP1 && resultP1.eSkierowanie">
                        <!-- <div v-html="resultP1.eSkierowanie"></div> -->
                        <iframe v-if="resultP1.eSkierowanie" :srcdoc="resultP1.eSkierowanie"
                          style="width:105%;height:24rem;"></iframe>
                      </section>
                    </div>

                    <div class="column ml-5 mr-5">
                      <ContentPanel title="APKolce - skierowanie" icon="heart-half-full" v-if="referralType">
                        <b-field v-if="perms.apkolceReferralDataNotRequired">
                          <b-checkbox v-model="apkolceReferralDataNotRequired">Dane skierowania APKolce zostaną dodane
                            później</b-checkbox>
                        </b-field>

                        <div v-if="referralType.id == 0">
                          <p>
                            Nazwa podmiotu:
                            <span class="has-text-weight-bold">"CENTRUM MEDYCZNO-DIAGNOSTYCZNE" SPÓŁKA Z OGRANICZONĄ
                              ODPOWIEDZIALNOŚCIĄ</span>
                          </p>
                          <b-field class="mt-3">
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Wybierz poradnię:" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-autocomplete v-model="subunitName" :open-on-focus="true"
                                  :data="filteredSubunitsInternalApkolce" :keep-first="true" field="name"
                                  @select="selectApkolceSubunit" clearable expanded
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''">
                                  <template slot-scope="props">
                                    <div style="white-space: pre-wrap">
                                      <span :class="{
                                        'has-text-success has-text-weight-bold':
                                          internalClinicId === props.option.id,
                                      }">{{ props.option.name }}</span>
                                      <br />
                                      <span>{{ props.option.facilityFullName }}</span>
                                    </div>
                                  </template>
                                </b-autocomplete>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                        </div>
                        <div v-else-if="referralType.id == 2 || referralType.id == 1">
                          <b-notification :closable="false" type="is-orange" class="mb-3 p-2"
                            style="font-size: 0.9rem;">Uwaga: jeżeli
                            nie jesteś w stanie uzupełnić pól, skorzystaj z numeru telefonu wystawiającego na pieczątce
                            ze skierowania
                            lub zmień typ wizyty na komercyjny.</b-notification>
                          <b-notification :closable="false" v-if="checkErrorExternalReferralApkolce" type="is-danger"
                            class="mb-3 p-2" style="font-size: 0.9rem;">Podano numer REGON podmiotu "CENTRUM
                            MEDYCZNO-DIAGNOSTYCZNE" - zmień typ wizyty
                            na komercyjny.</b-notification>
                          <b-field v-if="referralType.id == 2" label="Wybierz poradnię" label-position="on-border">
                            <b-autocomplete v-model="subunitName" :open-on-focus="true"
                              :data="filteredSubunitsExternalApkolce" :keep-first="true" field="name"
                              @select="selectApkolceSubunit" clearable expanded>
                              <template slot-scope="props">
                                <div style="white-space: pre-wrap">
                                  <span :class="{
                                    'has-text-success has-text-weight-bold':
                                      internalClinicId === props.option.id,
                                  }">{{ props.option.name }}</span>
                                  <br />
                                  <span>{{ props.option.facilityFullName }}</span>
                                </div>
                              </template>
                            </b-autocomplete>
                          </b-field>
                          <b-field label="Słownik zewnętrznych podmiotów" label-position="on-border"
                            style="word-wrap: break-word; white-space: pre-wrap">
                            <b-autocomplete v-model="externalSubjectsPaperApkolceFilter"
                              :data="externalSubjectsPaperApkolceFiltered" expanded placeholder="Wybierz podmiot..."
                              open-on-focus clear-on-select field="title" @select="selectExternalSubjectFormula">
                              <template #empty>
                                <p>Brak zewnętrznych podmiotów</p>
                              </template>
                              <template slot-scope="props">
                                <p v-if="props.option.title" style="
                                    word-wrap: break-word;
                                    white-space: pre-wrap;
                                    margin: 0;
                                    padding: 0;
                                  " aria-multiline="true">{{ props.option.title }}</p>
                              </template>
                            </b-autocomplete>
                            <p class="control">
                              <b-tooltip label="Dodaj zewnętrzny podmiot">
                                <b-button icon-left="plus" @click="externalSubjectsPaperApkolceModal = true">Dodaj
                                  podmiot</b-button>
                              </b-tooltip>
                            </p>
                          </b-field>

                          <b-field class="mt-3">
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Nazwa podmiotu" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-input v-model="referralApkolce.subject"
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                              regon14: true,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="REGON (14 cyfr)" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-input v-model="referralApkolce.regon"
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                              digitsPL: 12,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Kod część I (12 cyfr)" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-input v-model="referralApkolce.code1st"
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                              digitsPL: 3,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Kod część VII (3 cyfry)" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-input v-model="referralApkolce.code7th"
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                          <b-field>
                            <ValidationProvider :rules="{
                              required: !apkolceReferralDataNotRequired,
                              digitsPL: 4,
                            }" v-slot="{ errors, valid }" immediate>
                              <b-field label="Kod część VIII (4 cyfry)" label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                                <b-input v-model="referralApkolce.code8th"
                                  :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                                  :placeholder="!apkolceReferralDataNotRequired ? 'Wymagane' : ''"></b-input>
                              </b-field>
                            </ValidationProvider>
                          </b-field>
                        </div>

                        <b-field class="mt-3">
                          <ValidationProvider rules="numPL|length:5,6" v-slot="{ errors, valid }" immediate>
                            <b-field label="Kod miejsca udzielania świadczeń (5-6 cyfr, nieobowiązkowe)"
                              label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                              <b-input v-model="referralApkolce.musCode"></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>

                        <b-field>
                          <ValidationProvider :rules="{
                            required: !apkolceReferralDataNotRequired,
                          }" v-slot="{ errors, valid }" immediate>
                            <b-field
                              :label="!apkolceReferralDataNotRequired ? 'Data wystawienia (obowiązkowe)' : 'Data wystawienia'"
                              label-position="on-border" :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }" :message="t(errors[0])">
                              <b-input type="date" v-model="referralApkolce.issueDate"
                                :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"></b-input>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                        <!-- <b-field v-if="referralType.id == 2 || referralType.id == 0">
                        <ValidationProvider
                          :rules="{
                                required: !apkolceReferralDataNotRequired,
                              }"
                          v-slot="{ errors, valid }"
                        >
                          <b-field
                            :label="!apkolceReferralDataNotRequired ? 'Numer skierowania (obowiązkowe)' : 'Numer skierowania'"
                            label-position="on-border"
                            :type="{
                                'is-danger': errors[0],
                                'is-normal': valid,
                              }"
                            :message="t(errors[0])"
                          >
                            <b-input
                              type="text"
                              v-model="referralApkolce.referralNumber"
                              :icon="!apkolceReferralDataNotRequired ? 'alert-circle-outline' : ''"
                            ></b-input>
                          </b-field>
                        </ValidationProvider>
                        </b-field>-->
                        <b-field v-if="referralType.id == 2 || referralType.id == 0" :label="'Numer skierowania'"
                          label-position="on-border">
                          <b-input type="text" v-model="referralApkolce.referralNumber"></b-input>
                        </b-field>
                      </ContentPanel>
                    </div>
                    <div class="column ml-5 mr-5">
                      <ContentPanel v-if="
                        appointmentType != -1 &&
                        appointmentType &&
                        appointmentType.apkolce
                      " title="APKolce" icon="heart-half-full">
                        <b-field label="Przypadek" label-position="on-border" class="pt-3 mb-4 mt-1">
                          <b-radio v-model="isUrgent" :native-value="false">stabilny</b-radio>
                          <b-radio v-model="isUrgent" :native-value="true">pilny</b-radio>
                        </b-field>
                        <b-field class="mt-3">
                          <ValidationProvider :rules="{
                            required: true
                          }" v-slot="{ errors, valid }" immediate>
                            <b-field label="Status" label-position="on-border" :type="{
                              'is-danger': errors[0],
                              'is-normal': valid,
                            }" :message="t(errors[0])">
                              <b-select v-model="registrationType" expanded icon="alert-circle-outline"
                                placeholder="Wymagane">
                                <option>Przyjęty na bieżąco</option>
                                <option>Oczekujący</option>
                                <option>Przyjęty w stanie nagłym</option>
                                <option>Kontynuujący leczenie</option>
                                <option>Posiadający prawo do korzystania poza kolejnością</option>
                                <option>Oczekujący objęty diagnostyką onkologiczną lub leczeniem onkologicznym</option>
                              </b-select>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                        <Icd10SearchBar @selected="choosenIcd10" :referralIcd10Code="referralIcd10Code"
                          labelText="Wybierz rozpoznanie główne" class="mt-3 is-small"></Icd10SearchBar>
                        <div v-if="selectedIcd10" style="font-size: 0.9rem; width: 100%" class="mb-4">
                          <p style="font-size: 0.8rem">
                            Wybrane główne rozpoznanie:
                            <span class="is-pulled-right">
                              <b-tooltip label="Usuń" type="is-danger">
                                <b-icon @click.native="removeIcd10()" icon="close-circle-outline" type="is-danger"
                                  style="cursor: pointer"></b-icon>
                              </b-tooltip>
                            </span>
                          </p>

                          <p class="has-text-success has-text-weight-medium mt-1 ml-2">{{ selectedIcd10.code }} - {{
                            selectedIcd10.desc }}</p>
                        </div>
                        <div v-else style="font-size: 0.9rem; width: 100%" class="mb-4">
                          <p class="has-text-danger has-text-weight-medium mt-1 ml-2">Brak wybranego rozpoznania</p>
                        </div>

                        <Icd10SearchBar @selected="choosenIcd10Additional"
                          :labelText="'Wybierz rozpoznania współistniejące'" class="mt-3 is-small"></Icd10SearchBar>
                        <div v-if="selectedIcd10Additional.length > 0" style="font-size: 0.9rem; width: 100%"
                          class="mt-3">
                          <div v-for="(item, index) in selectedIcd10Additional" :key="index" class="mt-1">
                            <span>{{ item.code }} - {{ item.desc }}</span>
                            <span class="is-pulled-right">
                              <b-tooltip label="Usuń" type="is-danger">
                                <b-icon @click.native="selectedIcd10Additional.splice(index, 1)"
                                  icon="close-circle-outline" type="is-danger" style="cursor: pointer"></b-icon>
                              </b-tooltip>
                            </span>
                            <hr class="m-0 p-0 mt-1" />
                          </div>
                        </div>
                        <hr class="p-0 m-0 mt-3 mb-3" />
                        <b-field label="Komentarz" label-position="on-border">
                          <b-input v-model="comment" type="textarea" :rows="2"></b-input>
                        </b-field>
                        <b-field class="mt-3">
                          <ValidationProvider :rules="{
                            required: bodyAreas.length < 1
                          }" v-slot="{ errors, valid }" immediate>
                            <b-field label="Okolice ciała (wymagane)" label-position="on-border" :type="{
                              'is-danger': errors[0],
                              'is-normal': valid,
                            }" :message="t(errors[0])">
                              <b-autocomplete :data="filteredBodyAreas" v-model="bodyArea" placeholder="Wybierz"
                                open-on-focus field="name" group-field="type" group-options="items" clearable
                                @select="addBodyArea" clear-on-select>
                                <template #group="category">
                                  <b-tag type="is-light" class="ml-0 mr-2">
                                    <strong>{{ category.group.codeMaster }}</strong>
                                  </b-tag>
                                  <span class="mt-2 has-text-weight-bold has-text-grey">{{ category.group.nameMaster
                                    }}</span>
                                  <span class="has-text-grey" style="font-size:0.75rem">(Kategoria główna - nie można
                                    wybrać)</span>
                                </template>
                                <template slot-scope="props">
                                  <div :class="props.option.level > 2 ? 'ml-4' : 'ml-2'">
                                    <b-tag v-if="props.option.codeOKC" type="is-secondary" class="mr-2">
                                      <strong>{{ props.option.codeOKC }}</strong>
                                    </b-tag>
                                    <span class="mt-2 has-text-weight-bold">{{ props.option.name }}</span>
                                    <hr class="mt-0 mb-0" />
                                  </div>
                                </template>
                              </b-autocomplete>
                            </b-field>
                          </ValidationProvider>
                        </b-field>
                        <div v-if="bodyAreas.length > 0" style="font-size: 0.9rem; width: 100%" class="mt-3">
                          <div v-for="(item, item_index) in bodyAreas" :key="item_index" class="mt-1">
                            <b-tag v-if="item.codeOKC" type="is-primary">
                              {{
                                item.codeOKC
                              }}
                            </b-tag>
                            <span class="ml-2">{{ item.name }}</span>
                            <span class="is-pulled-right">
                              <b-tooltip label="Usuń" type="is-danger">
                                <b-icon @click.native="
                                  bodyAreas.splice(item_index, 1)
                                  " icon="close-circle-outline" type="is-danger" style="cursor: pointer"></b-icon>
                              </b-tooltip>
                            </span>
                            <hr class="m-0 p-0 mt-1" />
                          </div>
                        </div>
                        <div v-else style="font-size: 0.9rem; width: 100%" class="mb-4">
                          <p class="has-text-danger has-text-weight-medium mt-1 ml-2">Brak wybranych okolic ciała</p>
                        </div>
                      </ContentPanel>
                      <div class="mt-5 pt-5 has-text-centered">
                        <b-button type="is-secondary" @click="previewStepApkolce" :loading="isLoading"
                          icon-left="arrow-left-bold" class="mr-2">Cofnij</b-button>

                        <b-button :loading="isLoading" style="width: 12rem" type="is-primary"
                          @click="generateReferralTicket" icon-left="check-bold" :disabled="invalid ||
                            bodyAreas.length < 1 ||
                            !selectedIcd10
                            ">Realizuj</b-button>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </b-step-item>
            </b-steps>
          </section>
        </div>
      </ContentPanel>
    </b-modal>

    <AppointmentCancelReferralP1 @eReferral-canceled="cancelledEReferral" :trigger="triggerCancelReferral"
      :result="resultP1" :patientProp="patient" />

    <ValidationObserver ref="observerExternalDoctor" v-slot="{ invalid }">
      <b-modal v-model="externalDoctorFormulaModal" has-modal-card aria-role="dialog" aria-modal trap-focus
        :destroy-on-hide="false" style="z-index: 200">
        <div class="modal-card has-background-kin">
          <header class="modal-card-head has-background-kin">
            <p class="modal-card-title has-text-primelight" style="line-height: 2rem">Dodawanie zewnętrznego lekarza do
              słownika</p>
          </header>
          <section class="modal-card-body">
            <b-notification :closable="false" v-if="externalDoctorExists" type="is-danger" class="mb-3 p-2"
              style="font-size: 0.9rem;">Lekarz o podanym numerze PWZ został wprowadzony do słownika</b-notification>

            <b-field grouped>
              <b-field expanded>
                <ValidationProvider rules v-slot="{ errors, valid }" immediate>
                  <b-field label="Tytuł" label-position="on-border" :type="{
                    'is-danger': errors[0],
                    'is-normal': valid,
                  }" :message="t(errors[0])">
                    <b-input v-model="referralDoctorPrefix"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field expanded>
                <ValidationProvider :rules="{
                  required: true,
                  pwznumber: true,
                }" v-slot="{ errors, valid }" immediate>
                  <b-field label="PWZ" label-position="on-border" :type="{
                    'is-danger': errors[0],
                    'is-normal': valid,
                  }" :message="t(errors[0])">
                    <b-input v-model="referralDoctorPwz" icon="alert-circle-outline" placeholder="Wymagane"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>
            <b-field grouped>
              <b-field expanded>
                <ValidationProvider :rules="{
                  required: true,
                  name: true,
                }" v-slot="{ errors, valid }" immediate>
                  <b-field label="Imie:" label-position="on-border" :type="{
                    'is-danger': errors[0],
                    'is-normal': valid,
                  }" :message="t(errors[0])" expanded>
                    <b-input icon="alert-circle-outline" placeholder="Wymagane" v-model="referralDoctorFirstName"
                      expanded></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field expanded>
                <ValidationProvider :rules="{
                  required: true,
                  alpha_space_betwen_wordsPL: true,
                }" v-slot="{ errors, valid }" immediate>
                  <b-field label="Nazwisko:" label-position="on-border" :type="{
                    'is-danger': errors[0],
                    'is-normal': valid,
                  }" :message="t(errors[0])" expanded>
                    <b-input icon="alert-circle-outline" placeholder="Wymagane" expanded
                      v-model="referralDoctorLastName"></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot buttons is-right">
          <div>
            <b-button :loading="isLoading" icon-left="close" size="is-small"
              @click="externalDoctorFormulaModal = false">Zamknij</b-button>

            <b-button :disabled="invalid || externalDoctorExists" :loading="isLoading" icon-left="plus" size="is-small"
              @click="addDoctorFormula">Dodaj zewnętrznego lekarza</b-button>
          </div>
        </footer>
      </b-modal>
    </ValidationObserver>

    <ValidationObserver ref="observerExternalSubjectApkolce" v-slot="{ invalid }">
      <b-modal v-model="externalSubjectsPaperApkolceModal" has-modal-card aria-role="dialog" aria-modal trap-focus
        :destroy-on-hide="false" style="z-index: 200">
        <div class="modal-card has-background-kin">
          <header class="modal-card-head has-background-kin">
            <p class="modal-card-title has-text-primelight" style="line-height: 2rem">Dodawanie zewnętrznego podmiotu do
              słownika</p>
          </header>
          <section class="modal-card-body">
            <b-notification :closable="false" v-if="referralApkolce.regon == '71052989200024'" type="is-danger"
              class="mb-3 p-2" style="font-size: 0.9rem;">Podano numer REGON podmiotu "CENTRUM
              MEDYCZNO-DIAGNOSTYCZNE"</b-notification>

            <b-notification :closable="false" v-if="externalSubjectsPaperApkolceExists" type="is-danger"
              class="mb-3 p-2" style="font-size: 0.9rem;">Podmiot o podanym numerze REGON, kodach I, VII, VIII został
              wprowadzony do
              słownika</b-notification>

            <b-field class="mt-3">
              <ValidationProvider rules="required" v-slot="{ errors, valid }" immediate>
                <b-field label="Nazwa podmiotu" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="referralApkolce.subject" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }" immediate>
                <b-field label="Nazwa poradni" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="referralApkolce.subunit" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="regon14|required" v-slot="{ errors, valid }" immediate>
                <b-field label="REGON (14 cyfr)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="referralApkolce.regon" icon="alert-circle-outline" placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="digitsPL:12|required" v-slot="{ errors, valid }" immediate>
                <b-field label="Kod część I (12 cyfr)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="referralApkolce.code1st" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="digitsPL:3|required" v-slot="{ errors, valid }" immediate>
                <b-field label="Kod część VII (3 cyfry)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="referralApkolce.code7th" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="digitsPL:4|required" v-slot="{ errors, valid }" immediate>
                <b-field label="Kod część VIII (4 cyfry)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="referralApkolce.code8th" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot buttons is-right">
          <div>
            <b-button :loading="isLoading" icon-left="close" size="is-small"
              @click="externalSubjectsPaperApkolceModal = false">Zamknij</b-button>

            <b-button
              :disabled="invalid || referralApkolce.regon == '71052989200024' || externalSubjectsPaperApkolceExists"
              :loading="isLoading" icon-left="plus" size="is-small" @click="addExternalSubjectFormula">Dodaj zewnętrzny
              podmiot</b-button>
          </div>
        </footer>
      </b-modal>
    </ValidationObserver>

    <ValidationObserver ref="observerEditExternalSubjectApkolce" v-slot="{ invalid }">
      <b-modal v-model="editExternalSubjectsPaperApkolceModal" has-modal-card aria-role="dialog" aria-modal trap-focus
        :destroy-on-hide="false" style="z-index: 200">
        <div class="modal-card has-background-kin">
          <header class="modal-card-head has-background-kin">
            <p class="modal-card-title has-text-primelight" style="line-height: 2rem">Edycja zewnętrznego podmiotu ze
              słownika</p>
          </header>
          <section class="modal-card-body">
            <b-notification :closable="false" v-if="referralApkolce.regon == '71052989200024'" type="is-danger"
              class="mb-3 p-2" style="font-size: 0.9rem;">Podano numer REGON podmiotu "CENTRUM
              MEDYCZNO-DIAGNOSTYCZNE"</b-notification>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }" immediate>
                <b-field label="Wybierz zewnętrzny podmiot" label-position="on-border"
                  style="word-wrap: break-word; white-space: pre-wrap" :type="{
                    'is-danger': errors[0],
                    'is-normal': valid,
                  }" :message="t(errors[0])">
                  <b-autocomplete v-model="externalSubjectsPaperApkolceFilter"
                    :data="externalSubjectsPaperApkolceFiltered" expanded placeholder="Wybierz podmiot..." open-on-focus
                    field="title" @select="selectEditExternalSubjectFormula">
                    <template #empty>
                      <p>Brak zewnętrznych podmiotów</p>
                    </template>
                    <template slot-scope="props">
                      <p v-if="props.option.title" style="
                                          word-wrap: break-word;
                                          white-space: pre-wrap;
                                          margin: 0;
                                          padding: 0;
                                        " aria-multiline="true">{{ props.option.title }}</p>
                    </template>
                  </b-autocomplete>
                </b-field>
              </ValidationProvider>
            </b-field>

            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }" immediate>
                <b-field label="Tytuł formułki" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="editExternalSubjectApkolce.title" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="required" v-slot="{ errors, valid }" immediate>
                <b-field label="Nazwa podmiotu" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="editExternalSubjectApkolce.subject" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="regon14|required" v-slot="{ errors, valid }" immediate>
                <b-field label="REGON (14 cyfr)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="editExternalSubjectApkolce.regon" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="digitsPL:12|required" v-slot="{ errors, valid }" immediate>
                <b-field label="Kod część I (12 cyfr)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="editExternalSubjectApkolce.code1st" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="digitsPL:3|required" v-slot="{ errors, valid }" immediate>
                <b-field label="Kod część VII (3 cyfry)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="editExternalSubjectApkolce.code7th" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
            <b-field>
              <ValidationProvider rules="digitsPL:4|required" v-slot="{ errors, valid }" immediate>
                <b-field label="Kod część VIII (4 cyfry)" label-position="on-border" :type="{
                  'is-danger': errors[0],
                  'is-normal': valid,
                }" :message="t(errors[0])">
                  <b-input v-model="editExternalSubjectApkolce.code8th" icon="alert-circle-outline"
                    placeholder="Wymagane"></b-input>
                </b-field>
              </ValidationProvider>
            </b-field>
          </section>
        </div>
        <footer class="modal-card-foot buttons is-right">
          <div>
            <b-button :loading="isLoading" icon-left="close" size="is-small"
              @click="editExternalSubjectsPaperApkolceModal = false">Zamknij</b-button>

            <b-button
              :disabled="invalid || editExternalSubjectApkolce.regon == '71052989200024' || !editExternalSubjectApkolce.id"
              :loading="isLoading" icon-left="plus" size="is-small" @click="editExternalSubjectFormula">Edytuj
              zewnętrzny
              podmiot</b-button>
          </div>
        </footer>
      </b-modal>
    </ValidationObserver>

    <div style="display: none">
      <div id="printEReferralStatement">
        <div style="
            font-family: Calibri, sans-serif;
            max-width: 100%;
            padding: 1rem;
          ">
          <table style="width: 100%">
            <tr>
              <td style="width: 40%; vertical-align: center">
                <div style="min-height: 50px; border: 1px solid black"></div>
                <p style="margin: 0; padding: 0">Pieczątka zakładu</p>
              </td>
              <td style="
                        text-align: right;
                        vertical-align: bottom;
                        margin-top: 10px;
                      ">
                <p style="
                          margin: 0;
                          padding: 0;
                        ">
                  <span style="border-top: 1px dashed black;">
                    miejscowość
                    <span>dnia: {{ mNowDate() }}</span>
                  </span>
                </p>
              </td>
            </tr>
          </table>

          <h3 style="
                    margin-top: 50px;
                    margin-bottom: 50px;
                    text-align: center;
                    ">
            <b>OŚWIADCZENIE PACJENTA</b>
          </h3>

          <p style="margin-top: 50px; text-align: justify;">
            Ja, {{ patient.firstName }} {{ patient.lastName }}
            posługujący/a się numerem PESEL {{ patient.pesel }},
            niniejszym oświadczam, że dotychczas,
            tj. do dnia {{ mNowDate() }}, nie dokonałem/am zgłoszenia w celu udzielenia mi świadczenia opieki zdrowotnej
            u
            innego świadczeniodawcy na podstawie skierowania o następującym numerze identyfikującym
            kodzie dostępu do skierowania: {{ eReferralPaper.referralCode }}
          </p>

          <div style="
                    margin-top: 50px;
                    width: 33%;
                    float: right;
                    text-align: center;
                    ">
            <p style="
                        border-top: 1px dashed black;
                        font-size: 0.8rem;
                        margin: 0;
                        padding: 0;
                    ">Podpis</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import OrdersMixins from "@/mixins/orders/poz_orders";
import DateHelpers from "@/mixins/date_helpers";
import { Action } from "../../../store/config_actions";
import { Mutation } from "../../../store/config_mutations";
// import ContentPanel from "@/components/tools/ContentPanel";
import RISWorkshopAddExamination from "./RISWorkshopAddExaminationVer2";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ReferralMixins from "@/mixins/referrals.js";
import IkzExam from "@/mixins/ikz_exams.js";
import risMixins from "@/mixins/ris";
import Icd10SearchBar from "@/components/search-engines/Icd10SearchBar";

import AppointmentAddVisitReferralP1 from "@/components/appointments/AppointmentAddVisitReferralP1Ver2";
import AppointmentCancelReferralP1 from "@/components/appointments/AppointmentCancelReferralP1";

import ScannerButtonSmall from "@/components/test-dawid/DynamSoftScanner/ScannerButtonSmall.vue"

const typesRiSClinics = [
  7210, 7211, 7220, 7221, 7222, 7223, 7240, 7241, 7242, 7244, 7245, 7250, 7251,
  7912, 7913, 7950, 7951,
];

export default {
  name: "RISWorkshopAddVisitSchedule",
  mixins: [
    CommonHelpers,
    OrdersMixins,
    ReferralMixins,
    IkzExam,
    risMixins,
    DateHelpers,
  ],
  components: {
    //  ContentPanel,
    RISWorkshopAddExamination,
    ValidationProvider,
    ValidationObserver,
    AppointmentAddVisitReferralP1,
    AppointmentCancelReferralP1,
    Icd10SearchBar,
    ScannerButtonSmall,
  },

  props: {
    clinicType: { type: Number, required: true },
    ticketEdit: { type: Object },
    deviceType: { type: Number, required: true },
    appointmentType: { type: Object, required: true },
    officeId: { type: String, required: false },
    appointmentPayer: { type: Number, required: false },
    officeType: { type: Object, required: false },
    disabled: { type: Boolean, required: false },
    patient: { type: Object, required: true },
  },

  data: function () {
    return {
      interval: null,
      isActiveModal: false,
      isLoading: false,
      loading: false,
      tab: 0,
      days: [],
      queryP1: {
        referralId: null,
        referralCode: null,
        referralPesel: "",
      },

      buttonText: "Dodaj badanie radiologiczne",
      hasReferralInOtherSystem: false,
      referralType: null,
      clinicTypes: [],
      selectedSubunit: null,
      newSubunitName: "",
      subunits: [],
      subunitOffices: [],
      selectedOffice: null,
      withoutScanFile: false,
      referralFileId: null,

      referralDoctorPrefix: "",
      referralDoctorFirstName: "",
      referralDoctorLastName: "",
      referralDoctorPwz: "",
      referralDoctor: "",
      referralClinic: "",

      ikzFilter: "",
      ikzAdded: [],
      ikzIncluded: [],

      suggestedExaminationAdd: [],

      payerType: -1,
      isPaid: false,

      referredBy: -1,
      internalReferringClinicId: null,
      internalDoctor: true,
      contractorInput: "",
      contractor: null,

      internalClinic: true,
      subunitName: "",
      internalClinicId: null,

      showAllContractors: false,

      employeeName: "",

      typeSearch: 9,
      selectedDates: [],
      minDate: new Date(moment().add(-1, "days")),

      referralProcedure: this.proceduresObject,
      selectClinicType: null,
      newClinicName: "",
      searchCounter: 0,
      isAddPatientButtonActive: false,
      isAddPatientModalActive: false,

      referralProcedures: [],

      currentTicket: null,
      filters: {
        searchType: -1,
        dates: [],
        employee: -1,
        device: null,
        clinic: -1,
        spec: -1,
        area: 0 - 1,
        office: null,
        room: null,
      },

      resultP1: null,
      triggerP1: 0,
      codeP1: {
        referralId: "",
        referralCode: "",
        referralPesel: "",
      },

      file: null,
      fileDescription: "",

      triggerCancelReferral: 0,
      endVisit: false,

      referralIcd10Code: null,
      selectedIcd10: null,
      selectedIcd10Additional: [],
      comment: "",
      bodyArea: "",
      bodyAreas: [],
      isUrgent: false,
      registrationType: null,

      referralApkolce: {
        subject: "",
        subunit: "",
        type: 0,
        issueDate: null,
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
        musCode: "",
        referralNumber: ""
      },

      addEReferralScan: false,
      eReferralFail: false,
      statementFileId: null,
      fileStatement: null,
      fileStatementDescription:
        "Oświadczenie o niewykorzystaniu eskierowania u innego świadczeniodawcy",

      eReferralPaper: {
        rootOID: "",
        referralId: "",
        referralCode: "",
        idStatementFile: "",
      },

      externalDoctorFormulaModal: false,
      externalDoctorFormulaFilter: "",
      externalDoctorFormula: [],

      externalSubjectsPaperApkolceModal: false,
      externalSubjectsPaperApkolceFilter: "",
      externalSubjectsPaperApkolce: [],

      siteClinicCode: null,
      siteMedicalEntitiesRegistrationCode1st: null,
      siteIdentifierCode7th: null,
      siteRegon: null,

      diloNumber: "",

      apkolceReferralDataNotRequired: false,
      apkolceDataNotRequired: false,
      diloDataNotRequired: false,

      ticketsToSend: [],

      facilityFilter: "",
      resultSentToLocation: null,

      editExternalSubjectsPaperApkolceModal: false,
      editExternalSubjectApkolce: {
        id: "",
        title: "",
        subject: "",
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
      },

      filterSubunitsDomain: true,
      selectedService: null,

      isUsedTwain: false,
    }
  },

  mounted() {
    //this.getTypeClinics();

    if (this.clinicType) {
      let found = this.configClinics.find(
        (clinic) => clinic.id == this.clinicType
      );
      if (found) {
        this.selectClinicType = found;
      }
    }

    if (this.typesImage && this.typesImage.length > 0) {
      //ok, we have image types
    } else {
      this.getImageTypes();
    }

    if (!this.$store.state.finance.subjects) {
      this.getSubjects();
    }

    if (!this.$store.state.orgUnit.subunits) {
      //this.getSubUnits();
    }

    if (!this.$store.state.finance.specialSubjects) {
      this.getRISSubjects();
    }

    this.referralType = null;
    // this.referralsType && this.referralsType.length > 0
    //   ? this.referralsType[0]
    //   : null;

    if (this.appointmentType) {
      this.ikzIncluded = this.mGetServicesForDevice(
        this.appointmentType.defaultDeviceType
      );
    }

    if (!this.externalDoctorFormula.length) {
      this.getExternalDoctorFormula();
    }

    if (!this.externalSubjectsPaperApkolce.length) {
      this.getExternalSubjectFormula();
    }

    if (this.appointmentPayer === 0 && this.isRISDens) {
      this.buttonText = "Dodaj skierowanie (opcjonalne)"
    } else {
      this.buttonText = "Dodaj skierowanie (wymagane)"
    }

    if (this.meParentFacilityObject) {
      this.resultSentToLocation = this.meParentFacilityObject.internalId
      this.facilityFilter = this.meParentFacilityObject.name
    }
  },

  watch: {
    ticketEdit: {
      deep: true,
      handler() {
        this.currentTicket = this.ticketEdit;
      },
    },

    appPayers: {
      deep: true,
      handler() {
        if (this.appPayers != null && this.appPayers.length > 0) {
          if (!this.appPayers.includes(this.payerType)) {
            this.payerType = this.appPayers[0].id
          }
        }
      },
    },

    currentClinicType() {
      this.selectClinicType = this.currentClinicType;
    },

    referralType() {
      // if (this.referralType) {
      //   this.payerType = this.referralType.defaultPayer;
      // }

      if (this.referralType) {
        this.fileDescription = `${this.referralType.name} - ${this.clinicTypePropsName}`;
      }

      if (this.referralType && this.appointmentType && this.appointmentType.apkolce) {
        this.referralApkolce = {
          subject: "",
          subunit: "",
          type: 0,
          issueDate: null,
          regon: "",
          code1st: "",
          code7th: "",
          code8th: "",
          musCode: "",
          referralNumber: ""
        }
        this.internalClinicId = null
        this.subunitName = "";
      }

      if (this.referralType && (this.referralType.id === 4 || this.referralType.id === 5 || this.referralType.id === 6)) {
        this.withoutScanFile = true
        this.ikzFilter = ""
        this.ikzAdded = []
        this.selectedService = null
        this.referralProcedures = []
        this.suggestedExaminationAdd = [-1]
        this.$nextTick(() => {
          this.findSuggestedIkzForReferralType()
        })
      } else {
        this.withoutScanFile = false
        this.ikzFilter = ""
        this.ikzAdded = []
        this.selectedService = null
        this.referralProcedures = []
        this.suggestedExaminationAdd = [-1]
      }
    },

    filteredReferralsType() {
      if (this.filteredReferralsType && this.filteredReferralsType.length > 0) {
        if (!this.referralType) {
          this.referralType = this.filteredReferralsType[0];
        } else {
          let index = this.filteredReferralsType.indexOf(this.referralType);

          if (index > -1) {
            //do nothing
          } else {
            this.referralType = this.filteredReferralsType[0];
          }
        }
      } else {
        //do nothing
        this.referralType = null;
      }
    },

    appointmentType(val) {
      if (val) {
        this.ikzIncluded = this.mGetServicesForDevice(
          this.appointmentType.defaultDeviceType
        );

        this.payerType = this.appointmentType.defaultPayer;
      }
    },

    payerType() {
      this.isPaid = false;

    },

    resultP1() {
      if (
        this.resultP1 &&
        this.resultP1.icD10Data &&
        this.resultP1.icD10Data.code
      ) {
        this.referralIcd10Code = this.resultP1.icD10Data.code;
      }
    },

    appointmentPayer() {
      if (this.ticketsToSend.length < 1) {
        if (this.appointmentPayer === 0 && this.isRISDens) {
          this.buttonText = "Dodaj skierowanie (opcjonalne)"
        } else {
          this.buttonText = "Dodaj skierowanie (wymagane)"
        }
      }
    }
  },

  computed: {

    isModalEnableForUser() {
      return this.perms.testerScaner
    },

    perms() {
      return this.$store.state.employee.permits
    },
    me() { return this.$store.state.employee.me },
    meParentFacilityObject() {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.internalId === this.me.parentFacility);
        return found;
      } else return null;
    },
    registrationIkz() {
      return this.$store.state.registration.ikz
    },
    offices() {
      return this.$store.state.offices.offices;
    },
    officesc() {
      return this.$store.state.config.offices;
    },

    canHaveExternalReferral() {
      if (this.appointmentType) {
        return (
          this.appointmentType.id == 1001 ||
          this.appointmentType.id == 1010 ||
          this.appointmentType.id == 1020 ||
          this.appointmentType.id == 1030 ||
          this.appointmentType.id == 1024
        );
      } else return false;
    },

    filteredIkz() {
      if (this.ikzFilter.length < 1) {
        return this.ikzs.filter(x => !x.isRemoved);
      } else {
        this.ikzs.forEach((element) => {
          element.apc = element.pharse
            .toString()
            .toLowerCase()
            .indexOf(this.ikzFilter.toLowerCase());
        });

        var nonfiltered = this.ikzs.filter((option) => {
          return !option.isRemoved && option.apc >= 0;
        });

        var sorted = nonfiltered.sort((a, b) => {
          return a.apc - b.apc;
        });

        return sorted;

        // return this.ikzs.filter(option => {
        //   return (
        //     option.pharse
        //       .toString()
        //       .toLowerCase()
        //       .indexOf(this.ikzFilter.toLowerCase()) >= 0
        //   )
        // })
      }
    },

    // filteredIkz() {
    //   if (this.ikzFilter.length < 1) {
    //     return this.ikzs
    //   }
    //   else {
    //     return this.ikzs.filter(option => {
    //       return (
    //         option.pharse
    //           .toString()
    //           .toLowerCase()
    //           .indexOf(this.ikzFilter.toLowerCase()) >= 0
    //       )
    //     })
    //   }
    // },

    ikzs() {
      if (this.appointmentType) {
        let selected = [];
        let all = this.$store.state.finance.ikz.filter(x => !x.isRemoved);

        // if (this.appointmentPayer == 1 && ((this.appointmentType.id >= 1000 && this.appointmentType.id < 1010) || (this.appointmentType.id >= 10000 && this.appointmentType.id < 101000))) {
        //   all = all.filter(x => x.source == 6)
        // }

        all.forEach((element) => {
          if (this.ikzIncluded.includes(element.internalId)) {
            selected.push(element);
          }
        });

        return selected;
      } else {
        return this.$store.state.finance.ikz.filter(x => !x.isRemoved);
      }
    },

    configClinics() {
      return this.$store.state.config.clinics ?? [];
    },

    currentClinicType() {
      if (this.clinicType) {
        let found = this.configClinics.find(
          (clinic) => clinic.id == this.clinicType
        );
        if (found) {
          return found;
        }
      }
      return null;
    },

    cantAddExamination() {
      return false;
      // if (this.referralProcedures.length > 0) {
      //   return true;
      // } else {
      //   return false;
      // }
    },

    facilities() {
      return this.$store.state.clinic.clinics;
    },

    facilitiesFiltered() {
      return this.facilities.filter((option) => {
        if (option.name) {
          return (
            option.name
              .toLowerCase()
              .indexOf(this.facilityFilter.toLowerCase()) >= 0
          );
        } else return false;
      });
    },

    subunitsAll() {
      return this.$store.state.orgUnit.subunits ?? [];
    },

    typesImage() {
      return this.$store.state.results.examImageType ?? [];
    },

    employeesAll() {
      return this.$store.state.employee.all ?? [];
    },

    employees() {
      let array = [];
      if (this.employeesAll) {
        this.employeesAll.forEach((emp) => {
          if (
            emp.possibleRoles.includes(10) ||
            emp.possibleRoles.includes(11) ||
            emp.possibleRoles.includes(12) ||
            emp.possibleRoles.includes(20) ||
            emp.possibleRoles.includes(21)
          ) {
            if (
              !emp.possibleRoles.includes(999) &&
              !emp.possibleRoles.includes(997)
            ) {
              array.push(emp);
            }
          }
        });
      }
      return array;
    },

    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter((option) => {
          if (option.name) {
            return (
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.employeeName.toLowerCase()) >= 0
            );
          } else return false;
        });
      } else return [];
    },

    filteredExaminationImageByClinic() {
      if (this.selectClinicType) {
        return this.typesImage.filter((option) => {
          option.toString();
          if (option.clinics) {
            let found = option.clinics.indexOf(
              this.selectClinicType.code.toLowerCase()
            );
            return found >= 0;
          }
          return false;
        });
      }
      return [];
    },

    filterExaminationImage() {
      return this.filteredExaminationImageByClinic.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.newExaminationName.toLowerCase()) >= 0 ||
          option.codeICD9
            .toString()
            .toLowerCase()
            .indexOf(this.newExaminationName.toLowerCase()) >= 0
        );
      });
    },

    clinicsObject() {
      let filtered = [];
      if (this.configClinics) {
        this.clinicTypes.forEach((option) => {
          let found = this.configClinics.find((clinic) => clinic.id == option);
          if (found) {
            if (!filtered.includes(found)) {
              filtered.push(found);
            }
          }
        });
      }
      return filtered;
    },

    filteredClinicObject() {
      return this.clinicsObject.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.newClinicName.toLowerCase()) >= 0
        );
      });
    },

    transletedSubunits() {
      let translated = this.subunitsAll;

      translated.forEach((sub) => {
        if (sub) {
          if (sub.facilityId != null) {
            sub.facilityName = this.getFacility(sub.facilityId);
            sub.facilityObject = this.getFacilityObject(sub.facilityId);
            sub.facilityFullName = this.getFacilityFullName(sub.facilityId);
          } else {
            sub.facilityName = "Nieokreślona";
            sub.facilityObject = null;
          }
        }
      });
      return translated;
    },

    subunitsInternalApkolce() {
      let subjectsDomainCmdId = [];
      let subjectsDomainCmd = this.subjectsAll.filter((x) => x.domain === this.me.domainId);

      subjectsDomainCmd.forEach((subject) => {
        if (!subjectsDomainCmdId.includes(subject.id)) {
          subjectsDomainCmdId.push(subject.id);
        }
      });

      let subunitsInternal = this.transletedSubunits.filter((subunit) => {
        if (subjectsDomainCmdId.includes(subunit.ownerId)) {
          return true;
        } else {
          return false;
        }
      });

      return subunitsInternal;
    },

    subunitsExternalApkolce() {
      let subjectsDomainCmdMagId = [];
      let subjectsDomainCmdMag = this.subjectsAll.filter((x) => x.domain === this.me.domainId);

      subjectsDomainCmdMag.forEach((subject) => {
        if (!subjectsDomainCmdMagId.includes(subject.id)) {
          subjectsDomainCmdMagId.push(subject.id);
        }
      });

      let subunitsExternal = this.transletedSubunits.filter((subunit) => {
        if (!subjectsDomainCmdMagId.includes(subunit.ownerId)) {
          return true;
        } else {
          return false;
        }
      });

      return subunitsExternal;
    },

    filteredSubunitsInternalApkolce() {
      if (this.subunitsInternalApkolce) {
        return this.subunitsInternalApkolce.filter((option) => {
          let resultSubunitName = false;
          let resultFacility = false;

          if (option.facilityFullName) {
            resultFacility =
              option.facilityFullName
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          if (option.name) {
            resultSubunitName =
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          return resultSubunitName || resultFacility;
        });
      }

      return [];
    },

    filteredSubunitsExternalApkolce() {
      if (this.subunitsExternalApkolce) {
        return this.subunitsExternalApkolce.filter((option) => {
          let resultSubunitName = false;
          let resultFacility = false;

          if (option.facilityFullName) {
            resultFacility =
              option.facilityFullName
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          if (option.name) {
            resultSubunitName =
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          return resultSubunitName || resultFacility;
        });
      }

      return [];
    },

    filteredSubunitsDomain() {
      if (this.filterSubunitsDomain) {
        return this.filteredSubunitsInternalApkolce
      } else {
        return this.filteredSubunits
      }
    },

    filteredSubunits() {
      if (this.transletedSubunits) {
        return this.transletedSubunits.filter((option) => {
          let resultSubunitName = false;
          let resultFacility = false;

          if (option.facilityFullName) {
            resultFacility =
              option.facilityFullName
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          if (option.name) {
            resultSubunitName =
              option.name
                .toString()
                .toLowerCase()
                .indexOf(this.subunitName.toLowerCase()) >= 0;
          }

          return resultSubunitName || resultFacility;
        });
      }

      return [];
    },

    risSubjects() {
      return this.$store.state.finance.specialSubjects ?? [];
    },

    subjectsAll() {
      return this.$store.state.finance.subjects ?? [];
    },

    subjectsContractor() {
      if (this.showAllContractors)
        return this.$store.state.finance.subjects ?? [];
      else return this.risSubjects;
    },

    filteredSubjectsContractor() {
      if (this.subjectsContractor) {
        return this.subjectsContractor.filter((option) => {
          if (option.nameFull && option.domain != 1000) {
            let resultNameFull = false;
            let resultNameShort = false;

            resultNameFull =
              option.nameFull
                .toString()
                .toLowerCase()
                .indexOf(this.contractorInput.toLowerCase()) >= 0;

            if (option.nameShort) {
              resultNameShort =
                option.nameShort
                  .toString()
                  .toLowerCase()
                  .indexOf(this.contractorInput.toLowerCase()) >= 0;
            }

            return resultNameFull || resultNameShort;
          }
          return false;
        });
      } else return [];
    },

    filteredSubunitOffices() {
      if (this.subunitOffices) {
        return this.subunitOffices.filter((option) => {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.newSubunitName.toLowerCase()) >= 0
          );
        });
      }

      return [];
    },

    configApps() {
      return this.$store.state.config.appointments ?? [];
    },

    referralsType() {
      return this.$store.state.config.referrals ?? [];
    },

    clinicReferralTypes() {
      let filtered = [];
      if (this.selectClinicType) {
        if (
          this.selectClinicType.referrals &&
          this.selectClinicType.referrals.length > 0
        ) {
          this.selectClinicType.referrals.forEach((option) => {
            let found = this.referralsType.find(
              (refType) => refType.id == option
            );
            if (found) {
              if (!filtered.includes(found)) {
                filtered.push(found);
              }
            }
          });
        }
      }

      return filtered;
    },

    filteredReferralsType() {

      //if appointmentType is null or undefined return empty array
      if (this.appointmentType == null || this.appointmentType == undefined) return []

      //get referralTypes from appoinmentType
      if (this.appointmentType.referrals != null && this.appointmentType.referrals.length > 0) {
        let filtered = []
        this.appointmentType.referrals.forEach((option) => {
          let found = this.referralsType.find(
            (refType) => refType.id == option
          );
          if (found) {
            if (!filtered.includes(found)) {
              filtered.push(found);
            }
          }
        });
        return filtered
      }

      //get referralTypes from clinic and filter by payers
      if (this.clinicReferralTypes && this.appointmentType) {
        return this.clinicReferralTypes.filter((element) => {
          let found = element.payers.some((r) =>
            this.appointmentType.payers.includes(r)
          );

          if (found) {
            return true;
          } else {
            return false;
          }
        });
      }

      return [];
    },

    appPayers() {
      let payers = [];
      var apNone = { id: -1, name: "Brak płatników" };
      var ap0 = { id: 0, name: "Komercja", icon: "account-cash" };
      var ap1 = { id: 1, name: "NFZ", icon: "heart-half-full" };
      var ap2 = { id: 2, name: "MZ", icon: "clipboard-pulse" };

      if (this.appointmentType) {
        let configured = this.appointmentType; // this.configApps.find((x) => x.id == this.appointmentType.id);

        if (
          configured &&
          configured.payers &&
          this.referralType &&
          this.referralType.payers
        ) {
          configured.payers.forEach((p) => {
            if (p === 0 && this.referralType.payers.includes(p))
              payers.push(ap0);
            if (p === 1 && this.referralType.payers.includes(p))
              payers.push(ap1);
            if (p === 2 && this.referralType.payers.includes(p))
              payers.push(ap2);
          });

          if (payers.length < 1) {
            payers.push(apNone);
          }
        } else {
          payers.push(apNone);
        }
      } else {
        payers.push(apNone);
      }

      if (this.resultP1) {
        if (this.resultP1.isNFZ === true) {
          let findCommerceIndex = payers.findIndex((payer) => payer.id == 0);

          if (findCommerceIndex > -1) {
            payers.splice(findCommerceIndex, 1);
          }

          let findMZIndex = payers.findIndex((payer) => payer.id == 2);

          if (findMZIndex > -1) {
            payers.splice(findMZIndex, 1);
          }
        } else if (this.resultP1.isNFZ === false) {
          let findNFZIndex = payers.findIndex((payer) => payer.id == 1);

          if (findNFZIndex > -1) {
            payers.splice(findNFZIndex, 1);
          }
        }
      }

      return payers.sort((a, b) => b.id - a.id);
    },

    clinicTypePropsName() {
      let found = this.configClinics.find(
        (clinic) => clinic.id == this.clinicType
      );
      if (found) {
        return found.shortName;
      }

      return "Brak informacji";
    },

    officeFacilityName() {
      let off = this.offices.find((office) => office.id == this.officeId);

      if (off) {
        return this.getFacility(off.facilityId);
      }

      return "Brak informacji";
    },

    translateBodyAreas() {
      //return this.mBodyAreas.filter(option => option.level > 1)
      let array = this.mBodyAreas.filter(option => option.level > 1)
      let groups = []
      array.forEach(element => {
        element.nameMaster = element.levelMaster > -1 ? this.mGetBodyAreasName(element.levelMaster) : null
        element.codeMaster = element.levelMaster > -1 ? this.mGetBodyAreasCode(element.levelMaster) : null
        element.nameUp = element.levelUp > -1 ? this.mGetBodyAreasName(element.levelUp) : null
        element.codeUp = element.levelUp > -1 ? this.mGetBodyAreasCode(element.levelUp) : null

        let found = groups.find(item => item.nameMaster == element.nameMaster)
        if (found != null) {
          found.items.push(element)
        } else {
          let newGroup = {
            nameMaster: element.nameMaster,
            codeMaster: element.codeMaster,
            type: {
              nameMaster: element.nameMaster,
              codeMaster: element.codeMaster,
            },
            items: [element]
          }

          groups.push(newGroup);
        }
      });

      return groups;
    },

    // filteredBodyAreas() {
    //   return this.translateBodyAreas.filter((option) => {
    //     if (option.name) {
    //       return (
    //         option.name.toLowerCase().indexOf(this.bodyArea.toLowerCase()) >=
    //         0 ||
    //         option.codeOKC
    //           .toString()
    //           .toLowerCase()
    //           .indexOf(this.bodyArea.toLowerCase()) >= 0
    //       );
    //     } else return false;
    //   });
    // },

    filteredBodyAreas() {
      let filtered = []

      this.translateBodyAreas.forEach((option) => {
        let group = { ...option }

        group.items = group.items.filter((subItem) => {
          let isMain = false
          let isMaster = false
          let isCategory = false

          if (subItem.name) {
            isMain = (
              subItem.name.toLowerCase().indexOf(this.bodyArea.toLowerCase()) >=
              0 ||
              subItem.codeOKC
                .toString()
                .toLowerCase()
                .indexOf(this.bodyArea.toLowerCase()) >= 0
            );
          } else isMain = false;

          if (subItem.nameMaster) {
            isMaster = (
              subItem.nameMaster.toLowerCase().indexOf(this.bodyArea.toLowerCase()) >=
              0 ||
              subItem.codeMaster
                .toString()
                .toLowerCase()
                .indexOf(this.bodyArea.toLowerCase()) >= 0
            );
          } else isMaster = false;

          if (subItem.nameUp) {
            isCategory = (
              subItem.nameUp.toLowerCase().indexOf(this.bodyArea.toLowerCase()) >=
              0 ||
              subItem.codeUp
                .toString()
                .toLowerCase()
                .indexOf(this.bodyArea.toLowerCase()) >= 0
            );
          } else isCategory = false;

          if (isMain || isMaster || isCategory) return true
          else return false

        });

        if (group.items != null && group.items.length > 0)
          filtered.push(group)


      });

      return filtered
    },

    // Zablokowany wybór poradni zewnętrznej w przypadku APKolcy na wewnętrzne
    isInternalClinicDisabled() {
      if (
        this.appointmentType &&
        this.appointmentType.apkolce &&
        this.referralType &&
        this.referralType.isPaperReferral == true &&
        this.referralType.id == 0
      ) {
        return true;
      } else return false;
    },

    checkErrorExternalReferralApkolce() {
      if (
        this.referralType &&
        this.referralType.id == 2 &&
        this.referralApkolce.regon == "71052989200024"
      ) {
        return true;
      }

      return false;
    },

    externalDoctorFormulaFiltered() {
      return this.externalDoctorFormula.filter((option) => {
        if (option.title) {
          return (
            option.title
              .toString()
              .toLowerCase()
              .indexOf(this.externalDoctorFormulaFilter.toLowerCase()) >= 0
          );
        }
        return false;
      });
    },

    externalDoctorExists() {
      let findDoctor = this.externalDoctorFormula.find((option) => {
        if (option.doctor && option.doctor.pwz == this.referralDoctorPwz) {
          return true
        } else {
          return false
        }
      })

      return findDoctor
    },

    externalSubjectsPaperApkolceFiltered() {
      return this.externalSubjectsPaperApkolce.filter((option) => {
        if (option.title) {
          return (
            option.title
              .toString()
              .toLowerCase()
              .indexOf(this.externalSubjectsPaperApkolceFilter.toLowerCase()) >=
            0
          );
        }
        return false;
      });
    },

    externalSubjectsPaperApkolceExists() {
      let findSubject = this.externalSubjectsPaperApkolce.find((option) => {
        if (option.subject
          && option.subject.regon == this.referralApkolce.regon
          && option.subject.code1st == this.referralApkolce.code1st
          && option.subject.code7th == this.referralApkolce.code7th
          && option.subject.code8th == this.referralApkolce.code8th) {
          return true
        } else {
          return false
        }
      })

      return findSubject
    },

    isRISDens() {
      if (this.officeType) {
        return this.officeType.id == 1030
      } else return false
    },
  },

  methods: {
    handleFile(imageObject) {
      if (imageObject != null) {
        this.file = imageObject.file
        this.isUsedTwain = imageObject.isUsedTwain
      } else {
        this.file = null
        this.isUsedTwain = false
      }
    },

    ikzSelected(item) {
      this.ikzAdded.push(item);
      this.suggestedExaminationAdd = this.mGetIkzExam(item.internalId);
      if (item) {
        this.selectedService = item
      }
    },

    findSuggestedIkzForReferralType() {
      if (this.referralType && (this.referralType.id === 5 || this.referralType.id === 6)) {
        let findSuggestedIkz = this.filteredIkz.find(ikz => ikz.internalId === 100)
        if (findSuggestedIkz) {
          this.$refs.ikzAutocomplete.setSelected(findSuggestedIkz)
        }
      } else if (this.referralType && this.referralType.id === 4) {
        let findSuggestedIkz = this.filteredIkz.find(ikz => ikz.internalId === 1336)
        if (findSuggestedIkz) {
          this.$refs.ikzAutocomplete.setSelected(findSuggestedIkz)
        }
      }
    },

    removeIkz(index) {
      this.ikzFilter = "";
      this.ikzAdded.splice(index, 1);
      this.referralProcedures = [];
      this.suggestedExaminationAdd = [-1];
    },

    goNext() {
      if (this.referralType != null) {
        //this.isPaperReferral = this.referralType.isPaperReferral;
        if (this.referralType.isContractorAllowed == false) {
          this.contractorInput = "";
          this.contractor = null;
        }
        this.tab = 1;
      } else {
        this.dangerSnackbar("Wybierz rodzaj skierowania");
      }
    },

    previewStep() {
      if (this.tab == 1 && this.resultP1) {
        this.successSnackbar("Anulowanie skierowania");
        this.triggerCancelReferral += 1;
      } else {
        this.tab--;
      }
    },

    goNextApkolce() {
      this.tab++;
    },

    previewStepApkolce() {
      this.tab--;
    },

    nextForPaperReferral() {
      if (this.referralType != null) {
        if (this.referralType.isContractorAllowed == false) {
          this.contractorInput = "";
          this.contractor = null;
        }
        this.tab = 1;
      } else {
        this.dangerSnackbar("Wybierz rodzaj skierowania");
      }
    },

    createTicket() {
      this.tab++;
    },

    ticketTranslated() {
      this.finishReferralP1();
    },

    closeWindow() {
      this.$buefy.dialog.confirm({
        message:
          "Chcesz przerwać dodawanie wizyty? Zmiany nie zostaną zapisane.",
        confirmText: "Przerwij dodawanie",
        cancelText: "Wróć do edycji",
        type: "is-danger",
        scroll: "keep",
        hasIcon: true,
        onConfirm: () => {
          this.isActiveModal = false;
          this.endVisit = true;
          this.ikzAdded = [];
          this.isLoading = false;

          if (this.resultP1 != null) {
            this.successSnackbar("Anulowanie skierowania");
            this.triggerCancelReferral += 1;
          } else {
            this.finishReferralP1();
            this.addIkzReferralAddCancel()
          }
        },
      });
    },

    cancelledEReferral(responseReferral) {

      this.$store.commit(Mutation.REGISTRATION_EREFERRAL_READING, false);
      this.$store.commit(Mutation.REGISTRATION_ADD_EREFERRAL, responseReferral)

      if (this.endVisit === true) {
        this.finishReferralP1();
        this.addIkzEReferralCancel()
        this.addIkzReferralAddCancel()
      } else {
        this.tab -= 1;
        this.resultP1 = null;
        this.addIkzEReferralCancel()
      }
    },

    finishReferralP1() {
      this.isActiveModal = false;
      //this.selectClinicType = null;
      this.newClinicName = "";
      this.searchCounter = 0;
      this.isAddPatientButtonActive = false;
      this.isAddPatientModalActive = false;
      this.tab = 0;
      this.referralProcedures = [];
      this.ikzAdded = [];
      //this.$store.commit(Mutation.RIS_TRIGGER_ADD_UP);
      //this.$emit("created");
      this.referralFileId = null;
      this.withoutScanFile = false;
      this.currentTicket = null;

      this.referralDoctorPrefix = "";
      this.referralDoctorFirstName = "";
      this.referralDoctorLastName = "";
      this.referralDoctorPwz = "";
      this.referralDoctor = "";
      this.referralClinic = "";

      this.referredBy = -1;
      this.internalReferringClinicId = null;
      this.internalDoctor = true;
      this.contractorInput = "";
      this.contractor = null;

      this.internalClinic = true;
      this.subunitName = "";
      this.internalClinicId = null;

      this.employeeName = "";
      this.referralType = null;
      this.payerType = -1;
      this.isPaid = false;

      this.resultP1 = null;
      this.codeP1 = {
        referralId: "",
        referralCode: "",
        referralPesel: "",
      }
      this.triggerP1 = 0

      this.triggerCancelReferral = 0;
      this.endVisit = false;
      this.isLoading = false;

      this.referralIcd10Code = null;
      this.selectedIcd10 = null;
      this.selectedIcd10Additional = [];
      this.comment = "";
      this.bodyArea = "";
      this.bodyAreas = [];
      this.isUrgent = false;
      this.registrationType = null;

      this.referralApkolce = {
        subject: "",
        subunit: "",
        type: 0,
        issueDate: null,
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
        musCode: "",
        referralNumber: ""
      }
      this.addEReferralScan = false
      this.eReferralFail = false;
      this.statementFileId = null;
      this.fileStatement = null;
      this.fileStatementDescription =
        "Oświadczenie o niewykorzystaniu e-skierowania u innego świadczeniodawcy";

      this.eReferralPaper = {
        rootOID: "",
        referralId: "",
        referralCode: "",
        idStatementFile: "",
      }
      this.externalDoctorFormulaModal = false;
      this.externalDoctorFormulaFilter = "";

      this.externalSubjectsPaperApkolceModal = false;
      this.externalSubjectsPaperApkolceFilter = "";

      this.siteClinicCode = null;
      this.siteMedicalEntitiesRegistrationCode1st = null;
      this.siteIdentifierCode7th = null;
      this.siteRegon = null;

      this.diloNumber = ""
      this.apkolceReferralDataNotRequired = false
      this.apkolceDataNotRequired = false
      this.diloDataNotRequired = false

      this.ticketsToSend.length = 0
      this.resultSentToLocation = null
      this.facilityFilter = ""

      this.editExternalSubjectsPaperApkolceModal = false
      this.editExternalSubjectApkolce = {
        id: "",
        title: "",
        subject: "",
        regon: "",
        code1st: "",
        code7th: "",
        code8th: "",
      }
    },

    addClinicObject(option) {
      this.selectClinicType = option;
      this.referralProcedures = [];
      if (this.selectClinicType != null) {
        this.getSubunitTypeClinics();
      }
    },

    addExamination(exam) {
      if (!this.referralProcedures.includes(exam)) {
        this.referralProcedures.push(exam);
      } else {
        this.dangerSnackbar("Nie można dodać takiego samego badania");
      }
    },

    saveAppToSummary(app) {
      this.$store.commit(Mutation.REGISTRATION_ADD_SCHEDULE, app);
    },

    generateReferralTicket() {
      let ticket = JSON.parse(JSON.stringify(this.ticketObject))

      if (this.currentTicket != null) {
        ticket = this.currentTicket;
      }

      ticket.ikz = this.ikzAdded;
      ticket.procedures = [];
      ticket.patientId = this.patient.id;
      ticket.releaseDate = this.mNow();
      ticket.startDate = this.mNow();
      ticket.created = this.mNow();
      ticket.expiryDate = moment().add(2, "year"); // "2021-12-31T00:00:00.000Z";
      ticket.ticketType = 7230; //change to clinicId -> selectClinisType.code
      ticket.minMinutes = 10;
      ticket.isTimeless = false;
      ticket.ticketOffice = this.selectClinicType
        ? this.selectClinicType.code
        : "";
      ticket.payerType = this.payerType;
      ticket.isPaid = this.isPaid;
      ticket.deviceType = this.deviceType;
      ticket.resultSentToLocation = this.resultSentToLocation ?? 0


      let newReferralObject = JSON.parse(JSON.stringify(this.referralObject));

      if (this.referralType) {
        newReferralObject.isReferralExternal = this.referralType.isInternal;
        newReferralObject.type = this.referralType.id;
        newReferralObject.isPaperReferral = this.referralType.isPaperReferral;

        if (this.referralType.isPaperReferral == true) {
          newReferralObject.idReferralFile = this.referralFileId;
        } else {
          if (this.resultP1 && this.addEReferralScan == false) {
            newReferralObject.eReferral = {
              id: this.resultP1.id,
              eSkierowanieKod: this.resultP1.eSkierowanieKod,
              eSkierowanieKlucz: this.resultP1.eSkierowanieKlucz,
              eSkierowanieNumer: this.resultP1.eSkierowanieNumer,
              eSkierowanie: this.resultP1.eSkierowanie,
              isNFZ: this.resultP1.isNFZ,
              authorUnitCode: this.resultP1.authorUnitCode,
              eSkierowanieData: this.resultP1.date !== null ? moment(this.resultP1.date).format("yyyy-MM-DD") : '0001-01-01T00:00:00'
            };
            newReferralObject.statusSkierowaniaP1 =
              this.resultP1.eSkierowanieStatus;
            newReferralObject.acceptedId = this.resultP1.acceptedId;

            newReferralObject.number = this.resultP1.eSkierowanieNumer;
          }
        }
      } else {
        //do nothing typeReferral is empty
      }

      // Skan papierowy e-skierowania
      if (
        this.referralType.isPaperReferral == false &&
        this.addEReferralScan == true
      ) {
        let eReferralPaperObject = {
          rootOID: "",
          referralId: "",
          referralCode: "",
          idStatementFile: "",
        };

        eReferralPaperObject.rootOID = this.eReferralPaper.rootOID;
        eReferralPaperObject.referralId = this.eReferralPaper.referralId;
        eReferralPaperObject.referralCode = this.eReferralPaper.referralCode;
        eReferralPaperObject.idStatementFile = this.statementFileId;

        newReferralObject.eReferralPaper = eReferralPaperObject;
      }

      this.referralDoctor =
        this.referralDoctorLastName +
        "^" +
        this.referralDoctorFirstName +
        "^" +
        this.referralDoctorPwz +
        "^" +
        this.referralDoctorPrefix;

      if (this.internalClinic) {
        newReferralObject.internalReferringClinicId = this.internalClinicId;
      } else {
        newReferralObject.externalReferringClinic = this.referralClinic;
      }

      if (this.internalDoctor) {
        newReferralObject.referredBy = this.referredBy;
      } else {
        newReferralObject.externalReferredBy = this.referralDoctor;
      }

      if (newReferralObject) {
        newReferralObject.hasReferralInOtherSystem =
          this.hasReferralInOtherSystem;
      }

      // DILO
      if (this.appointmentType && (this.appointmentType.id === 1000 || this.appointmentType.id === 1012 || this.appointmentType.id === 1023 || this.appointmentType.id === 10000 || this.appointmentType.id === 10001)) {
        newReferralObject.diloNumber = this.diloNumber
      }

      //APKOLCE - SKIEROWANIE
      if (
        this.appointmentType != -1 &&
        this.appointmentType &&
        this.appointmentType.apkolce
      ) {
        newReferralObject.apkolce = {
          subject: this.referralApkolce.subject,
          type: 0,
          issueDate: this.referralApkolce.issueDate ? moment(this.referralApkolce.issueDate).toDate() : '0001-01-01T00:00:00',
          regon: this.referralApkolce.regon,
          code1st: this.referralApkolce.code1st,
          code7th: this.referralApkolce.code7th,
          code8th: this.referralApkolce.code8th,
          musCode: this.referralApkolce.musCode,
        };

        if (this.referralType.id != 1) {
          newReferralObject.number = this.referralApkolce.referralNumber;
        }

        if (this.referralType.id == 0) {
          newReferralObject.apkolce.type = 1;
        } else if (this.referralType.id == 2) {
          newReferralObject.apkolce.type = 2;
        }
      }

      //APKOLCE

      if (
        this.appointmentType != -1 &&
        this.appointmentType &&
        this.appointmentType.apkolce
      ) {
        ticket.apkolce = {
          firstName: this.patient.firstName,
          lastName: this.patient.lastName,
          time: this.mNow(),
          created: this.mNow(),
          icd10Code: "",
          icd10Name: "",
          icd10Additional: [],
          comment: "",
          eReferral: false,
          rootCode: "",
          extensionCode: "",
          isUrgent: this.isUrgent,
          isOncologicalDilo: false,
          precision: 0,
          bodyParts: [],
          registrationType: "",
        };

        if (
          this.selectedIcd10 &&
          this.selectedIcd10.code &&
          this.selectedIcd10.desc
        ) {
          ticket.apkolce.icd10Code = this.selectedIcd10.code;
          ticket.apkolce.icd10Name = this.selectedIcd10.desc;
        }

        ticket.apkolce.comment = this.comment;

        this.selectedIcd10Additional.forEach((icd) => {
          let object = {
            code: icd.code,
            name: icd.desc,
          };
          ticket.apkolce.icd10Additional.push(object);
        });

        if (this.referralType && this.referralType.isPaperReferral == false) {
          ticket.apkolce.eReferral = true;
        }

        ticket.apkolce.registrationType = this.registrationType

        // APKOLCE DILO
        if (this.appointmentType && (this.appointmentType.id === 1000 || this.appointmentType.id === 1012 || this.appointmentType.id === 1023 || this.appointmentType.id === 10000 || this.appointmentType.id === 10001)) {
          ticket.apkolce.isOncologicalDilo = true;
        }

        if (this.bodyAreas && this.bodyAreas.length > 0) {
          this.bodyAreas.forEach((element) => {
            let obj = {
              code: element.codeOKC,
              name: element.name,
            };
            ticket.apkolce.bodyParts.push(obj);
          });
        }
      }

      ticket.referral = JSON.parse(JSON.stringify(newReferralObject));

      this.referralProcedures.forEach((exam) => {
        if (this.contractor != null) {
          exam.externalContractor = this.contractor;
        }
        ticket.procedures.push(JSON.parse(JSON.stringify(exam)));
      });

      if (this.currentTicket != null) {

        ticket.id = this.$store
          .dispatch(Action.TICKET_PUT, ticket)
          .then((response) => {
            this.currentTicket = response;
            let resp = [];
            resp.push(response);
            //this.translateTicket2(response);
            this.$emit("created", response);
            this.successSnackbar("Zmiany zapisane");
            this.isActiveModal = false;
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      } else {
        this.ticketsToSend.length = 0;
        let copy = JSON.parse(JSON.stringify(ticket));
        this.ticketsToSend.push(copy);
        // this.referralProcedures.forEach((exam) => {
        //   let copy = JSON.parse(JSON.stringify(ticket));
        //   if (this.contractor != null) {
        //     exam.externalContractor = this.contractor;
        //   }
        //   copy.procedures.push(JSON.parse(JSON.stringify(exam)));
        //   this.ticketsToSend.push(copy);
        // });

        // this.$store
        //   .dispatch(Action.TICKET_CREATE, ticket)
        //   .then((response) => {
        //     this.currentTicket = response;
        //     //this.translateTicket2(response);
        //     this.successSnackbar("Wygenerowano bilet");
        this.$emit("awaiting-create", this.ticketsToSend);
        this.isActiveModal = false;
        this.buttonText = "Edytuj badania radiologiczne";
        // })
        // .catch((error) => {
        //   this.apiProblem(error);
        // });
      }
    },

    translateTicket2(item) {
      this.filters.dates = [];
      //this.filters.office = null;
      if (this.selectedOffice) {
        this.filters.office = this.selectedOffice.id;
      } else {
        this.filters.office = null;
      }

      item.startDate = this.mDateTimeRaw(item.startDate);
      item.expiryDate = this.mDateTimeRaw(item.expiryDate);

      if (item.ticketType === 7230) {
        //var office = this.offices.find((o) => o.clinicId === 7230);

        if (this.typeSearch == 9) {
          this.filters.dates.push(item.startDate);
          this.filters.dates.push(item.expiryDate);
        } else {
          var startDate = this.mDate(this.selectedDates[0]);
          var endDate = this.mDate(this.selectedDates[1]);
          this.filters.dates = [];
          let count = moment(endDate).diff(startDate, "days") + 1;

          for (let index = 0; index < count; index++) {
            const element = moment(startDate).add(index, "day");
            this.filters.dates.push(this.mDate(element));
          }
        }
        this.filters.searchType = this.typeSearch;

        this.$store
          .dispatch(Action.S4_GET_SCHEDULE, this.filters)
          .then((response) => {
            this.days = response.data;
            if (response.data && response.data.days) {
              let count = 0;
              response.data.days.forEach((element) => {
                if (element.blocks && element.blocks.length > 0) {
                  count++;
                }
              });
              if (count > 0) {
                this.days = response.data;
                this.tab++;
              } else {
                this.dangerSnackbar(
                  "Brak ustalonych i wolnych terminów w wybranym przedziale czasowym"
                );
              }
            } else {
              this.dangerSnackbar("Błąd terminarza!");
            }
            this.days = response.data;
          })
          .catch((err) => {
            this.apiProblem(err);
          });
      }
    },

    procedureScheduled() {
      this.updateTicket();
    },

    getDates(dates) {
      this.refreshDays(dates);
    },

    updateTicket() {
      this.requestInProgress = true;
      this.$store
        .dispatch(Action.TICKET_PUT, this.currentTicket)
        .then((data) => {
          this.successSnackbar("Zmiany zapisane");
          this.currentTicket = data;
          this.refreshDays();
          this.requestInProgress = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.requestInProgress = false;
        });
    },

    refreshDays(dates) {
      let item = this.currentTicket;
      this.filters.dates = [];
      //this.filters.office = null;
      if (this.selectedOffice) {
        this.filters.office = this.selectedOffice.id;
      } else {
        this.filters.office = null;
      }

      item.startDate = this.mDateTimeRaw(item.startDate);
      item.expiryDate = this.mDateTimeRaw(item.expiryDate);

      if (item.ticketType === 7230) {
        if (dates) {
          this.selectedDates = dates;
          this.typeSearch = 8;
        } else {
          this.typeSearch = 9;
        }

        if (this.typeSearch == 9) {
          this.filters.dates.push(item.startDate);
          this.filters.dates.push(item.expiryDate);
        } else {
          var startDate = this.mDate(this.selectedDates[0]);
          var endDate = this.mDate(this.selectedDates[1]);
          this.filters.dates = [];
          let count = moment(endDate).diff(startDate, "days") + 1;

          for (let index = 0; index < count; index++) {
            const element = moment(startDate).add(index, "day");
            this.filters.dates.push(this.mDate(element));
          }
        }

        this.filters.searchType = this.typeSearch;

        this.$store
          .dispatch(Action.S4_GET_SCHEDULE, this.filters)
          .then((response) => {
            this.days = response.data;
          })
          .catch((err) => {
            this.apiProblem(err);
          });
        //}
      }
    },

    patientSearch() {
      this.isLoading = true;
      this.$store.commit(Mutation.REGISTRATION_RESET);
      this.$store
        .dispatch(Action.PATIENT_GET_BY_PESEL, this.queryP1.referralPesel)
        .then((response) => {
          if (response.data) {
            this.$store.commit(
              Mutation.REGISTRATION_SET_PATIENT,
              response.data
            );
            this.searchCounter++;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          this.searchCounter++;
          this.isAddPatientButtonActive = true;
          this.isLoading = false;
        });
    },

    patientSearchReset() {
      this.$store.commit(Mutation.REGISTRATION_RESET);
    },

    closeAddPatientModal() {
      this.isAddPatientModalActive = false;
    },

    sendScan() {
      this.loading = true;

      let scan = {
        patientId: this.patient.id,
        workerId: this.me.id,
        datetime: this.mNow(),
        uploadedFile: this.file,
        isUsedTwain: this.isUsedTwain,
        uploadType: 500,
        description: this.fileDescription,
      };

      this.$store
        .dispatch(Action.PATIENT_UPLOAD_FILE, scan)
        .then((response) => {
          this.referralFileId = response.data;
          this.file = null;
          this.isUsedTwain = false
          this.fileDescription = "";
          this.loading = false;
          this.successSnackbar("Zapisano na serwerze");

          //ADD IKZ - PLIK SKIEROWANIA
          this.addIkzScanUpload()
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    sendScanStatement() {
      this.loading = true;

      let scan = {
        patientId: this.patient.id,
        workerId: this.me.id,
        datetime: this.mNow(),
        uploadedFile: this.fileStatement,
        uploadType: 999,
        description: this.fileStatementDescription,
      };

      this.$store
        .dispatch(Action.PATIENT_UPLOAD_FILE, scan)
        .then((response) => {
          this.statementFileId = response.data;
          this.fileStatement = null;
          this.fileStatementDescription = "";
          this.loading = false;
          this.successSnackbar("Zapisano na serwerze");
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getTypeClinics() {
      this.loading = true;
      this.$store
        .dispatch(
          Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES,
          typesRiSClinics
        )
        .then((data) => {
          this.clinicTypes = data;
          this.loading = false;
          this.successSnackbar("Zapisano na serwerze");
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    selectSubunit(option) {
      this.selectedSubunit = option;
    },

    selectSubunitOffice(option) {
      this.selectedOffice = option;
    },

    getOffices() {
      this.getSubunitTypeClinics();
    },

    getSubjects() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_SUBJECTS_GET_ALL)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getSubUnits() {
      this.loading = true;
      this.$store
        .dispatch(Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getSubunitTypeClinics() {
      this.loading = true;
      let typeSubunit = [];
      if (this.selectClinicType) {
        typeSubunit.push(this.selectClinicType.id);
      }

      this.$store
        .dispatch(
          Action.FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES,
          typeSubunit
        )
        .then((data) => {
          this.subunits = data;
          this.subunitOffices = [];
          this.subunits.forEach((sub) => {
            let offs = this.offices.filter(
              (office) => office.subUnitId == sub.id
            );
            if (offs && offs.length > 0) {
              offs.forEach((office) => {
                office.facilityName = this.getFacility(office.facilityId);
                office.subUnitName = sub.name;
                this.subunitOffices.push(office);
              });
            }
          });
          //this.tab++;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    getFacility(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found != null ? found.name : `Brak (${id})`;
      } else return `Brak placówki (${id})`;
    },

    getFacilityObject(id) {
      if (this.facilities) {
        var found = this.facilities.find((x) => x.id === id);
        return found;
      } else return null;
    },

    getFacilityFullName(id) {
      if (this.facilities) {
        let found = this.facilities.find((x) => x.id === id);
        if (found) {
          let formatAdress = `Placówka: ${found.name} \nAdres: ${found.address.street
            } ${found.address.buildingNumber}${found.address.apartmentNumber
              ? ` / ${found.address.apartmentNumber}`
              : ""
            } ${found.address.zipCode} ${found.address.city}`;
          return formatAdress;
        } else return `Brak (${id})`;
      } else return null;
    },

    getImageTypes() {
      //this.loading = true;
      this.$store
        .dispatch(Action.RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL)
        .then(() => {
          //this.loading = false;
        })
        .catch((error) => {
          this.apiProblem(error);
          //this.loading = false;
        });
    },

    getRISSubjects() {
      this.loading = true;
      var medSpecType = [2]; //get from mContractSpecType; 2-radiologia
      this.$store
        .dispatch(Action.FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE, medSpecType)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.apiProblem(error);
        });
    },

    referralTrigger() {
      this.triggerP1 += 1;
      this.isLoading = true;
      this.addIkzReferralGet()
    },

    codeP1Change(code) {
      this.codeP1 = code;
    },

    eReferralSuccess(resultP1) {
      this.resultP1 = resultP1;
      this.tab = 1;
      this.isLoading = false;
      this.getReferralData();
    },

    eReferralFailure() {
      this.isLoading = false;
    },

    choosenIcd10(option) {
      this.selectedIcd10 = option;
    },

    choosenIcd10Additional(option) {
      let icd10Present = this.selectedIcd10Additional.find(
        (x) => x.desc == option.desc
      );

      if (icd10Present) {
        this.dangerSnackbar("Wybrane rozpoznanie znajduje się już na liście");
      } else {
        this.selectedIcd10Additional.push(option);
      }
    },

    addBodyArea(option) {
      if (option) {
        let found = this.bodyAreas.find((area) => area.id == option.id);

        if (found) {
          this.dangerSnackbar(
            "Wybrana okolica ciała znajduje się już na liście"
          );
          this.bodyArea = "";
        } else {
          this.bodyAreas.push(option);
          this.bodyArea = "";
        }
      }
    },

    removeIcd10() {
      this.selectedIcd10 = null;
    },

    getReferralData() {

      if (this.resultP1 && this.resultP1.date !== null) {
        let dateFormatted = this.mDate(this.resultP1.date)
        if (dateFormatted && dateFormatted !== "0001-01-01") {
          this.referralApkolce.issueDate = moment(this.resultP1.date).format("yyyy-MM-DD")
        }
      }

      if (this.resultP1 && this.resultP1.siteData !== null) {
        if (
          this.resultP1.siteData.code != null &&
          this.resultP1.siteData.code != "" &&
          this.resultP1.siteData.code != "string"
        ) {
          this.siteClinicCode = this.resultP1.siteData.code;
        }

        if (
          this.resultP1.siteData.p1_KsiegaRejestrowa != null &&
          this.resultP1.siteData.p1_KsiegaRejestrowa != "" &&
          this.resultP1.siteData.p1_KsiegaRejestrowa != "string"
        ) {
          this.siteMedicalEntitiesRegistrationCode1st =
            this.resultP1.siteData.p1_KsiegaRejestrowa;
        }

        if (
          this.resultP1.siteData.p1_VII != null &&
          this.resultP1.siteData.p1_VII != "" &&
          this.resultP1.siteData.p1_VII != "string"
        ) {
          this.siteIdentifierCode7th = this.resultP1.siteData.p1_VII;
        }

        if (
          this.resultP1.siteData.regon14 != null &&
          this.resultP1.siteData.regon14 != "" &&
          this.resultP1.siteData.regon14 != "string"
        ) {
          this.siteRegon = this.resultP1.siteData.regon14;
        }

        let subunitsFilter = this.subunitsAll;

        if (this.siteClinicCode) {
          subunitsFilter = subunitsFilter.filter(
            (x) => x.clinicCode == this.siteClinicCode
          );
        }

        if (this.siteMedicalEntitiesRegistrationCode1st) {
          subunitsFilter = subunitsFilter.filter(
            (x) =>
              x.departmentalCodes &&
              x.departmentalCodes.medicalEntitiesRegistrationCode1st ==
              this.siteMedicalEntitiesRegistrationCode1st
          );
        }

        if (this.siteIdentifierCode7th) {
          subunitsFilter = subunitsFilter.filter(
            (x) =>
              x.departmentalCodes &&
              x.departmentalCodes.identifierCode7th == this.siteIdentifierCode7th
          );
        }

        if (subunitsFilter.length == 1) {
          let option = subunitsFilter[0];
          this.internalClinicId = option.id;

          if (this.appointmentType && this.appointmentType.apkolce) {
            let subject = this.subjectsAll.find((x) => x.id == option.ownerId);

            if (subject) {
              this.referralApkolce.subject = subject.nameFull;
            }

            this.referralApkolce.regon = option.regon;
            this.referralApkolce.code1st = option.departmentalCodes
              ? option.departmentalCodes.medicalEntitiesRegistrationCode1st
              : "";
            this.referralApkolce.code7th = option.departmentalCodes
              ? option.departmentalCodes.identifierCode7th
              : "";
            this.referralApkolce.code8th = option.clinicCode
              ? option.clinicCode
              : "";
          }
        } else {
          this.referralApkolce.regon = this.siteRegon ?? "";
          this.referralApkolce.code1st = this.siteMedicalEntitiesRegistrationCode1st ?? "";
          this.referralApkolce.code7th = this.siteIdentifierCode7th ?? "";
          this.referralApkolce.code8th = this.siteClinicCode ?? "";
        }
      }

      if (this.resultP1 && this.resultP1.authorData !== null) {
        // DANE LEKARZA

        if (
          this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
          null &&
          this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
          "" &&
          this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
          "string"
        ) {
          let employee = this.employees.find(
            (emp) =>
              emp.pwz != null &&
              emp.pwz ==
              this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt
          );

          if (employee) {
            this.internalDoctor = true;
            this.referredBy = employee.id;
            this.employeeName = employee.name;
          } else {
            this.internalDoctor = false;

            if (
              this.resultP1.authorData.prefix != null &&
              this.resultP1.authorData.prefix != "" &&
              this.resultP1.authorData.prefix != "string"
            ) {
              this.referralDoctorPrefix = this.resultP1.authorData.prefix;
            }

            if (
              this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
              null &&
              this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
              "" &&
              this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
              "string"
            ) {
              this.referralDoctorPwz =
                this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt;
            }

            if (
              this.resultP1.authorData.medfirstname != null &&
              this.resultP1.authorData.medfirstname != "" &&
              this.resultP1.authorData.medfirstname != "string"
            ) {
              this.referralDoctorFirstName =
                this.resultP1.authorData.medfirstname;
            }

            if (
              this.resultP1.authorData.medlastname != null &&
              this.resultP1.authorData.medlastname != "" &&
              this.resultP1.authorData.medlastname != "string"
            ) {
              this.referralDoctorLastName =
                this.resultP1.authorData.medlastname;
            }
          }
        } else {
          this.internalDoctor = false;

          if (
            this.resultP1.authorData.prefix != null &&
            this.resultP1.authorData.prefix != "" &&
            this.resultP1.authorData.prefix != "string"
          ) {
            this.referralDoctorPrefix = this.resultP1.authorData.prefix;
          }

          if (
            this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
            null &&
            this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
            "" &&
            this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt !=
            "string"
          ) {
            this.referralDoctorPwz =
              this.resultP1.authorData.id_pracownika_ext_idUzytkownikaOidExt;
          }

          if (
            this.resultP1.authorData.medfirstname != null &&
            this.resultP1.authorData.medfirstname != "" &&
            this.resultP1.authorData.medfirstname != "string"
          ) {
            this.referralDoctorFirstName =
              this.resultP1.authorData.medfirstname;
          }

          if (
            this.resultP1.authorData.medlastname != null &&
            this.resultP1.authorData.medlastname != "" &&
            this.resultP1.authorData.medlastname != "string"
          ) {
            this.referralDoctorLastName = this.resultP1.authorData.medlastname;
          }
        }
      }

      // DANE PRACOWNI
      // if(this.resultP1 && this.resultP1.siteData !== null) {
      //     let code = ""
      //     let displayName = ""

      //       if(this.resultP1.siteData.code != null &&
      //       this.resultP1.siteData.code != "" &&
      //       this.resultP1.siteData.code != "string") {
      //         code = this.resultP1.siteData.code
      //       }

      //       if(this.resultP1.siteData.displayName != null &&
      //       this.resultP1.siteData.displayName != "" &&
      //       this.resultP1.siteData.displayName != "string") {
      //         displayName = this.resultP1.siteData.displayName
      //       }

      //     this.internalClinic = false
      //     this.referralClinic = `${displayName} (${code})`
      // }

      // PESEL PACJENTA
      // if(this.resultP1 && this.resultP1.patientData !== null) {

      //       if(this.resultP1.patientData.pesel != null &&
      //       this.resultP1.patientData.pesel != "" &&
      //       this.resultP1.patientData.pesel != "string") {
      //         this.patientPesel = this.resultP1.patientData.pesel
      //       }
      // }
    },

    selectApkolceSubunit(option) {
      if (option) {
        this.internalClinicId = option.id;

        let subject = this.subjectsAll.find((x) => x.id == option.ownerId);

        if (subject) {
          this.referralApkolce.subject = subject.nameFull;
        }

        this.referralApkolce.regon = option.regon;
        this.referralApkolce.code1st = option.departmentalCodes
          ? option.departmentalCodes.medicalEntitiesRegistrationCode1st
          : "";
        this.referralApkolce.code7th = option.departmentalCodes
          ? option.departmentalCodes.identifierCode7th
          : "";
        this.referralApkolce.code8th = option.clinicCode
          ? option.clinicCode
          : "";
      } else {
        this.internalClinicId = null;
        this.referralApkolce.subject = "";
        this.referralApkolce.regon = "";
        this.referralApkolce.code1st = "";
        this.referralApkolce.code7th = "";
        this.referralApkolce.code8th = "";
      }
    },

    printIt() {
      this.$htmlToPaper("printEReferralStatement");
    },

    getExternalDoctorFormula() {
      this.isLoading = true;

      let filters = {
        type: 2,
        type2: -1,
        type3: -1,
      };

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_GET_BY_TYPE, filters)
        .then((response) => {
          this.externalDoctorFormula = response;
          this.isLoading = false;

          this.externalDoctorFormula.forEach((object) => {
            object.doctor = JSON.parse(object.formula);
          })
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    addDoctorFormula() {
      this.isLoading = true;
      let formulaTitle = `${this.referralDoctorFirstName} ${this.referralDoctorLastName} (PWZ: ${this.referralDoctorPwz})`;
      let formulaContent = {
        firstName: this.referralDoctorFirstName,
        lastName: this.referralDoctorLastName,
        pwz: this.referralDoctorPwz,
        title: this.referralDoctorPrefix,
      };

      let payload = {
        employee: -1,
        type: 2, // LEKARZ ZEWNĘTRZNY
        type2: -1,
        type3: -1,
        title: formulaTitle,
        formula: JSON.stringify(formulaContent),
      };

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_CREATE, payload)
        .then((response) => {
          this.externalDoctorFormula.push(response);
          this.isLoading = false;
          this.externalDoctorFormulaModal = false;
          this.successSnackbar("Dodano zewnętrznego lekarza do słownika");
          this.addIkzAddExternalDoctor()
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    selectExternalDoctorFormula(option) {
      let formulaContent = JSON.parse(option.formula);

      if (formulaContent) {
        this.referralDoctorFirstName = formulaContent.firstName.toUpperCase();
        this.referralDoctorLastName = formulaContent.lastName.toUpperCase();
        this.referralDoctorPwz = formulaContent.pwz;
        this.referralDoctorPrefix = formulaContent.title;
      }
    },

    getExternalSubjectFormula() {
      this.isLoading = true;

      let filters = {
        type: 3,
        type2: -1,
        type3: -1,
      };

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_GET_BY_TYPE, filters)
        .then((response) => {
          this.externalSubjectsPaperApkolce = response;
          this.isLoading = false;

          this.externalSubjectsPaperApkolce.forEach((object) => {
            object.subject = JSON.parse(object.formula);
          })
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    addExternalSubjectFormula() {
      this.isLoading = true;
      let formulaTitle = `${this.referralApkolce.subunit} - ${this.referralApkolce.subject} (REGON: ${this.referralApkolce.regon})`
      let formulaContent = {
        subject: this.referralApkolce.subject,
        regon: this.referralApkolce.regon,
        code1st: this.referralApkolce.code1st,
        code7th: this.referralApkolce.code7th,
        code8th: this.referralApkolce.code8th
      };

      let payload = {
        employee: -1,
        type: 3, // LEKARZ ZEWNĘTRZNY
        type2: -1,
        type3: -1,
        title: formulaTitle,
        formula: JSON.stringify(formulaContent),
      };

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_CREATE, payload)
        .then((response) => {
          response.subject = JSON.parse(response.formula);
          this.externalSubjectsPaperApkolce.push(response);
          this.isLoading = false;
          this.externalSubjectsPaperApkolceModal = false;
          this.referralApkolce.subunit = ""
          this.successSnackbar("Dodano zewnętrzny podmiot do słownika");
          this.addIkzAddExternalApkolceSubject()
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    editExternalSubjectFormula() {
      this.isLoading = true;
      let formulaContent = {
        subject: this.editExternalSubjectApkolce.subject,
        regon: this.editExternalSubjectApkolce.regon,
        code1st: this.editExternalSubjectApkolce.code1st,
        code7th: this.editExternalSubjectApkolce.code7th,
        code8th: this.editExternalSubjectApkolce.code8th
      }

      let payload = {
        id: this.editExternalSubjectApkolce.id,
        employee: -1,
        type: 3, // PODMIOT ZEWNĘTRZNY
        type2: -1,
        type3: -1,
        title: this.editExternalSubjectApkolce.title,
        formula: JSON.stringify(formulaContent)
      }

      this.$store
        .dispatch(Action.EMPLOYEE_FORMULA_UPDATE, payload)
        .then((response) => {
          let editFormula = this.externalSubjectsPaperApkolce.find(x => x.id == response.id)
          if (editFormula) {
            editFormula.title = response.title
            editFormula.formula = response.formula
            editFormula.subject = JSON.parse(response.formula)
          }
          this.isLoading = false;
          this.editExternalSubjectsPaperApkolceModal = false;
          this.editExternalSubjectApkolce = {
            id: "",
            title: "",
            subject: "",
            regon: "",
            code1st: "",
            code7th: "",
            code8th: "",
          }
          this.externalSubjectsPaperApkolceFilter = ""
          this.successSnackbar("Edytowano zewnętrzny podmiot")
          this.addIkzEditExternalApkolceSubject()
        })
        .catch((error) => {
          this.isLoading = false;
          this.dangerSnackbar(error);
        });
    },

    selectExternalSubjectFormula(option) {
      let formulaContent = JSON.parse(option.formula);

      if (formulaContent) {
        this.referralApkolce.subject = formulaContent.subject;
        this.referralApkolce.regon = formulaContent.regon;
        this.referralApkolce.code1st = formulaContent.code1st;
        this.referralApkolce.code7th = formulaContent.code7th;
        this.referralApkolce.code8th = formulaContent.code8th;
      }
    },

    selectEditExternalSubjectFormula(option) {
      if (option) {
        this.editExternalSubjectApkolce.id = option.id
        this.editExternalSubjectApkolce.title = option.title

        let formulaContent = JSON.parse(option.formula)
        if (formulaContent) {
          this.editExternalSubjectApkolce.subject = formulaContent.subject
          this.editExternalSubjectApkolce.regon = formulaContent.regon
          this.editExternalSubjectApkolce.code1st = formulaContent.code1st
          this.editExternalSubjectApkolce.code7th = formulaContent.code7th
          this.editExternalSubjectApkolce.code8th = formulaContent.code8th
        }
      } else {
        this.editExternalSubjectApkolce = {
          id: "",
          title: "",
          subject: "",
          regon: "",
          code1st: "",
          code7th: "",
          code8th: "",
        }
      }
    },

    addIkzReferralGet() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 595)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzReferralConfirm() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 596)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzReferralResign() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 597)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzReferralRefuse() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 598)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzEReferralCancel() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 599)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzReferralAddCancel() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 630)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzAddExternalApkolceSubject() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 601)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzEditExternalApkolceSubject() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 602)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzAddExternalDoctor() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 603)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    addIkzScanUpload() {
      let facilityId = this.meParentFacilityObject ? this.meParentFacilityObject.id : null
      let selected = this.registrationIkz.find(x => x.internalId == 482)

      if (selected) {
        var payload = {
          "ikzId": selected.id,
          "employeeId": this.me.id,
          "patientId": this.patient.id,
          "group": selected.group,
          "type": selected.type,
          "source": selected.source,
          "points": selected.points,
          "ikzInternalId": selected.internalId,
          "name": selected.name,
          "entry": 3, // 3 - ENTRY - AUTOMATYCZNE
          "amount": 1,
          "facility": facilityId
        }

        this.$store
          .dispatch(Action.FINANCE_USER_IKZ_ADD, payload)
          .then(() => {
            //this.successSnackbar("Dodano IKZ");
          })
          .catch((error) => {
            this.apiProblem(error);
          });
      }
    },

    checkFileSize() {
      if (!this.mPatientFileCheckSize(this.file.size)) {
        this.file = null
        this.isUsedTwain = false
      }
    },

    checkFileStatementSize() {
      if (!this.mPatientFileCheckSize(this.fileStatement.size)) {
        this.fileStatement = null
      }
    },
  },
};
</script>

<style lang="scss"></style>
