<template>
  <div style=" margin:20px;">

    <section class="modal-card" style="
    width: 110%">
      <form style=" overflow-y: auto;">
        <header class="modal-card-head">
          <b-icon icon="scanner" size="is-medium"></b-icon>
          <h4 class="ml-3 title is-5 has-text-info">Skanowanie</h4>
        </header>
        <section class="modal-card-body" style="width:100%">
          <b-notification :closable="false">
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
            <div class="columns">
              <div class="column">
                <label class="label">Ustawienia</label>
                <form class="box">
                  <b-notification v-if="!isDynamsoftServiceAvilable" type="is-warning is-light" role="alert"
                    :closable=false>
                    Brak dostępnej usługi do skanowania
                    <span v-if="isTwainNeedUpgrade"> Oprogramowanie do skanowania Twain wymaga aktualizacji. Skontaktuj
                      się z działem IT </span>
                  </b-notification>
                  <div class=" my-5">
                    <b-field style="width: 100%" label="Urządzenie">
                      <b-select :disabled="!isDynamsoftServiceAvilable" expanded v-model="selectedIndex"
                        placeholder="Wybierz urządzenie" required>
                        <option v-for="device in avilableDevices" :value="device.index" :key="device.index">{{
                          device.name }}</option>
                      </b-select>
                    </b-field>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-button :disabled="!isDynamsoftServiceAvilable" style="width: 100%" @click="searchForDevices()"
                        type="is-light">Wyszukaj
                        urządzenia</b-button>
                      <b-button class="mt-3" style="width: 100%" @click="removeAllScans()" type="is-light"
                        :disabled="!isScanInBuffer">Usuń skany</b-button>

                      <b-button class="mt-3" style="width: 100%" @click="rotateSelectedScan(true)" type="is-light"
                        :disabled="!isScanInBuffer">Obróć skan w lewo</b-button>


                    </div>
                    <div class="column">
                      <b-button style="width: 100%" @click="startScan()" type="is-info"
                        :disabled="!isScannerAvilable">Skanuj</b-button>

                      <b-button class="mt-3" style="width: 100%" @click="removeSelectedScan()" type="is-light"
                        :disabled="!isScanInBuffer">Usuń wybrane skany</b-button>



                      <b-button class="mt-3" style="width: 100%" @click="rotateSelectedScan(false)" type="is-light"
                        :disabled="!isScanInBuffer">Obróć skan w prawo</b-button>
                    </div>
                  </div>
                  <div class="mb-5">
                    <label class="label">Typ pliku: {{ scanType }}</label>

                    <b-radio :disabled="!isScanInBuffer" v-model="scanType" name="name" native-value="pdf">
                      PDF
                    </b-radio>
                    <b-radio v-if="!onlyPdfAllowed" :disabled="!isScanInBuffer" v-model="scanType" name="name"
                      native-value="jpeg">
                      JPEG
                    </b-radio>
                    <b-radio v-if="!onlyPdfAllowed" :disabled="!isScanInBuffer" v-model="scanType" name="name"
                      native-value="png">
                      PNG
                    </b-radio>
                  </div>
                  <label class="label">Nazwa pliku</label>
                  <b-input :disabled="!isScanInBuffer" v-model="scanName" placeholder="Nazwa pliku"></b-input>
                </form>
                <label class="label">Prześlij plik</label>
                <form class="box">
                  <span v-if="!onlyPdfAllowed">
                    <b-upload v-model="file" class="file-label"
                      accept="image/jpeg, application/pdf, image/png, image/jpg"
                      validationMessage="Pliki jedynie w formatach pdf,jpg,png">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Prześlij plik z komputera</span>
                      </span>
                      <span class="file-name" v-if="file">{{ file.name }}</span>
                    </b-upload>
                  </span>
                  <span v-else>
                    <b-upload v-model="file" class="file-label" accept="application/pdf"
                      validationMessage="Pliki jedynie w formatach pdf">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Prześlij plik PDF z komputera</span>
                      </span>
                      <span class="file-name" v-if="file">{{ file.name }}</span>
                    </b-upload>
                  </span>

                </form>
              </div>
              <div class="column is-half">
                <label class="label">Podgląd</label>
                <form class="box" style="width: 100%; min-height: 400px;">
                  <div v-bind:id="containerId"></div>
                </form>
              </div>
            </div>
          </b-notification>
        </section>
        <footer class="modal-card-foot">
          <div style="width: 100%;" class="is-flex-direction-row is-flex is-justify-content-space-between">
            <b-button @click="emitClose()" type="is-danger">Anuluj</b-button>
            <b-button :disabled="file == null" @click="emitFile()">Zapisz przesłany plik</b-button>
            <b-button :disabled="!isScanInBuffer" @click="convertScan()">Zapisz skan</b-button>
          </div>
        </footer>
      </form>
    </section>
  </div>
</template>

<script>
import Dynamsoft from "dwt";
import CommonHelpers from '@/mixins/commons'
import { RUN_MODE } from '@/store/config_apis'
export default {
  name: 'ScannerModal',
  mixins: [CommonHelpers],
  components: {
    // DawidScanComponentVue,
  },

  props: {
    onlyPdfAllowed: { type: Boolean, required: true, },
  },

  data: function () {
    return {
      isDisabled: true,
      isScannerAvilable: false,
      isLoading: false,
      isScanInBuffer: false,
      isFullPage: true,
      isTwainNeedUpgrade: false,
      isComponentModalActive: false,
      scanType: 'pdf',
      scanName: '',
      selectedDevice: '',
      avilableDevices: [],
      containerId: "dwtControlContainer",
      sourceList: [],
      selectedIndex: 0,
      file: null,
      DWObject: null,
      isDynamsoftServiceAvilable: true

    }
  },

  mounted() {
    this.createDynamSoft()

  },

  watch: {

  },

  computed: {
    me() { return this.$store.state.employee.me },
    runMode() { return RUN_MODE },
    perms() {
      return this.$store.state.employee.permits
    },
  },

  methods: {
    createDynamSoft() {
      if (this.runMode == 'P') {
        // Prod key
        Dynamsoft.DWT.ProductKey = "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAzMzMyMzc0LTEwMzM0Njk2OCIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMzMzMjM3NCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NkbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJjaGVja0NvZGUiOjE4MzEwMzk5MzZ9";
      }
      else if (this.runMode == 'K') {
        //klon key
        Dynamsoft.DWT.ProductKey = "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAzMzMyMzc0LTEwMzM0Njk2OCIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMzMzMjM3NCIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NkbHMuZHluYW1zb2Z0b25saW5lLmNvbS8iLCJjaGVja0NvZGUiOjE4MzEwMzk5MzZ9";
      }
      else if (this.runMode == 'T') {
        Dynamsoft.DWT.ProductKey = "t01898AUAALj5KvAqyBx8dDwZpETS7zYlANb2+YaRh+ZQzTkT7M3hZkT+vkrfiCisvhNOweJIOin5br5IZQpwO50+pp+M5Kft9supBk6r7zTUd7KBU5ecwGMe5mW3wzxvmqAReM6AbcdhA1gCSy0HoItrbfQAeYA5gHk10AO+ruL884VjQMqv/2zo5FQDp9V3lgGp42QDpy45h4CkjNAPq+2XgLA8OTtAHmBfAa4X2SkgOALyADsBgYgpyj5KJjF9";
      }
      //if (!this.perms.testerScaner) 
      else {
        Dynamsoft.DWT.ProductKey = ""
      }
      Dynamsoft.OnWebTwainNeedUpgradeCallback = () => {
        this.isDynamsoftServiceAvilable = false
        this.isTwainNeedUpgrade = true
        this.dangerSnackbar("Oprogramowanie do skanowania Twain wymaga aktualizacji. Skontaktuj się z działem IT")
      }
      Dynamsoft.OnWebTwainNotFoundOnWindowsCallback = () => {
        this.isDynamsoftServiceAvilable = false
      }
      Dynamsoft.OnWebTwainNotFoundOnAndroidCallback = () => {
        this.isDynamsoftServiceAvilable = false
      }
      Dynamsoft.OnWebTwainNotFoundOnLinuxCallback = () => {
        this.isDynamsoftServiceAvilable = false
      }
      Dynamsoft.OnWebTwainNotFoundOnMacCallback = () => {
        this.isDynamsoftServiceAvilable = false
      }
      Dynamsoft.OnLicenseError = () => {
        this.isDynamsoftServiceAvilable = false
        // this.dangerSnackbar("Wystąpił problem z licencją do skanera")
      }
      Dynamsoft.OnLicenseExpiredWarning = () => {
        this.isDynamsoftServiceAvilable = false
        this.dangerSnackbar("Wystąpił problem z licencją do skanera")
      }
      Dynamsoft.DWT.ResourcesPath = "/dwt-resources";
      Dynamsoft.DWT.Containers = [
        {
          WebTwainId: "dwtObject",
          ContainerId: this.containerId,
          Width: "100%",
          Height: "400px",
        },
      ];

      Dynamsoft.DWT.RegisterEvent("OnWebTwainReady", () => {
        this.Dynamsoft_OnReady()
      })

      Dynamsoft.DWT.RegisterEvent('OnWebTwainError', () => {
        this.Dynamsoft_OnError()
      }),


        Dynamsoft.DWT.OnWebTwainPreExecute = () => {
          this.isLoading = true;
        },

        Dynamsoft.DWT.OnWebTwainPostExecute = () => {
          this.isLoading = false;
        },

        Dynamsoft.DWT.Load()
    },

    startScan() {
      this.selectedDevice = this.avilableDevices[this.selectedIndex]
      if (this.selectedDevice == undefined) {
        this.dangerSnackbar("Wybierz skaner")
        return
      }
      let deviceConfiguration = {
        IfShowUI: false,
        PixelType: Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
        Resolution: 300,
        IfFeederEnabled: true,
        IfDuplexEnabled: false,
        IfDisableSourceAfterAcquire: true,
        IfGetImageInfo: true,
        IfGetExtImageInfo: true,
        extendedImageInfoQueryLevel: 0,
        IfCloseSourceAfterAcquire: true,
      };
      // this.DWObject.IfShowUI = true;
      this.DWObject.SelectDeviceAsync(this.selectedDevice)
        .then(() => {
          return this.DWObject.AcquireImage(deviceConfiguration,
            () => {
              //Success callback
              this.checkImageBuffor()
            }
            , () => {
              //Failure callback
              this.dangerSnackbar("Wystąpił błąd poczas skanowania dokumentu");
            });
        })
        .catch((e) => {
          this.dangerSnackbar("Wystąpił błąd poczas skanowania dokumentu");
          if (e) {
            this.dangerSnackbar(e.message)
          }
        })
    },

    // sendFile() {
    //   if (this.file) {
    //     this.$emit('scanFile', this.file)
    //     this.file = null
    //     this.scanDescription = ''
    //     this.removeAllScans()
    //   } else {
    //     this.dangerSnackbar("Brak wybranego pliku")
    //   }
    // },
    //Do sprawdzenia
    emitFile() {
      if (this.file) {
        let emitObjectFile = {
          file: this.file,
          isUsedTwain: false
        }
        this.$emit('scanFile', emitObjectFile)
      }
    },

    convertScan() {
      if (this.scanType === '') {
        this.dangerSnackbar("Nie wybrano typu pliku")
      }
      let indexArr = this.DWObject.SelectAllImages()
      let convertType = ''
      switch (this.scanType) {
        case 'pdf':
          convertType = Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF
          break;
        case 'png':
          convertType = Dynamsoft.DWT.EnumDWT_ImageType.IT_PNG
          break;
        case 'jpeg':
          convertType = Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG
          break;
      }

      this.DWObject.ConvertToBlob(
        indexArr,
        convertType,
        (result) => {
          // Success callback
          this.DWObject.ClearAllHTTPFormField()
          // result.name = "scan_" + convertType + "." + this.scanType
          this.emitImage(result, true)
        },
        () => {
          // Failure callback
          // Chceck if try to convert multiple pages scan to png or jpeg
          if (this.DWObject.HowManyImagesInBuffer > 1 && convertType != Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF) {
            this.dangerSnackbar("Skany o większej ilości stron można zapisać tylko jako PDF.")
          }
          this.dangerSnackbar("Nie udało się przekonwertować zdjęć.")
        }
      );
    },
    checkImageBuffor() {
      if (this.DWObject) {
        if (this.DWObject.HowManyImagesInBuffer != 0) {
          this.isScanInBuffer = true
        } else {
          this.isScanInBuffer = false
        }
      }
    },
    emitClose() {
      try {
        this.file = null
        this.scanName = ''
        this.removeAllScans()
      } finally {
        this.$emit('close')
      }

    },

    removeAllScans() {
      if (this.DWObject) {
        this.DWObject.RemoveAllImages()
        this.checkImageBuffor()
      }
    },

    rotateSelectedScan(onLeft) {
      let selectedImages = this.DWObject.SelectedImagesIndices
      if (selectedImages.length != 1) {
        this.dangerSnackbar("Można obracać tylko jedno zdjęcie jednocześnie")
      } else {
        if (onLeft) {
          this.DWObject.RotateLeft(selectedImages[0])
        } else {
          this.DWObject.RotateRight(selectedImages[0])
        }
      }
    },

    removeSelectedScan() {
      let selectedImages = this.DWObject.SelectedImagesIndices
      if (selectedImages.length != 0) {
        this.DWObject.SelectImages(selectedImages)
        if (this.DWObject.RemoveAllSelectedImages()) {
          this.dangerSnackbar("Usunięto zdjęcia")
        } else {
          this.dangerSnackbar("Nie udało się usunąć zdjęcia")
        }
      } else {
        this.dangerSnackbar("Nie zostały zaznaczone zdjęcia")
      }
      this.checkImageBuffor()
    },

    searchForDevices() {
      // Jeśli brak DynamSoft service na urządzeniu:
      if (!this.isDynamsoftServiceAvilable) {
        return;
      }
      this.avilableDevices = ''
      this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
      if (!this.DWObject) {
        try {
          Dynamsoft.DWT.CreateDWTObject(this.containerId)
          this.DWObject = Dynamsoft.DWT.GetWebTwain(this.containerId);
        } catch (_) {
          this.dangerSnackbar("Nie udało się uruchomić usługi skanera")
        }
      }
      this.DWObject.GetDevicesAsync()
        .then((deviceList) => {
          if (deviceList.length != 0) {
            this.sourceList = deviceList;
            this.avilableDevices = deviceList
            this.selectedIndex = 0
            let index = 0
            this.avilableDevices.forEach(element => {
              element.index = index
              index++
            });
            this.isScannerAvilable = true
          } else {
            this.isScannerAvilable = false
            this.dangerSnackbar("Brak dostępnych skanerów")
          }
        })
        .catch(() => {
          this.dangerSnackbar("Wystąpił błąd podczas wyszukiwania skanerów");
          // this.dangerSnackbar(e)
        });
    },

    emitImage(fileSend, isUsedTwain = false) {
      // fileSend.description = this.scanDescription
      // fileSend.workerId = this.me.id
      this.file = null
      if (this.scanName == '') {
        this.scanName = "skan"
      }
      let emitFile = new File([fileSend], this.scanName + "." + this.scanType)
      let emitObjectFile = {
        file: emitFile,
        isUsedTwain: isUsedTwain
      }

      this.$emit('scanFile', emitObjectFile)
      this.removeAllScans()

    },

    Dynamsoft_OnError(error) {
      this.isLoading = false;
      if (error) {
        this.dangerSnackbar("Wystąpił błąd ze skanowaniem");
        // this.dangerSnackbar(error.message);
      }

    },

    Dynamsoft_OnReady() {
      this.isLoading = false;
      this.searchForDevices()
      this.DWObject.Viewer.acceptDrop = false,
        this.DWObject.Viewer.allowSlide = false,
        this.DWObject.Viewer.cursor = "pointer"
      this.DWObject.IfAllowLocalCache = false;
    },


  }
}
</script>

<style scoped lang="scss">
.label {
  color: hsl(0, 0%, 21%) !important;
  display: block !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  position: static !important;
}


.b-skeleton {
  height: 100%;
}

#dwtControlContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  height: 450px;
  overflow-y: auto;
}
</style>