export const Action = {

    SET_BLOCK_LOGIN: 'SET_BLOCK_LOGIN',

    // TMP MODULE
    S2_SEND_WORKTIME: 'S2_SEND_WORKTIME',
    S2_SEND_APPOINTMENT: 'S2_SEND_APPOINTMENT',
    S2_SEND_APPOINTMENT_VEHICLE: 'S2_SEND_APPOINTMENT_VEHICLE',
    S2_EDIT_APPOINTMENT_VEHICLE: 'S2_EDIT_APPOINTMENT_VEHICLE',//NEW
    S2_EDIT_APPOINTMENT: 'S2_EDIT_APPOINTMENT',
    S2_DELETE_APPOINTMENT: 'S2_DELETE_APPOINTMENT',
    S2_GET_APPOINTMENT: 'S2_GET_APPOINTMENT',
    S2_DELETE_WORKTIME: 'S2_DELETE_WORKTIME',
    S2_GET_SCHEDULE_WORKER: 'S2_GET_SCHEDULE_WORKER',
    S4_GET_MANY_APPOINTMENTS: 'S4_GET_MANY_APPOINTMENTS',
    S2_GET_SCHEDULE: 'S2_GET_SCHEDULE',
    S2_SET_STATUS: 'S2_SET_STATUS',
    S2_POST_STATUS: 'S2_POST_STATUS',
    S3_GET_SCHEDULE: 'S3_GET_SCHEDULE',
    S4_GET_SCHEDULE: 'S4_GET_SCHEDULE',
    SCHEDULE4_ADD_AREA: 'SCHEDULE4_ADD_AREA',
    SCHEDULE4_ADD_AREA_EXPANDED: 'SCHEDULE4_ADD_AREA_EXPANDED',
    S3_MODIFY_WORKTIME: 'S3_MODIFY_WORKTIME',
    S3_OVERWRITE_WORKTIMES: 'S3_OVERWRITE_WORKTIMES',
    S3_MOVE_PRO: 'S3_MOVE_PRO',
    S3_FILL_WORKTIMES: 'S3_FILL_WORKTIMES',
    S3_DELETE_WORKTIMES: 'S3_DELETE_WORKTIMES',
    S3_CONFIG_POST: 'S3_CONFIG_POST',
    S3_CONFIG_GET: 'S3_CONFIG_GET',

    S3_MARK_PATIENT_WAITING: 'S3_MARK_PATIENT_WAITING',
    ER_END_APP_08: 'ER_END_APP_08',

    S3_GET_UNFINISHED_APPOINTMENTS: 'S3_GET_UNFINISHED_APPOINTMENTS',

    CONFIG_GET_DICTIONARY: 'CONFIG_GET_DICTIONARY',
    CONFIG_GET_ALL_DICTIONARIES: 'CONFIG_GET_ALL_DICTIONARIES',

    S3_WORKTIME_MULTI: 'S3_WORKTIME_MULTI',

    S3_GET_APPOINTMENTS_PATIENT: 'S3_GET_APPOINTMENTS_PATIENT',

    S3_ADD_TO_GROUP: 'S3_ADD_TO_GROUP',
    S3_REMOVE_FROM_GROUP: 'S3_REMOVE_FROM_GROUP',
    S3_SPLIT_GROUP: 'S3_SPLIT_GROUP',

    S2_RESCHEDULE_APPOINTMENT: 'S2_RESCHEDULE_APPOINTMENT',

    S2_CANCEL_APPOINTMENT: 'S2_CANCEL_APPOINTMENT',

    S2_APPOINTMENT_CANCEL_EXTENDED: 'S2_APPOINTMENT_CANCEL_EXTENDED',

    S3_WORKTIMES_CYCLICAL: 'S3_WORKTIMES_CYCLICAL',

    TOOLS_GET_FILTERED_APPOINTMENTS: 'TOOLS_GET_FILTERED_APPOINTMENTS',

    S3_GET_APPOINTMENTS_FOR_PATIENT: 'S3_GET_APPOINTMENTS_FOR_PATIENT',

    S3_GET_APPOINTMENTS_FILTER: 'S3_GET_APPOINTMENTS_FILTER',

    S2_REFERRAL_PROBLEMS: 'S2_REFERRAL_PROBLEMS',
    S2_REFERRAL_PROBLEMS_FOR_PATIENT: 'S2_REFERRAL_PROBLEMS_FOR_PATIENT',

    S2_GET_APPOINTMENTS_BY_TICKET: 'S2_GET_APPOINTMENTS_BY_TICKET',
    S2_GET_APPOINTMENTS_BY_MANY_TICKETS: 'S2_GET_APPOINTMENTS_BY_MANY_TICKETS',
    S2_APPOINTMENT_ADD_TICKET: 'S2_APPOINTMENT_ADD_TICKET',
    S2_APPOINTMENT_REMOVE_TICKET: 'S2_APPOINTMENT_REMOVE_TICKET',

    S3_APPOINTMENT_PATIENT_ABSENT: 'S3_APPOINTMENT_PATIENT_ABSENT',
    S3_APPOINTMENT_PATIENT_LATE: 'S3_APPOINTMENT_PATIENT_LATE',
    S2_GET_APPOINTMENT_VERSION: 'S2_GET_APPOINTMENT_VERSION',

    S3_APPOINTMENTS_PATIENT_UNFINISHED: 'S3_APPOINTMENTS_PATIENT_UNFINISHED',

    // ORDERS MODULE
    ORDERS_GET_PRODUCTS: 'ORDERS_GET_PRODUCTS',
    ORDERS_ADD_PRODUCT: 'ORDERS_ADD_PRODUCT',
    ORDERS_MODIFY_PRODUCT: 'ORDERS_MODIFY_PRODUCT',
    ORDERS_DELETE_PRODUCT: 'ORDERS_DELETE_PRODUCT',
    ORDERS_GET_SUPPLIERS: 'ORDERS_GET_SUPPLIERS',
    ORDERS_ADD_SUPPLIER: 'ORDERS_ADD_SUPPLIER',
    ORDERS_MODIFY_SUPPLIER: 'ORDERS_MODIFY_SUPPLIER',
    ORDERS_DELETE_SUPPLIER: 'ORDERS_DELETE_SUPPLIER',
    ORDERS_CREATE_NEW: 'ORDERS_CREATE_NEW',
    ORDERS_GET_ALL: 'ORDERS_GET_ALL',
    ORDERS_GET: 'ORDERS_GET',
    ORDERS_DELETE: 'ORDERS_DELETE',

    // HUB
    HUB_GET_ACTIVE_DEVICES: 'HUB_GET_ACTIVE_DEVICES',
    HUB_CHECK_DEVICE: 'HUB_CHECK_DEVICE',
    HUB_UPLOAD: 'HUB_UPLOAD',
    HUB_CREATE_JOB: 'HUB_CREATE_JOB',
    HUB_GET_JOB_BY_ID: 'HUB_GET_JOB_BY_ID',
    HUB_FINISH_JOB: 'HUB_FINISH_JOB',
    HUB_VIEW_SIGNED: 'HUB_VIEW_SIGNED',
    HUB_REJECT_JOB: 'HUB_REJECT_JOB',
    HUB_CHECK_JOB: 'HUB_CHECK_JOB',

    // NEW NEW NEW FOR HUB MODULE
    HUB_DOCUMENT_TEMPLATE_GET_ALL: 'HUB_DOCUMENT_TEMPLATE_GET_ALL',
    HUB_DOCUMENT_TEMPLATE_CREATE: 'HUB_DOCUMENT_TEMPLATE_CREATE',
    HUB_DOCUMENT_TEMPLATE_UPDATE: 'HUB_DOCUMENT_TEMPLATE_UPDATE',
    HUB_DOCUMENT_TEMPLATE_DELETE: 'HUB_DOCUMENT_TEMPLATE_DELETE',
    HUB_DOCUMENT_TEMPLATE_GET_BY_ID: 'HUB_DOCUMENT_TEMPLATE_GET_BY_ID',
    HUB_DOCUMENT_TEMPLATE_GET_BY_CODE: 'HUB_DOCUMENT_TEMPLATE_GET_BY_CODE',
    HUB_DOCUMENT_TEMPLATE_UPLOAD: 'HUB_DOCUMENT_TEMPLATE_UPLOAD',
    HUB_DOCUMENT_TEMPLATE_GET_ZIP_BY_ID: 'HUB_DOCUMENT_TEMPLATE_GET_ZIP_BY_ID',

    // EDU MODULE
    EDU_DOWNLOAD_INSTRUCTION: 'EDU_DOWNLOAD_INSTRUCTION',
    EDU_DOWNLOAD_INSTRUCTION_FOR_USER_MANUAL: 'EDU_DOWNLOAD_INSTRUCTION_FOR_USER_MANUAL',


    EDU_GET_COURSES: 'EDU_GET_COURSES',
    EDU_GET_COURSES_LITE: 'EDU_GET_COURSES_LITE',
    EDU_ADD_COURSE: 'EDU_ADD_COURSE',
    EDU_MODIFY_COURSE: 'EDU_MODIFY_COURSE',
    EDU_UPLOAD_FILE: 'EDU_UPLOAD_FILE',
    EDU_GET_FILE: 'EDU_GET_FILE',
    EDU_GET_PROGRESS: 'EDU_GET_PROGRESS',
    EDU_MODIFY_PROGRESS: 'EDU_MODIFY_PROGRESS',

    // POZ MODULE
    POZ_BEGIN_APPOINTMENT: 'POZ_BEGIN_APPOINTMENT',
    POZ_END_APPOINTMENT: 'POZ_END_APPOINTMENT',
    POZ_GET_PATHS: 'POZ_GET_PATHS',
    POZ_MODIFY_PATH: 'POZ_MODIFY_PATHS',
    POZ_ADD_PATH: 'POZ_ADD_PATH',
    POZ_GET_DIAGNOSES: 'POZ_GET_DIAGNOSES',
    POZ_GET_APPOINTMENTS: 'POZ_GET_APPOINTMENTS',
    POZ_SIGN_APPOINTMENT: 'POZ_SIGN_APPOINTMENT',

    PD_BUNDLE_STATS: 'PD_BUNDLE_STATS',

    PD_PATIENT_DATA_QUESTIONS_GET_BY_PATIENT: 'PD_PATIENT_DATA_QUESTIONS_GET_BY_PATIENT',
    PD_PATIENT_DATA_QUESTIONS_GET_BY_REFERRAL: 'PD_PATIENT_DATA_QUESTIONS_GET_BY_REFERRAL',
    PD_PATIENT_DATA_QUESTIONS_GET_BY_TYPE: 'PD_PATIENT_DATA_QUESTIONS_GET_BY_TYPE',
    PD_PATIENT_DATA_QUESTIONS_CREATE: 'PD_PATIENT_DATA_QUESTIONS_CREATE',
    PD_PATIENT_DATA_QUESTIONS_UPDATE: 'PD_PATIENT_DATA_QUESTIONS_UPDATE',
    PD_PATIENT_DATA_QUESTIONS_REMOVE: 'PD_PATIENT_DATA_QUESTIONS_REMOVE',
    PD_PATIENT_DATA_QUESTIONS_RESTORE: 'PD_PATIENT_DATA_QUESTIONS_RESTORE',
    PD_PATIENT_DATA_QUESTIONS_ADD_USED_IN: 'PD_PATIENT_DATA_QUESTIONS_ADD_USED_IN',
    PD_PATIENT_DATA_QUESTIONS_REMOVE_USED_IN: 'PD_PATIENT_DATA_QUESTIONS_REMOVE_USED_IN',

    //SIGN VISIT - ERC SERVICE, CREATE EDM
    EDM_VISITSUMMARY_SEND: 'EDM_VISITSUMMARY_SEND',
    EDM_VISITSUMMARY_GET: 'EDM_VISITSUMMARY_GET',
    EDM_VISITSUMMARY_GET_DIAGNOSIS: 'EDM_VISITSUMMARY_GET_DIAGNOSIS',

    //IPOM - ERC SERVICE, CREATE, GET IPOM
    IPOM_SEND: 'IPOM_SEND',
    IPOM_GET: 'IPOM_GET',

    //IPOM - PATIENT SERVICE, CREATE, GET PATIENT IPOM
    PATIENT_IPOM_GET: 'PATIENT_IPOM_GET',
    PATIENT_IPOM_GET_BY_PATIENT_ID: 'PATIENT_IPOM_GET_BY_PATIENT_ID',
    PATIENT_IPOM_LIGHT_GET_BY_PATIENT_ID: 'PATIENT_IPOM_LIGHT_GET_BY_PATIENT_ID',
    PATIENT_IPOM_DIAGNOSES_GET_BY_PATIENT_ID: 'PATIENT_IPOM_DIAGNOSES_GET_BY_PATIENT_ID',
    PATIENT_IPOM_DELETE: 'PATIENT_IPOM_DELETE',
    PATIENT_IPOM_UPDATE: 'PATIENT_IPOM_UPDATE',
    PATIENT_IPOM_CREATE: 'PATIENT_IPOM_CREATE',
    PATIENT_IPOM_CHANGE_STATUS: 'PATIENT_IPOM_CHANGE_STATUS',
    PATIENT_IPOM_ADD_VERSION: 'PATIENT_IPOM_ADD_VERSION',
    PATIENT_IPOM_SET_DOCUMENT: 'PATIENT_IPOM_SET_DOCUMENT',
    PATIENT_IPOM_CANCEL_DOCUMENT: 'PATIENT_IPOM_CANCEL_DOCUMENT',


    //HIPOM - PATIENT SERVICE, CREATE, GET PATIENT HIPOM
    PATIENT_HIPOM_GET: 'PATIENT_HIPOM_GET',
    PATIENT_HIPOM_GET_BY_PATIENT_ID: 'PATIENT_HIPOM_GET_BY_PATIENT_ID',
    PATIENT_HIPOM_DELETE: 'PATIENT_HIPOM_DELETE',
    PATIENT_HIPOM_UPDATE: 'PATIENT_HIPOM_UPDATE',
    PATIENT_HIPOM_CREATE: 'PATIENT_HIPOM_CREATE',
    PATIENT_HIPOM_CHANGE_STATUS: 'PATIENT_HIPOM_CHANGE_STATUS',
    PATIENT_HIPOM_ADD_VERSION: 'PATIENT_HIPOM_ADD_VERSION',
    PATIENT_HIPOM_SET_DOCUMENT: 'PATIENT_HIPOM_SET_DOCUMENT',
    PATIENT_HIPOM_CANCEL_DOCUMENT: 'PATIENT_HIPOM_CANCEL_DOCUMENT',


    // CLINICS MODULE
    CLINICS_GET_ALL: 'CLINICS_GET_ALL',
    CLINICS_GET_ADRESSES: 'CLINICS_GET_ADRESSES',
    CLINICS_ADD_CLINIC_ADDRESS: 'CLINICS_ADD_CLINIC_ADDRESS',
    CLINICS_UPDATE_ADDRESS: 'CLINICS_UPDATE_ADDRESS',

    FACILITIES_GET_ALL: 'FACILITIES_GET_ALL',

    FACILITY_REMOVE: 'FACILITY_REMOVE',

    // CLINICS MODULE
    PHARMA_PRODUCT_SEARCH: 'PHARMA_PRODUCT_SEARCH',
    PHARMA_DRUG_SEARCH: 'PHARMA_DRUG_SEARCH',
    PHARMA_DRUG_SEARCH_SUBSTANCE: 'PHARMA_DRUG_SEARCH_SUBSTANCE',
    PHARMA_DRUG_SEARCH_OTC: 'PHARMA_DRUG_SEARCH_OTC',
    PHARMA_DRUG_SEARCH_OTC_SUBSTANCE: 'PHARMA_DRUG_SEARCH_OTC_SUBSTANCE',
    PHARMA_DRUG_SEARCH_DELETED: 'PHARMA_DRUG_SEARCH_DELETED',
    PHARMA_DRUG_GET_DELETED: 'PHARMA_DRUG_GET_DELETED',
    PHARMA_SEARCH_ICD10: 'PHARMA_SEARCH_ICD10',
    PHARMA_SEARCH_ICD9: 'PHARMA_SEARCH_ICD9',
    PHARMA_GET_VACCINATION_ICDS: 'PHARMA_GET_VACCINATION_ICDS',
    PHARMA_SEARCH_SUBSTANCE: 'PHARMA_SEARCH_SUBSTANCE',
    PHARMA_SEARCH_GROUPER_ITEM_LIMITED: 'PHARMA_SEARCH_GROUPER_ITEM_LIMITED',
    PHARMA_SEARCH_GROUPER_ITEM_FULL: 'PHARMA_SEARCH_GROUPER_ITEM_FULL',
    PHARMA_CALCULATE_GROUPER: 'PHARMA_CALCULATE_GROUPER',
    PHARMA_SEARCH_COMPONENT: 'PHARMA_SEARCH_COMPONENT',




    JGP2_CALCULATE: 'JGP2_CALCULATE',
    JGP2_INFO: 'JGP2_INFO',
    JGP2_GET_ALL_CODES: 'JGP2_GET_ALL_CODES',
    JGP2_GET_ALL_ITEMS: 'JGP2_GET_ALL_ITEMS',




    PHARMA_SEARCH_TOWNS: 'PHARMA_SEARCH_TOWNS',
    PHARMA_SEARCH_TOWNS_TERYT: 'PHARMA_SEARCH_TOWNS_TERYT',
    PHARMA_SEARCH_TERYT: 'PHARMA_SEARCH_TERYT',
    PHARMA_SEARCH_FILTER: 'PHARMA_SEARCH_FILTER',
    PHARMA_SEARCH_GET_BY_VALUES: 'PHARMA_SEARCH_GET_BY_VALUES',
    PHARMA_SEARCH_TERYT2: 'PHARMA_SEARCH_TERYT2',
    PHARMA_SEARCH_TERYT3: 'PHARMA_SEARCH_TERYT3',

    PHARMA_INDEX_SEARCH_COMPONENT: 'PHARMA_INDEX_SEARCH_COMPONENT',
    PHARMA_INDEX_SEARCH_RECIPE: 'PHARMA_INDEX_SEARCH_RECIPE',
    PHARMA_INDEX_GET_RECIPE_BY_ID: 'PHARMA_INDEX_GET_RECIPE_BY_ID',
    PHARMA_INDEX_SEARCH_DRUG: 'PHARMA_INDEX_SEARCH_DRUG',
    PHARMA_INDEX_SEARCH_DRUG_VAC: 'PHARMA_INDEX_SEARCH_DRUG_VAC',
    PHARMA_INDEX_GET_SINGLE_DRUG: 'PHARMA_INDEX_GET_SINGLE_DRUG',
    PHARMA_INDEX_GET_SINGLE_DRUG_MAX: 'PHARMA_INDEX_GET_SINGLE_DRUG_MAX',

    // NEW FOR PHARMA MODULE
    PHARMA_ICD_DIAGNOSIS_GET_ALL: 'PHARMA_ICD_DIAGNOSIS_GET_ALL',
    PHARMA_ICD_DIAGNOSIS_UPDATE: 'PHARMA_ICD_DIAGNOSIS_UPDATE',

    PHARMA_DRUG_ADDS_CREATE: 'PHARMA_DRUG_ADDS_CREATE',
    PHARMA_DRUG_ADDS_UPDATE: 'PHARMA_DRUG_ADDS_UPDATE',
    PHARMA_DRUG_ADDS_DELETE: 'PHARMA_DRUG_ADDS_DELETE',
    PHARMA_DRUG_ADDS_GET_ALL: 'PHARMA_DRUG_ADDS_GET_ALL',
    PHARMA_DRUG_ADDS_GET: 'PHARMA_DRUG_ADDS_GET',
    PHARMA_DRUG_ADD_GET_DANGEROUS: 'PHARMA_DRUG_ADD_GET_DANGEROUS',
    PHARMA_DRUG_ADD_GET_MEDKITS: 'PHARMA_DRUG_ADD_GET_MEDKITS',
    PHARMA_DRUG_ADD_GET_VACCINES: 'PHARMA_DRUG_ADD_GET_VACCINES',
    PHARMA_DRUG_ADD_GET_BY_DRUG: 'PHARMA_DRUG_ADD_GET_BY_DRUG',

    PHARMA_DRUG_GET_UNDESCRIBED_ALL: 'PHARMA_DRUG_GET_UNDESCRIBED_ALL',
    PHARMA_DRUG_GET_UNDESCRIBED_AND_WITHOUT_SUBSTANCE: 'PHARMA_DRUG_GET_UNDESCRIBED_AND_WITHOUT_SUBSTANCE',

    PHARMA_ACTIVE_SUBSTANCES_GET_ALL: 'PHARMA_ACTIVE_SUBSTANCES_GET_ALL',
    PHARMA_ACTIVE_SUBSTANCE_POST: 'PHARMA_ACTIVE_SUBSTANCE_POST',

    PHARMA_DRUG_REMOVED_GET_ALL: 'PHARMA_DRUG_REMOVED_GET_ALL',

    PHARMA_BMI_GET: 'PHARMA_BMI_GET',

    PHARMA_DRUGS_GET_MANY: 'PHARMA_DRUGS_GET_MANY',
    PHARMA_CREATE_DRUG_HISTORY: 'PHARMA_CREATE_DRUG_HISTORY',
    PHARMA_EDIT_DRUG_HISTORY: 'PHARMA_EDIT_DRUG_HISTORY',
    PHARMA_GET_DRUG_HISTORY: 'PHARMA_GET_DRUG_HISTORY',

    // STATS MODULE
    STATS_GET_WORKER_TIMES: 'STATS_GET_WORKER_TIMES',
    STATS_SEND_WORKER_TIME: 'STATS_SEND_WORKER_TIME',
    STATS_GET_FEEDBACKS: 'STATS_GET_FEEDBACKS',
    STATS_SEND_FEEDBACK: 'STATS_SEND_FEEDBACK',
    STATS_COMPLETE_FEEDBACK: 'STATS_COMPLETE_FEEDBACK',
    STATS_GET_EMPLOYEE_TIME: 'STATS_GET_EMPLOYEE_TIME',
    STATS_EMPLOYEE_NIGHTIME: 'STATS_EMPLOYEE_NIGHTIME',

    STATS_CRASH_LOGS_ALL: 'STATS_CRASH_LOGS_ALL',
    STATS_CRASH_LOG_CREATE: 'STATS_CRASH_LOG_CREATE',
    STATS_CRASH_LOG_MODIFY: 'STATS_CRASH_LOG_MODIFY',

    STATS_CHANGELOG_GET_CURRENT: 'STATS_CHANGELOG_GET_CURRENT',
    STATS_CHANGELOG_ADD: 'STATS_CHANGELOG_ADD',
    STATS_CHANGELOG_EDIT: 'STATS_CHANGELOG_EDIT',
    STATS_CHANGELOG_DELETE: 'STATS_CHANGELOG_DELETE',

    STATS_API_ERROR_CREATE: 'STATS_API_ERROR_CREATE',
    STATS_API_ERRORS_GET_ALL: 'STATS_API_ERRORS_GET_ALL',

    STATS_SEND_USER_FEEDBACK: 'STATS_POST_SEND_FEEDBACK',



    SWAGGER_DOCS: 'SWAGGER_DOCS',





    // LAB MODULE
    LAB_GET_CONTAINERS: 'LAB_GET_CONTAINERS',
    LAB_ADD_CONTAINER: 'LAB_ADD_CONTAINER',
    LAB_EDIT_CONTAINER: 'LAB_EDIT_CONTAINER',
    LAB_RESET_CONTAINER: 'LAB_RESET_CONTAINER',
    LAB_UNPACK_CONTAINER: 'LAB_UNPACK_CONTAINER',
    LAB_UPDATE_CONTAINER_TEMPERATURE: 'LAB_UPDATE_CONTAINER_TEMPERATUREF',

    LAB_GET_STANDS: 'LAB_GET_STANDS',
    LAB_GET_MANY_STANDS_BY_CODE: 'LAB_GET_MANY_STANDS_BY_CODE',
    LAB_ADD_STAND: 'LAB_ADD_STAND',
    LAB_EDIT_STAND: 'LAB_EDIT_STAND',
    LAB_GET_STAND_EVENT_BY_ID: 'LAB_GET_STAND_EVENT_BY_ID',
    LAB_DELETE_STAND: 'LAB_DELETE_STAND',
    LAB_GET_STAND_BY_CODE: 'LAB_GET_STAND_BY_CODE',

    LAB_MATERIAL_ADD_FLAG: 'LAB_MATERIAL_ADD_FLAG',
    LAB_MATERIAL_REMOVE_FLAG: 'LAB_MATERIAL_REMOVE_FLAG',

    LAB_SAMPLE_ADD_FLAG: 'LAB_SAMPLE_ADD_FLAG',
    LAB_SAMPLE_REMOVE_FLAG: 'LAB_SAMPLE_REMOVE_FLAG',

    LAB_CLINICAL_REPORT_GENERATE: 'LAB_CLINICAL_REPORT_GENERATE',

    LAB_SEARCH_TELEADDRESS: 'LAB_SEARCH_TELEADDRESS',

    LAB_CREATE_TEST_SAMPLE: 'LAB_CREATE_TEST_SAMPLE',
    LAB_CREATE_SAMPLE_SET: 'LAB_CREATE_SAMPLE_SET',
    LAB_SPLIT_MATERIAL: 'LAB_SPLIT_MATERIAL',
    LAB_GET_ORDER_BY_TICKET: 'LAB_GET_ORDER_BY_TICKET',
    LAB_GET_BY_MANY_ORDER_TICKET: 'LAB_GET_BY_MANY_ORDER_TICKET',
    LAB_MATERIAL_MARK: 'LAB_MATERIAL_MARK',
    LAB_MATERIAL_MARK_GROUP: 'LAB_MATERIAL_MARK_GROUP',
    LAB_MATERIAL_REVERT_MARK: 'LAB_MATERIAL_REVERT_MARK',
    LAB_SIGN_SAMPLES: 'LAB_SIGN_SAMPLES',
    LAB_UNSIGN_SAMPLES: 'LAB_UNSIGN_SAMPLES',
    LAB_DOWNLOAD_SAMPLES: 'LAB_DOWNLOAD_SAMPLES',
    LAB_GET_SAMPLE_FILE: 'LAB_GET_SAMPLE_FILE',
    LAB_DOWNLOAD_SAMPLE: 'LAB_DOWNLOAD_SAMPLE',
    LAB_DOWNLOAD_SAMPLE_UNDONE: 'LAB_DOWNLOAD_SAMPLE_UNDONE',
    LAB_GET_SAMPLE_RESULT_FILE: 'LAB_GET_SAMPLE_RESULT_FILE',
    LAB_GET_SAMPLES: 'LAB_GET_SAMPLES',
    LAB_ADD_SAMPLE: 'LAB_ADD_SAMPLE',
    LAB_EDIT_SAMPLE: 'LAB_EDIT_SAMPLE',
    LAB_EDIT_SAMPLE_PID: 'LAB_EDIT_SAMPLE_PID',
    LAB_GET_SAMPLE_BY_BARCODE: 'LAB_GET_SAMPLE_BY_BARCODE',
    LAB_GET_SAMPLE_BY_PATIENT_ID: 'LAB_GET_SAMPLE_BY_PATIENT_ID',
    LAB_GET_SAMPLES_BY_DATES: 'LAB_GET_SAMPLES_BY_DATES',
    LAB_GET_SAMPLES_FILTERED: 'LAB_GET_SAMPLES_FILTERED',
    LAB_GET_SAMPLES_FILTERED_2: 'LAB_GET_SAMPLES_FILTERED_2',
    LAB_GET_SAMPLES_FILTERED_2_LIGHT: 'LAB_GET_SAMPLES_FILTERED_2_LIGHT',
    LAB_GET_SAMPLES_FILTERED_3: 'LAB_GET_SAMPLES_FILTERED_3',
    LAB_GET_SAMPLES_FILTERED_LIGHT: 'LAB_GET_SAMPLES_FILTERED_LIGHT',
    LAB_GET_SAMPLES_BY_DATES_WITH_TIME: 'LAB_GET_SAMPLES_BY_DATES_WITH_TIME',
    LAB_GET_SAMPLES_BY_DATES_AND_LOCALS: 'LAB_GET_SAMPLES_BY_DATES_AND_LOCALS',
    LAB_FIND_SAMPLES: 'LAB_FIND_SAMPLES',
    LAB_GET_SAMPLE_BY_ID: 'LAB_GET_SAMPLE_BY_ID',
    LAB_ADD_MULTIPLE: 'LAB_ADD_MULTIPLE',
    LAB_REVERT_SAMPLE: 'LAB_REVERT_SAMPLE',
    LAB_UPDATE_MULTIPLE: 'LAB_UPDATE_MULTIPLE',

    LAB_GET_PLATES: 'LAB_GET_PLATES',
    LAB_GET_LATEST_PLATES: 'LAB_GET_LATEST_PLATES',
    LAB_POST_PLATE: 'LAB_POST_PLATE',
    LAB_UNDO_PLATE: 'LAB_UNDO_PLATE',
    LAB_EDIT_PLATE: 'LAB_EDIT_PLATE',
    LAB_DOWNLOAD_PLATE_BATCH: 'LAB_DOWNLOAD_PLATE_BATCH',
    LAB_UPLOAD_PLATE_RESULTS: 'LAB_UPLOAD_PLATE_RESULTS',
    LAB_UPLOAD_GENERAL_RESULTS: 'LAB_UPLOAD_GENERAL_RESULTS',
    LAB_UPLOAD_HPV_RESULTS: 'LAB_UPLOAD_HPV_RESULTS',
    LAB_UPLOAD_HPV_RESULTS_LH: 'LAB_UPLOAD_HPV_RESULTS_LH',
    LAB_UPLOAD_COVID2_RESULTS: 'LAB_UPLOAD_COVID2_RESULTS',
    LAB_UPLOAD_COVID4_RESULTS: 'LAB_UPLOAD_COVID4_RESULTS',
    LAB_UPLOAD_COVID_CL_RESULTS: 'LAB_UPLOAD_COVID_CL_RESULTS',

    LAB_GET_STRIPS: 'LAB_GET_STRIPS',
    LAB_GET_UNUSED_STRIPS: 'LAB_GET_UNUSED_STRIPS',
    LAB_GET_STRIP_BY_ID: 'LAB_GET_STRIP_BY_ID',
    LAB_NEW_STRIP: 'LAB_NEW_STRIP',
    LAB_ADD_TO_STRIP: 'LAB_ADD_TO_STRIP',
    LAB_REMOVE_FROM_STRIP: 'LAB_REMOVE_FROM_STRIP',
    LAB_MODIFY_STRIP: 'LAB_MODIFY_STRIP',

    LAB_COVID_CREATE_REQUEST: 'LAB_COVID_CREATE_REQUEST',
    LAB_COVID_CHECK_REQUEST: 'LAB_COVID_CHECK_REQUEST',
    LAB_COVID_GET_REQUEST_DATA: 'LAB_COVID_GET_REQUEST_DATA',

    LAB_SAMPLES_ACCEPT: 'LAB_SAMPLES_ACCEPT',
    LAB_SAMPLES_VIRO_P12: 'LAB_SAMPLES_VIRO_P12',

    LAB_ADD_SAMPLE_STATUS: 'LAB_ADD_SAMPLE_STATUS',

    LAB_QUEUE_SEARCH_PESEL: 'LAB_QUEUE_SEARCH_PESEL',

    LAB_GET_CYTO_RESULTS: 'LAB_GET_CYTO_RESULTS',

    LAB_SAMPLE_DELETE: 'LAB_SAMPLE_DELETE',

    //NEW FOR LAB MODULE
    LAB_ORDER_EXTERNAL_GET_BY_CODE: 'LAB_ORDER_EXTERNAL_GET_BY_CODE',
    LAB_ORDER_GET_BY_PATIENT: 'LAB_ORDER_GET_BY_PATIENT',
    LAB_ORDER_GET_BY_DATE: 'LAB_ORDER_GET_BY_DATE',
    LAB_ORDER_GET_ALL: 'LAB_ORDER_GET_ALL',
    LAB_ORDER_GET_BY_ID: 'LAB_ORDER_GET_BY_ID',
    LAB_ORDER_GET_BY_CODE: 'LAB_ORDER_GET_BY_CODE',
    LAB_ORDER_CREATE: 'LAB_ORDER_CREATE',
    LAB_ORDER_UPDATE: 'LAB_ORDER_UPDATE',
    LAB_ORDER_DELETE: 'LAB_ORDER_DELETE',

    LAB_GET_PATIENTS_HIGH_RISK: 'LAB_GET_PATIENTS_HIGH_RISK',

    LAB_ACCEPT_SAMPLES_P12_NEW: 'LAB_ACCEPT_SAMPLES_P12_NEW',
    LAB_ACCEPT_SAMPLES_EXTERNAL: 'LAB_ACCEPT_SAMPLES_EXTERNAL',

    LAB_GET_SOLAB_ORDERS: 'LAB_GET_SOLAB_ORDERS',
    LAB_GET_INFO_ORDERS: 'LAB_GET_INFO_ORDERS',
    LAB_MARK_SOLAB_SIGNED: 'LAB_MARK_SOLAB_SIGNED',

    LAB_CYTOLOGY_CREATE_DATASET: 'LAB_CYTOLOGY_CREATE_DATASET',
    LAB_MIDWIFE_CREATE_DATASET_V3: 'LAB_MIDWIFE_CREATE_DATASET_V3',
    LAB_ADD_MATERIAL_TO_ORDER: 'LAB_ADD_MATERIAL_TO_ORDER',
    LAB_ADD_SAMPLE_TO_MATERIAL: 'LAB_ADD_SAMPLE_TO_MATERIAL',

    LAB_FILTER_HISPAT: 'LAB_FILTER_HISPAT',

    LAB_GET_RESULTS_BY_PATIENT_ID: 'LAB_GET_RESULTS_BY_PATIENT_ID',

    LAB_GET_STATS: 'LAB_GET_STATS',
    LAB_GET_STATS_FINAL: 'LAB_GET_STATS_FINAL',
    LAB_GET_STATS_RSC: 'LAB_GET_STATS_RSC',
    LAB_GET_STATS_GLOBAL: 'LAB_GET_STATS_GLOBAL',
    LAB_GET_STATUS_TIMES: 'LAB_GET_STATUS_TIMES',

    LAB_MATERIAL_ADD_STATUS: 'LAB_MATERIAL_ADD_STATUS',
    LAB_MATERIAL_ADD_STATUS_GROUPED: 'LAB_MATERIAL_ADD_STATUS_GROUPED',

    LAB_CREATE_HPV_SOLAB_OR_CYTO: 'LAB_CREATE_HPV_SOLAB_OR_CYTO',
    LAB_GET_ORDER_INFO_BY_MATERIAL_SOLAB_ID: 'LAB_GET_ORDER_INFO_BY_MATERIAL_SOLAB_ID',
    LAB_GET_STATS_GLOBAL_COLLECTED_BY: 'LAB_GET_STATS_GLOBAL_COLLECTED_BY',
    LAB_GET_STATS_GLOBAL_COLLECTED_BY_SITES: 'LAB_GET_STATS_GLOBAL_COLLECTED_BY_SITES',

    LAB_FILTER_BY_LOCATION: 'LAB_FILTER_BY_LOCATION',

    LAB_COLLECTION_POINT_SAMPLE_UPDATE_ORDER_TYPE: 'LAB_COLLECTION_POINT_SAMPLE_UPDATE_ORDER_TYPE',

    LAB_SEARCH_FIND_MANY: 'LAB_SEARCH_FIND_MANY',
    LAB_SAMPLE_DELETE_MANY: 'LAB_SAMPLE_DELETE_MANY',

    // MOBI MODULE
    // MOBI_GET_MY_ACCOUNT: 'MOBI_GET_MY_ACCOUNT',
    // MOBI_GET_ACCOUNTS: 'MOBI_GET_ACCOUNTS',
    // MOBI_GET_ACCOUNT: 'MOBI_GET_ACCOUNT',
    // MOBI_ADD_ACCOUNT: 'MOBI_ADD_ACCOUNT',
    // MOBI_ADD_MY_ACCOUNT: 'MOBI_ADD_MY_ACCOUNT',
    // MOBI_ADD_NEW_ACCOUNT: 'MOBI_ADD_NEW_ACCOUNT',
    // MOBI_ADD_NEW_FACILITY_ACCOUNT: 'MOBI_ADD_NEW_FACILITY_ACCOUNT',
    // MOBI_GET_ACCOUNT_BY_EXTERNAL: 'MOBI_GET_ACCOUNT_BY_EXTERNAL',
    // MOBI_EDIT_ACCOUNT: 'MOBI_EDIT_ACCOUNT',
    // MOBI_DELETE_ACCOUNT: 'MOBI_DELETE_ACCOUNT',

    // MOBI_GET_PARCELS: 'MOBI_GET_PARCELS',
    // MOBI_GET_PARCELS_FOR_USER: 'MOBI_GET_PARCELS_FOR_USER',
    // MOBI_GET_PARCEL: 'MOBI_GET_PARCEL',
    // MOBI_GET_PARCEL_BY_CODE: 'MOBI_GET_PARCEL_BY_CODE',
    // MOBI_ADD_PARCEL: 'MOBI_ADD_PARCEL',
    // MOBI_ADD_PARCEL_GROUP: 'MOBI_ADD_PARCEL_GROUP',
    // MOBI_CHANGE_STATUS: 'MOBI_CHANGE_STATUS',
    // MOBI_MODIFY_PARCEL: 'MOBI_MODIFY_PARCEL',
    // MOBI_DELETE_PARCEL: 'MOBI_DELETE_PARCEL',
    // MOBI_FILTER_PARCELS_BY_CONTENT: 'MOBI_FILTER_PARCELS_BY_CONTENT',

    // MOBI_GET_VEHICLES: 'MOBI_GET_VEHICLES',
    // MOBI_GET_VEHICLE: 'MOBI_GET_VEHICLE',
    // MOBI_ADD_VEHICLE: 'MOBI_ADD_VEHICLE',
    // MOBI_MODIFY_VEHICLE: 'MOBI_MODIFY_VEHICLE',
    // MOBI_DELETE_VEHICLE: 'MOBI_DELETE_VEHICLE',

    // MOBI_GET_GROUPS: 'MOBI_GET_GROUPS',
    // MOBI_GET_GROUP: 'MOBI_GET_GROUP',
    // MOBI_ADD_GROUP: 'MOBI_ADD_GROUP',
    // MOBI_MODIFY_GROUP: 'MOBI_MODIFY_GROUP',
    // MOBI_DELETE_GROUP: 'MOBI_DELETE_GROUP',

    // MOBI 2

    MOBI2_GET_GROUPS: 'MOBI2_GET_GROUPS',
    MOBI2_GET_GROUP: 'MOBI2_GET_GROUP',
    MOBI2_ADD_GROUP: 'MOBI2_ADD_GROUP',
    MOBI2_MODIFY_GROUP: 'MOBI2_MODIFY_GROUP',
    MOBI2_DELETE_GROUP: 'MOBI2_DELETE_GROUP',

    MOBI2_GET_MY_ACCOUNT: 'MOBI2_GET_MY_ACCOUNT',
    MOBI2_GET_ACCOUNTS: 'MOBI2_GET_ACCOUNTS',
    MOBI2_GET_ACCOUNT: 'MOBI2_GET_ACCOUNT',
    MOBI2_ADD_ACCOUNT: 'MOBI2_ADD_ACCOUNT',
    MOBI2_ADD_MY_ACCOUNT: 'MOBI2_ADD_MY_ACCOUNT',
    MOBI2_ADD_NEW_ACCOUNT: 'MOBI2_ADD_NEW_ACCOUNT',
    MOBI2_ADD_NEW_FACILITY_ACCOUNT: 'MOBI2_ADD_NEW_FACILITY_ACCOUNT',
    MOBI2_GET_ACCOUNT_BY_EXTERNAL: 'MOBI2_GET_ACCOUNT_BY_EXTERNAL',
    MOBI2_EDIT_ACCOUNT: 'MOBI2_EDIT_ACCOUNT',
    MOBI2_DELETE_ACCOUNT: 'MOBI2_DELETE_ACCOUNT',

    MOBI2_GET_ALL_ROUTES: 'MOBI2_GET_ALL_ROUTES',
    MOBI2_GET_ROUTE: 'MOBI2_GET_ROUTE',
    MOBI2_CREATE_ROUTE: 'MOBI2_CREATE_ROUTE',
    MOBI2_EDIT_ROUTE: 'MOBI2_EDIT_ROUTE',
    MOBI2_DELETE_ROUTE: 'MOBI2_DELETE_ROUTE',

    MOBI2_GET_VEHICLES: 'MOBI2_GET_VEHICLES',
    MOBI2_GET_VEHICLE: 'MOBI2_GET_VEHICLE',
    MOBI2_ADD_VEHICLE: 'MOBI2_ADD_VEHICLE',
    MOBI2_MODIFY_VEHICLE: 'MOBI2_MODIFY_VEHICLE',
    MOBI2_DELETE_VEHICLE: 'MOBI2_DELETE_VEHICLE',

    MOBI2_GET_PARCELS: 'MOBI2_GET_PARCELS',
    MOBI2_GET_PARCELS_FOR_USER: 'MOBI2_GET_PARCELS_FOR_USER',
    MOBI2_GET_PARCEL: 'MOBI2_GET_PARCEL',
    MOBI2_GET_PARCEL_BY_CODE: 'MOBI2_GET_PARCEL_BY_CODE',
    MOBI2_ADD_FLAG: 'MOBI2_ADD_FLAG',
    MOBI2_ADD_PARCEL: 'MOBI2_ADD_PARCEL',
    MOBI2_ADD_PARCEL_GROUP: 'MOBI2_ADD_PARCEL_GROUP',
    MOBI2_CHANGE_STATUS: 'MOBI2_CHANGE_STATUS',
    MOBI2_MODIFY_PARCEL: 'MOBI2_MODIFY_PARCEL',
    MOBI2_DELETE_PARCEL: 'MOBI2_DELETE_PARCEL',
    MOBI2_FILTER_PARCELS_BY_CONTENT: 'MOBI2_FILTER_PARCELS_BY_CONTENT',
    MOBI2_FILTER_PARCELS: 'MOBI2_FILTER_PARCELS',
    MOBI2_COUNT_PARCELS: 'MOBI2_COUNT_PARCELS',

    // REGISTRATION MODULE
    REGISTRATION_RESET: 'REGISTRATION_RESET',

    // SCHEDULE MODULE
    SCHEDULE_ADD: 'SCHEDULE_ADD',
    SCHEDULE_FREESLOT_ADD: 'SCHEDULE_FREESLOT_ADD',
    SCHEDULE_GET_GROUPS: 'SCHEDULE_GET_GROUPS',
    SCHEDULE_REMOVE: 'SCHEDULE_REMOVE',
    SCHEDULE_GET_APPOINTMENTS_FOR_WORKER: 'SCHEDULE_GET_APPOINTMENTS_FOR_WORKER',

    CGATE_EMPLOYEES_STATS: 'CGATE_EMPLOYEES_STATS',
    CGATE_ADD_SLOTS: 'CGATE_ADD_SLOTS',
    CGATE_MODIFY_SLOT: 'CGATE_MODIFY_SLOT',
    CGATE_EREGISTER_APP: 'CGATE_EREGISTER_APP',

    MEDICAL_DEVICE_GET_ALL: 'MEDICAL_DEVICE_GET_ALL',
    MEDICAL_DEVICE_GET: 'MEDICAL_DEVICE_GET',
    MEDICAL_DEVICE_ADD: 'MEDICAL_DEVICE_ADD',
    MEDICAL_DEVICE_DELETE: 'MEDICAL_DEVICE_DELETE',
    MEDICAL_DEVICE_MODIFY: 'MEDICAL_DEVICE_MODIFY',

    // PATIENT MODULE
    PATIENT_SEARCH: 'PATIENT_SEARCH',
    PATIENT_SEARCH_DOUBLE: 'PATIENT_SEARCH_DOUBLE',
    PATIENT_GET_BY_ID: 'PATIENT_GET_BY_ID',
    PATIENT_GET_BY_PESEL: 'PATIENT_GET_BY_PESEL',
    PATIENT_ADD: 'PATIENT_ADD',
    PATIENT_GET_MY_DATA: 'PATIENT_GET_MY_DATA',
    PATIENT_GET_DATA: 'PATIENT_GET_DATA',
    PATIENT_DELETE: 'PATIENT_DELETE',
    PATIENT_MODIFY: 'PATIENT_MODIFY',
    PATIENT_GET_EWUS: 'PATIENT_GET_EWUS',
    PATIENT_UPDATE_ALLERGIES: 'PATIENT_UPDATE_ALLERGIES',
    PATIENT_GET_ALLERGIES: 'PATIENT_GET_ALLERGIES',
    PATIENT_UPLOAD_FILE: 'PATIENT_UPLOAD_FILE',
    PATIENT_GET_RISKS: 'PATIENT_GET_RISKS',
    PATIENT_GET_MANY: 'PATIENT_GET_MANY',
    PATIENT_UPDATE_NOTE: 'PATIENT_UPDATE_NOTE',
    PATIENT_GET_FAMILY_INFO: 'PATIENT_GET_FAMILY_INFO',
    PATIENT_SEND_FAMILY_INFO: 'PATIENT_SEND_FAMILY_INFO',
    PATIENT_GET_CHANGELOG: 'PATIENT_GET_CHANGELOG',
    PATIENT_GET_FOR_40_PLUS: 'PATIENT_GET_FOR_40_PLUS',

    PATIENT_GET_GUARDIAN: 'PATIENT_GET_GUARDIAN',
    PATIENT_GET_GUARDIAN_FOR_PATIENT: 'PATIENT_GET_GUARDIAN_FOR_PATIENT',
    PATIENT_GET_GUARDIAN_ACTIVE_FOR_PATIENT: 'PATIENT_GET_GUARDIAN_ACTIVE_FOR_PATIENT',
    PATIENT_POST_GUARDIAN: 'PATIENT_POST_GUARDIAN',
    PATIENT_ACTIVATE_GUARDIAN: 'PATIENT_ACTIVATE_GUARDIAN',
    PATIENT_DEACTIVATE_GUARDIAN: 'PATIENT_DEACTIVATE_GUARDIAN',


    PRIVILEGE_TYPE_GET_ALL: 'PRIVILEGE_TYPE_GET_ALL',
    PRIVILEGE_TYPE_ADD: 'PRIVILEGE_TYPE_ADD',
    PRIVILEGE_TYPE_GET_BY_ID: 'PRIVILEGE_TYPE_GET_BY_ID',
    PRIVILEGE_TYPE_MODIFY: 'PRIVILEGE_TYPE_MODIFY',
    PRIVILEGE_TYPE_DELETE: 'PRIVILEGE_TYPE_DELETE',

    PATIENT_PRIVILEGE_GET_BY_ID: 'PATIENT_PRIVILEGE_GET_BY_ID',
    PATIENT_PRIVILEGE_MODIFY: 'PATIENT_PRIVILEGE_MODIFY',
    PATIENT_PRIVILEGE_GET_ACTIVE: 'PATIENT_PRIVILEGE_GET_ACTIVE',
    PATIENT_PRIVILEGE_GET_ALL: 'PATIENT_PRIVILEGE_GET_ALL',
    PATIENT_PRIVILEGE_ADD: 'PATIENT_PRIVILEGE_ADD',

    PD_ADD_BUNDLE: 'PD_ADD_BUNDLE',
    PD_ADD_REGISTRATION_TO_BUNDLE: 'PD_ADD_REGISTRATION_TO_BUNDLE',
    PD_GET_UNFINISHED_REGISTRATIONS: 'PD_GET_UNFINISHED_REGISTRATIONS',
    PD_GET_UNFINISHED_REGISTRATIONS_FACILITY: 'PD_GET_UNFINISHED_REGISTRATIONS_FACILITY',

    PD_GET_DRUGS_INFO_BY_PATIENT_ID: 'PD_GET_DRUGS_INFO_BY_PATIENT_ID',
    PD_GET_DIAGNOSES_INFO_BY_PATIENT_ID: 'PD_GET_DIAGNOSES_INFO_BY_PATIENT_ID',
    PD_GET_DIAGNOSES_WITH_DRUGS_BY_PATIENT_ID: 'PD_GET_DIAGNOSES_WITH_DRUGS_BY_PATIENT_ID',

    PD_UPDATE_DRUG_INFO: 'PD_UPDATE_DRUG_INFO',
    PD_UPDATE_DIAGNOSE_INFO: 'PD_UPDATE_DIAGNOSE_INFO',

    PATIENT_DEKLE_GENERATE: 'PATIENT_DEKLE_GENERATE',

    PATIENT_SEND_DECLARATION: 'PATIENT_SEND_DECLARATION',

    PATIENT_POST_INSURANCE_STATUS: 'PATIENT_POST_INSURANCE_STATUS',
    PATIENT_GET_INSURANCE_STATUS_BY_PATIENT_ID: 'PATIENT_GET_INSURANCE_STATUS_BY_PATIENT_ID',
    PATIENT_GET_INSURANCE_STATUS_BY_PATIENT_PESEL: 'PATIENT_GET_INSURANCE_STATUS_BY_PATIENT_PESEL',

    PATIENT_GET_INSURANCE_INFO: 'PATIENT_GET_INSURANCE_INFO',
    PATIENT_SEND_INSURANCE_INFO: 'PATIENT_SEND_INSURANCE_INFO',
    PATIENT_GET_CHECKLIST: 'PATIENT_GET_CHECKLIST',
    PATIENT_UPDATE_THERAPEUTIC_PROGRAM: 'PATIENT_UPDATE_THERAPEUTIC_PROGRAM',
    PATIENT_GET_PREGNANCY_INFO: 'PATIENT_GET_PREGNANCY_INFO',
    PATIENT_SEND_PREGNANCY_INFO: 'PATIENT_SEND_PREGNANCY_INFO',

    PATIENT_GET_IDS_INFO: 'PATIENT_GET_IDS_INFO',
    PATIENT_SEND_IDS_INFO: 'PATIENT_SEND_IDS_INFO',


    PATIENT_SEND_COMPLIENCE: 'PATIENT_SEND_COMPLIENCE',
    PATIENT_SEND_EXPERIENCE: 'PATIENT_SEND_EXPERIENCE',
    PATIENT_SEND_DISABILITY_STATUS: 'PATIENT_SEND_DISABILITY_STATUS',
    PATIENT_SEND_HEARING_STATUS: 'PATIENT_SEND_HEARING_STATUS',
    PATIENT_SEND_HOMELESS_STATUS: 'PATIENT_SEND_HOMELESS_STATUS',
    PATIENT_SEND_QUARANTINE_STATUS: 'PATIENT_SEND_QUARANTINE_STATUS',
    PATIENT_SEND_DEBT_STATUS: 'PATIENT_SEND_DEBT_STATUS',

    PATIENT_GET_DECLARATIONS_BY_PATIENT_ID: 'PATIENT_GET_DECLARATIONS_BY_PATIENT_ID',

    PATIENT_GET_ALL_MONGO_INCIDENTS: 'PATIENT_GET_ALL_MONGO_INCIDENTS',
    PATIENT_GET_MONGO_INCIDENT_BY_ID: 'PATIENT_GET_MONGO_INCIDENT_BY_ID',
    PATIENT_GET_MONGO_INCIDENTS_BY_PATIENT_ID: 'PATIENT_GET_MONGO_INCIDENTS_BY_PATIENT_ID',
    PATIENT_CREATE_MONGO_INCIDENT: 'PATIENT_CREATE_MONGO_INCIDENT',
    PATIENT_UPDATE_MONGO_INCIDENT_BY_ID: 'PATIENT_UPDATE_MONGO_INCIDENT_BY_ID',

    PATIENT_POST_STATUSES: 'PATIENT_POST_STATUSES',
    PATIENT_GET_STATUSES: 'PATIENT_GET_STATUSES',
    PATIENT_GET_MANY_STATUSES: 'PATIENT_GET_MANY_STATUSES',

    PATIENT_GET_NOTES: 'PATIENT_GET_NOTES',
    PATIENT_POST_NOTES: 'PATIENT_POST_NOTES',

    PATIENT_GET_FAMILY_INFOS: 'PATIENT_GET_FAMILY_INFOS',

    PATIENT_GET_INSURANCES: 'PATIENT_GET_INSURANCES',

    PATIENT_UPDATE_STATS: 'PATIENT_UPDATE_STATS',

    // PATIENT_GET_DECLARATIONS: 'PATIENT_GET_DECLARATIONS',
    // PATIENT_POST_DECLARATIONS: 'PATIENT_POST_DECLARATIONS',

    PATIENT_ADD_FILE_INFO: 'PATIENT_ADD_FILE_INFO',
    PATIENT_GET_PATIENT_FILES: 'PATIENT_GET_PATIENT_FILES',
    PATIENT_GET_PATIENT_FILES_PROGRAM: 'PATIENT_GET_PATIENT_FILES_PROGRAM',
    PATIENT_REMOVE_FILE: 'PATIENT_REMOVE_FILE',
    PATIENT_RESTORE_FILE: 'PATIENT_RESTORE_FILE',
    PATIENT_GET_FILES_USED_IN_APPOINTMENT: 'PATIENT_GET_FILES_USED_IN_APPOINTMENT',

    PATIENT_GET_FILES: 'PATIENT_GET_FILES',
    PATIENT_DOWNLOAD_FILE: 'PATIENT_DOWNLOAD_FILE',
    PATIENT_REMOVE_FILE_UPLOAD: 'PATIENT_REMOVE_FILE_UPLOAD',
    PATIENT_RESTORE_FILE_UPLOAD: 'PATIENT_RESTORE_FILE_UPLOAD',
    PATIENT_CHECK_USE_IN_APPOINTMENT_FILE_UPLOAD: 'PATIENT_CHECK_USE_IN_APPOINTMENT_FILE_UPLOAD',
    PATIENT_UNCHECK_USE_IN_APPOINTMENT_FILE_UPLOAD: 'PATIENT_UNCHECK_USE_IN_APPOINTMENT_FILE_UPLOAD',

    PATIENT_COUNT_VISITS: ' PATIENT_COUNT_VISITS',

    PATIENT_GET_MED_GROUPS: 'PATIENT_GET_MED_GROUPS',
    PATIENT_POST_MED_GROUPS: 'PATIENT_POST_MED_GROUPS',

    PATIENT_GET_NURSE_PRESCRIPTIONS: 'PATIENT_GET_NURSE_PRESCRIPTIONS',
    PATIENT_POST_NURSE_PRESCRIPTIONS: 'PATIENT_POST_NURSE_PRESCRIPTIONS',

    PATIENT_GET_CYTO_QUESTIONS: 'PATIENT_GET_CYTO_QUESTIONS',

    PATIENT_GET_END_APPOINTMENT_BY_EXTERNAL_SCHEDULE_ID: 'PATIENT_GET_END_APPOINTMENT_BY_EXTERNAL_SCHEDULE_ID',
    PATIENT_GET_END_APPOINTMENT: 'PATIENT_GET_END_APPOINTMENT',
    PATIENT_GET_END_APPOINTMENT_EDM: 'PATIENT_GET_END_APPOINTMENT_EDM',
    PATIENT_GET_END_APPOINTMENT_HISTORY_DIAGNOSES: 'PATIENT_GET_END_APPOINTMENT_HISTORY_DIAGNOSES',

    PATIENT_POST_ONGOING_APPOINTMENT: 'PATIENT_POST_ONGOING_APPOINTMENT',
    PATIENT_GET_ONGOING_APPOINTMENT_BY_EXTERNAL_ID: 'PATIENT_GET_ONGOING_APPOINTMENT_BY_EXTERNAL_ID',
    PATIENT_UPDATE_ONGOING_APPOINTMENT: 'PATIENT_UPDATE_ONGOING_APPOINTMENT',
    PATIENT_DELETE_ONGOING_APPOINTMENT_BY_EXTERNAL_ID: 'PATIENT_DELETE_ONGOING_APPOINTMENT_BY_EXTERNAL_ID',
    PATIENT_GET_ONGOING_APPOINTMENT: 'PATIENT_GET_ONGOING_APPOINTMENT',
    PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_APPOINTMENT: 'PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_APPOINTMENT',
    PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_CONTROL: 'PATIENT_DELETE_ONGOING_APPOINTMENT_FOR_CONTROL',
    ONGOING_FOR_APPOINTMENT: 'ONGOING_FOR_APPOINTMENT',

    PATIENT_GET_LOG_APPOINTMENT_BY_ID: 'PATIENT_GET_LOG_APPOINTMENT_BY_ID',
    PATIENT_UPDATE_LOG_APPOINTMENT: 'PATIENT_UPDATE_LOG_APPOINTMENT',
    PATIENT_CREATE_LOG_APPOINTMENT: 'PATIENT_CREATE_LOG_APPOINTMENT',
    PATIENT_DELETE_LOG_APPOINTMENT: 'PATIENT_DELETE_LOG_APPOINTMENT',
    PATIENT_GET_LOG_APPOINTMENT_BY_APPOINTMENT: 'PATIENT_GET_LOG_APPOINTMENT_BY_APPOINTMENT',
    PATIENT_GET_LOG_APPOINTMENT_BY_PATIENT: 'PATIENT_GET_LOG_APPOINTMENT_BY_PATIENT',
    PATIENT_GET_LOG_APPOINTMENT_BY_EMPLOYEE: 'PATIENT_GET_LOG_APPOINTMENT_BY_EMPLOYEE',

    PATIENT_GET_CODES: 'PATIENT_GET_CODES',
    PATIENT_IPOM_BUNDLE: 'PATIENT_IPOM_BUNDLE',

    PATIENT_REPORT_GET_ALL: 'PATIENT_REPORT_GET_ALL',
    PATIENT_REPORT_GET: 'PATIENT_REPORT_GET',
    PATIENT_REPORT_CREATE: 'PATIENT_REPORT_CREATE',
    PATIENT_REPORT_UPDATE: 'PATIENT_REPORT_UPDATE',
    PATIENT_REPORT_DELETE: 'PATIENT_REPORT_DELETE',
    PATIENT_REPORT_GET_BY_PATIENT_ID: 'PATIENT_REPORT_GET_BY_PATIENT_ID',
    PATIENT_REPORT_GET_BY_APPOINTMENT_ID: 'PATIENT_REPORT_GET_BY_APPOINTMENT_ID',
    PATIENT_REPORT_REMOVE: 'PATIENT_REPORT_REMOVE',
    PATIENT_REPORT_RESTORE: 'PATIENT_REPORT_RESTORE',


    RIGET_HISTORY: 'RIGET_HISTORY',


    DEKLE_GET_ALL: "DEKLE_GET_ALL",
    DEKLE_PROGRESS: "DEKLE_PROGRESS",
    DEKLE_CREATE: "DEKLE_CREATE",
    DEKLE_DOWNLOAD: "DEKLE_DOWNLOAD",
    DEKLE_RESPONSE_UPLOAD: "DEKLE_RESPONSE_UPLOAD",
    DEKLE_GET_BY_ID: "DEKLE_GET_BY_ID",






    // NEW FOR PATIENT MODULE
    PATIENT_GET_APPOINTMENT_HISTORY: 'PATIENT_GET_APPOINTMENT_HISTORY',

    PATIENT_GET_TASKS: 'PATIENT_GET_TASKS',
    PATIENT_ADD_TASK: 'PATIENT_ADD_TASK',
    PATIENT_PUT_TASK: 'PATIENT_PUT_TASK',
    PATIENT_ADD_TASK_STATUS: 'PATIENT_ADD_TASK_STATUS',
    PATIENT_DELETE_TASK: 'PATIENT_DELETE_TASK',

    PATIENT_GET_DECLARATIONS_V2_STATS: 'PATIENT_GET_DECLARATIONS_V2_STATS',
    PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_ID: 'PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_ID',
    PATIENT_GET_DECLARATION_V2_BY_ID: 'PATIENT_GET_DECLARATION_V2_BY_ID',
    PATIENT_PUT_DECLARATION_V2: 'PATIENT_PUT_DECLARATION_V2',
    PATIENT_DELETE_DECLARATION_V2: 'PATIENT_DELETE_DECLARATION_V2',
    PATIENT_POST_DECLARATION_V2: 'PATIENT_POST_DECLARATION_V2',
    PATIENT_FILTER_DECLARATIONS_V2: 'PATIENT_FILTER_DECLARATIONS_V2',
    PATIENT_FILTER_DECLARATIONS_V2_WITH_ERRORS: 'PATIENT_FILTER_DECLARATIONS_V2_WITH_ERRORS',
    PATIENT_CHANGES_DECLARATIONS_V2_BY_PATIENT_ID: 'PATIENT_CHANGES_DECLARATIONS_V2_BY_PATIENT_ID',
    PATIENT_CHANGES_DECLARATIONS_V2_BY_ID: 'PATIENT_CHANGES_DECLARATIONS_V2_BY_ID',
    PATIENT_DECLARATIONS_V2_MULTI_CANCEL: 'PATIENT_DECLARATIONS_V2_MULTI_CANCEL',
    PATIENT_DECLARATIONS_V2_ADD_FILE: 'PATIENT_DECLARATIONS_V2_ADD_FILE',
    PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_PESEL: 'PATIENT_GET_DECLARATIONS_V2_BY_PATIENT_PESEL',

    PATIENT_PROGRAM_GET_BY_ID: 'PATIENT_PROGRAM_GET_BY_ID',
    PATIENT_PROGRAM_ADD: 'PATIENT_PROGRAM_ADD',
    PATIENT_PROGRAM_PUT: 'PATIENT_PROGRAM_PUT',
    PATIENT_PROGRAM_DELETE: 'PATIENT_PROGRAM_DELETE',
    PATIENT_PROGRAM_GET_PROGRAM_WITH_PATIENTS: 'PATIENT_PROGRAM_GET_PROGRAM_WITH_PATIENTS',
    PATIENT_PROGRAM_GET_MANY_PROGRAMS_WITH_PATIENTS: 'PATIENT_PROGRAM_GET_MANY_PROGRAMS_WITH_PATIENTS',
    PATIENT_PROGRAM_GET_FOR_PATIENT: 'PATIENT_PROGRAM_GET_FOR_PATIENT',
    PATIENT_PROGRAM_GET_FOR_MANY_PATIENTS: 'PATIENT_PROGRAM_GET_FOR_MANY_PATIENTS',
    PATIENT_PROGRAM_ADD_EVENT: 'PATIENT_PROGRAM_ADD_EVENT',
    PATIENT_PROGRAM_EDIT_EVENT: 'PATIENT_PROGRAM_EDIT_EVENT',
    PATIENT_PROGRAM_GET_WITH_CYCLES: 'PATIENT_PROGRAM_GET_WITH_CYCLES',
    PATIENT_PROGRAM_FILTER: 'PATIENT_PROGRAM_FILTER',
    PATIENT_PROGRAM_STATS_BY_PROGRAM: 'PATIENT_PROGRAM_STATS_BY_PROGRAM',

    PATIENT_GET_RANDOM_BY_AGE: 'PATIENT_GET_RANDOM_BY_AGE',

    PATIENT_ALERT_GET_BY_ID: 'PATIENT_ALERT_GET_BY_ID',
    PATIENT_ALERT_ADD: 'PATIENT_ALERT_ADD',
    PATIENT_ALERT_PUT: 'PATIENT_ALERT_PUT',
    PATIENT_ALERT_DELETE: 'PATIENT_ALERT_DELETE',
    PATIENT_ALERT_ADD_STATUS: 'PATIENT_ALERT_ADD_STATUS',
    PATIENT_ALERT_GET_FOR_PATIENT: 'PATIENT_ALERT_GET_FOR_PATIENT',
    PATIENT_ALERT_GET_BY_FILTER: 'PATIENT_ALERT_GET_BY_FILTER',

    PATIENT_CREATE_MED_QUESTIONS_FROM_TEMPLATES: 'PATIENT_CREATE_MED_QUESTIONS_FROM_TEMPLATES',

    // WORKER MODULE
    WORKER_GET_ALL_WORKERS: 'WORKER_GET_ALL_WORKERS',
    WORKER_GET_WORKER: 'WORKER_GET_WORKER',
    WORKER_ADD_WORKER: 'WORKER_ADD_WORKER',
    WORKER_GET_MY_DATA: 'WORKER_GET_MY_DATA',
    WORKER_DELETE_WORKER: 'WORKER_DELETE_WORKER',
    WORKER_MODIFY_WORKER: 'WORKER_MODIFY_WORKER',
    WORKER_GET_ALL_DOCTORS: 'WORKER_GET_ALL_DOCTORS',

    EMPLOYEE_UNIREPORT_PROFILE_POST: 'EMPLOYEE_UNIREPORT_PROFILE_POST',
    EMPLOYEE_UNIREPORT_PROFILE_DELETE_BY_ID: 'EMPLOYEE_UNIREPORT_PROFILE_DELETE_BY_ID',
    EMPLOYEE_UNIREPORT_PROFILE_BY_EMPLOYEE: 'EMPLOYEE_UNIREPORT_PROFILE_BY_EMPLOYEE',

    WORKER_GET_ALL_MEDICAL_TEAMS: 'WORKER_GET_ALL_MEDICAL_TEAMS',
    WORKER_ADD_MEDICAL_TEAM: 'WORKER_ADD_MEDICAL_TEAM',

    OFFICE_GET_ALL: 'OFFICE_GET_ALL',
    OFFICE_GET_ALL_DOMAINLESS: 'OFFICE_GET_ALL_DOMAINLESS',
    OFFICE_GET_BY_DOMAIN: 'OFFICE_GET_BY_DOMAIN',
    OFFICE_GET_OFFICE: 'OFFICE_GET_OFFICE',
    OFFICE_ADD_OFFICE: 'OFFICE_ADD_OFFICE',
    OFFICE_DELETE_OFFICE: 'OFFICE_DELETE_OFFICE',
    OFFICE_MODIFY_OFFICE: 'OFFICE_MODIFY_OFFICE',

    OFFICEROOM_GET_ALL: 'OFFICEROOM_GET_ALL',
    OFFICEROOM_GET_OFFICE: 'OFFICEROOM_GET_OFFICE',
    OFFICE_GET_BY_WORKER: 'OFFICE_GET_BY_WORKER',
    OFFICEROOM_ADD_OFFICE: 'OFFICEROOM_ADD_OFFICE',
    OFFICEROOM_DELETE_OFFICE: 'OFFICEROOM_DELETE_OFFICE',
    OFFICEROOM_MODIFY_OFFICE: 'OFFICEROOM_MODIFY_OFFICE',

    GDPR_PUT: 'GDPR_PUT',

    EMPLOYEE_SETTINGS_MODIFY: 'EMPLOYEE_SETTINGS_MODIFY',
    MY_SETTINGS: 'MY_SETTINGS',

    SLOT_GET_ALL: 'SLOT_GET_ALL',
    SLOT_ADD: 'SLOT_ADD',
    SLOT_GET_BY_ID: 'SLOT_GET_BY_ID',
    SLOT_UPDATE: 'SLOT_UPDATE',
    SLOT_DELETE: 'SLOT_DELETE',
    SLOT_GET_BY_SUBUNIT: 'SLOT_GET_BY_SUBUNIT',
    SLOT_GET_BY_EMPLOYEE: 'SLOT_GET_BY_EMPLOYEE',
    SLOT_UPDATE_HR: 'SLOT_UPDATE_HR',

    // CREW MODULE
    EMPLOYEE_GET_ALL: 'EMPLOYEE_GET_ALL',
    EMPLOYEE_GET: 'EMPLOYEE_GET',
    EMPLOYEE_CREATE: 'EMPLOYEE_CREATE',
    EMPLOYEE_GET_ME: 'EMPLOYEE_GET_ME',
    EMPLOYEE_DELETE: 'EMPLOYEE_DELETE',
    EMPLOYEE_PUT: 'EMPLOYEE_PUT',
    EMPLOYEE_PUT_EMAIL: 'EMPLOYEE_PUT_EMAIL',
    EMPLOYEE_CHANGE_MY_DOMAIN: 'EMPLOYEE_CHANGE_MY_DOMAIN',
    EMPLOYEE_JOBS: 'EMPLOYEE_JOBS',
    EMPLOYEE_GET_BY_POSITION: 'EMPLOYEE_GET_BY_POSITION',
    EMPLOYEE_GET_BY_MANY_POSITIONS: 'EMPLOYEE_GET_BY_MANY_POSITIONS',

    EMPLOYEE_GET_CHANGES: 'EMPLOYEE_GET_CHANGES',
    EMPLOYEE_GET_CHANGE_DETAILS: 'EMPLOYEE_GET_CHANGE_DETAILS',

    // EMPLOYEE PERMISSION MODULE
    EMPLOYEE_PERMISSION_GET_ALL: 'EMPLOYEE_PERMISSION_GET_ALL',
    EMPLOYEE_PERMISSION_GET: 'EMPLOYEE_PERMISSION_GET',
    EMPLOYEE_PERMISSION_ADD: 'EMPLOYEE_PERMISSION_ADD',
    EMPLOYEE_PERMISSION_PUT: 'EMPLOYEE_PERMISSION_PUT',
    EMPLOYEE_PERMISSION_DELETE: 'EMPLOYEE_PERMISSION_DELETE',

    EMPLOYEE_PERMISSION_TYPE_GET_ALL: 'EMPLOYEE_PERMISSION_TYPE_GET_ALL',
    EMPLOYEE_PERMISSION_TYPE_GET: 'EMPLOYEE_PERMISSION_TYPE_GET',
    EMPLOYEE_PERMISSION_TYPE_ADD: 'EMPLOYEE_PERMISSION_TYPE_ADD',
    EMPLOYEE_PERMISSION_TYPE_PUT: 'EMPLOYEE_PERMISSION_TYPE_PUT',
    EMPLOYEE_PERMISSION_TYPE_DELETE: 'EMPLOYEE_PERMISSION_TYPE_DELETE',

    // EMPLOYEE POSITION MODULE
    EMPLOYEE_POSITION_GET_ALL: 'EMPLOYEE_POSITION_GET_ALL',
    EMPLOYEE_POSITION_GET: 'EMPLOYEE_POSITION_GET',
    EMPLOYEE_POSITION_ADD: 'EMPLOYEE_POSITION_ADD',
    EMPLOYEE_POSITION_PUT: 'EMPLOYEE_POSITION_PUT',
    EMPLOYEE_POSITION_DELETE: 'EMPLOYEE_POSITION_DELETE',
    EMPLOYEE_POSITION_GET_ALL_BY_EMPLOYEE: 'EMPLOYEE_POSITION_GET_ALL_BY_EMPLOYEE',
    EMPLOYEE_POSITION_GET_ALL_BY_ME: 'EMPLOYEE_POSITION_GET_ALL_BY_ME',


    EMPLOYEE_POSITION_TYPE_GET_ALL: 'EMPLOYEE_POSITION_TYPE_GET_ALL',
    EMPLOYEE_POSITION_TYPE_GET: 'EMPLOYEE_POSITION_TYPE_GET',
    EMPLOYEE_POSITION_TYPE_ADD: 'EMPLOYEE_POSITION_TYPE_ADD',
    EMPLOYEE_POSITION_TYPE_PUT: 'EMPLOYEE_POSITION_TYPE_PUT',
    EMPLOYEE_POSITION_TYPE_DELETE: 'EMPLOYEE_POSITION_TYPE_DELETE',

    //NEW FOR EMPLOYEE POSITION MODULE
    EMPLOYEE_POSITION_SUBTYPE_GET_ALL: 'EMPLOYEE_POSITION_SUBTYPE_GET_ALL',
    EMPLOYEE_POSITION_SUBTYPE_GET: 'EMPLOYEE_POSITION_SUBTYPE_GET',
    EMPLOYEE_POSITION_SUBTYPE_ADD: 'EMPLOYEE_POSITION_SUBTYPE_ADD',
    EMPLOYEE_POSITION_SUBTYPE_PUT: 'EMPLOYEE_POSITION_SUBTYPE_PUT',
    EMPLOYEE_POSITION_SUBTYPE_DELETE: 'EMPLOYEE_POSITION_SUBTYPE_DELETE',

    //NEW FOR EMPLOYEE MODULE
    EMPLOYEE_CERTIFICATE_UPLOAD: 'EMPLOYEE_CERTIFICATE_UPLOAD',
    EMPLOYEE_CERTIFICATE_GET_ALL: 'EMPLOYEE_CERTIFICATE_GET_ALL',
    EMPLOYEE_CERTIFICATE_GET: 'EMPLOYEE_CERTIFICATE_GET',
    EMPLOYEE_CERTIFICATE_ADD: 'EMPLOYEE_CERTIFICATE_ADD',
    EMPLOYEE_CERTIFICATE_PUT: 'EMPLOYEE_CERTIFICATE_PUT',
    EMPLOYEE_CERTIFICATE_DELETE: 'EMPLOYEE_CERTIFICATE_DELETE',
    EMPLOYEE_CERTIFICATE_GET_BY_WORKER: 'EMPLOYEE_CERTIFICATE_GET_BY_WORKER',
    EMPLOYEE_CERTIFICATE_DOWNLOAD: 'EMPLOYEE_CERTIFICATE_DOWNLOAD',

    //EMPLOYEE FILE
    EMPLOYEE_FILE_UPLOAD: 'EMPLOYEE_FILE_UPLOAD',
    EMPLOYEE_FILE_GET_ALL: 'EMPLOYEE_FILE_GET_ALL',
    EMPLOYEE_FILE_GET: 'EMPLOYEE_FILE_GET',
    EMPLOYEE_FILE_ADD: 'EMPLOYEE_FILE_ADD',
    EMPLOYEE_FILE_PUT: 'EMPLOYEE_FILE_PUT',
    EMPLOYEE_FILE_DELETE: 'EMPLOYEE_FILE_DELETE',
    EMPLOYEE_FILE_GET_BY_WORKER: 'EMPLOYEE_FILE_GET_BY_WORKER',
    EMPLOYEE_FILE_DOWNLOAD: 'EMPLOYEE_FILE_DOWNLOAD',

    //EMPLOYEE CUSTOM DRUGS
    EMPLOYEE_CUSTOM_DRUG_POST: 'PHARMA_CUSTOM_DRUG_POST',
    EMPLOYEE_CUSTOM_DRUG_GET_BY_EMPLOYEE: 'PHARMA_CUSTOM_DRUG_GET_BY_EMPLOYEE',
    EMPLOYEE_CUSTOM_DRUG_PUT: 'EMPLOYEE_CUSTOM_DRUG_PUT',
    EMPLOYEE_CUSTOM_DRUG_DELETE: 'EMPLOYEE_CUSTOM_DRUG_DELETE',

    //EXAMINATION CONFIG
    EMPLOYEE_EXAMINATION_CONFIGURATION_GET_ALL: 'EMPLOYEE_EXAMINATION_CONFIGURATION_GET_ALL',
    EMPLOYEE_EXAMINATION_CONFIGURATION_ADD: 'EMPLOYEE_EXAMINATION_CONFIGURATION_ADD',
    EMPLOYEE_EXAMINATION_CONFIGURATION_GET_BY_ID: 'EMPLOYEE_EXAMINATION_CONFIGURATION_GET_BY_ID',
    EMPLOYEE_EXAMINATION_CONFIGURATION_DELETE_BY_ID: 'EMPLOYEE_EXAMINATION_CONFIGURATION_DELETE_BY_ID',
    EMPLOYEE_EXAMINATION_CONFIGURATION_FILTER: 'EMPLOYEE_EXAMINATION_CONFIGURATION_FILTER',

    //EXAM SET
    EMPLOYEE_EXAMSET_GET_ALL: 'EMPLOYEE_EXAMSET_GET_ALL',
    EMPLOYEE_EXAMSET_ADD: 'EMPLOYEE_EXAMSET_ADD',
    EMPLOYEE_EXAMSET_PUT_BY_ID: 'EMPLOYEE_EXAMSET_PUT_BY_ID',
    EMPLOYEE_EXAMSET_DELETE_BY_ID: 'EMPLOYEE_EXAMSET_DELETE_BY_ID',
    EMPLOYEE_EXAMSET_GET_BY_WORKER_ID: 'EMPLOYEE_EXAMSET_GET_BY_WORKER_ID',

    // SMS CONFIG
    EMPLOYEE_SMS_CONFIG_GET_ALL: 'EMPLOYEE_SMS_CONFIG_GET_ALL',
    EMPLOYEE_SMS_CONFIG_ADD: 'EMPLOYEE_SMS_CONFIG_ADD',
    EMPLOYEE_SMS_CONFIG_GET_BY_ID: 'EMPLOYEE_SMS_CONFIG_GET_BY_ID',
    EMPLOYEE_SMS_CONFIG_PUT: 'EMPLOYEE_SMS_CONFIG_PUT',
    EMPLOYEE_SMS_CONFIG_DELETE: 'EMPLOYEE_SMS_CONFIG_DELETE',

    EMPLOYEE_PROJECT_GOAL_GET_ALL: 'EMPLOYEE_PROJECT_GOAL_GET_ALL',
    EMPLOYEE_PROJECT_GOAL_GET_BY_ID: 'EMPLOYEE_PROJECT_GOAL_GET_BY_ID',
    EMPLOYEE_PROJECT_GOAL_PUT: 'EMPLOYEE_PROJECT_GOAL_PUT',
    EMPLOYEE_PROJECT_GOAL_ADD_TASK_GROUPS: 'EMPLOYEE_PROJECT_GOAL_ADD_TASK_GROUPS',
    EMPLOYEE_PROJECT_GOAL_ADD_TASKS: 'EMPLOYEE_PROJECT_GOAL_ADD_TASKS',
    EMPLOYEE_PROJECT_GOAL_POST: 'EMPLOYEE_PROJECT_GOAL_POST',
    EMPLOYEE_PROJECT_GOAL_DELETE: 'EMPLOYEE_PROJECT_GOAL_DELETE',
    EMPLOYEE_PROJECT_GOAL_GET_BY_FILTER: 'EMPLOYEE_PROJECT_GOAL_GET_BY_FILTER',
    EMPLOYEE_PROJECT_GOAL_GET_FULL_INFO: 'EMPLOYEE_PROJECT_GOAL_GET_FULL_INFO',
    EMPLOYEE_PROJECT_GOAL_REMOVE: 'EMPLOYEE_PROJECT_GOAL_REMOVE',
    EMPLOYEE_PROJECT_GOAL_GET_CHILDREN: 'EMPLOYEE_PROJECT_GOAL_GET_CHILDREN',

    EMPLOYEE_PROGRAMME_GET_ALL: 'EMPLOYEE_PROGRAMME_GET_ALL',
    EMPLOYEE_PROGRAMME_ADD: 'EMPLOYEE_PROGRAMME_ADD',
    EMPLOYEE_PROGRAMME_GET_BY_ID: 'EMPLOYEE_PROGRAMME_GET_BY_ID',
    EMPLOYEE_PROGRAMME_PUT: 'EMPLOYEE_PROGRAMME_PUT',
    EMPLOYEE_PROGRAMME_DELETE: 'EMPLOYEE_PROGRAMME_DELETE',
    EMPLOYEE_PROGRAMME_CREATE_GOAL: 'EMPLOYEE_PROGRAMME_CREATE_GOAL',
    EMPLOYEE_PROGRAMME_DELETE_GOAL: 'EMPLOYEE_PROGRAMME_DELETE_GOAL',

    EMPLOYEE_PROJECT_GET_ALL: 'EMPLOYEE_PROJECT_GET_ALL',
    EMPLOYEE_PROJECT_ADD: 'EMPLOYEE_PROJECT_ADD',
    EMPLOYEE_PROJECT_GET_BY_ID: 'EMPLOYEE_PROJECT_GET_BY_ID',
    EMPLOYEE_PROJECT_PUT: 'EMPLOYEE_PROJECT_PUT',
    EMPLOYEE_PROJECT_DELETE: 'EMPLOYEE_PROJECT_DELETE',
    EMPLOYEE_PROJECT_CREATE_GOAL: 'EMPLOYEE_PROJECT_CREATE_GOAL',
    EMPLOYEE_PROJECT_DELETE_GOAL: 'EMPLOYEE_PROJECT_DELETE_GOAL',

    EMPLOYEE_TEAM_GET_ALL: 'EMPLOYEE_TEAM_GET_ALL',
    EMPLOYEE_TEAM_ADD: 'EMPLOYEE_TEAM_ADD',
    EMPLOYEE_TEAM_GET_BY_WORKER_ID: 'EMPLOYEE_TEAM_GET_BY_WORKER_ID',
    EMPLOYEE_TEAM_GET_BY_ID: 'EMPLOYEE_TEAM_GET_BY_ID',
    EMPLOYEE_TEAM_PUT: 'EMPLOYEE_TEAM_PUT',
    EMPLOYEE_TEAM_DELETE: 'EMPLOYEE_TEAM_DELETE',
    EMPLOYEE_TEAM_UPLOAD_ATTACHMENT: 'EMPLOYEE_TEAM_UPLOAD_ATTACHMENT',
    EMPLOYEE_TEAM_UPLOAD_VERSION_ATTACHMENT: 'EMPLOYEE_TEAM_UPLOAD_VERSION_ATTACHMENT',
    EMPLOYEE_TEAM_CHECK_EXIST_ATTACHMENT: 'EMPLOYEE_TEAM_CHECK_EXIST_ATTACHMENT',
    EMPLOYEE_TEAM_ATTACHMENT_GET_BY_TEAM: 'EMPLOYEE_TEAM_ATTACHMENT_GET_BY_TEAM',
    EMPLOYEE_TEAM_CREATE_GOAL: 'EMPLOYEE_TEAM_CREATE_GOAL',
    EMPLOYEE_TEAM_DELETE_GOAL: 'EMPLOYEE_TEAM_DELETE_GOAL',
    EMPLOYEE_TEAM_GET_BY_INTERNAL_ID: 'EMPLOYEE_TEAM_GET_BY_INTERNAL_ID',

    EMPLOYEE_TASK_INFO_GET_ALL: 'EMPLOYEE_TASK_INFO_GET_ALL',
    EMPLOYEE_TASK_INFO_ADD: 'EMPLOYEE_TASK_INFO_ADD',
    EMPLOYEE_TASK_INFO_GET_BY_ID: 'EMPLOYEE_TASK_INFO_GET_BY_ID',
    EMPLOYEE_TASK_INFO_PUT: 'EMPLOYEE_TASK_INFO_PUT',
    EMPLOYEE_TASK_INFO_DELETE: 'EMPLOYEE_TASK_INFO_DELETE',
    EMPLOYEE_TASK_INFO_GET_BY_TEAM: 'EMPLOYEE_TASK_INFO_GET_BY_TEAM',
    EMPLOYEE_TASK_INFO_GET_BY_PROJECT: 'EMPLOYEE_TASK_INFO_GET_BY_PROJECT',
    EMPLOYEE_TASK_INFO_GET_BY_PROGRAMME: 'EMPLOYEE_TASK_INFO_GET_BY_PROGRAMME',
    EMPLOYEE_TASK_INFO_GROUPS_GET_ALL: 'EMPLOYEE_TASK_INFO_GROUPS_GET_ALL',
    EMPLOYEE_TASK_INFO_GROUP_GET_BY_ID: 'EMPLOYEE_TASK_INFO_GROUP_GET_BY_ID',
    EMPLOYEE_TASK_INFO_GROUP_ADD: 'EMPLOYEE_TASK_INFO_GROUP_ADD',
    EMPLOYEE_TASK_INFO_GROUP_PUT: 'EMPLOYEE_TASK_INFO_GROUP_PUT',
    EMPLOYEE_TASK_INFO_GROUP_DELETE: 'EMPLOYEE_TASK_INFO_GROUP_DELETE',
    EMPLOYEE_TASK_INFO_GET_BY_WORKER_ID: 'EMPLOYEE_TASK_INFO_GET_BY_WORKER_ID',

    EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_ID: 'EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_ID',
    EMPLOYEE_PAPER_NUMBER_GROUP_UPLOAD: 'EMPLOYEE_PAPER_NUMBER_GROUP_UPLOAD',
    EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_STATUS: 'EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_STATUS',
    EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_NUMBER_STATUS: 'EMPLOYEE_PAPER_NUMBER_GROUP_CHANGE_NUMBER_STATUS',
    EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_WORKER: 'EMPLOYEE_PAPER_NUMBER_GROUP_GET_BY_WORKER',
    EMPLOYEE_PAPER_NUMBER_GROUP_GET_PRESCRIPTION_DATA: 'EMPLOYEE_PAPER_NUMBER_GROUP_GET_PRESCRIPTION_DATA',
    EMPLOYEE_PAPER_NUMBER_GROUP_GET_AVAILABLE_FOR_WORKER: 'EMPLOYEE_PAPER_NUMBER_GROUP_GET_AVAILABLE_FOR_WORKER',

    OFFICES_GET_BY_FACILITY_ID: 'OFFICES_GET_BY_FACILITY_ID',

    // IDENTITY MODULE
    IDENTITY_LOGIN: "IDENTITY_LOGIN",
    IDENTITY_LOGOUT: "IDENTITY_LOGOUT",
    IDENTITY_LOGOUT_SOFT: "IDENTITY_LOGOUT_SOFT",
    IDENTITY_REGISTER: "IDENTITY_REGISTER",
    IDENTITY_REGISTER_WORKER: "IDENTITY_REGISTER_WORKER",
    IDENTITY_PASSWORD_RESET: "IDENTITY_PASSWORD_RESET",
    IDENTITY_PASSWORD_RESET_REQUEST: "IDENTITY_PASSWORD_RESET_REQUEST",
    IDENTITY_GET_ROLES: "IDENTITY_GET_ROLES",
    IDENTITY_GET_USERS: "IDENTITY_GET_USERS",
    IDENTITY_GET_DRIVERS: "IDENTITY_GET_DRIVERS",
    IDENTITY_DELETE_USER: "IDENTITY_DELETE_USER",
    IDENTITY_CHANGE_PASSWORD: "IDENTITY_CHANGE_PASSWORD",
    IDENTITY_SESSION_RENEW_TOKEN: "IDENTITY_SESSION_RENEW_TOKEN",
    IDENTITY_SESSION_UPDATE: "IDENTITY_SESSION_UPDATE",
    IDENTITY_DEACTIVATE_USER: 'IDENTITY_DEACTIVATE_USER',
    IDENTITY_ACTIVATE_USER: 'IDENTITY_ACTIVATE_USER',
    IDENTITY_MODIFY_USER: 'IDENTITY_MODIFY_USER',
    IDENTITY_MODIFY_EMAIL: 'IDENTITY_MODIFY_EMAIL',
    IDENTITY_LOGOUT_USER: 'IDENTITY_LOGOUT_USER',
    IDENTITY_REMOVE_EMAIL_BLOCK: 'IDENTITY_REMOVE_EMAIL_BLOCK',
    IDENTITY_VERIFY: 'IDENTITY_VERIFY',

    IDENTITY_UAC_CHECK: 'IDENTITY_UAC_CHECK',
    IDENTITY_UACS: 'IDENTITY_UACS',
    IDENTITY_FORCE: 'IDENTITY_FORCE',

    IDENTITY_GET_CODES: 'IDENTITY_GET_CODES',

    IDENTITY_TELEPHONE_VERIFICATION_TOKEN: 'IDENTITY_TELEPHONE_VERIFICATION_TOKEN',
    IDENTITY_TELEPHONE_TOKEN_VALIDATION: 'IDENTITY_TELEPHONE_TOKEN_VALIDATION',

    UAC_CREATE_CONNECTION: 'UAC_CREATE_CONNECTION',
    UAC_ACTIVITY_SEND: 'UAC_ACTIVITY_SEND',
    UAC_LOGOUT_CONNECTION: 'UAC_LOGOUT_CONNECTION',



    QUICKTOKEN_REQUEST: 'QUICKTOKEN_REQUEST',
    QUICKTOKEN_VALIDATE: 'QUICKTOKEN_VALIDATE',

    PLC_ALL: 'PLC_ALL',
    PLC_CHECK: 'PLC_CHECK',
    PLC_ME: 'PLC_ME',
    PLC_LOCK: 'PLC_LOCK',
    PLC_UNLOCK: 'PLC_UNLOCK',

    // SMS
    SMS_SEND: "SMS_SEND",
    SMS_FILTER: "SMS_FILTER",
    SMS_ARCHIVE_PATIENT: "SMS_ARCHIVE_PATIENT",

    // TALK MODULE
    TALK_CREATE_CHAT_CONNECTION: "TALK_CREATE_CHAT_CONNECTION",
    TALK_GET_MY_GROUPS: "TALK_GET_MY_GROUPS",
    TALK_SEND_MESSAGE: "TALK_SEND_MESSAGE",
    TALK_MARK_READ: "TALK_MARK_READ",
    TALK_CRETE_GROUP: "TALK_CRETE_GROUP",
    TALK_ADD_EMPLOYEE_TO_GROUP: "TALK_ADD_EMPLOYEE_TO_GROUP",
    TALK_REMOVE_EMPLOYEE_FROM_GROUP: "TALK_REMOVE_EMPLOYEE_FROM_GROUP",
    TALK_GET_MESSAGES: "TALK_GET_MESSAGES",


    STATLOGS_CREATE_CHAT_CONNECTION: "STATLOGS_CREATE_CHAT_CONNECTION",
    STATLOGS_GET_MY_GROUPS: "STATLOGS_GET_MY_GROUPS",
    STATLOGS_SEND_LOG: "STATLOGS_SEND_LOG",
    STATLOGS_MARK_READ: "STATLOGS_MARK_READ",
    STATLOGS_CRETE_GROUP: "STATLOGS_CRETE_GROUP",
    STATLOGS_ADD_EMPLOYEE_TO_GROUP: "STATLOGS_ADD_EMPLOYEE_TO_GROUP",
    STATLOGS_REMOVE_EMPLOYEE_FROM_GROUP: "STATLOGS_REMOVE_EMPLOYEE_FROM_GROUP",
    STATLOGS_GET_MESSAGES: "STATLOGS_GET_MESSAGES",

    // TIME MODULE
    TIME_START: "TIME_START",
    SIGNATURE_TEST: "SIGNATURE_TEST",

    // INVENTORY MODULE
    INVENTORY_GET_ROOM: "INVENTORY_GET_ROOM",
    INVENTORY_GET_ROOMS: "INVENTORY_GET_ROOMS",
    INVENTORY_GET_ALL_ROOMS: "INVENTORY_GET_ALL_ROOMS",
    INVENTORY_SEND_ROOM: "INVENTORY_SEND_ROOM",
    INVENTORY_UPDATE_ROOM: "INVENTORY_UPDATE_ROOM",
    INVENTORY_DELETE_ROOM: "INVENTORY_DELETE_ROOM",
    INVENTORY_GET_ITEMS: "INVENTORY_GET_ITEMS",
    INVENTORY_GET_MULTIPLE: "INVENTORY_GET_MULTIPLE",
    INVENTORY_DELETE_ITEM: "INVENTORY_DELETE_ITEM",
    INVENTORY_SEND_ITEM: "INVENTORY_SEND_ITEM",
    INVENTORY_UPDATE_ITEM: "INVENTORY_UPDATE_ITEM",
    INVENTORY_GET_FULL_FACILITY_INFO: "INVENTORY_GET_FULL_FACILITY_INFO",

    INVENTORY_GET_ITEM_BY_BARCODE: "INVENTORY_GET_ITEM_BY_BARCODE",
    INVENTORY_GET_ITEM_BY_ID: "INVENTORY_GET_ITEM_BY_ID",

    INVENTORY_RELEASE_BARCODE: "INVENTORY_RELEASE_BARCODE",

    INVENTORY_SEARCH_BARCODE: "INVENTORY_SEARCH_BARCODE",
    INVENTORY_SEARCH_SERIAL: "INVENTORY_SEARCH_SERIAL",

    INVENTORY_WORKPLACE_GET_ALL: 'INVENTORY_WORKPLACE_GET_ALL',
    INVENTORY_WORKPLACE_GET: 'INVENTORY_WORKPLACE_GET',
    INVENTORY_WORKPLACE_GET_FULL_INFO: 'INVENTORY_WORKPLACE_GET_FULL_INFO',
    INVENTORY_WORKPLACE_GET_BY_ROOM: 'INVENTORY_WORKPLACE_GET_BY_ROOM',
    INVENTORY_WORKPLACE_GET_BY_CATEGORY: 'INVENTORY_WORKPLACE_GET_BY_CATEGORY',
    INVENTORY_WORKPLACE_CREATE: 'INVENTORY_WORKPLACE_CREATE',
    INVENTORY_WORKPLACE_UPDATE: 'INVENTORY_WORKPLACE_UPDATE',
    INVENTORY_WORKPLACE_DELETE: 'INVENTORY_WORKPLACE_DELETE',

    //EZWM
    EZWM_SEND: 'EZWM_SEND',
    EZWM_GET: 'EZWM_GET',
    EZWM_DOWNLOAD_RESULT: 'EZWM_DOWNLOAD_RESULT',
    EZWM_WORKERCREDS_CREATE: 'EZWM_WORKERCREDS_CREATE',
    EZWM_WORKERCREDS_UPDATE: 'EZWM_WORKERCREDS_UPDATE',
    EZWM_WORKERCREDS_GET: 'EZWM_WORKERCREDS_GET',
    EZWM_WORKERCREDS_ADD_CONFIGURATION: 'EZWM_WORKERCREDS_ADD_CONFIGURATION',
    EZWM_WORKERCREDS_UPDATE_CONFIGURATION: 'EZWM_WORKERCREDS_UPDATE_CONFIGURATION',
    EZWM_WORKERCREDS_SETACTIVE_CONFIGURATION: 'EZWM_WORKERCREDS_SETACTIVE_CONFIGURATION',
    EZWM_WORKERCREDS_GET_BY_WORKER: 'EZWM_WORKERCREDS_GET_BY_WORKER',
    EZWM_WORKERCREDS_UPDATE_PASSWORD: 'EZWM_WORKERCREDS_UPDATE_PASSWORD',
    EZWM_CHECK_PASSWORD: 'EZWM_CHECK_PASSWORD',
    EZWM_DOWNLOAD_ORDER_PDF: 'EZWM_DOWNLOAD_ORDER_PDF',
    EZWM_GET_INITIAL_DATA: 'EZWM_GET_INITIAL_DATA',
    EZWM_WORKERCREDS_ADD_CERT: 'EZWM_WORKERCREDS_ADD_CERT',

    //DILO
    DILO_SEND: 'DILO_SEND',
    DILO_GET: 'DILO_GET',
    DILO_DOWNLOAD_RESULT: 'DILO_DOWNLOAD_RESULT',
    DILO_WORKERCREDS_CREATE: 'DILO_WORKERCREDS_CREATE',
    DILO_WORKERCREDS_UPDATE: 'DILO_WORKERCREDS_UPDATE',
    DILO_WORKERCREDS_GET: 'DILO_WORKERCREDS_GET',
    DILO_WORKERCREDS_ADD_CONFIGURATION: 'DILO_WORKERCREDS_ADD_CONFIGURATION',
    DILO_WORKERCREDS_UPDATE_CONFIGURATION: 'DILO_WORKERCREDS_UPDATE_CONFIGURATION',
    DILO_WORKERCREDS_SETACTIVE_CONFIGURATION: 'DILO_WORKERCREDS_SETACTIVE_CONFIGURATION',
    DILO_WORKERCREDS_GET_BY_WORKER: 'DILO_WORKERCREDS_GET_BY_WORKER',
    DILO_WORKERCREDS_UPDATE_PASSWORD: 'DILO_WORKERCREDS_UPDATE_PASSWORD',


    // TEST MODULE
    TEST_PATIENT_SEARCH: "TEST_PATIENT_SEARCH",
    TEST_EWUS_SEARCH: "TEST_EWUS_SEARCH",

    TEST_CREATE_PRESCRIPTION: "TEST_CREATE_PRESCRIPTION",
    TEST_GET_COMPLETED_PRESCRIPTION: "TEST_GET_COMPLETED_PRESCRIPTION",
    TEST_GET_PRESCRIPTIONS_BY_WORKER: "TEST_GET_PRESCRIPTIONS_BY_WORKER",
    TEST_ASK_PRESCRIPTIONS_BY_PATIENT: "TEST_ASK_PRESCRIPTIONS_BY_PATIENT",
    TEST_RESEND_PRESCRIPTION: "TEST_RESEND_PRESCRIPTION",

    TEST_CREATE_REFERRAL: "TEST_CREATE_REFERRAL",
    TEST_GET_COMPLETED_REFERRAL: "TEST_GET_COMPLETED_REFERRAL",
    TEST_GET_REFERRALS_BY_WORKER: "TEST_GET_REFERRALS_BY_WORKER",
    TEST_ASK_REFERRALS_BY_PATIENT: "TEST_ASK_REFERRALS_BY_PATIENT",
    TEST_RESEND_REFERRAL: "TEST_RESEND_REFERRAL",

    TEST_ZUS_ACTION: "TEST_ZUS_ACTION",
    TEST_ZUS_GET_WORK_STATUS_TASKS_BY_GUID: "TEST_ZUS_GET_WORK_STATUS_TASKS_BY_GUID",
    TEST_ZUS_GET_COMPLETED_TASKS_BY_GUID: "TEST_ZUS_GET_COMPLETED_TASKS_BY_GUID",

    // TICKETS
    TICKET_CREATE: "TICKET_CREATE",
    TICKET_GET: "TICKET_GET",
    TICKET_PUT: "TICKET_PUT",
    TICKET_DELETE: "TICKET_DELETE",
    TICKET_SET_STATUS: "TICKET_SET_STATUS",
    TICKET_GET_ACTIVE_FOR_PATIENT: "TICKET_GET_ACTIVE_FOR_PATIENT",
    TICKET_GET_ALL_FOR_PATIENT: "TICKET_GET_ALL_FOR_PATIENT",
    TICKET_TRANSLATE: "TICKET_TRANSLATE",
    STATS_GET_TICKETS_MULTIPLE: "STATS_GET_TICKETS_MULTIPLE",
    STATS_TICKET_FILTER: 'STATS_TICKET_FILTER',
    STATS_TICKET_GET_BY_PROGRAM_FOR_PATIENT: 'STATS_TICKET_GET_BY_PROGRAM_FOR_PATIENT',
    STATS_TICKET_GET_BY_PATIENT_PROGRAM: 'STATS_TICKET_GET_BY_PATIENT_PROGRAM',
    STATS_TICKET_GET_BY_PATIENT_PROGRAM_FOR_PROFILE: 'STATS_TICKET_GET_BY_PATIENT_PROGRAM_FOR_PROFILE',
    TICKET_CREATE_WITH_EXTENSION: 'TICKET_CREATE_WITH_EXTENSION',
    TICKET_GET_WITH_EXTENSION: 'TICKET_GET_WITH_EXTENSION',
    TICKET_GET_BY_REFERRAL: 'TICKET_GET_BY_REFERRAL',
    TICKET_EXTENSION_CREATE: 'TICKET_EXTENSION_CREATE',
    TICKET_EXTENSION_GET: 'TICKET_EXTENSION_GET',
    TICKET_EXTENSION_PUT: 'TICKET_EXTENSION_PUT',
    TICKET_EXTENSION_DELETE: 'TICKET_EXTENSION_DELETE',
    TICKET_SET_CONSULTATION: 'TICKET_SET_CONSULTATION',
    TICKET_GET_VERSION: 'TICKET_GET_VERSION',

    TICKET_PASS_CREATE: 'TICKET_PASS_CREATE',
    TICKET_PASS_GET: 'TICKET_PASS_GET',
    TICKET_PASS_PUT: 'TICKET_PASS_PUT',
    TICKET_PASS_DELETE: 'TICKET_PASS_DELETE',
    TICKET_PASS_GET_BY_PATIENT: 'TICKET_PASS_GET_BY_PATIENT',
    TICKET_PASS_GET_FULL: 'TICKET_PASS_GET_FULL',
    TICKET_PASS_GET_FULL_BY_PATIENT: 'TICKET_PASS_GET_FULL_BY_PATIENT',

    // CRM
    CRM_GET_ALL_REPORTS: 'CRM_GET_ALL_REPORTS',
    CRM_GET_REPORT_BY_ID: 'CRM_GET_REPORT_BY_ID',
    CRM_MODIFY_REPORT: 'CRM_MODIFY_REPORT',
    CRM_ADD_REPORT: 'CRM_ADD_REPORT',
    CRM_REMOVE_REPORT: 'CRM_REMOVE_REPORT',
    CRM_GET_FULL_REPORT_BY_ID: 'CRM_GET_FULL_REPORT_BY_ID',
    CRM_REPORT_GET_BY_USER_ID: 'CRM_REPORT_GET_BY_USER_ID',
    CRM_REPORT_GET_BY_USER_EMAIL: 'CRM_REPORT_GET_BY_USER_EMAIL',
    CRM_GET_REPORT_TASKS_PERFORMER_CHANGELOG: 'CRM_GET_REPORT_TASKS_PERFORMER_CHANGELOG',
    CRM_PUT_ANOTHER_DIAGNOSIS: 'CRM_PUT_ANOTHER_DIAGNOSIS',
    CRM_REPORT_ADD_FILE: 'CRM_REPORT_ADD_FILE',

    CRM_GET_ALL_TASKS: 'CRM_GET_ALL_TASKS',
    CRM_GET_TASK_BY_ID: 'CRM_GET_TASK_BY_ID',
    CRM_MODIFY_TASK: 'CRM_MODIFY_TASK',
    CRM_ADD_TASK: 'CRM_ADD_TASK',
    CRM_REMOVE_TASK: 'CRM_REMOVE_TASK',

    CRM_GET_ALL_CHECKLISTS: 'CRM_GET_ALL_CHECKLISTS',
    CRM_GET_CHECKLIST_BY_ID: 'CRM_GET_CHECKLIST_BY_ID',
    CRM_MODIFY_CHECKLIST: 'CRM_MODIFY_CHECKLIST',
    CRM_ADD_CHECKLIST: 'CRM_ADD_CHECKLIST',
    CRM_REMOVE_CHECKLIST: 'CRM_REMOVE_CHECKLIST',
    CRM_GET_FULL_CHECKLIST_BY_ID: 'CRM_GET_FULL_CHECKLIST_BY_ID',

    CRM_CRMMEDIA_UPLOAD: 'CRM_CRMMEDIA_UPLOAD',
    CRM_CRMMEDIA_DOWNLOAD: 'CRM_CRMMEDIA_DOWNLOAD',
    CRM_CRMMEDIA_GET_ALL: 'CRM_CRMMEDIA_GET_ALL',
    CRM_CRMMEDIA_CREATE: 'CRM_CRMMEDIA_CREATE',
    CRM_CRMMEDIA_GET_BY_ID: 'CRM_CRMMEDIA_GET_BY_ID',
    CRM_CRMMEDIA_UPDATE: 'CRM_CMRMEDIA_UPDATE',
    CRM_CRMMEDIA_DELETE: 'CRM_CRMMEDIA_DELETE',


    CRM_GET_CONVERSATION: 'CRM_GET_CONVERSATION',
    CRM_CONVERSATION_REPLY: 'CRM_CONVERSATION_REPLY',

    //TPK CONTRACTORS
    CONTRACTORS_GET_ALL: 'CONTRACTORS_GET_ALL',
    CONTRACTORS_GET_BY_ID: 'CONTRACTORS_GET_BY_ID',
    CONTRACTORS_CREATE: 'CONTRACTORS_CREATE',
    CONTRACTORS_UPDATE: 'CONTRACTORS_UPDATE',
    CONTRACTORS_DELETE: 'CONTRACTORS_DELETE_DELETE',
    // more todo

    // TPK MODULE
    TPK_RECEIPT_SEND: "TPK_RECEIPT_SEND",
    TPK_RECEIPT_GET: "TPK_RECEIPT_GET",

    TPK_REFERRAL_SEND: "TPK_REFERRAL_SEND",
    TPK_REFERRAL_GET: "TPK_REFERRAL_GET",

    TPK_EDM_RESULT_SEND: 'TPK_EDM_RESULT_SEND',

    TPK_ARCHIVE_SEARCH: 'TPK_ARCHIVE_SEARCH',

    GENERATE_PDF_FROM_HTML: 'GENERATE_PDF_FROM_HTML',
    GOLD_TOOLS_PDF_FROM_HTML: 'GOLD_TOOLS_PDF_FROM_HTML',

    P1_DICT_GET_BY_ID: 'P1_DICT_GET_BY_ID',

    // TELE MODULE
    TELE_RESULT_GET_ALL: 'TELE_RESULT_GET_ALL',
    TELE_RESULT_GET_BY_ID: 'TELE_RESULT_GET_BY_ID',
    TELE_RESULT_GET_BY_DOMAIN: 'TELE_RESULT_GET_BY_DOMAIN',
    TELE_RESULT_GET_BY_PATIENT_ID: 'TELE_RESULT_GET_BY_PATIENT_ID',

    TELE_RESULT_PUT: 'TELE_RESULT_PUT',
    TELE_RESULT_DELETE: 'TELE_RESULT_DELETE',

    TELE_RESULT_SEND: 'TELE_RESULT_SEND',

    //FINANCE MODULE
    FINANCE_REPORTS_MONTHLY_MEDSETS: 'FINANCE_REPORTS_MONTHLY_MEDSETS',

    NFZ_GRUPER_GET_ALL: 'NFZ_GRUPER_GET_ALL',
    NFZ_GRUPER_UPDATE_GROUP: 'NFZ_GRUPER_UPDATE_GROUP',
    NFZ_GRUPER_UPDATE_LIST: 'NFZ_GRUPER_UPDATE_LIST',


    FINANCE_UNIREPORTS_GENERAL: 'FINANCE_UNIREPORTS_GENERAL',
    FINANCE_UNIREPORTS_DOWNLOAD: 'FINANCE_UNIREPORTS_DOWNLOAD',

    FINANCE_SUBJECTS_GET_ALL: 'FINANCE_SUBJECTS_GET_ALL',
    FINANCE_SUBJECTS_CREATE: 'FINANCE_SUBJECTS_CREATE',
    FINANCE_SUBJECTS_GET_BY_ID: 'FINANCE_SUBJECTS_GET_BY_ID',
    FINANCE_SUBJECTS_UPDATE: 'FINANCE_SUBJECTS_UPDATE',
    FINANCE_SUBJECTS_DELETE: 'FINANCE_SUBJECTS_DELETE',
    FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE: 'FINANCE_SUBJECTS_GET_WITH_MEDSPEC_TYPE',
    FINANCE_SUBJECTS_FILTER: 'FINANCE_SUBJECTS_FILTER',
    FINANCE_SUBJECTS_GET_ALL_IN_MY_DOMAIN: 'FINANCE_SUBJECTS_GET_ALL_IN_MY_DOMAIN',
    FINANCE_SUBJECTS_MAIN_WITH_DOMAINS: 'FINANCE_SUBJECTS_MAIN_WITH_DOMAINS',


    NFZ_INVOICE_GET_ALL: "NFZ_INVOICE_GET_ALL",
    NFZ_INVOICE_FILTER: "NFZ_INVOICE_FILTER",
    NFZ_INVOICE_GET_BY_ID: "NFZ_INVOICE_GET_BY_ID",
    NFZ_INVOICE_PREVIEW_FROM_TEMPLATE: "NFZ_INVOICE_PREVIEW_FROM_TEMPLATE",
    NFZ_INVOICE_ADD_FROM_TEMPLATE: "NFZ_INVOICE_ADD_FROM_TEMPLATE",
    NFZ_INVOICE_ADD_CUSTOM: "NFZ_INVOICE_ADD_CUSTOM",
    NFZ_INVOICE_GENERATE_XML: "NFZ_INVOICE_GENERATE_XML",
    NFZ_INVOICE_ADD_FROM_UMX: "NFZ_INVOICE_ADD_FROM_UMX",

    NFZ_TEMPLATE_GET_ALL: "NFZ_TEMPLATE_GET_ALL",
    NFZ_TEMPLATE_GET_BY_ID: "NFZ_TEMPLATE_GET_BY_ID",
    NFZ_TEMPLATE_UPLOAD: "NFZ_TEMPLATE_UPLOAD",
    NFZ_TEMPLATE_DOWNLOAD: "NFZ_TEMPLATE_DOWNLOAD",



    FINANCE_MED_SERVICE_RECALCULATE_ITEM: 'FINANCE_MED_SERVICE_RECALCULATE_ITEM',
    UMX_GET_ACTIVE_WITH_ID: 'UMX_GET_ACTIVE_WITH_ID',
    UMX_GET_SERVICES_FOR_OFFICE: 'UMX_GET_SERVICES_FOR_OFFICE',
    UMX_GET_ALL: 'UMX_GET_ALL',
    UMX_GET_FOR_DOMAIN: 'UMX_GET_FOR_DOMAIN',
    UMX_UPLOAD: 'UMX_UPLOAD',
    FINANCE_MED_SERVICE_MULTI_INCREASE: 'FINANCE_MED_SERVICE_MULTI_INCREASE',
    UMX_ACTIVE_FOR_SWD: 'UMX_ACTIVE_FOR_SWD',
    UMX_VALIDATE_SERVICES: 'UMX_VALIDATE_SERVICES',
    UMX_GET_BY_ID: 'UMX_GET_BY_ID',
    UMX_GET_SERVICES_FOR_ENDAPP: 'UMX_GET_SERVICES_FOR_ENDAPP',

    ZBPOZ_GET_ALL: 'ZBPOZ_GET_ALL',
    ZBPOZ_CREATE: 'ZBPOZ_CREATE',
    ZBPOZ_MODIFY: 'ZBPOZ_MODIFY',
    ZBPOZ_DOWNLOAD: 'ZBPOZ_DOWNLOAD',

    LIOCZ_GET_ALL: 'LIOCZ_GET_ALL',
    LIOCZ_CREATE: 'LIOCZ_CREATE',
    LIOCZ_MODIFY: 'LIOCZ_MODIFY',
    LIOCZ_DOWNLOAD: 'LIOCZ_DOWNLOAD',

    NFZ_SERVICE_RULES_GET_ALL: 'NFZ_SERVICE_RULES_GET_ALL',
    NFZ_SERVICE_RULES_CHECK_CODE: 'NFZ_SERVICE_RULES_CHECK_CODE',
    NFZ_SERVICE_GET_ALL: 'NFZ_SERVICE_GET_ALL',
    NFZ_SERVICE_EDIT: 'NFZ_SERVICE_EDIT',

    NFZ_DICTIONARY_GET_ALL: 'NFZ_DICTIONARY_GET_ALL',
    NFZ_DICTIONARY_CREATE: 'NFZ_DICTIONARY_CREATE',
    NFZ_DICTIONARY_GET_BY_ID: 'NFZ_DICTIONARY_GET_BY_ID',
    NFZ_DICTIONARY_UPDATE: 'NFZ_DICTIONARY_UPDATE',
    NFZ_DICTIONARY_DELETE: 'NFZ_DICTIONARY_DELETE',

    //FINANCE MODULE SUBJECTS WORKERS NEW!!!!
    FINANCE_SUBJECTS_WORKERS_GET_ALL: 'FINANCE_SUBJECTS_WORKERS_GET_ALL',
    FINANCE_SUBJECTS_WORKERS_GET_BY_ID: 'FINANCE_SUBJECTS_WORKERS_GET_BY_ID',
    FINANCE_SUBJECTS_WORKERS_ADD: 'FINANCE_SUBJECTS_WORKERS_ADD',
    FINANCE_SUBJECTS_WORKERS_UPDATE: 'FINANCE_SUBJECTS_WORKERS_UPDATE',
    FINANCE_SUBJECTS_WORKERS_DELETE: 'FINANCE_SUBJECTS_WORKERS_DELETE',

    FINANCE_SUBJECTS_ADD_WORKER_TO_SUBJECT: 'FINANCE_SUBJECTS_ADD_WORKER_TO_SUBJECT',
    FINANCE_SUBJECTS_REMOVE_WORKER_FROM_SUBJECT: 'FINANCE_SUBJECTS_REMOVE_WORKER_FROM_SUBJECT',

    FINANCE_GET_EXTERNAL_WORKERS: 'FINANCE_GET_EXTERNAL_WORKERS',
    FINANCE_EXTERNAL_EMPLOYEE_ADD: 'FINANCE_EXTERNAL_EMPLOYEE_ADD',
    FINANCE_EXTERNAL_WORKER_GET_BY_ID: 'FINANCE_EXTERNAL_WORKER_GET_BY_ID',
    FINANCE_EXTERNAL_WORKER_UPDATE: 'FINANCE_EXTERNAL_WORKER_UPDATE',
    FINANCE_EXTERNAL_WORKER_DELETE: 'FINANCE_EXTERNAL_WORKER_DELETE',

    FINANCE_CONTRACTS_GET_ALL: 'FINANCE_CONTRACTS_GET_ALL',
    FINANCE_CONTRACTS_CREATE: 'FINANCE_CONTRACTS_CREATE',
    FINANCE_CONTRACTS_GET_BY_ID: 'FINANCE_CONTRACTS_GET_BY_ID',
    FINANCE_CONTRACTS_UPDATE: 'FINANCE_CONTRACTS_UPDATE',
    FINANCE_CONTRACTS_DELETE: 'FINANCE_CONTRACTS_DELETE',
    FINANCE_CONTRACTS_FILTER: 'FINANCE_CONTRACTS_FILTER',

    FINANCE_INVOICE_GET_ALL: 'FINANCE_INVOICE_GET_ALL',
    FINANCE_INVOICE_CREATE: 'FINANCE_INVOICE_CREATE',
    FINANCE_INVOICE_GET_BY_ID: 'FINANCE_INVOICE_GET_BY_ID',
    FINANCE_INVOICE_UPDATE: 'FINANCE_INVOICE_UPDATE',
    FINANCE_INVOICE_DELETE: 'FINANCE_INVOICE_DELETE',

    FINANCE_DATABASE_GET_ALL: 'FINANCE_DATABASE_GET_ALL',
    FINANCE_DATABASE_CREATE: 'FINANCE_DATABASE_CREATE',
    FINANCE_DATABASE_GET_BY_ID: 'FINANCE_DATABASE_GET_BY_ID',
    FINANCE_DATABASE_UPDATE: 'FINANCE_DATABASE_UPDATE',
    FINANCE_DATABASE_DELETE: 'FINANCE_DATABASE_DELETE',

    FINANCE_CLIENT_GET_ALL: 'FINANCE_CLIENT_GET_ALL',
    FINANCE_CLIENT_CREATE: 'FINANCE_CLIENT_CREATE',
    FINANCE_CLIENT_GET_BY_ID: 'FINANCE_CLIENT_GET_BY_ID',
    FINANCE_CLIENT_UPDATE: 'FINANCE_CLIENT_UPDATE',
    FINANCE_CLIENT_DELETE: 'FINANCE_CLIENT_DELETE',

    FINANCE_CLIENTFRAME_GET_ALL: "FINANCE_CLIENTFRAME_GET_ALL",
    FINANCE_CLIENTFRAME_CREATE: "FINANCE_CLIENTFRAME_CREATE",
    FINANCE_CLIENTFRAME_GET_BY_ID: "FINANCE_CLIENTFRAME_GET_BY_ID",
    FINANCE_CLIENTFRAME_GET_BY_CLIENT: "FINANCE_CLIENTFRAME_GET_BY_CLIENT",
    FINANCE_CLIENTFRAME_UPDATE: "FINANCE_CLIENTFRAME_UPDATE",
    FINANCE_CLIENTFRAME_DELETE: "FINANCE_CLIENTFRAME_DELETE",
    FINANCE_CLIENTFRAME_STRATEGY_ADD: "FINANCE_CLIENTFRAME_STRATEGY_ADD",
    FINANCE_CLIENTFRAME_STRATEGY_UPDATE: "FINANCE_CLIENTFRAME_STRATEGY_UPDATE",
    FINANCE_CLIENTFRAME_STRATEGY_DELETE: "FINANCE_CLIENTFRAME_STRATEGY_DELETE",

    FINANCE_UPLOAD_GET_ALL: 'FINANCE_UPLOAD_GET_ALL',
    FINANCE_UPLOAD_CREATE: 'FINANCE_UPLOAD_CREATE',
    FINANCE_UPLOAD_GET_BY_ID: 'FINANCE_UPLOAD_GET_BY_ID',
    FINANCE_UPLOAD_UPDATE: 'FINANCE_UPLOAD_UPDATE',
    FINANCE_UPLOAD_DELETE: 'FINANCE_UPLOAD_DELETE',
    FINANCE_UPLOAD_UPLOAD: 'FINANCE_UPLOAD_UPLOAD',
    FINANCE_UPLOAD_DOWNLOAD: 'FINANCE_UPLOAD_DOWNLOAD',

    FINANCE_IKZ_GET_ALL: 'FINANCE_IKZ_GET_ALL',
    FINANCE_IKZ_CREATE: 'FINANCE_IKZ_CREATE',
    FINANCE_IKZ_GET_BY_ID: 'FINANCE_IKZ_GET_BY_ID',
    FINANCE_IKZ_UPDATE: 'FINANCE_IKZ_UPDATE',
    FINANCE_IKZ_DELETE: 'FINANCE_IKZ_DELETE',
    FINANCE_IKZ_GET_BY_TYPE: 'FINANCE_IKZ_GET_BY_TYPE',
    FINANCE_IKZ_FILTER: 'FINANCE_IKZ_FILTER',

    FINANCE_MEDICAL_SERVICES_GET_ALL: 'FINANCE_MEDICAL_SERVICES_GET_ALL',
    FINANCE_MEDICAL_SERVICES_CREATE: 'FINANCE_MEDICAL_SERVICES_CREATE',
    FINANCE_MEDICAL_SERVICES_GET_BY_ID: 'FINANCE_MEDICAL_SERVICES_GET_BY_ID',
    FINANCE_MEDICAL_SERVICES_GET_BY_TYPE: 'FINANCE_MEDICAL_SERVICES_GET_BY_TYPE',
    FINANCE_MEDICAL_SERVICES_UPDATE: 'FINANCE_MEDICAL_SERVICES_UPDATE',
    FINANCE_MEDICAL_SERVICES_DELETE: 'FINANCE_MEDICAL_SERVICES_DELETE',
    FINANCE_MED_SERVICE_GET_BY_BILL_IDS: 'FINANCE_MED_SERVICE_GET_BY_BILL_IDS',

    FINANCE_USER_IKZ_ADD: 'FINANCE_USER_IKZ_ADD',
    FINANCE_USER_IKZ_GET_MY: 'FINANCE_USER_IKZ_GET_MY',
    FINANCE_USER_IKZ_REMOVE: 'FINANCE_USER_IKZ_REMOVE',
    FINANCE_USER_IKZ_GET_FOR_EMPLOYEE: 'FINANCE_USER_IKZ_GET_FOR_EMPLOYEE',
    FINANCE_USER_IKZ_FILTER: 'FINANCE_USER_IKZ_FILTER',
    FINANCE_USER_IKZ_FILTER_VER2: 'FINANCE_USER_IKZ_FILTER_VER2',
    FINANCE_USER_IKZ_GET_FOR_APPOINTMENT: 'FINANCE_USER_IKZ_GET_FOR_APPOINTMENT',
    FINANCE_USER_IKZ_SAVE_IN_APPOINTMENT: 'FINANCE_USER_IKZ_SAVE_IN_APPOINTMENT',

    FINANCE_OPERATION_PRINTER_GET_ALL: 'FINANCE_OPERATION_PRINTER_GET_ALL',
    FINANCE_OPERATION_PRINTER_ADD: 'FINANCE_OPERATION_PRINTER_ADD',
    FINANCE_OPERATION_PRINTER_CREATE_AND_PRINT: 'FINANCE_OPERATION_PRINTER_CREATE_AND_PRINT',

    //ORGANIZATIONAL_UNIT MODULE
    FINANCE_ORGANIZATIONALUNIT_GET_ALL: 'FINANCE_ORGANIZATIONALUNIT_GET_ALL',
    FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE: 'FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE',
    FINANCE_ORGANIZATIONALUNIT_GET_CLUSTERS: 'FINANCE_ORGANIZATIONALUNIT_GET_CLUSTERS',
    FINANCE_ORGANIZATIONALUNIT_GET_REGIONS: 'FINANCE_ORGANIZATIONALUNIT_GET_REGIONS',
    FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE_BY_ID: 'FINANCE_ORGANIZATIONALUNIT_GET_STRUCTURE_BY_ID',
    FINANCE_ORGANIZATIONALUNIT_CREATE: 'FINANCE_ORGANIZATIONALUNIT_CREATE',
    FINANCE_ORGANIZATIONALUNIT_ADD_UNIT: 'FINANCE_ORGANIZATIONALUNIT_ADD_UNIT',
    FINANCE_ORGANIZATIONALUNIT_GET_BY_ID: 'FINANCE_ORGANIZATIONALUNIT_GET_BY_ID',
    FINANCE_ORGANIZATIONALUNIT_GET_TOP: 'FINANCE_ORGANIZATIONALUNIT_GET_TOP',
    FINANCE_ORGANIZATIONALUNIT_UPDATE: 'FINANCE_ORGANIZATIONALUNIT_UPDATE',
    FINANCE_ORGANIZATIONALUNIT_DELETE: 'FINANCE_ORGANIZATIONALUNIT_DELETE',
    FINANCE_ORGANIZATIONALUNIT_DELETE_ID: 'FINANCE_ORGANIZATIONALUNIT_DELETE_ID',
    FINANCE_ORGANIZATIONALUNIT_CONNECT_LOCAL: 'FINANCE_ORGANIZATIONALUNIT_CONNECT_LOCAL',
    FINANCE_ORGANIZATIONALUNIT_GET_LOCAL_STRUCTURE_BY_ID: 'FINANCE_ORGANIZATIONALUNIT_GET_LOCAL_STRUCTURE_BY_ID',
    FINANCE_ORGANIZATIONALUNIT_GET_OFFICES: 'FINANCE_ORGANIZATIONALUNIT_GET_OFFICES',
    FINANCE_ORGANIZATIONALUNIT_GET_BY_TYPE: 'FINANCE_ORGANIZATIONALUNIT_GET_BY_TYPE',

    // NEW FOR ORGANIZATIONAL UNIT TYPE
    FINANCE_ORGANIZATIONALUNIT_TYPE_GET_ALL: 'FINANCE_ORGANIZATIONALUNIT_TYPE_GET_ALL',
    FINANCE_ORGANIZATIONALUNIT_TYPE_CREATE: 'FINANCE_ORGANIZATIONALUNIT_TYPE_CREATE',
    FINANCE_ORGANIZATIONALUNIT_TYPE_GET_BY_ID: 'FINANCE_ORGANIZATIONALUNIT_TYPE_GET_BY_ID',
    FINANCE_ORGANIZATIONALUNIT_TYPE_UPDATE: 'FINANCE_ORGANIZATIONALUNIT_TYPE_UPDATE',
    FINANCE_ORGANIZATIONALUNIT_TYPE_DELETE: 'FINANCE_ORGANIZATIONALUNIT_TYPE_DELETE',

    FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL: 'FINANCE_ORGANIZATIONALSUBUNIT_GET_ALL',
    FINANCE_ORGANIZATIONALSUBUNIT_CREATE: 'FINANCE_ORGANIZATIONALSUBUNIT_CREATE',
    FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID: 'FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_ID',
    FINANCE_ORGANIZATIONALSUBUNIT_UPDATE: 'FINANCE_ORGANIZATIONALSUBUNIT_UPDATE',
    FINANCE_ORGANIZATIONALSUBUNIT_DELETE: 'FINANCE_ORGANIZATIONALSUBUNIT_DELETE',
    FINANCE_ORGANIZATIONALSUBUNIT_ADD: 'FINANCE_ORGANIZATIONALSUBUNIT_ADD',

    FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID: 'FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_OFFICE_ID',

    FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES: 'FINANCE_ORGANIZATIONALSUBUNIT_GET_TYPES',
    FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES: 'FINANCE_ORGANIZATIONALSUBUNIT_GET_BY_TYPES',
    SUBUNIT_SET_SCHEDULEDISABLED: 'SUBUNIT_SET_SCHEDULEDISABLED',

    FINANCE_COMPANY_GET_ALL: 'FINANCE_COMPANY_GET_ALL',
    FINANCE_COMPANY_CREATE: 'FINANCE_COMPANY_CREATE',
    FINANCE_COMPANY_GET_BY_ID: 'FINANCE_COMPANY_GET_BY_ID',
    FINANCE_COMPANY_UPDATE: 'FINANCE_COMPANY_UPDATE',
    FINANCE_COMPANY_DELETE: 'FINANCE_COMPANY_DELETE',
    FINANCE_COMPANY_GET_STRUCTURE: 'FINANCE_COMPANY_GET_STRUCTURE',
    FINANCE_COMPANY_GET_LOCAL_STRUCTURE: 'FINANCE_COMPANY_GET_LOCAL_STRUCTURE',

    // NOWE ! NFZ SERVICE TYPE
    FINANCE_NFZ_SERVICE_TYPE_GET_ALL: 'FINANCE_NFZ_SERVICE_TYPE_GET_ALL',
    FINANCE_NFZ_SERVICE_TYPE_CREATE: 'FINANCE_NFZ_SERVICE_TYPE_CREATE',
    FINANCE_NFZ_SERVICE_TYPE_GET_BY_ID: 'FINANCE_NFZ_SERVICE_TYPE_GET_BY_ID',
    FINANCE_NFZ_SERVICE_TYPE_UPDATE: 'FINANCE_NFZ_SERVICE_TYPE_UPDATE',
    FINANCE_NFZ_SERVICE_TYPE_DELETE: 'FINANCE_NFZ_SERVICE_TYPE_DELETE',

    FINANCE_GET_NFZ_CONTRACT_BY_NIP: 'FINANCE_GET_NFZ_CONTRACT_BY_NIP',
    FINANCE_GET_NFZ_CONTRACT_BY_SWD: 'FINANCE_GET_NFZ_CONTRACT_BY_SWD',
    FINANCE_GET_NFZ_CONTRACTS: 'FINANCE_GET_NFZ_CONTRACTS',

    FINANCE_GET_NFZ_MUS_ITEMS: 'FINANCE_GET_NFZ_MUS_ITEMS',

    // NOWE ! MED SERVICE SET
    FINANCE_MED_SERVICE_SET_GET_ALL: 'FINANCE_MED_SERVICE_SET_GET_ALL',
    FINANCE_MED_SERVICE_SET_GET: 'FINANCE_MED_SERVICE_SET_GET',
    FINANCE_MED_SERVICE_SET_CREATE: 'FINANCE_MED_SERVICE_SET_CREATE',
    FINANCE_MED_SERVICE_SET_UPDATE: 'FINANCE_MED_SERVICE_SET_UPDATE',
    FINANCE_MED_SERVICE_SET_DELETE: 'FINANCE_MED_SERVICE_SET_DELETE',
    FINANCE_MED_SERVICE_SET_FILTER: 'FINANCE_MED_SERVICE_SET_FILTER',
    FINANCE_MED_SERVICE_SET_HISTORY: 'FINANCE_MED_SERVICE_SET_HISTORY',
    FINANCE_MED_SERVICE_SET_REVERT: 'FINANCE_MED_SERVICE_SET_REVERT',
    FINANCE_MED_SERVICE_SET_COUNT_PROD: 'FINANCE_MED_SERVICE_SET_COUNT_PROD',

    FINANCE_MED_SERVICE_SET_MED_POINTS: 'FINANCE_MED_SERVICE_SET_MED_POINTS',
    FINANCE_MED_SERVICE_SET_ERRORS: 'FINANCE_MED_SERVICE_SET_ERRORS',

    //get office by subunit
    OFFICE_GET_BY_SUBUNIT: 'OFFICE_GET_BY_SUBUNIT',
    OFFICE_GET_BY_TYPE: 'OFFICE_GET_BY_TYPE',
    OFFICE_GET_BY_REPORTED_SUBUNIT: 'OFFICE_GET_BY_REPORTED_SUBUNIT',
    OFFICE_GET_BY_MASTER: 'OFFICE_GET_BY_MASTER',
    OFFICE_GET_STRAY_OFFICES: 'OFFICE_GET_STRAY_OFFICES',

    // P12
    P12_VERIFY_CODE: 'P12_VERIFY_CODE',

    ERC_WORKERCREDS_GET_BY_EMPLOYE: 'ERC_WORKERCREDS_GET_BY_EMPLOYE',


    // NOWE!!! BUILDINGS MODULE
    FINANCE_BUILDING_GET_ALL: 'FINANCE_BUILDING_GET_ALL',
    FINANCE_BUILDING_GET: 'FINANCE_BUILDING_GET',
    FINANCE_BUILDING_ADD: 'FINANCE_BUILDING_ADD',
    FINANCE_BUILDING_PUT: 'FINANCE_BUILDING_PUT',
    FINANCE_BUILDING_DELETE: 'FINANCE_BUILDING_DELETE',


    // NOWE!!! NF1 MODULE
    EWUS_CHECK_PESEL: 'EWUS_CHECK_PESEL',
    EWUS_CHECK_RESPONSE: 'EWUS_CHECK_RESPONSE',
    EWUS_GET_HISTORY: 'EWUS_GET_HISTORY',
    EWUS_GET_HISTORY_BY_PESEL: 'EWUS_GET_HISTORY_BY_PESEL',

    EWUS_UPDATE_APPOINTMENT_INFO: 'EWUS_UPDATE_APPOINTMENT_INFO',

    EWUS_CONFIG_GET_BY_WORKER_ID: 'EWUS_CONFIG_GET_BY_WORKER_ID',
    EWUS_CONFIG_CREATE: 'EWUS_CONFIG_CREATE',
    EWUS_CONFIG_UPDATE: 'EWUS_CONFIG_UPDATE',

    // NOWE!!! CLINICS MODULE
    FINANCE_FACILITIES_GET_BY_ID: 'FINANCE_FACILITIES_GET_BY_ID',
    FINANCE_FACILITY_UPDATE: 'FINANCE_FACILITY_UPDATE',

    FINANCE_ORGANIZATIONALSUBUNIT_GET_REPORTED_BY_OFFICE_ID: 'FINANCE_ORGANIZATIONALSUBUNIT_GET_REPORTED_BY_OFFICE_ID',

    //RIS MODULE - NEW
    RIS_SWX_GET_ALL: 'RIS_SWX_GET_ALL',
    RIS_SWX_GET_BY_ID: 'RIS_SWX_GET_BY_ID',
    RIS_SWX_GET_BY_DOMAIN: 'RIS_SWX_GET_BY_DOMAIN',
    RIS_SWX_CREATE_FOR_CONTRACT: 'RIS_SWX_CREATE_FOR_CONTRACT',
    RIS_SWX_CREATE: 'RIS_SWX_CREATE',
    RIS_SWX_NOTE: 'RIS_SWX_NOTE',
    RIS_SWX_UPDATE: 'RIS_SWX_UPDATE',
    RIS_SWX_GENERATE_XML: 'RIS_SWX_GENERATE_XML',
    RIS_SWX_PREVALIDATE: 'RIS_SWX_PREVALIDATE',
    RIS_SWX_RESPONSE_UPLOAD: 'RIS_SWX_RESPONSE_UPLOAD',
    RIS_SWX_MULTI_RESPONSE_UPLOAD: 'RIS_SWX_MULTI_RESPONSE_UPLOAD',

    RIS_APPOINTMENT_GET_ALL: 'RIS_APPOINTMENT_GET_ALL',
    RIS_APPOINTMENT_GET_BY_ID: 'RIS_APPOINTMENT_GET_BY_ID',
    RIS_APPOINTMENT_CREATE: 'RIS_APPOINTMENT_CREATE',
    RIS_APPOINTMENT_CREATE_WITHOUT_PACKS: 'RIS_APPOINTMENT_CREATE_WITHOUT_PACKS',
    RIS_APPOINTMENT_CREATE_V2: 'RIS_APPOINTMENT_CREATE_V2',
    RIS_APPOINTMENT_UPDATE: 'RIS_APPOINTMENT_UPDATE',
    RIS_APPOINTMENT_DELETE: 'RIS_APPOINTMENT_DELETE',
    RIS_BEGIN_APPOINTMENT: 'RIS_BEGIN_APPOINTMENT',
    RIS_GET_APPOINTMENTS_BY_PATIENT: 'RIS_GET_APPOINTMENTS_BY_PATIENT',
    RIS_BEGIN_APPOINTMENT_WORKSHOP: 'RIS_BEGIN_APPOINTMENT_WORKSHOP',
    RIS_APPOINTMENTS_AVAILABLE: 'RIS_APPOINTMENTS_AVAILABLE',
    RIS_APPOINTMENTS_AVAILABLE_FILTER: 'RIS_APPOINTMENTS_AVAILABLE_FILTER',
    RIS_APPOINTMENTS_FOR_DEVICE: 'RIS_APPOINTMENTS_FOR_DEVICE',
    RIS_APPOINTMENT_GET_PACKS_STATUS: 'RIS_APPOINTMENT_GET_PACKS_STATUS',
    RIS_APPOINTMENTS_DELETE_PACKSAPPOINTMENT: 'RIS_APPOINTMENTS_DELETE_PACKSAPPOINTMENT',
    RIS_APPOINTMENTS_UPDATE_PACKSAPPOINTMENT: 'RIS_APPOINTMENTS_UPDATE_PACKSAPPOINTMENT',
    RIS_APPOINTMENTS_GET_BY_SCHEDULEAPPOINTMENT_ID: 'RIS_APPOINTMENTS_GET_BY_SCHEDULEAPPOINTMENT_ID',
    RIS_GENERATE_XML: 'RIS_GENERATE_XML',
    RIS_SEND_TO_R2: 'RIS_SEND_TO_R2',
    RIS_ADD_STATUS: 'RIS_ADD_STATUS',
    RIS_APPOINTMENT_UPLOAD_EXTERNALRESULT: 'RIS_APPOINTMENT_UPLOAD_EXTERNALRESULT',
    RIS_APPOINTMENT_DOWNLOAD_RESULT: 'RIS_APPOINTMENT_DOWNLOAD_RESULT',
    RIS_APPOINTMENT_FILTER: 'RIS_APPOINTMENT_FILTER',
    RIS_APPOINTMENT_GET_BLOCKED: 'RIS_APPOINTMENT_GET_BLOCKED',
    RIS_APPOINTMENT_BLOCK: 'RIS_APPOINTMENT_BLOCK',
    RIS_APPOINTMENT_BLOCK_REMOVE: 'RIS_APPOINTMENT_BLOCK_REMOVE',
    RIS_APPOINTMENT_FILTER_LIGHT: 'RIS_APPOINTMENT_FILTER_LIGHT',
    RIS_GET_LIGHT_APPOINTMENTS_BY_PATIENT: 'RIS_GET_LIGHT_APPOINTMENTS_BY_PATIENT',
    RIS_APPOINTMENT_SET_RESULT_IN_OTHER_SYSTEM: 'RIS_APPOINTMENT_SET_RESULT_IN_OTHER_SYSTEM',
    RIS_APPOINTMENT_SET_REFERRAL_FILE_ID: 'RIS_APPOINTMENT_SET_REFERRAL_FILE_ID',
    RIS_APPOINTMENT_SET_DESCRIPTION_DECISION: 'RIS_APPOINTMENT_SET_DESCRIPTION_DECISION',
    RIS_APPOINTMENT_CHANGE_DESCRIPTION_INTENDED: 'RIS_APPOINTMENT_CHANGE_DESCRIPTION_INTENDED',

    RIS_EXAMDESCRIPTIONACCESS_GET_ALL: 'RIS_EXAMDESCRIPTIONACCESS_GET_ALL',
    RIS_EXAMDESCRIPTIONACCESS_GET_BY_ID: 'RIS_EXAMDESCRIPTIONACCESS_GET_BY_ID',
    RIS_EXAMDESCRIPTIONACCESS_CREATE: 'RIS_EXAMDESCRIPTIONACCESS_CREATE',
    RIS_EXAMDESCRIPTIONACCESS_UPDATE: 'RIS_EXAMDESCRIPTIONACCESS_UPDATE',
    RIS_EXAMDESCRIPTIONACCESS_DELETE: 'RIS_EXAMDESCRIPTIONACCESS_DELETE',

    RIS_BURN_RESULT: 'RIS_BURN_RESULT',

    RIS_ADD_FILES: 'RIS_ADD_FILES',

    RIS_EMPLOYEE_STATS: 'RIS_EMPLOYEE_STATS',

    RIS_APPOINTMENT_CORRECT: 'RIS_APPOINTMENT_CORRECT',
    RIS_APPOINTMENT_ADD_EDMHISTORY: 'RIS_APPOINTMENT_ADD_EDMHISTORY',

    RIS_APPOINTMENT_EXAMINATOR_COMMENT_UPDATE: 'RIS_APPOINTMENT_EXAMINATOR_COMMENT_UPDATE',
    RIS_APPOINTMENT_CHANGE_DESCRIPTION_BY_REFERRER: 'RIS_APPOINTMENT_CHANGE_DESCRIPTION_BY_REFERRER',

    RIS_GET_STATS: 'RIS_GET_STATS',
    RIS_GET_ACK_STATS: 'RIS_GET_ACK_STATS',

    RIS_APPOINTMENT_CANCEL_SIMPLE: 'RIS_APPOINTMENT_CANCEL_SIMPLE',
    RIS_APPOINTMENT_UPDATE_SIMPLE: 'RIS_APPOINTMENT_UPDATE_SIMPLE',

    //RESULTS MODULE - NEW
    RESULTS_MEDICAL_PROCEDURE_GET_ALL: 'RESULTS_MEDICAL_PROCEDURE_GET_ALL',
    RESULTS_MEDICAL_PROCEDURE_GET: 'RESULTS_MEDICAL_PROCEDURE_GET',
    RESULTS_MEDICAL_PROCEDURE_ADD: 'RESULTS_MEDICAL_PROCEDURE_ADD',
    RESULTS_MEDICAL_PROCEDURE_PUT: 'RESULTS_MEDICAL_PROCEDURE_PUT',
    RESULTS_MEDICAL_PROCEDURE_DELETE: 'RESULTS_MEDICAL_PROCEDURE_DELETE',

    RESULTS_LAB_EXAMINATION_TYPE_GET_ALL: 'RESULTS_LAB_EXAMINATION_TYPE_GET_ALL',
    RESULTS_LAB_EXAMINATION_TYPE_GET_BY_ID: 'RESULTS_LAB_EXAMINATION_TYPE_GET_BY_ID',
    RESULTS_LAB_EXAMINATION_TYPE_CREATE: 'RESULTS_LAB_EXAMINATION_TYPE_CREATE',
    RESULTS_LAB_EXAMINATION_TYPE_UPDATE: 'RESULTS_LAB_EXAMINATION_TYPE_UPDATE',
    RESULTS_LAB_EXAMINATION_TYPE_DELETE: 'RESULTS_LAB_EXAMINATION_TYPE_DELETE',

    RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL: 'RESULTS_IMAGE_EXAMINATION_TYPE_GET_ALL',
    RESULTS_IMAGE_EXAMINATION_TYPE_GET_BY_ID: 'RESULTS_IMAGE_EXAMINATION_TYPE_GET_BY_ID',
    RESULTS_IMAGE_EXAMINATION_TYPE_CREATE: 'RESULTS_IMAGE_EXAMINATION_TYPE_CREATE',
    RESULTS_IMAGE_EXAMINATION_TYPE_UPDATE: 'RESULTS_IMAGE_EXAMINATION_TYPE_UPDATE',
    RESULTS_IMAGE_EXAMINATION_TYPE_DELETE: 'RESULTS_IMAGE_EXAMINATION_TYPE_DELETE',

    RESULTS_SAMPLE_BASE_GET_ALL: 'RESULTS_SAMPLE_BASE_GET_ALL',
    RESULTS_SAMPLE_BASE_GET_BY_ID: 'RESULTS_SAMPLE_BASE_GET_BY_ID',
    RESULTS_SAMPLE_BASE_CREATE: 'RESULTS_SAMPLE_BASE_CREATE',
    RESULTS_SAMPLE_BASE_UPDATE: 'RESULTS_SAMPLE_BASE_UPDATE',
    RESULTS_SAMPLE_BASE_DELETE: 'RESULTS_SAMPLE_BASE_DELETE',

    RESULTS_GET_RESULTS_BY_PATIENT: 'RESULTS_GET_RESULTS_BY_PATIENT',
    RESULTS_GET_FULL_RESULTS_BY_PATIENT: 'RESULTS_GET_FULL_RESULTS_BY_PATIENT',
    RESULTS_GET_RESULT_FILE_BY_ID: 'RESULTS_GET_RESULT_FILE_BY_ID',
    RESULTS_GET_RESULTS_BY_PATIENT_PROGRAM: 'RESULTS_GET_RESULTS_BY_PATIENT_PROGRAM',
    RESULTS_DOWNLOAD_RESULT_FILE_BY_ID: 'RESULTS_DOWNLOAD_RESULT_FILE_BY_ID',
    RESULTS_GET_RESULT_BY_ID: 'RESULTS_GET_RESULT_BY_ID',
    RESULTS_ADD_STATUS: 'RESULTS_ADD_STATUS',
    RESULTS_REMOVE: 'RESULTS_REMOVE',
    RESULTS_RESTORE: 'RESULTS_RESTORE',

    RESULTRS_GET_DEEPZOOM_FILE: 'RESULTRS_GET_DEEPZOOM_FILE',

    //RESULTS MODULE - NEW
    RESULTS_OTHER_EXAMINATION_TYPE_GET_ALL: 'RESULTS_OTHER_EXAMINATION_TYPE_GET_ALL',
    RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID: 'RESULTS_OTHER_EXAMINATION_TYPE_GET_BY_ID',
    RESULTS_OTHER_EXAMINATION_TYPE_CREATE: 'RESULTS_OTHER_EXAMINATION_TYPE_CREATE',
    RESULTS_OTHER_EXAMINATION_TYPE_UPDATE: 'RESULTS_OTHER_EXAMINATION_TYPE_UPDATE',
    RESULTS_OTHER_EXAMINATION_TYPE_DELETE: 'RESULTS_OTHER_EXAMINATION_TYPE_DELETE',

    RESULTS_DENTAL_EXAMINATION_TYPE_GET_ALL: 'RESULTS_DENTAL_EXAMINATION_TYPE_GET_ALL',
    RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID: 'RESULTS_DENTAL_EXAMINATION_TYPE_GET_BY_ID',
    RESULTS_DENTAL_EXAMINATION_TYPE_CREATE: 'RESULTS_DENTAL_EXAMINATION_TYPE_CREATE',
    RESULTS_DENTAL_EXAMINATION_TYPE_UPDATE: 'RESULTS_DENTAL_EXAMINATION_TYPE_UPDATE',
    RESULTS_DENTAL_EXAMINATION_TYPE_DELETE: 'RESULTS_DENTAL_EXAMINATION_TYPE_DELETE',

    RESULTS_PSYCHE_EXAMINATION_TYPE_GET_ALL: 'RESULTS_PSYCHE_EXAMINATION_TYPE_GET_ALL',
    RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID: 'RESULTS_PSYCHE_EXAMINATION_TYPE_GET_BY_ID',
    RESULTS_PSYCHE_EXAMINATION_TYPE_CREATE: 'RESULTS_PSYCHE_EXAMINATION_TYPE_CREATE',
    RESULTS_PSYCHE_EXAMINATION_TYPE_UPDATE: 'RESULTS_PSYCHE_EXAMINATION_TYPE_UPDATE',
    RESULTS_PSYCHE_EXAMINATION_TYPE_DELETE: 'RESULTS_PSYCHE_EXAMINATION_TYPE_DELETE',

    RESULTS_PHYSIO_EXAMINATION_TYPE_GET_ALL: 'RESULTS_PHYSIO_EXAMINATION_TYPE_GET_ALL',
    RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID: 'RESULTS_PHYSIO_EXAMINATION_TYPE_GET_BY_ID',
    RESULTS_PHYSIO_EXAMINATION_TYPE_CREATE: 'RESULTS_PHYSIO_EXAMINATION_TYPE_CREATE',
    RESULTS_PHYSIO_EXAMINATION_TYPE_UPDATE: 'RESULTS_PHYSIO_EXAMINATION_TYPE_UPDATE',
    RESULTS_PHYSIO_EXAMINATION_TYPE_DELETE: 'RESULTS_PHYSIO_EXAMINATION_TYPE_DELETE',

    RESULTS_DOWNLOAD_RESULT_BY_CODE: 'RESULTS_DOWNLOAD_RESULT_BY_CODE',

    RESULTS_CYTO_HISTORY_DOMAIN_100_BY_PESEL: 'RESULTS_CYTO_HISTORY_DOMAIN_100_BY_PESEL',

    RESULTS_FILTER: 'RESULTS_FILTER',
    RESULTS_FILTER_BY_EXAM: 'RESULTS_FILTER_BY_EXAM',

    RESULTS_UPLOAD_EXTERNAL: 'RESULTS_UPLOAD_EXTERNAL',
    RESULTS_ADD_EXTERNAL: 'RESULTS_ADD_EXTERNAL',

    RESULTS_USE_IN_APPOINTMENT: 'RESULTS_USE_IN_APPOINTMENT',

    RESULTS_EDIT_EXAM_TYPE_IDS: 'RESULTS_EDIT_EXAM_TYPE_IDS',

    RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE: 'RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE',
    RESULTS_P1_REFERRAL_GET_BY_ID: 'RESULTS_P1_REFERRAL_GET_BY_ID',
    RESULTS_P1_REFERRAL_GET_BY_KLUCZ: 'RESULTS_P1_REFERRAL_GET_BY_KLUCZ',
    RESULTS_P1_REFERRAL_UPLOAD_P1_RESULT: 'RESULTS_P1_REFERRAL_UPLOAD_P1_RESULT',
    RESULTS_P1_REFERRAL_UPDATE: 'RESULTS_P1_REFERRAL_UPDATE',
    RESULTS_P1_REFERRAL_UPDATE_BY_KLUCZ: 'RESULTS_P1_REFERRAL_UPDATE_BY_KLUCZ',
    RESULTS_P1_REFERRAL_HTML_DOWNLOAD: 'RESULTS_P1_REFERRAL_HTML_DOWNLOAD',

    RESULTS_P1_REFERRAL_CREATE: 'RESULTS_P1_REFERRAL_CREATE',
    RESULTS_P1_REFERRAL_REMOVE: 'RESULTS_P1_REFERRAL_REMOVE',
    RESULTS_P1_REFERRAL_RESTORE: 'RESULTS_P1_REFERRAL_RESTORE',

    RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE_SHORT: 'RESULTS_P1_REFERRAL_GET_BY_DESTINATION_TYPE_SHORT',
    RESULTS_P1_REFERRAL_GET_BY_DESTINATION_CLINIC: 'RESULTS_P1_REFERRAL_GET_BY_DESTINATION_CLINIC',
    RESULTS_P1_REFERRAL_USED_IN_AOS: 'RESULTS_P1_REFERRAL_USED_IN_AOS',
    RESULTS_P1_REFERRAL_FILTER: 'RESULTS_P1_REFERRAL_FILTER',

    RESULTS_P1_RESULT_ZM_GET_BY_KLUCZ: 'RESULTS_P1_RESULT_ZM_GET_BY_KLUCZ',
    RESULTS_P1_RESULT_ZM_GET_BY_APP_ID: 'RESULTS_P1_RESULT_ZM_GET_BY_APP_ID',
    RESULTS_P1_RESULT_ZM_GET_STATUS_BY_APP_ID: 'RESULTS_P1_RESULT_ZM_GET_STATUS_BY_APP_ID',

    RESULTS_IMMUNIZATION_GET: 'RESULTS_IMMUNIZATION_GET',
    RESULTS_IMMUNIZATION_EXTENDED_GET: 'RESULTS_IMMUNIZATION_EXTENDED_GET',
    RESULTS_IMMUNIZATION_GET_BY_PATIENT: 'RESULTS_IMMUNIZATION_GET_BY_PATIENT',
    RESULTS_IMMUNIZATION_GET_BY_APPOINTMENT: 'RESULTS_IMMUNIZATION_GET_BY_APPOINTMENT',
    RESULTS_IMMUNIZATION_UPDATE: 'RESULTS_IMMUNIZATION_UPDATE',
    RESULTS_IMMUNIZATION_CREATE: 'RESULTS_IMMUNIZATION_CREATE',
    RESULTS_IMMUNIZATION_REMOVE: 'RESULTS_IMMUNIZATION_REMOVE',
    RESULTS_IMMUNIZATION_RESTORE: 'RESULTS_IMMUNIZATION_RESTORE',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET: 'RESULTS_IMMUNIZATION_QUALIFICATION_GET',
    RESULTS_IMMUNIZATION_QUALIFICATION_EXTENDED_GET: 'RESULTS_IMMUNIZATION_QUALIFICATION_EXTENDED_GET',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_PATIENT: 'RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_PATIENT',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_APPOINTMENT: 'RESULTS_IMMUNIZATION_QUALIFICATION_GET_BY_APPOINTMENT',
    RESULTS_IMMUNIZATION_QUALIFICATION_CREATE: 'RESULTS_IMMUNIZATION_QUALIFICATION_CREATE',
    RESULTS_IMMUNIZATION_QUALIFICATION_UPDATE: 'RESULTS_IMMUNIZATION_QUALIFICATION_UPDATE',
    RESULTS_IMMUNIZATION_QUALIFICATION_REMOVE: 'RESULTS_IMMUNIZATION_QUALIFICATION_REMOVE',
    RESULTS_IMMUNIZATION_QUALIFICATION_RESTORE: 'RESULTS_IMMUNIZATION_QUALIFICATION_RESTORE',
    RESULTS_IMMUNIZATION_JOIN_OBSERVATION: 'RESULTS_IMMUNIZATION_JOIN_OBSERVATION',
    RESULTS_IMMUNIZATION_CREATE_WITH_QUALIFICATION: 'RESULTS_IMMUNIZATION_CREATE_WITH_QUALIFICATION',
    RESULTS_IMMUNIZATION_QUALIFICATION_GET_COMPLETED_BY_PATIENT: 'RESULTS_IMMUNIZATION_QUALIFICATION_GET_COMPLETED_BY_PATIENT',
    RESULTS_IMMUNIZATION_QUALIFICATION_POST_COMPLETED_BY_PATIENT: 'RESULTS_IMMUNIZATION_QUALIFICATION_POST_COMPLETED_BY_PATIENT',

    RESULTS_IMMUNIZATION_FILTER: 'RESULTS_IMMUNIZATION_FILTER',
    RESULTS_IMMUNIZATION_QUALIFICATION_FILTER: 'RESULTS_IMMUNIZATION_QUALIFICATION_FILTER',

    RESULTS_TEST_RESULT_GET_BY_PATIENT: 'RESULTS_TEST_RESULT_GET_BY_PATIENT',

    RESULTS_SOLAB_EXAM_GET_BY_ID: 'RESULTS_SOLAB_EXAM_GET_BY_ID',
    RESULTS_SOLAB_EXAM_GET_BY_PATIENT: 'RESULTS_SOLAB_EXAM_GET_BY_PATIENT',
    RESULTS_SOLAB_EXAM_GET_BY_PATIENT_GROUPED: 'RESULTS_SOLAB_EXAM_GET_BY_PATIENT_GROUPED',
    RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_ICD: 'RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_ICD',
    RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_DATE: 'RESULTS_SOLAB_EXAM_GET_BY_PATIENT_AND_DATE',
    RESULTS_SOLAB_LAST_EXAMS_GET_BY_PATIENT_AND_ICD: 'RESULTS_SOLAB_LAST_EXAMS_GET_BY_PATIENT_AND_ICD',

    //ZUS MODULE - NEW
    ZUS_CHECK_PASSWORD: 'ZUS_CHECK_PASSWORD',
    ZUS_ENTRY_ACTION: 'ZUS_ENTRY_ACTION',
    ZUS_GET_RESULT: 'ZUS_GET_RESULT',

    // ATTESTATIONS MODULE - NEW
    PATIENT_ATTESTATION_GET_ALL: 'PATIENT_ATTESTATION_GET_ALL',
    PATIENT_ATTESTATION_GET: 'PATIENT_ATTESTATION_GET',
    PATIENT_ATTESTATION_CREATE: 'PATIENT_ATTESTATION_CREATE',
    PATIENT_ATTESTATION_UPDATE: 'PATIENT_ATTESTATION_UPDATE',
    PATIENT_ATTESTATION_DELETE: 'PATIENT_ATTESTATION_DELETE',
    PATIENT_ATTESTATION_GET_BY_PATIENT: 'PATIENT_ATTESTATION_GET_BY_PATIENT',
    PATIENT_ATTESTATION_REMOVE: 'PATIENT_ATTESTATION_REMOVE',
    PATIENT_ATTESTATION_RESTORE: 'PATIENT_ATTESTATION_RESTORE',


    // REFFERAL MODULE - NEW
    PATIENT_CUSTOM_REFFERAL_GET_ALL: 'PATIENT_CUSTOM_REFFERAL_GET_ALL',
    PATIENT_CUSTOM_REFFERAL_GET: 'PATIENT_CUSTOM_REFFERAL_GET',
    PATIENT_CUSTOM_REFFERAL_CREATE: 'PATIENT_CUSTOM_REFFERAL_CREATE',
    PATIENT_CUSTOM_REFFERAL_UPDATE: 'PATIENT_CUSTOM_REFFERAL_UPDATE',
    PATIENT_CUSTOM_REFFERAL_DELETE: 'PATIENT_CUSTOM_REFFERAL_DELETE',
    PATIENT_CUSTOM_REFFERAL_GET_BY_PATIENT: 'PATIENT_CUSTOM_REFFERAL_GET_BY_PATIENT',
    PATIENT_CUSTOM_REFERRAL_REMOVE: 'PATIENT_CUSTOM_REFERRAL_REMOVE',
    PATIENT_CUSTOM_REFERRAL_RESTORE: 'PATIENT_CUSTOM_REFERRAL_RESTORE',

    //PatientPrescriptionPaper
    PATIENT_PAPER_PRESCRIPTION_GET_ALL: 'PATIENT_PAPER_PRESCRIPTION_GET_ALL',
    PATIENT_PAPER_PRESCRIPTION_CREATE: 'PATIENT_PAPER_PRESCRIPTION_CREATE',
    PATIENT_PAPER_PRESCRIPTION_GET: 'PATIENT_PAPER_PRESCRIPTION_GET',
    PATIENT_PAPER_PRESCRIPTION_UPDATE: 'PATIENT_PAPER_PRESCRIPTION_UPDATE',
    PATIENT_PAPER_PRESCRIPTION_DELETE: 'PATIENT_PAPER_PRESCRIPTION_DELETE',
    PATIENT_PAPER_PRESCRIPTION_GET_BY_PATIENT: 'PATIENT_PAPER_PRESCRIPTION_GET_BY_PATIENT',
    PATIENT_PAPER_PRESCRIPTION_GET_BY_APPOINTMENT: 'PATIENT_PAPER_PRESCRIPTION_GET_BY_APPOINTMENT',
    PATIENT_PAPER_PRESCRIPTION_REMOVE: 'PATIENT_PAPER_PRESCRIPTION_REMOVE',
    PATIENT_PAPER_PRESCRIPTION_RESTORE: 'PATIENT_PAPER_PRESCRIPTION_RESTORE',


    //PRICELIST MODULE - NEW
    FINANCE_PRICE_LIST_GET_ALL: 'FINANCE_PRICE_LIST_GET_ALL',
    FINANCE_PRICE_LIST_GET: 'FINANCE_PRICE_LIST_GET',
    FINANCE_PRICE_LIST_CREATE: 'FINANCE_PRICE_LIST_CREATE',
    FINANCE_PRICE_LIST_UPDATE: 'FINANCE_PRICE_LIST_UPDATE',
    FINANCE_PRICE_LIST_DELETE: 'FINANCE_PRICE_LIST_DELETE',
    FINANCE_PRICE_LIST_GET_BY_SUBJECT: 'FINANCE_PRICE_LIST_GET_BY_SUBJECT',
    FINANCE_PRICE_LIST_GET_BY_SUBJECT_GROUPED: 'FINANCE_PRICE_LIST_GET_BY_SUBJECT_GROUPED',

    //PRICELIST MODULE - NEW
    FINANCE_SUBJECT_PRICES_GET_ALL: 'FINANCE_SUBJECT_PRICES_GET_ALL',
    FINANCE_SUBJECT_PRICES_GET: 'FINANCE_SUBJECT_PRICES_GET',
    FINANCE_SUBJECT_PRICES_CREATE: 'FINANCE_SUBJECT_PRICES_CREATE',
    FINANCE_SUBJECT_PRICES_UPDATE: 'FINANCE_SUBJECT_PRICES_UPDATE',
    FINANCE_SUBJECT_PRICES_DELETE: 'FINANCE_SUBJECT_PRICES_DELETE',
    FINANCE_SUBJECT_PRICES_GET_BY_SUBJECT: 'FINANCE_SUBJECT_PRICES_GET_BY_SUBJECT',

    FINANCE_SUBJECT_PRICES_PRICE_CREATE: 'FINANCE_SUBJECT_PRICES_PRICE_CREATE',
    FINANCE_SUBJECT_PRICES_PRICE_UPDATE: 'FINANCE_SUBJECT_PRICES_PRICE_UPDATE',
    FINANCE_SUBJECT_PRICES_PRICE_DELTE: 'FINANCE_SUBJECT_PRICES_PRICE_DELTE',

    //MEDQUESTIONS MODULE - NEW
    MED_QUESTIONS_CONFIG_GET_ALL: 'MED_QUESTIONS_CONFIG_GET_ALL',
    MED_QUESTIONS_CONFIG_GET: 'MED_QUESTIONS_CONFIG_GET',
    MED_QUESTIONS_CONFIG_CREATE: 'MED_QUESTIONS_CONFIG_CREATE',
    MED_QUESTIONS_CONFIG_UPDATE: 'MED_QUESTIONS_CONFIG_UPDATE',
    MED_QUESTIONS_CONFIG_DELETE: 'MED_QUESTIONS_CONFIG_DELETE',
    MED_QUESTIONS_CONFIG_GET_BY_PATIENT: 'MED_QUESTIONS_CONFIG_GET_BY_PATIENT',
    MED_QUESTIONS_CONFIG_GET_BY_EMPLOYEE: 'MED_QUESTIONS_CONFIG_GET_BY_EMPLOYEE',
    MED_QUESTIONS_CONFIG_GET_FILTER: 'MED_QUESTIONS_CONFIG_GET_FILTER',
    MED_QUESTIONS_CONFIG_GET_FULL_BY_ID: 'MED_QUESTIONS_CONFIG_GET_FULL_BY_ID',
    MED_QUESTIONS_CONFIG_GET_FULL_BY_CLINIC: 'MED_QUESTIONS_CONFIG_GET_FULL_BY_CLINIC',

    MED_QUESTION_GROUP_GET_ALL: 'MED_QUESION_GROUP_GET_ALL',
    MED_QUESTION_GROUP_GET: 'MED_QUESTION_GROUP_GET',
    MED_QUESTION_GROUP_CREATE: 'MED_QUESTION_GROUP_CREATE',
    MED_QUESTION_GROUP_UPDATE: 'MED_QUESTION_GROUP_UPDATE',
    MED_QUESTION_GROUP_DELETE: 'MED_QUESTION_GROUP_DELETE',
    MED_QUESTION_GROUP_GET_BY_INTERNAL_ID: 'MED_QUESTION_GROUP_GET_BY_INTERNAL_ID',
    MED_QUESTION_GROUP_GET_BY_TYPE: 'MED_QUESTION_GROUP_GET_BY_TYPE',

    MED_QUESTION_GET_ALL: 'MED_QUESTION_GET_ALL',
    MED_QUESTION_GET: 'MED_QUESTION_GET',
    MED_QUESTION_GET_SORTED: 'MED_QUESTION_GET_SORTED',
    MED_QUESTION_CREATE: 'MED_QUESTION_CREATE',
    MED_QUESTION_CREATE_FROM_OLD: 'MED_QUESTION_CREATE_FROM_OLD',
    MED_QUESTION_CREATE_MANY_FROM_OLD: 'MED_QUESTION_CREATE_MANY_FROM_OLD',
    MED_QUESTION_UPDATE: 'MED_QUESTION_UPDATE',
    MED_QUESTION_DELETE: 'MED_QUESTION_DELETE',

    MED_QUESTION_ATTACHMENTS_GET_ALL: 'MED_QUESTION_ATTACHMENTS_GET_ALL',
    MED_QUESTION_ATTACHMENTS_CREATE: 'MED_QUESTION_ATTACHMENTS_CREATE',
    MED_QUESTION_ATTACHMENTS_UPDATE: 'MED_QUESTION_ATTACHMENTS_UPDATE',
    MED_QUESTION_ATTACHMENTS_DELETE: 'MED_QUESTION_ATTACHMENTS_DELETE',
    MED_QUESTION_ATTACHMENTS_GET: 'MED_QUESTION_ATTACHMENTS_GET',
    MED_QUESTION_ATTACHMENTS_GET_BY_INTERNAL_ID: 'MED_QUESTION_ATTACHMENTS_GET_BY_INTERNAL_ID',
    MED_QUESTION_ATTACHMENTS_GET_BY_TYPE: 'MED_QUESTION_ATTACHMENTS_GET_BY_TYPE',
    MED_QUESTION_ATTACHMENTS_UPLOAD_ATTACHMENT: 'MED_QUESTION_ATTACHMENTS_UPLOAD_ATTACHMENT',
    MED_QUESTION_ATTACHMENTS_DOWNLOAD: 'MED_QUESTION_ATTACHMENTS_DOWNLOAD',

    USED_QUESTION_TEMPLATE_GET_ALL: 'USED_QUESTION_TEMPLATE_GET_ALL',
    USED_QUESTION_TEMPLATE_CREATE: 'USED_QUESTION_TEMPLATE_CREATE',
    USED_QUESTION_TEMPLATE_UPDATE: 'USED_QUESTION_TEMPLATE_UPDATE',
    USED_QUESTION_TEMPLATE_GET_BY_ID: 'USED_QUESTION_TEMPLATE_GET_BY_ID',
    USED_QUESTION_TEMPLATE_GET_BY_APPOINTMENT: 'USED_QUESTION_TEMPLATE_GET_BY_APPOINTMENT',
    USED_QUESTION_TEMPLATE_REMOVE: 'USED_QUESTION_TEMPLATE_REMOVE',
    USED_QUESTION_TEMPLATE_RESTORE: 'USED_QUESTION_TEMPLATE_RESTORE',

    //EMPLOYEE FORMULA MODULE - NEW
    EMPLOYEE_FORMULA_GET_ALL: 'EMPLOYEE_FORMULA_GET_ALL',
    EMPLOYEE_FORMULA_GET: 'EMPLOYEE_FORMULA_GET',
    EMPLOYEE_FORMULA_CREATE: 'EMPLOYEE_FORMULA_CREATE',
    EMPLOYEE_FORMULA_UPDATE: 'EMPLOYEE_FORMULA_UPDATE',
    EMPLOYEE_FORMULA_DELETE: 'EMPLOYEE_FORMULA_DELETE',
    EMPLOYEE_FORMULA_GET_BY_EMPLOYEE: 'EMPLOYEE_FORMULA_GET_BY_EMPLOYEE',
    EMPLOYEE_FORMULA_GET_BY_TYPE: 'EMPLOYEE_FORMULA_GET_BY_TYPE',

    //SOLAB MODULE - NEW
    SOLAB_LAB_ORDER_COMPLETE_CREATE: 'SOLAB_LAB_ORDER_COMPLETE_CREATE',
    SOLAB_RESULT_ORDER_COMPLETE: 'SOLAB_RESULT_ORDER_COMPLETE',

    // PROGRAMS MODULE - NEW
    PROGRAMS_POPULATION_GET_ALL: 'PROGRAMS_POPULATION_GET_ALL',
    PROGRAMS_POPULATION_GET: 'PROGRAMS_POPULATION_GET',
    PROGRAMS_POPULATION_CREATE: 'PROGRAMS_POPULATION_CREATE',
    PROGRAMS_POPULATION_UPDATE: 'PROGRAMS_POPULATION_UPDATE',
    PROGRAMS_POPULATION_DELETE: 'PROGRAMS_POPULATION_DELETE',
    PROGRAMS_POPULATION_GET_FOR_CONFIG: 'PROGRAMS_POPULATION_GET_FOR_CONFIG',
    PROGRAMS_POPULATION_GET_BY_FILTER: 'PROGRAMS_POPULATION_GET_BY_FILTER',

    PROGRAMS_POPULATION_CONFIG_GET_ALL: 'PROGRAMS_POPULATION_CONFIG_GET_ALL',
    PROGRAMS_POPULATION_CONFIG_GET: 'PROGRAMS_POPULATION_CONFIG_GET',
    PROGRAMS_POPULATION_CONFIG_GET_BY_INTERNAL_ID: 'PROGRAMS_POPULATION_CONFIG_GET_BY_INTERNAL_ID',
    PROGRAMS_POPULATION_CONFIG_CREATE: 'PROGRAMS_POPULATION_CONFIG_CREATE',
    PROGRAMS_POPULATION_CONFIG_UPDATE: 'PROGRAMS_POPULATION_CONFIG_UPDATE',
    PROGRAMS_POPULATION_CONFIG_DELETE: 'PROGRAMS_POPULATION_CONFIG_DELETE',
    PROGRAMS_POPULATION_CONFIG_ADD_EVENT: 'PROGRAMS_POPULATION_CONFIG_ADD_EVENT',
    PROGRAMS_POPULATION_CONFIG_UPDATE_EVENT: 'PROGRAMS_POPULATION_CONFIG_UPDATE_EVENT',
    PROGRAMS_POPULATION_CONFIG_DELETE_EVENT: 'PROGRAMS_POPULATION_CONFIG_DELETE_EVENT',
    PROGRAMS_POPULATION_CONFIG_GET_EVENTS: 'PROGRAMS_POPULATION_CONFIG_GET_EVENTS',

    PROGRAMS_POPULATION_ATTACHMENT_GET_ALL: 'PROGRAMS_POPULATION_ATTACHMENT_GET_ALL',
    PROGRAMS_POPULATION_ATTACHMENT_GET: 'PROGRAMS_POPULATION_ATTACHMENT_GET',
    PROGRAMS_POPULATION_ATTACHMENT_UPDATE: 'PROGRAMS_POPULATION_ATTACHMENT_UPDATE',
    PROGRAMS_POPULATION_ATTACHMENT_DELETE: 'PROGRAMS_POPULATION_ATTACHMENT_DELETE',
    PROGRAMS_POPULATION_ATTACHMENT_DOWNLOAD: 'PROGRAMS_POPULATION_ATTACHMENT_DOWNLOAD',
    PROGRAMS_POPULATION_ATTACHMENT_UPLOAD: 'PROGRAMS_POPULATION_ATTACHMENT_UPLOAD',
    PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM: 'PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM',
    PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM_CONFIG: "PROGRAMS_POPULATION_ATTACHMENT_GET_FOR_PROGRAM_CONFIG",

    PROGRAMS_POPULATION_EVENT_GET_ALL: 'PROGRAMS_POPULATION_EVENT_GET_ALL',
    PROGRAMS_POPULATION_EVENT_GET: 'PROGRAMS_POPULATION_EVENT_GET',
    PROGRAMS_POPULATION_EVENT_CREATE: 'PROGRAMS_POPULATION_EVENT_CREATE',
    PROGRAMS_POPULATION_EVENT_UPDATE: 'PROGRAMS_POPULATION_EVENT_UPDATE',
    PROGRAMS_POPULATION_EVENT_DELETE: 'PROGRAMS_POPULATION_EVENT_DELETE',

    PROGRAMS_POPULATION_EVENT_TYPE_GET_ALL: 'PROGRAMS_POPULATION_EVENT_TYPE_GET_ALL',
    PROGRAMS_POPULATION_EVENT_TYPE_GET: 'PROGRAMS_POPULATION_EVENT_TYPE_GET',
    PROGRAMS_POPULATION_EVENT_TYPE_CREATE: 'PROGRAMS_POPULATION_EVENT_TYPE_CREATE',
    PROGRAMS_POPULATION_EVENT_TYPE_UPDATE: 'PROGRAMS_POPULATION_EVENT_TYPE_UPDATE',
    PROGRAMS_POPULATION_EVENT_TYPE_DELETE: 'PROGRAMS_POPULATION_EVENT_TYPE_DELETE',

    PROGRAMS_POPULATION_CONFIG_EVENT_GET_ALL: 'PROGRAMS_POPULATION_CONFIG_EVENT_GET_ALL',
    PROGRAMS_POPULATION_CONFIG_EVENT_GET: 'PROGRAMS_POPULATION_CONFIG_EVENT_GET',
    PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_INTERNAL: 'PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_INTERNAL',
    PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_PROGRAM_CONFIG: 'PROGRAMS_POPULATION_CONFIG_EVENT_GET_BY_PROGRAM_CONFIG',
    PROGRAMS_POPULATION_CONFIG_EVENT_CREATE: 'PROGRAMS_POPULATION_CONFIG_EVENT_CREATE',
    PROGRAMS_POPULATION_CONFIG_EVENT_UPDATE: 'PROGRAMS_POPULATION_CONFIG_EVENT_UPDATE',
    PROGRAMS_POPULATION_CONFIG_EVENT_DELETE: 'PROGRAMS_POPULATION_CONFIG_EVENT_DELETE',

    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_ALL: 'PROGRAMS_POPULATION_CONFIG_PROFILE_GET_ALL',
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET: 'PROGRAMS_POPULATION_CONFIG_PROFILE_GET',
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_INTERNAL: 'PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_INTERNAL',
    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PROGRAM_CONFIG: 'PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PROGRAM_CONFIG',
    PROGRAMS_POPULATION_CONFIG_PROFILE_CREATE: 'PROGRAMS_POPULATION_CONFIG_PROFILE_CREATE',
    PROGRAMS_POPULATION_CONFIG_PROFILE_UPDATE: 'PROGRAMS_POPULATION_CONFIG_PROFILE_UPDATE',
    PROGRAMS_POPULATION_CONFIG_PROFILE_DELETE: 'PROGRAMS_POPULATION_CONFIG_PROFILE_DELETE',

    PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PATIENT_PROGRAM_ID: 'PROGRAMS_POPULATION_CONFIG_PROFILE_GET_BY_PATIENT_PROGRAM_ID',

    //DEEPZOOM
    LAB_DEEPZOOM_GET_BY_ID: 'LAB_DEEPZOOM_GET_BY_ID',
    LAB_DEEPZOOM_UPDATE: 'LAB_DEEPZOOM_UPDATE',
    LAB_DEEPZOOM_CREATE: 'LAB_DEEPZOOM_CREATE',
    LAB_DEEPZOOM_DELETE: 'LAB_DEEPZOOM_DELETE',
    LAB_DEEPZOOM_SET_LOCK: 'LAB_DEEPZOOM_SET_LOCK',
    LAB_SAMPLE_DEEPZOOM_UPDATE_IMAGE: 'LAB_SAMPLE_DEEPZOOM_UPDATE_IMAGE',
    LAB_SAMPLES_DEEPZOOM_SET_SHAPES: 'LAB_SAMPLES_DEEPZOOM_SET_SHAPES',

    TEMPLATE_REFERRAL_GET_ALL: 'TEMPLATE_REFERRAL_GET_ALL',
    TEMPLATE_REFERRAL_GET: 'TEMPLATE_REFERRAL_GET',
    TEMPLATE_REFERRAL_GET_BY_PATIENT: 'TEMPLATE_REFERRAL_GET_BY_PATIENT',
    TEMPLATE_REFERRAL_GET_BY_EMPLOYEE: 'TEMPLATE_REFERRAL_GET_BY_EMPLOYEE',
    TEMPLATE_REFERRAL_GET_BY_EMPLOYEE_TYPE: 'TEMPLATE_REFERRAL_GET_BY_EMPLOYEE_TYPE',
    TEMPLATE_REFERRAL_GET_BY_APPOINTMENT: 'TEMPLATE_REFERRAL_GET_BY_APPOINTMENT',
    TEMPLATE_REFERRAL_GET_BY_P1: 'TEMPLATE_REFERRAL_GET_BY_P1',
    TEMPLATE_REFERRAL_CREATE: 'TEMPLATE_REFERRAL_CREATE',
    TEMPLATE_REFERRAL_UPDATE: 'TEMPLATE_REFERRAL_UPDATE',
    TEMPLATE_REFERRAL_DELETE: 'TEMPLATE_REFERRAL_DELETE',


    PATIENT_DOCTOR_NOTE_GET_ALL: 'PATIENT_DOCTOR_NOTE_GET_ALL',
    PATIENT_DOCTOR_NOTE_CREATE: 'PATIENT_DOCTOR_NOTE_CREATE',
    PATIENT_DOCTOR_NOTE_UPDATE: 'PATIENT_DOCTOR_NOTE_UPDATE',
    PATIENT_DOCTOR_NOTE_DELETE: 'PATIENT_DOCTOR_NOTE_DELETE',
    PATIENT_DOCTOR_NOTE_GET_BY_ID: 'PATIENT_DOCTOR_NOTE_GET_BY_ID',
    PATIENT_DOCTOR_NOTE_GET_BY_PATIENT: 'PATIENT_DOCTOR_NOTE_GET_BY_PATIENT',
    PATIENT_DOCTOR_NOTE_GET_BY_APPOINTMENT: 'PATIENT_DOCTOR_NOTE_GET_BY_APPOINTMENT',
    PATIENT_DOCTOR_NOTE_UPDATE_NODE: 'PATIENT_DOCTOR_NOTE_UPDATE_NODE',
    PATIENT_DOCTOR_NOTE_REMOVE: 'PATIENT_DOCTOR_NOTE_REMOVE',
    PATIENT_DOCTOR_NOTE_RESTORE: 'PATIENT_DOCTOR_NOTE_RESTORE',
    PATIENT_DOCTOR_NOTE_CONTENT_RESTORE: 'PATIENT_DOCTOR_NOTE_CONTENT_RESTORE',
    PATIENT_DOCTOR_NOTE_GET_SPECIAL_BY_PATIENT: 'PATIENT_DOCTOR_NOTE_GET_SPECIAL_BY_PATIENT',
    PATIENT_DOCTOR_NOTE_GET_HISTORY_NOTE: 'PATIENT_DOCTOR_NOTE_GET_HISTORY_NOTE',

    ///
    PATIENT_ORDER_IMMUNIZATION_GET_BY_QUALIFICATION: 'PATIENT_ORDER_IMMUNIZATION_GET_BY_QUALIFICATION',
    PATIENT_ORDER_IMMUNIZATION_CREATE: 'PATIENT_ORDER_IMMUNIZATION_CREATE',
    PATIENT_ORDER_IMMUNIZATION_GET_BY_NURSE_APPOINTMENT: 'PATIENT_ORDER_IMMUNIZATION_GET_BY_NURSE_APPOINTMENT',
    PATIENT_ORDER_IMMUNIZATION_GET_BY_PATIENT: 'PATIENT_ORDER_IMMUNIZATION_GET_BY_PATIENT',
    PATIENT_ORDER_IMMUNIZATION_GET_BY_APPOINTMENT: 'PATIENT_ORDER_IMMUNIZATION_GET_BY_APPOINTMENT',

    //RECOMMENDATIONS MODULE
    RECOMMENDATION_CONFIG_GET_WITH_REMOVED: 'RECOMMENDATION_CONFIG_GET_WITH_REMOVED',
    RECOMMENDATION_CONFIG_GET_ALL: 'RECOMMENDATION_CONFIG_GET_ALL',
    RECOMMENDATION_CONFIG_GET: 'RECOMMENDATION_CONFIG_GET',
    RECOMMENDATION_CONFIG_CREATE: 'RECOMMENDATION_CONFIG_CREATE',
    RECOMMENDATION_CONFIG_UPDATE: 'RECOMMENDATION_CONFIG_UPDATE',
    RECOMMENDATION_CONFIG_DELETE: 'RECOMMENDATION_CONFIG_DELETE',
    RECOMMENDATION_CONFIG_GET_BY_TYPE: 'RECOMMENDATION_CONFIG_GET_BY_TYPE',
    RECOMMENDATION_CONFIG_REMOVE: 'RECOMMENDATION_CONFIG_REMOVE',
    RECOMMENDATION_CONFIG_RESTORE: 'RECOMMENDATION_CONFIG_RESTORE',

    RECOMMENDATION_CONFIG_GROUP_GET_WITH_REMOVED: 'RECOMMENDATION_CONFIG_GROUP_GET_WITH_REMOVED',
    RECOMMENDATION_CONFIG_GROUP_GET_ALL: 'RECOMMENDATION_CONFIG_GROUP_GET_ALL',
    RECOMMENDATION_CONFIG_GROUP_GET: 'RECOMMENDATION_CONFIG_GROUP_GET',
    RECOMMENDATION_CONFIG_GROUP_CREATE: 'RECOMMENDATION_CONFIG_GROUP_CREATE',
    RECOMMENDATION_CONFIG_GROUP_UPDATE: 'RECOMMENDATION_CONFIG_GROUP_UPDATE',
    RECOMMENDATION_CONFIG_GROUP_DELETE: 'RECOMMENDATION_CONFIG_GROUP_DELETE',
    RECOMMENDATION_CONFIG_GROUP_GET_BY_TYPE: 'RECOMMENDATION_CONFIG_GROUP_GET_BY_TYPE',
    RECOMMENDATION_CONFIG_GROUP_REMOVE: 'RECOMMENDATION_CONFIG_GROUP_REMOVE',
    RECOMMENDATION_CONFIG_GROUP_RESTORE: 'RECOMMENDATION_CONFIG_GROUP_RESTORE',

    RECOMMENDATION_GOAL_GET_WITH_REMOVED: 'RECOMMENDATION_GOAL_GET_WITH_REMOVED',
    RECOMMENDATION_GOAL_GET_ALL: 'RECOMMENDATION_GOAL_GET_ALL',
    RECOMMENDATION_GOAL_GET: 'RECOMMENDATION_GOAL_GET',
    RECOMMENDATION_GOAL_CREATE: 'RECOMMENDATION_GOAL_CREATE',
    RECOMMENDATION_GOAL_UPDATE: 'RECOMMENDATION_GOAL_UPDATE',
    RECOMMENDATION_GOAL_DELETE: 'RECOMMENDATION_GOAL_DELETE',
    RECOMMENDATION_GOAL_GET_BY_TYPE: 'RECOMMENDATION_GOAL_GET_BY_TYPE',
    RECOMMENDATION_GOAL_REMOVE: 'RECOMMENDATION_GOAL_REMOVE',
    RECOMMENDATION_GOAL_RESTORE: 'RECOMMENDATION_GOAL_RESTORE',

    RECOMMENDATION_GOAL_GROUP_GET_WITH_REMOVED: 'RECOMMENDATION_GOAL_GROUP_GET_WITH_REMOVED',
    RECOMMENDATION_GOAL_GROUP_GET_ALL: 'RECOMMENDATION_GOAL_GROUP_GET_ALL',
    RECOMMENDATION_GOAL_GROUP_GET: 'RECOMMENDATION_GOAL_GROUP_GET',
    RECOMMENDATION_GOAL_GROUP_CREATE: 'RECOMMENDATION_GOAL_GROUP_CREATE',
    RECOMMENDATION_GOAL_GROUP_UPDATE: 'RECOMMENDATION_GOAL_GROUP_UPDATE',
    RECOMMENDATION_GOAL_GROUP_DELETE: 'RECOMMENDATION_GOAL_GROUP_DELETE',
    RECOMMENDATION_GOAL_GROUP_GET_BY_TYPE: 'RECOMMENDATION_GOAL_GROUP_GET_BY_TYPE',
    RECOMMENDATION_GOAL_GROUP_REMOVE: 'RECOMMENDATION_GOAL_GROUP_REMOVE',
    RECOMMENDATION_GOAL_GROUP_RESTORE: 'RECOMMENDATION_GOAL_GROUP_RESTORE',


    //PROGRAMS APPOINTMENT MODULE
    PROGRAM_APPOINTMENT_BEGIN_APPOINTMENT: 'PROGRAM_APPOINTMENT_BEGIN_APPOINTMENT',
    PROGRAM_APPOINTMENT_END_APPOINTMENT: 'PROGRAM_APPOINTMENT_END_APPOINTMENT',

    //CASH REGISTERS MODULE
    CASH_OPERATION_GET_ALL: 'CASH_OPERATION_GET_ALL',
    CASH_OPERATION_GET: 'CASH_OPERATION_GET',
    CASH_OPERATION_CREATE: 'CASH_OPERATION_CREATE',
    CASH_OPERATION_UPDATE: 'CASH_OPERATION_UPDATE',
    CASH_OPERATION_DELETE: 'CASH_OPERATION_DELETE',

    FISCAL_DOCUMENTS_GET_ALL: 'FISCAL_DOCUMENTS_GET_ALL',
    FISCAL_DOCUMENTS_GET: 'FISCAL_DOCUMENTS_GET',
    FISCAL_DOCUMENTS_CREATE: 'FISCAL_DOCUMENTS_CREATE',
    FISCAL_DOCUMENTS_UPDATE: 'FISCAL_DOCUMENTS_UPDATE',
    FISCAL_DOCUMENTS_DELETE: 'FISCAL_DOCUMENTS_DELETE',

    FISCAL_PRINTER_RECEIPT: 'FISCAL_PRINTER_RECEIPT',
    FISCAL_PRINTER_INVOICE: 'FISCAL_PRINTER_INVOICE',
    FISCAL_PRINTER_REPORT: 'FISCAL_PRINTER_REPORT',
    FISCAL_PRINTER_HEALTHCHECK: 'FISCAL_PRINTER_HEALTHCHECK',

    FISCAL_PRINTER_CREATE: 'FISCAL_PRINTER_CREATE',
    FISCAL_PRINTER_GET_ALL: 'FISCAL_PRINTER_GET_ALL',
    FISCAL_PRINTER_GET: 'FISCAL_PRINTER_GET',
    FISCAL_PRINTER_DELETE: 'FISCAL_PRINTER_DELETE',
    FISCAL_PRINTER_UPDATE: 'FISCAL_PRINTER_UPDATE',

    PRODUCTS_CART_GET_ALL: 'PRODUCTS_CART_GET_ALL',
    PRODUCTS_CART_GET: 'PRODUCTS_CART_GET',
    PRODUCTS_CART_CREATE: 'PRODUCTS_CART_CREATE',
    PRODUCTS_CART_UPDATE: 'PRODUCTS_CART_UPDATE',
    PRODUCTS_CART_DELETE: 'PRODUCTS_CART_DELETE',

    TRANSACTION_GET_ALL: 'TRANSACTION_GET_ALL',
    TRANSACTION_GET: 'TRANSACTION_GET',
    TRANSACTION_CREATE: 'TRANSACTION_CREATE',
    TRANSACTION_UPDATE: 'TRANSACTION_UPDATE',
    TRANSACTION_DELETE: 'TRANSACTION_DELETE',

    TRANSACTION_PAYMENT_GET_ALL: 'TRANSACTION_PAYMENT_GET_ALL',
    TRANSACTION_PAYMENT_GET: 'TRANSACTION_PAYMENT_GET',
    TRANSACTION_PAYMENT_CREATE: 'TRANSACTION_PAYMENT_CREATE',
    TRANSACTION_PAYMENT_UPDATE: 'TRANSACTION_PAYMENT_UPDATE',
    TRANSACTION_PAYMENT_DELETE: 'TRANSACTION_PAYMENT_DELETE',

    MISC_P1EDEKL_ACTION: 'MISC_P1EDEKL_ACTION',
    MISC_P1EDEKL_GET_RESULT: 'MISC_P1EDEKL_GET_RESULT',
    MISC_P1ZM_ACTION: 'MISC_P1ZM_ACTION',
    MISC_P1ZM_GET_RESULT: 'MISC_P1ZM_GET_RESULT',
    MISC_WORKERCREDS_GET: 'MISC_WORKERCREDS_GET',
    MISC_P1ZLK_ACTION: 'MISC_P1ZLK_ACTION',
    MISC_P1ZLK_GET_RESULT: 'MISC_P1ZLK_GET_RESULT',
    MISC_P1EREJESTRACJA_CLOSE_VISIT: 'MISC_P1EREJESTRACJA_CLOSE_VISIT',

    //APKOLCE
    APKOLCE_WORKERCREDS_CREATE: 'APKOLCE_WORKERCREDS_CREATE',
    APKOLCE_WORKERCREDS_UPDATE: 'APKOLCE_WORKERCREDS_UPDATE',
    APKOLCE_WORKERCREDS_GET: 'APKOLCE_WORKERCREDS_GET',
    APKOLCE_WORKERCREDS_ADD_CONFIGURATION: 'APKOLCE_WORKERCREDS_ADD_CONFIGURATION',
    APKOLCE_WORKERCREDS_UPDATE_CONFIGURATION: 'APKOLCE_WORKERCREDS_UPDATE_CONFIGURATION',
    APKOLCE_WORKERCREDS_GET_BY_WORKER: 'APKOLCE_WORKERCREDS_GET_BY_WORKER',
    APKOLCE_WORKERCREDS_UPDATE_PASSWORD: 'APKOLCE_WORKERCREDS_UPDATE_PASSWORD',
    APKOLCE_WORKERCREDS_UPDATE_CONF_PASSWORD: 'APKOLCE_WORKERCREDS_UPDATE_CONF_PASSWORD',
    APKOLCE_ACTION_CHECK_PASSWORD: 'APKOLCE_ACTION_CHECK_PASSWORD',
    APKOLCE_ACTION_GET_RESULT: 'APKOLCE_ACTION_GET_RESULT',
    APKOLCE_WORKERCREDS_CHECK_WORKER_CONFIG: 'APKOLCE_WORKERCREDS_CHECK_WORKER_CONFIG',

    //
    VERIFIED_FILE_UPLOAD_GET: 'VERIFIED_FILE_UPLOAD_GET',
    VERIFY_FILE_SET_VERIFIED: 'VERIFY_FILE_SET_VERIFIED',

}