export default {
    data: function () {
        return {
            /**Rodzaje skierowań
             * Refferals type
             */
            mReferralsType: [
                {
                    name: "Skierowanie wewnętrzne (papierowe)",
                    id: 0,
                    isPaperReferral: true,
                    isInternal: true,
                    isDoctorRequired: true,
                    //isShowContractor: true,
                    isContractorAllowed: true,
                    isSimpAllowed: false,
                    isHighCostAllowed: true,
                    isSimpRequired: false,
                    payers: [0, 1],
                    defaultPayer: 0
                },
                {
                    name: "E-skierowanie",
                    id: 1,
                    isPaperReferral: false,
                    isInternal: false,
                    isDoctorRequired: true,
                    IsContractorAllowed: true,
                    isSimpAllowed: false,
                    isHighCostAllowed: true,
                    isSimpRequired: false,
                    payers: [0, 1],
                    defaultPayer: 0
                },
                {
                    name: "Skierowanie zewnętrzne (papierowe)",
                    id: 2,
                    isPaperReferral: true,
                    isInternal: false,
                    isDoctorRequired: true,
                    IsContractorAllowed: true,
                    isSimpAllowed: false,
                    isHighCostAllowed: true,
                    isSimpRequired: false,
                    payers: [0, 1],
                    defaultPayer: 0
                },
                {
                    name: "Skierowanie z programu przesiewowego",
                    id: 3,
                    isPaperReferral: true,
                    isInternal: false,
                    isDoctorRequired: false,
                    IsContractorAllowed: false,
                    isSimpAllowed: false,
                    isHighCostAllowed: false,
                    isSimpRequired: false,
                    payers: [2],
                    defaultPayer: 2
                },
                // {
                //     name: "Skierowanie z programu przesiewowego (NDTK)",
                //     id: 3,
                //     isPaperReferral: true,
                //     isInternal: false,
                //     isDoctorRequired: false,
                //     IsContractorAllowed: false,
                //     isSimpAllowed:false,
                //     isHighCostAllowed:false,
                //     isSimpRequired:false,
                //     payers:[2],
                //     defaultPayer:2
                // }
                // ,
                // {
                //     name: "Skierowanie z programu przesiewowego (SIMP)",
                //     id: 3,
                //     isPaperReferral: true,
                //     isInternal: false,
                //     isDoctorRequired: false,
                //     IsContractorAllowed: false,
                //     isSimpAllowed:false,
                //     isHighCostAllowed:true,
                //     isSimpRequired:true,
                //     payers:[2],
                //     defaultPayer:2
                // }
            ],

            mReferralFormsTypes: [
                {
                    id: 0,
                    isSelectable: true,
                    isEReferral: true,
                    Code: "02.11",
                    Description: "Skierowanie do poradni specjalistycznej",
                },
                {
                    id: 1,
                    isSelectable: true,
                    isEReferral: true,
                    Code: "02.10",
                    Description: "Skierowanie do szpitala",
                },
                {
                    id: 2,
                    isSelectable: true,
                    isEReferral: true,
                    Code: "02.10",
                    Description: "Skierowanie na badania",
                },
                {
                    id: 3,
                    isSelectable: false,
                    isEReferral: true,                    
                    Code: "02.10",
                    Description: "Skierowanie do laboratorium",
                },
                {
                    id: 4,
                    isSelectable: true,
                    isEReferral: true,
                    Code: "02.10",
                    Description: "Skierowanie do hospicjum",
                },
                {
                    id: 5,
                    isSelectable: true,
                    isEReferral: false,
                    Code: "02.10",
                    Description: "Skierowanie do szpitala psychiatrycznego",
                },
                {
                    id: 6,
                    isSelectable: true,
                    isEReferral: false,
                    Code: "02.10",
                    Description: "Skierowanie na leczenie uzdrowiskowe",
                },
                {
                    id: 7,
                    isSelectable: true,
                    isEReferral: false,
                    Code: "02.10",
                    Description: "Skierowanie na leczenie uzdrowiskowe (AZBEST)",
                },
                {
                    id: 8,
                    isSelectable: true,
                    isEReferral: false,
                    Code: "02.10",
                    Description: "Skierowanie na rehabilitację leczniczą (KRUS)",
                },
                {
                    id: 9,
                    isSelectable: true,
                    isEReferral: false,
                    Code: "02.12",
                    Description: "Skierowanie do ZOL",
                },
                // {
                //     id: 10,
                //     isSelectable: false,
                //     isEReferral: false,
                //     Code: "02.10",
                //     Description: "Skierowanie na zabiegi fizjoterapeutyczne",
                // },
                {
                    id: 11,
                    isSelectable: false,
                    isEReferral: false,
                    Code: "02.12",
                    Description: "Skierowanie na objęcie pielęgniarską opieką długoterminową",
                },
                {
                    id: 12,
                    isSelectable: false,
                    isEReferral: true,
                    Code: "02.10",
                    Description: "Skierowanie na szczepienie (COVID-19)",
                },
                {
                    id: 13,
                    isSelectable: true,
                    isEReferral: true,
                    Code: "02.10",
                    Description: "Skierowanie na rehabilitację",
                },
                {
                    id: 14,
                    isSelectable: false,
                    isEReferral: false,
                    canNurse: true,
                    Code: "02.12",
                    Description: "Zgłoszenie do objęcia pielęgniarską opieką długoterminową",
                }
            ],
        }
    },

    methods: {
        mGetReferralType(id) {
            let type = this.mReferralsType.find(t => t.id == id)
            if (type) return type
            else return null
        },

        mGetReferralTypeName(id) {
            let type = this.mReferralsType.find(t => t.id == id)
            if (type) return type.name
            else return `Nie znaleziono [${id}]`
        },

        mGetReferralFormsType(id) {
            let type = this.mReferralFormsTypes.find(t => t.id == id)
            if (type) return type
            else return null
        },

        mGetReferralFormsTypeName(id) {
            let type = this.mReferralFormsTypes.find(t => t.id == id)
            if (type) return type.Description
            else return id
        },
    }
}