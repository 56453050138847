import axios from 'axios'
import { Apis } from '../config_apis'
import { Action } from '../config_actions'
import { Mutation } from '../config_mutations'
import moment from 'moment'

export default {
    namespaced: false,

    state: {
        employees: [],
        assistants: [],
        selectedPatient: null,
        patients: null,
        appointments: null,
        tempApp: [],
        app: null,
        appointmentHistory: [],
        appointmentDiagnoses: [],
        appointmentAllergies: [],
        ongoingAppointment: null,
        currentRis: null,
        workingList: [],
        devices: [],
        trigger: 0,
        triggerADD: 0,
        appointmentsRIS: [],
        editingAppointment: false,
        selectedDate: null,
        ticketsRis: [],
        supervisorTK: null,
        selectedUnit: null,
        selectedOffice: null,

        ikz: [],
    },

    mutations: {
        [Mutation.RIS_DATA_FLUSH](currentState) {
            currentState.appointments = null
            currentState.workingList.length = 0// = []
            currentState.appointmentsRIS.length = 0// = []
            currentState.tempApp.length = 0// = []
            currentState.ticketsRis.length = 0// = []
        },

        [Mutation.RIS_ADD_EMPLOYEE](currentState, payload) {
            let index = currentState.employees.indexOf(payload)

            if (index < 0) {
                currentState.employees.push(payload)
            }
        },

        [Mutation.RIS_REMOVE_EMPLOYEE](currentState, payload) {
            let index = currentState.employees.indexOf(payload)

            if (index > -1) {
                currentState.employees.splice(index, 1)
            }
        },

        [Mutation.RIS_SET_SELECTED](currentState, payload) {
            currentState.selectedPatient = payload
        },

        [Mutation.RIS_UPDATE_STATUS](currentState, payload) {
            currentState.selectedPatient.status = payload
        },

        [Mutation.RIS_SET_UNSELECTED](currentState) {
            currentState.selectedPatient = null
        },

        [Mutation.RIS_RESET](currentState) {
            currentState.employees.length = 0// = []
        },

        [Mutation.RIS_SET_RISAPPOINTMENTS](currentState, appointments) {
            currentState.appointmentsRIS = appointments
        },

        [Mutation.RIS_RESET_RISAPPOINTMENTS](currentState) {
            currentState.appointmentsRIS.length = 0// = []
        },

        [Mutation.RIS_PUSH_RISAPPOINTMENT](currentState, appointment) {
            let found = currentState.appointmentsRIS.find((app) => app.id == appointment.id)

            if (found) {
                let index = currentState.appointmentsRIS.indexOf(found)
                currentState.appointmentsRIS[index] = appointment
            } else {
                currentState.appointmentsRIS.push(appointment)
            }

        },

        [Mutation.RIS_PUSH_TICKET_TO_STORE](currentState, ticket) {
            let found = currentState.ticketsRis.find((tick) => tick.id == ticket.id)

            if (found) {
                let index = currentState.ticketsRis.indexOf(found)
                currentState.ticketsRis.splice(index, 1, ticket)
            } else {
                currentState.ticketsRis.push(ticket)
            }
        },

        [Mutation.RIS_RESET_TICKET_TO_STORE](currentState) {
            currentState.ticketsRis.length = 0
        },

        [Mutation.RIS_SET_ALL](currentState, appointments) {
            currentState.appointments = appointments
        },

        [Mutation.RIS_SET_PATIENTS](currentState, patients) {
            currentState.patients = patients;
        },

        [Mutation.RIS_SET_DEVICES](currentState, devices) {
            currentState.devices = devices;
        },

        [Mutation.RIS_SET_SELECTED_DATE](currentState, datetime) {
            currentState.selectedDate = datetime;
        },

        [Mutation.RIS_APPOINTMENT_PUSH](currentState, appointment) {
            if (!currentState.appointments) {
                currentState.appointments.length = 0// = [];
            }
            if (!currentState.appointments.includes(appointment)) {
                currentState.appointments.push(appointment)
            }
        },

        [Mutation.RIS_APPOINTMENT_TEMP_ADD](currentState, app) {
            if (!currentState.tempApp.includes(app)) {
                currentState.tempApp.push(app)
            }
        },

        [Mutation.RIS_SET_FOCUS_APPOINTMENT](currentState, payload) {
            //currentState.app = app;
            if (currentState.patients.length > 0) {
                let patient = currentState.patients.find(p => p.id === payload.patientId)

                if (patient) {
                    payload.patient = patient
                    currentState.app = payload
                }
            }
        },

        [Mutation.RIS_SET_FOCUS_APPOINTMENT_AFTER_FILES_ADD](currentState, payload) {
            //currentState.app = app;
            let newObj = JSON.parse(JSON.stringify(currentState.currentRis))

            newObj.version = payload.version
            newObj.pdfFilePath = payload.pdfFilePath
            newObj.pdfFileDescription = payload.pdfFileDescription
            newObj.pdfFile2Path = payload.pdfFile2Path
            newObj.pdfFile2Description = payload.pdfFile2Description
            newObj.edmHistory = payload.edmHistory
            newObj.secondEdmHistory = payload.secondEdmHistory

            if (currentState.patients.length > 0) {
                let patient = currentState.patients.find(p => p.id === newObj.patientId)

                if (patient) {
                    payload.patient = patient
                    currentState.app = newObj
                }
            }
        },

        [Mutation.RIS_SET_OBJECT_RIS](currentState, object) {
            currentState.currentRis = object
        },

        [Mutation.RIS_SET_OBJECT_RIS_AFTER_FILES_ADD](currentState, payload) {

            let newObj = JSON.parse(JSON.stringify(currentState.currentRis))

            newObj.version = payload.version
            newObj.pdfFilePath = payload.pdfFilePath
            newObj.pdfFileDescription = payload.pdfFileDescription
            newObj.pdfFile2Path = payload.pdfFile2Path
            newObj.pdfFile2Description = payload.pdfFile2Description
            newObj.edmHistory = payload.edmHistory
            newObj.secondEdmHistory = payload.secondEdmHistory

            currentState.currentRis = newObj
        },

        [Mutation.RIS_SET_APPOINTMENT_INITIAL_DATA](state, payload) {
            if (payload) {

                if (payload.oldAppointments) {
                    state.appointmentHistory = payload.oldAppointments
                }
                else {
                    state.appointmentHistory = []
                }

                if (payload.diagnoses) {
                    state.appointmentDiagnoses = payload.diagnoses
                }
                else {
                    state.appointmentDiagnoses = { diagnoses: [] }
                }

                if (payload.allergies) {
                    state.appointmentAllergies = payload.allergies
                }
                else {
                    state.appointmentAllergies = {
                        id: "tmp",
                        patientId: state.app.patient.id,
                        //patientId: state.focusAppointment.patient.id,
                        changeDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
                        items: []
                    }
                }
            }
        },

        [Mutation.RIS_SET_ONGOING_APPOINTMENT](state, payload) {
            state.ongoingAppointment = payload
        },

        [Mutation.RIS_TRIGGER_UP](state) {
            state.trigger++
        },

        [Mutation.RIS_TRIGGER_ADD_UP](state) {
            state.triggerADD++
        },

        [Mutation.RIS_SET_IS_EDITING_APP](state, payload) {
            state.editingAppointment = payload
        },


        [Mutation.RIS_ADD_ASSISTANT](currentState, payload) {
            let index = currentState.assistants.indexOf(payload)

            if (index < 0) {
                currentState.assistants.push(payload)
            }
        },

        [Mutation.RIS_REMOVE_ASSISTANT](currentState, payload) {
            let index = currentState.assistants.indexOf(payload)

            if (index > -1) {
                currentState.assistants.splice(index, 1)
            }
        },

        [Mutation.RIS_SET_SUPERVISOR_TK](currentState, payload) {
            currentState.supervisorTK = payload
        },

        [Mutation.RIS_RESET_SUPERVISOR_TK](currentState) {
            currentState.supervisorTK = null
        },

        [Mutation.RIS_SET_OFFICE](currentState, payload) {
            currentState.selectedOffice = payload
        },

        [Mutation.RIS_SET_UNIT](currentState, payload) {
            currentState.selectedUnit = payload
        },

        [Mutation.RIS_RESET_UNIT](currentState) {
            currentState.selectedUnit = null
        },

        [Mutation.RIS_SET_IKZ](currentState, payload) {
            currentState.ikz = payload
        }
    },

    actions: {
















        //get all
        [Action.RIS_APPOINTMENT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.RIS_SET_ALL, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get by id
        [Action.RIS_APPOINTMENT_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENT_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_CREATE](context, appointment) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_CREATE,
                        data: appointment,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_CREATE_WITHOUT_PACKS](context, appointment) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_CREATE_WITHOUT_PACKS,
                        data: appointment,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_CREATE_V2](context, appointment) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_CREATE_V2,
                        data: appointment,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_PUSH, response.data)
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_APPOINTMENT_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        //context.commit(Mutation.FINANCE_CONTRACTS_SET_UPDATE,response.data)
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RIS_APPOINTMENT_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_GET_APPOINTMENTS_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENTS_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_GET_LIGHT_APPOINTMENTS_BY_PATIENT](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENTS_GET_LIGHT_BY_PATIENT.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENTS_AVAILABLE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENTS_AVAILABLE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENTS_AVAILABLE_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_AVAILABLE_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //RIS_APPOINTMENTS_FOR_DEVICE
        [Action.RIS_APPOINTMENTS_FOR_DEVICE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_FOR_DEVICE,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_GET_PACKS_STATUS](context, idPacks) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENT_GET_PACKS_STATUS.replace('{id}', idPacks),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENTS_DELETE_PACKSAPPOINTMENT](context, app) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_DELETE_PACKSAPPOINTMENT,
                        data: app,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENTS_UPDATE_PACKSAPPOINTMENT](context, app) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_UPDATE_PACKSAPPOINTMENT,
                        data: app,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get risAppointment by array of appointment.id
        [Action.RIS_APPOINTMENTS_GET_BY_SCHEDULEAPPOINTMENT_ID](context, idAppointments) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_GET_BY_SCHEDULEAPPOINTMENT_ID,
                        data: idAppointments,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_GENERATE_XML](context, risApp) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_GENERATE_XML,
                        data: risApp,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_SEND_TO_R2](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_SEND_TO_R2.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_ADD_STATUS](context, object) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_ADD_STATUS.replace('{id}', object.id),
                        data: object.data,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_BEGIN_APPOINTMENT](context) {
            return new Promise((resolve, reject) => {
                if (context.state.app) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    let s = context.state.app
                    // let worker = s.appointment.subjectId

                    // if (s.subjectId < 0) {
                    //     let me = context.rootState.employee.me;
                    //     worker = me.id
                    // }

                    if (token != null) {
                        axios({
                            method: 'get',
                            // url: Apis.POZ_START_APPOINTMENT.replace('{patientId}', s.appointment.patientId).replace('{workerId}', worker).replace('{scheduleId}', s.appointment.externalId),
                            url: Apis.PD_START_APPOINTMENT.replace('{patientId}', s.appointment.patientId),
                            headers: { Authorization: "Bearer " + token }
                        }).then((response) => {
                            context.commit(Mutation.RIS_SET_ONGOING_APPOINTMENT, context.state.app)
                            context.commit(Mutation.RIS_SET_APPOINTMENT_INITIAL_DATA, response.data)

                            // let scheduleUpdate = {
                            //     id: s.id,
                            //     data: {
                            //         workerId: worker,
                            //         status: 1,
                            //         assistants: context.state.assistants.map(e => e.id)
                            //     }
                            // }

                            // context.dispatch(Action.S2_POST_STATUS, scheduleUpdate)
                            resolve()
                        }).catch(error => { reject(error) })
                    } else reject('401')
                }
                else reject('no focus!!!')
            })
        },

        [Action.RIS_BEGIN_APPOINTMENT_WORKSHOP](context, objectAppointment) {
            return new Promise((resolve, reject) => {
                if (objectAppointment.appointment) {
                    let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                    let s = objectAppointment.appointment

                    if (token != null) {
                        axios({
                            method: 'get',
                            // url: Apis.POZ_START_APPOINTMENT.replace('{patientId}', s.patientId).replace('{workerId}', objectAppointment.workerId).replace('{scheduleId}', s.externalId),
                            url: Apis.PD_START_APPOINTMENT.replace('{patientId}', s.patientId),
                            headers: { Authorization: "Bearer " + token }
                        }).then(() => {
                            //context.commit(Mutation.RIS_SET_ONGOING_APPOINTMENT, context.state.app)
                            //context.commit(Mutation.RIS_SET_APPOINTMENT_INITIAL_DATA, response.data)

                            // let scheduleUpdate = {
                            //     id: s.id,
                            //     data: {
                            //         workerId: objectAppointment.workerId,
                            //         status: 1,
                            //         assistants: []
                            //     }
                            // }

                            // context.dispatch(Action.S2_POST_STATUS, scheduleUpdate)
                            resolve()
                        }).catch(error => { reject(error) })
                    } else reject('401')
                }
                else reject('no focus!!!')
            })
        },

        [Action.RIS_EXAMDESCRIPTIONACCESS_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_EXAMDESCRIPTIONACCESS_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        //get by id
        [Action.RIS_EXAMDESCRIPTIONACCESS_GET_BY_ID](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_EXAMDESCRIPTIONACCESS_GET_BY_ID.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_EXAMDESCRIPTIONACCESS_CREATE](context, appointment) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_EXAMDESCRIPTIONACCESS_CREATE,
                        data: appointment,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_EXAMDESCRIPTIONACCESS_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_EXAMDESCRIPTIONACCESS_UPDATE.replace('{id}', payload.id),
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_EXAMDESCRIPTIONACCESS_DELETE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'delete',
                        url: Apis.RIS_EXAMDESCRIPTIONACCESS_DELETE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_DOWNLOAD_RESULT](context, risId) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.RIS_APPOINTMENT_DOWNLOAD_RESULT.replace('{id}', risId),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_UPLOAD_EXTERNALRESULT](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session ? context.rootState.identity.session.token : null;
                if (token != null) {
                    const payload = new FormData();

                    payload.append('appointmentId', form.appointmentId);
                    payload.append('file', form.file);
                    payload.append('fileDescription', form.fileDescription);
                    payload.append('file2', form.file2);
                    payload.append('file2Description', form.file2Description);

                    if (form.isUsedTwain) {
                        payload.append('isUsedTwain', form.isUsedTwain)
                    }

                    if (form.isUsedTwain2) {
                        payload.append('isUsedTwain2', form.isUsedTwain2)
                    }

                    payload.append('externalResult.workerId', form.externalResult ? form.externalResult.workerId : -1);
                    payload.append('externalResult.firstName', form.externalResult ? form.externalResult.firstName : null);
                    payload.append('externalResult.lastName', form.externalResult ? form.externalResult.lastName : null);
                    payload.append('externalResult.pwz', form.externalResult ? form.externalResult.pwz : null);
                    payload.append('externalResult.externalNumber', form.externalResult ? form.externalResult.externalNumber : null);
                    payload.append('externalResult.subjectId', form.externalResult ? form.externalResult.subjectId : null);
                    payload.append('externalResult.subjectName', form.externalResult ? form.externalResult.subjectName : null);
                    payload.append('externalResult.signed', form.externalResult ? form.externalResult.signed : "0001-01-01T12:00:00.000Z");
                    payload.append('skipEvent', form.skipEvent ? form.skipEvent : false);

                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_UPLOAD_EXTERNALRESULT,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },

        //get by id
        [Action.RIS_BURN_RESULT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_BURN_RESULT.replace('{site}', payload.site).replace('{number}', payload.number),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_ADD_FILES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_ADD_FILES,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(error => { reject(error) });
                } else reject('401')
            })
        },


        [Action.RIS_APPOINTMENT_FILTER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_FILTER,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_FILTER_LIGHT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_FILTER_LIGHT,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_EMPLOYEE_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_EMPLOYEE_STATS,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        [Action.RIS_APPOINTMENT_CORRECT](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_CORRECT,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_ADD_EDMHISTORY](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_APPOINTMENT_ADD_EDMHISTORY.replace('{id}', payload.id).replace('{edmNumber}', payload.edmNumber),
                        headers: { Authorization: "Bearer " + token },
                        data: payload.edmHeader
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_EXAMINATOR_COMMENT_UPDATE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_APPOINTMENT_EXAMINATOR_COMMENT_UPDATE.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload.examinatorComment
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_CHANGE_DESCRIPTION_BY_REFERRER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'put',
                        url: Apis.RIS_APPOINTMENT_CHANGE_DESCRIPTION_BY_REFERRER.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_GET_BLOCKED](context, domain) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.RIS_APPOINTMENT_GET_BLOCKED.replace('{domain}', domain),
                        headers: { Authorization: "Bearer " + token }                        
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_BLOCK](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_BLOCK.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
        
        [Action.RIS_APPOINTMENT_BLOCK_REMOVE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_BLOCK_REMOVE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_SET_RESULT_IN_OTHER_SYSTEM](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_SET_RESULT_IN_OTHER_SYSTEM.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_SET_REFERRAL_FILE_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_SET_REFERRAL_FILE_ID.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_SET_DESCRIPTION_DECISION](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_SET_DESCRIPTION_DECISION.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_CHANGE_DESCRIPTION_INTENDED](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENTS_CHANGE_DESCRIPTION_INTENDED.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_CANCEL_SIMPLE](context, id) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_CANCEL_SIMPLE.replace('{id}', id),
                        headers: { Authorization: "Bearer " + token },
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_APPOINTMENT_UPDATE_SIMPLE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_APPOINTMENT_UPDATE_SIMPLE.replace('{id}', payload.id),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
    
        [Action.RIS_GET_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_GET_STATS,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.RIS_GET_ACK_STATS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.identity.session.token;
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.RIS_GET_ACK_STATS,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }
    }
}