<template>
    <div>
        <b-button icon-left="scanner" label="Skanuj dokument" @click="isScanComponentModalActive = true" expanded />


        <b-modal :active="true" v-model="isScanComponentModalActive" has-modal-card trap-focus
            :render-on-mounted="false" :destroy-on-hide="true" aria-role="dialog" close-button-aria-label="Close"
            aria-modal :can-cancel="false">
            <ScannerModal :onlyPdfAllowed="onlyPdfAllowed" v-on:scanFile="addImage($event)" v-on:close="closeModal()">
            </ScannerModal>
        </b-modal>
        <div>

        </div>
    </div>
</template>

<script>
import CommonHelpers from '@/mixins/commons'
import ScannerModal from './ScannerModal.vue'

export default {
    name: 'ScannerButtonSmall',
    mixins: [CommonHelpers],
    components: {
        ScannerModal,
    },

    props: {
        onlyPdfAllowed: { type: Boolean, required: true, },
    },

    data: function () {
        return {
            scannedImage: null,
            imagesId: 0,
            testTrue: true,
            isScanComponentModalActive: false,
            scanType: '',
            scanName: '',
            selectedDevice: '',
            avilableDevices: [],
            containerId: "dwtControlContainer",
            sourceList: [],
            selectedIndex: 0,
            columns: []

        }
    },
    mounted() {
    },
    watch: {
    },

    computed: {
        me() { return this.$store.state.employee.me }
    },

    methods: {
        closeModal() {
            this.isScanComponentModalActive = false
        },
        addImage(imageObject) {
            this.$emit('file', imageObject)
            this.isScanComponentModalActive = false
        }

    },


}
</script>

<style scoped lang="scss">
.b-skeleton {
    height: 100%;
}
</style>